import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";

const DatatableGrid = ({rows = [], columns = []}) => {
  useEffect(()=>{
    console.log("lksdlaksl")
  },[])

  return ( <div style={{ width: '100%' }}>
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      pageSizeOptions={[5, 10, 15]}
      checkboxSelection
    />
  </div>);
}

export default DatatableGrid
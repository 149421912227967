import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, InputAdornment, Modal, TableCell, TextField, Typography, styled } from "@mui/material";
import Tabla from "./Tabla"
import inventarioContext from "../../../context/inventario/inventarioContext";
import React, {useContext, useEffect, useRef, useState} from "react";
import { AddToPhotos, ExpandMore, MonetizationOn, Search } from "@mui/icons-material";
import Variantes from "../Variantes";
import urlEmptyImage from '../../../img/upload.jpg'
import { ProductosContext, RutasContext } from "../../../context";
import Detalle from "./Detalle"
import MenuComponente from '../../elements/MenuComponente'
import Productos_Masivos from "../Productos_masivos";
import { ButtonGroup } from "react-bootstrap";
import { BotonJCs, EstadoJC } from "../../material";
import {read, utils } from 'xlsx';
import Icon from "../../layout/Material/Icons";
import { CheckOne } from "../../../elementos";
import { transferKeysImp } from "../../functions";
import AuthContext from "../../../context/authentication/authContext";

function createData(
    id,
    s_title,
    n_variantes,
    s_variant_sku,
    n_variant_price,
    s_product_category,
    s_vendor,
    // i_mercados,
    // i_canales_venta,
    // i_tiendas,
    s_value,
    search,
    status
) {
    return {
      id,
      s_title,
      n_variantes,
      s_variant_sku,
      n_variant_price,
      s_product_category, 
      s_vendor,
    //   i_mercados,
    //   i_canales_venta,
    //   i_tiendas,
      s_value,
      search,
      status
    };
  }


  const useContainerDimensions = myRef => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  
    useEffect(() => {
      const getDimensions = () => ({
        width:  myRef?.current?.offsetWidth ? myRef.current.offsetWidth: 0,
        height: myRef?.current?.offsetHeight ? myRef.current.offsetHeight: 0
      })
  
      const handleResize = () => {
        setDimensions(getDimensions())
      }
  
      if (myRef.current) {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])
  
    return dimensions;
};

const Productos = ({reenviar}) => {
    const [dataProductos, setDataProductos] = useState([])
    // const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataTable, setDataTable] = useState([])
    const [filterTable, setFilterTable] = useState([])
    const [array_edicion, setArray_edicion] = useState([])
    const [form, setForm] = useState(false)
    // const [open, setOpen] = useState(false)
    const [b_importat, setB_importar] = useState(false)
    const [b_exportar, setB_exportar] = useState(false)
    const [seleccion, setSeleccion] = useState(false)
    let [verDetalle, setVerDetalle] = useState(false)
    let [verEdicionMasiva, setVerEdicionMasiva] = useState(false)

    const authContext = useContext(AuthContext);
    const { obtener_negocios } = authContext;

    // const rutasContext = useContext(RutasContext);
    // const { getRoute } = rutasContext;

    const InventarioContext = useContext(inventarioContext);
    const { modalView, abrirModal, guardarProducto} = InventarioContext;

    const productosContext = useContext(ProductosContext);
    const { productos, obtenerProductos, guardar_todos_productos, n_variantes, modificar_productos } = productosContext;

    useEffect(()=>{
        setFilterTable(dataTable)
    },[dataTable])

    useEffect(()=>{
        obtenerProductos()
        let id_negocio = localStorage.getItem('id_route');
        const transform_business = async () => {
            let business = await obtener_negocios(id_negocio)
            let urlPath = `/business/${business}/channels/products`
            window.history.pushState(`${business}`, 'Title', urlPath);
        }

        transform_business();
    },[])

    useEffect(()=>{
        let news_datatable = dataProductos.map((producto, id_producto) => {
            let total_variantes = producto?.a_grupo_variantes ? producto?.a_grupo_variantes: 0, n_suma = 0;

            if(total_variantes?.length > 0){
                let arr_total_variantes = total_variantes.map(item_variante => {
                    return item_variante?.valores?.length ? item_variante.valores.length:0
                })

                n_suma = arr_total_variantes.reduce((a, b) => a * b);
            }
            
            return createData(
                id_producto, 
                (<p style={{
                    fontFamily: "'semibold'",
                    fontSize: "12px"
                }} onClick={e=>{
                    setVerDetalle(true)
                    setSeleccion(producto)
                    console.log(producto)
                }}>{producto.s_title}</p>),
                producto.b_variantes ? n_suma: 0,
                producto?.s_variant_sku ? producto.s_variant_sku: "-",
                `$ ${parseFloat(producto?.n_variant_price ? producto.n_variant_price: 0).toFixed(2)}`,
                producto.s_product_category,
                producto.s_vendor,
                // producto.i_mercados,
                // producto.i_canales_venta,
                // producto.i_tiendas,
                (<EstadoJC
                    estado={producto.i_status}
                    texto={producto?.i_status ?
                        producto.i_status == 1 ? "Borrador":
                        producto.i_status == 2 ? "Activo": "Pendiente":
                    null}/>),
                producto.s_title,
                producto.i_status
            )
        })

        setLoading(false)
        setDataTable(news_datatable)
    },[dataProductos])

    useEffect(()=>{
        setDataProductos(productos)
    },[productos])

    const back_to_menu = () =>{
        setSeleccion(false)
        setVerDetalle(false)
    }

    const handleClose = () => abrirModal(false);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const ImagenNotFound = () => {
        return(<Box sx={{
            height: "32px",
            width: "32px",
            border: "1px solid #EBEBEB",
            display: "grid",
            justifyContent: "center",
            borderRadius: "3px",
            alignContent: "center"
        }}>
            <AddToPhotos/>
        </Box>)
    }

    const Options = ({options, name, id}) => {
        return(<TextField
          select
          label={name}
          onChange={e => {
            setForm({
            ...form,
            [id]: e.target.value
          })}} 
          value={form[`${id}`]} 
          id={`${id}`}
          sx={{
            "& svg, div":{ color: "black !important"},
            "& fieldset":{ borderRadius: "12px"},
            color: "black !important",
            width: "100% !important"
          }}
          SelectProps={{
            native: true,
          }}
          focused
          size='small'
        >
          <option value="" selected disabled hidden>Elegir...</option>
          {/* <option style={{color: "#bdbdbd !important"}}>Elegir item</option> */}
          {options.map((option, i) => (
          <option key={i} value={option._id} style={{color: "black !important"}}>
            {option.s_title}
          </option>
        ))}</TextField>)
    }

    const AreaButton = ({setB_importar}) => {
        return(<Box sx={{
            display: "grid",
            alignContent: "start",
            justifyContent: "end"
        }}>
            <Box sx={{display: "flex"}}>
                <BotonJCs onClick={e=>setB_exportar(true)}>Exportar</BotonJCs>
                <BotonJCs sx={{marginLeft: "8px"}} onClick={e=>setB_importar(true)}>Importar</BotonJCs>
                <BotonJCs sx={{
                    marginLeft: "8px",
                    bgcolor: "#3F3F3F !important",
                    height: "fit-content"
                }} onClick={() => {
                    setVerDetalle(true)
                }}>Agregar Producto</BotonJCs>
            </Box>
        </Box>)
    }

    const MenuSeccion = () => {
        return(
            <Box className='area-modulo' sx={{borderRadius: "5px"}}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography id="titulo-modulo" sx={[{
                            paddingBottom: '0px',
                            textAlign: 'start',
                            fontWeight: "bold",
                            color: "black !important"
                        }]} variant='h6'>Productos</Typography>
                        <Box sx={{"& p":{color: "#626262 !important", fontSize: "12px", margin: "0px !important"}}}>
                            <Box>
                                <Typography>Crea y administra los productos físicos, digitales, o servicios de tu negocio.</Typography> 
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{
                        textAlign: "end",
                        "& #btn-agregar":{
                            margin: "10px !important", padding: '5px 20px !important'
                        }
                    }}>
                        <AreaButton setB_importar={setB_importar} nombre_modulo="Productos"/>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const estatus = [
        {_id: 1, s_title: "Borrador"},
        {_id: 2, s_title: "Activo"}
      ]

    
    const ref = useRef(null), { height } = useContainerDimensions(ref)

    return(<Box>
        <Importar
            setB_importar={setB_importar}
            b_importat={b_importat}
            setDataProductos={setDataProductos}
            guardar_todos_productos={guardar_todos_productos}
        />
        <Exportar setB_exportar={setB_exportar} b_exportar={b_exportar}/>
        <Modal
            open={modalView}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleModal}>
                <Grid container sx={{
                    background: "#f1f1f1 !important",
                    p: 2,
                    borderRadius: "8px 8px 0px 0px",
                    boxShadow: "1px 1px 8px -5px black"
                }}>
                    <Grid item xs={8}>
                        <Typography sx={{color: "black !important"}} id="modal-modal-title" variant="h6" component="h2">Producto nuevo</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{textAlign: "right"}}>
                        <Button sx={{
                    background: "#091621 !important",
                    fontSize: "13px",
                    borderRadius: "25px",
                    textTransform: "none"
                }} variant="contained" onClick={() => {
                    if(!form["s_title"]) return console.log("Se requiere Titulo") 
                            let s_title = form["s_title"], s_title_trim = s_title.replace(/ /g, "-");; 
                            if(!form["s_handle"]) form["s_handle"] = `${s_title_trim.toLowerCase()}-${Date.now()}`;
                            guardarProducto(form)
                            // guardarInventario([form])
                            handleClose()
                        }}>
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
                <Grid sx={{
                    padding: "0px 20px"
                }} container>
                    <Grid item xs={9}>
                        <Grid className='scrollHide' container spacing={2} sx={{
                        height: "66vh",
                        overflowY: "scroll",
                        marginTop: "5px",
                        paddingRight: "20px"
                        }}>
                            <Grid item xs={12} sx={{
                                // background: "white",
                                height: "fit-content",
                                paddingRight: "15px",
                                marginBottom: "30px"
                                // borderRadius: "10px",
                                // border: "1px solid #969696"
                            }}>
                                <Typography variant="h6" sx={{
                                    color: "black !important",
                                    marginBottom: "15px",
                                    borderLeftStyle: "solid",
                                    borderLeftColor: "coral",
                                    borderLeftWidth: "6px",
                                    paddingLeft: "10px"
                                }}>General</Typography>
                                <TextField 
                                    sx={{
                                        color: "black !important",
                                        width: "100% !important",
                                        "& fieldset":{borderRadius: "15px"},
                                        "& input":{color:"black !important"}
                                    }} 
                                    onChange={e => { setForm({ ...form, s_title: e.target.value })}} 
                                    value={form[`s_title`]}
                                    id="s_title"
                                    label="Título"
                                    size="small"
                                    variant="outlined"
                                />
                                <TextField 
                                    sx={{
                                        color: "black !important",
                                        width: "100% !important",
                                        marginTop: "15px",
                                        "& fieldset":{borderRadius: "15px", color:"black !important"},
                                        "& input, textarea":{color:"black !important"}
                                    }} 
                                    onChange={e => { setForm({ ...form, s_body: e.target.value })}} 
                                    value={form[`s_body`]}
                                    id="s_body"
                                    label="Descripción"
                                    multiline
                                    rows={4}
                                    size="small"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sx={{
                                height: "17vh",
                                paddingRight: "15px",
                            }}>
                                <Typography variant="h6" sx={{
                                    color: "black !important",
                                    marginBottom: "15px",
                                    borderLeftStyle: "solid",
                                    borderLeftColor: "coral",
                                    borderLeftWidth: "6px",
                                    paddingLeft: "10px"
                                }}>Precio</Typography>
                                <TextField 
                                    sx={{
                                        color: "black !important",
                                        width: "40% !important",
                                        marginRight: "15px",
                                        "& fieldset":{
                                            borderRadius: "15px",
                                            color:"black !important"
                                        },
                                        "& input":{color:"black !important"}
                                    }} 
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment sx={{color: "black !important"}} position="start">
                                                <MonetizationOn sx={{color: "#b2b2b2 !important"}}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={e => { setForm({ ...form, n_variant_price: e.target.value })}} 
                                    value={form[`n_variant_price`]}
                                    id="n_variant_price"
                                    label="Precio"
                                    size="small"
                                    variant="outlined"
                                />
                                <TextField 
                                    sx={{
                                        color: "black !important",
                                        width: "40% !important",
                                        "& fieldset":{
                                            borderRadius: "15px",
                                            color:"black !important"
                                        },
                                        "& input":{color:"black !important"}
                                    }} 
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment sx={{color: "black !important"}} position="start">
                                                <MonetizationOn sx={{color: "#b2b2b2 !important"}}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={e => { setForm({ ...form, n_variant_compare_price: e.target.value })}} 
                                    value={form[`n_variant_compare_price`]}
                                    id="n_variant_compare_price"
                                    label="Precio comparación"
                                    size="small"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sx={{
                                height: "20vh",
                                paddingRight: "15px",
                            }}>
                                <Typography variant="h6" sx={{
                                    color: "black !important",
                                    marginBottom: "15px",
                                    borderLeftStyle: "solid",
                                    borderLeftColor: "coral",
                                    borderLeftWidth: "6px",
                                    paddingLeft: "10px"
                                }}>Variantes</Typography>
                                <Variantes setForm={setForm} form={form}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid className='scrollHide' container spacing={2} sx={{
                            height: "66vh",
                            overflowY: "scroll",
                            marginTop: "5px",
                            paddingRight: "20px"
                        }}>
                            <Box sx={{margin: "20px 0px"}}><Options id="i_status" name="Estatus" options={estatus}/></Box>
                            <Box sx={{display: "grid", justifyContent: "center", width: "100%"}}>
                                {form["s_image_src"] ? (<ImagenURL
                                    src={form["s_image_src"] ? form["s_image_src"]: urlEmptyImage}
                                    alt="imagen src"
                                    width="500"
                                    height="600"
                                />):(<ImagenNotFound/>)}
                            </Box>
                            <TextField 
                                sx={{
                                    marginTop: "20px",
                                    color: "black !important",
                                    width: "100% !important",
                                    "& fieldset":{borderRadius: "15px"},
                                    "& input":{color:"black !important"}
                                }} 
                                onChange={e => { setForm({ ...form, s_image_src: e.target.value })}} 
                                value={form[`s_image_src`]}
                                id="s_image_src"
                                label="Imagen (url)"
                                size="small"
                                variant="outlined"
                            />
                            <TextField 
                                sx={{
                                    marginTop: "20px",
                                    color: "black !important",
                                    width: "100% !important",
                                    "& fieldset":{borderRadius: "15px"},
                                    "& input":{color:"black !important"}
                                }} 
                                onChange={e => { setForm({ ...form, s_variant_sku: e.target.value })}} 
                                value={form[`s_variant_sku`]}
                                id="s_variant_sku"
                                label="SKU"
                                size="small"
                                variant="outlined"
                            />
                            <Box sx={{marginBottom: "20px"}}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        sx={{"& svg": {color: "black !important"}}}
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography sx={{color: "black !important", fontSize: "14px"}}>
                                            Configuraciones avanzadas                                         
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <TextField 
                                        sx={{
                                            color: "black !important",
                                            width: "100% !important",
                                            "& fieldset":{borderRadius: "15px"},
                                            "& input":{color:"black !important"}
                                        }} 
                                        onChange={e => { setForm({ ...form, s_handle: e.target.value })}} 
                                        value={form[`s_handle`]}
                                        id="s_handle"
                                        label="Handle"
                                        size="small"
                                        variant="outlined"
                                    />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
        {verDetalle ? (<Detalle go_to_menu={back_to_menu} sel_producto={seleccion}/>): verEdicionMasiva ? 
        (<Productos_Masivos productos_seleccionados={array_edicion} regresar={e=>setVerEdicionMasiva(false)}/>):
        (<Box>
            <Box ref={ref}>
                <MenuSeccion/>
                <Side_Filter
                    n_totales={n_variantes}
                    n_productos={productos?.length ? productos.length: 0}
                    filterFunction={e=>{
                        if(dataTable.length > 0){
                            let result_filter = dataTable.filter(item_table => item_table.search.search(e.target.value) > -1)
                            setFilterTable(result_filter)
                        }
                    }}
                    statusFilter={status=>{
                        // 0-Todos
                        // 1-Activos
                        // 2-Borradores
                        // 3-Archivados
                        if(dataTable.length > 0){
                            if(status != 0){
                                let result_filter = dataTable.filter(item_table => item_table.status == status)
                                setFilterTable(result_filter)
                            }else{
                                setFilterTable(dataTable)
                            }
                        }
                    }}
                />
            </Box>
            <Box sx={{
                width: "100%",
                background: "white",
                borderRadius: "8px"
            }}>
                <Tabla
                    edicion_masiva={data =>{ 
                        setVerEdicionMasiva(data)
                        setArray_edicion(data.map(id_map=>{
                            let result_filter = productos.filter((data_filter, id_filter)=> id_filter == id_map)
                            if(result_filter?.length > 0){
                                return result_filter[0]
                            }
                            return {}
                        }))
                    }}
                    eliminacion_masiva={async data => {
                        setLoading(true)
                        let result = await modificar_productos(data.map(id_map=>{
                            let result_filter = productos.filter((data_filter, id_filter)=> id_filter == id_map)
                            if(result_filter?.length > 0){
                                return {...result_filter[0], deleted: 1}
                            }
                            return {}
                        }))

                        if(result){
                            setLoading(false)
                            setSeleccion([])
                        }
                    }}
                    hx={height}
                    loading={loading}
                    rows={filterTable}
                    headCells={[{
                        id: 's_title',
                        numeric: true,
                        disablePadding: true,
                        label: 'Nombre',
                        width: 250
                    },{
                        id: 'n_variantes',
                        align: "right",
                        numeric: true,
                        disablePadding: false,
                        label: 'Variantes',
                    },{
                        id: 's_variant_sku',
                        numeric: true,
                        disablePadding: false,
                        empty: "No SKU",
                        label: 'SKU',
                    },{
                        id: 'n_variant_price',
                        numeric: true,
                        align: "right",
                        disablePadding: false,
                        label: 'Precio',
                    },{
                        id: 's_product_category',
                        numeric: true,
                        disablePadding: false,
                        label: 'Categoría',
                    },
                    {
                        id: 's_vendor',
                        numeric: true,
                        disablePadding: false,
                        label: 'Proveedor',
                    },
                    // {
                    //     id: 'i_mercados',
                    //     numeric: true,
                    //     align: "right",
                    //     disablePadding: false,
                    //     label: 'Mercados',
                    // },
                    // {
                    //     id: 'i_canales_venta',
                    //     align: "right",
                    //     numeric: true,
                    //     disablePadding: false,
                    //     label: 'Canales',
                    // },
                    // {
                    //     id: 'i_tiendas',
                    //     align: "right",
                    //     numeric: true,
                    //     disablePadding: false,
                    //     label: 'Inventario',
                    // },
                    {
                        id: 's_value',
                        align: "left",
                        numeric: false,
                        disablePadding: false,
                        label: 'Estado'
                    }
                    ]}
                />
            </Box>
        </Box>)}
    </Box>)
}

export default Productos;

const Side_Filter = (props) => {
    let {
        n_productos     = 0,
        n_existencia    = 0,
        n_totales       = 0,
        n_variantes     = 0,
        filterFunction  = e=>console.log("filter"),
        statusFilter    = e=>console.log("filter")
    } = props

    const [btnActivado, setBtnActivado] = useState(0);
    const [search, setSearch] = useState(false);

    useEffect(()=>{
        statusFilter(btnActivado)
    }, [btnActivado])

    useEffect(()=>{
        console.log(n_totales)
    },[n_totales])

    return(<Box>
        <Box sx={{
            marginY: "20px",
            borderRadius: "8px",
            background: "white !important",
            width: "100%",
            boxShadow: "0px 2px 5px -4px black"
        }}>
            <Grid container>
                {search ? (<Grid item xs={10} sx={{paddingLeft: "5px", display: "flex", justifyContent: "start", alignItems: "center"}}>
                    <Box sx={{width: "100%", "& input":{
                        outline: "none !important",
                        padding: "5px 2px !important",
                        fontSize: "12px",
                    }}}>
                        <TextField
                            onChange={filterFunction}
                            placeholder="Buscando en todos los productos"
                            sx={{
                                width: "100%",
                                outline: "none",
                                margin: "12px 10px",
                                background: "#FAFAFA",
                                fontFamily: "semibold",
                                "& .MuiOutlinedInput-root": {
                                    fontFamily: "'semibold'",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "#EBEBEB",
                                      borderWidth: "1px",
                                    },
                                  },
                                  // Class for the label of the input field
                                  "& .MuiInputLabel-outlined": {
                                    color: "#626262",
                                  },
                            }}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <Icon name="SearchLigth" sx={{height: "12px", width: "12px"}}/>
                                </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>):(<Grid item xs={10} sx={{paddingLeft: "5px", display: "flex", justifyContent: "start"}}>
                    <Box sx={{margin: "6px"}}>
                        <Typography sx={{fontSize: "12px", fontWeight: "bold"}}>
                            {n_productos}
                        </Typography>
                        <Typography sx={{
                            fontSize: "12px",
                            color: "#626262"
                        }}>
                            Productos
                        </Typography>
                    </Box>
                    <Box sx={{margin: "6px"}}>
                        <Typography sx={{fontSize: "12px", fontWeight: "bold"}}>{n_totales}</Typography>
                        <Typography sx={{
                            fontSize: "12px",
                            color: "#626262"
                        }}>
                            SKU
                        </Typography>
                    </Box>
                    <Box sx={{margin: "6px"}}>
                        <Typography sx={{fontSize: "12px", fontWeight: "bold"}}>{n_existencia}</Typography>
                        <Typography sx={{
                            fontSize: "12px",
                            color: "#626262"
                        }}>
                            Proveedores
                        </Typography>
                    </Box>
                </Grid>)}
                <Grid item xs={2} sx={{display: "flex", justifyContent: "end"}}>
                    <Box sx={{margin: "6px", display: "flex", marginRight: "10px", alignItems: "center"}}>
                        <Box onClick={e=>{
                                setSearch(!search)
                            }} sx={{
                            marginX: "5px",
                            background: "#EBEBEB",
                            width: "30px",
                            height: "30px",
                            display: "grid",
                            justifyContent: "center",
                            alignContent: "center",
                            borderRadius: "8px",
                            cursor: "pointer"
                        }}>
                            <Icon name="SearchDark" sx={{height: "12px", width: "12px"}}/>
                        </Box>
                        <Box sx={{
                            marginX: "5px",
                            background: "#EBEBEB",
                            width: "30px",
                            height: "30px",
                            display: "grid",
                            justifyContent: "center",
                            alignContent: "center",
                            borderRadius: "8px",
                            cursor: "pointer"
                        }}>
                            <MenuComponente/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Divider color="#EBEBEB"/>
            <Box sx={{
                marginLeft: "5px",
                "& #filtro":{fontSize: "12px !important"},
                "& button": {
                    borderRadius: "8px !important",
                }
            }}>
                <ButtonGroup variant="standard" aria-label="outlined button group">
                    <Button onClick={e => setBtnActivado(0)} sx={btnActivado == 0? btnMenu: btnMenuActive}>Todos</Button>
                    <Button onClick={e => setBtnActivado(1)} sx={btnActivado == 1? btnMenu: btnMenuActive}>Borrador</Button>
                    <Button onClick={e => setBtnActivado(2)} sx={btnActivado == 2? btnMenu: btnMenuActive}>Activos</Button>
                    {/* <Button onClick={e => setBtnActivado(3)} sx={btnActivado == 3? btnMenu: btnMenuActive}>Archivados</Button> */}
                </ButtonGroup>
            </Box>
        </Box> 
    </Box>)
}

const Importar = ({b_importat, setB_importar, setDataProductos, guardar_todos_productos}) => {
    const [filedata, setFileData] = useState(null)
    const [openFile, setOpenFile] = useState(false)
    const [transferimport, setTransferImport] = useState([])

    const style_importar = {
        position: 'absolute',
        color: "black !important",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "70%",
        height: "80vh",
        maxHeight: "253px",
        maxWidth: "642px",
        // background: "#f1f1f1",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: "15px",
        borderRadius: "10px"
    };

    const TxtModal = styled('p')({
        color: "black",
        fontSize: "12px",
        margin: "0px",
        fontFamily: "mulish-bold"
    })

    const LinkModal = styled('p')({
        color: "#08306B",
        fontSize: "10px",
        margin: "0px",
        textDecoration: "underline",
        cursor: "pointer"
    })

    const BtnModal = styled('input')({
        fontSize: "12px",
        borderRadius: "8px",
        outline: "none",
        border: "none",
        cursor: "pointer",
        padding: "8px 12px",
        position: "relative",
        fontFamily: "semibold"
    })

    const TxtFile = styled('label')({
        background:"#f0f0f0",
        textAlign: "center",
        fontSize: "12px",
        borderRadius: "8px",
        outline: "none",
        border: "none",
        cursor: "pointer",
        padding: "8px 12px",
        position: "relative",
        fontFamily: "semibold"
    })

    const FileInput = styled('input')({display: 'none'})

    const close_importar = () => setB_importar(false)

    useEffect(async ()=>{
        let result_transfer = await transferKeysImp(filedata)
        setTransferImport(result_transfer)
    },[filedata])

    return(<Modal
            open={b_importat}
            onClose={close_importar}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style_importar}>
                <TxtModal>Importar productos con archivo CSV</TxtModal>
                <LinkModal>Descargar archivo de muestra CSV</LinkModal>
                <Box sx={{
                    border: "1px dashed #cdccc8",
                    height: "140px",
                    borderRadius: "8px",
                    marginTop: "10px",
                    display: "grid",
                    justifyContent: "center",
                    alignContent: "center"
                }}>
                    <FileInput type="file" id="fileInput" onChange={e=>{
                        e.preventDefault();
                        if(e.target.files) {
                            var reader = new FileReader();
                            reader.onload = async e => {
                            const resFile = e.target.result;
                            const workbook = read(resFile, { type: "array" });
                            const sheetName = workbook.SheetNames[0];
                            const worksheet = workbook.Sheets[sheetName];
                            let json = await utils.sheet_to_json(worksheet);
                            if(json.length > 0){ setFileData(json)}
                            setOpenFile(true)
                        }
                        reader.readAsArrayBuffer(e.target.files[0]);
                    }}}/>
                    <TxtFile for="fileInput">Adjuntar archivo</TxtFile>
                </Box>
                <Box sx={{
                    position: "absolute",
                    bottom: "10px",
                    right: "12px"
                }}>
                    <BtnModal sx={{marginRight: "6px", background: "#626262", color: "white"}} type="button" value="Cancelar"/>
                    <BtnModal onClick={async e=>{
                        if(transferimport.length > 0){
                            console.log(transferimport)
                            // let result_ = await guardar_todos_productos(transferimport)
                            
                            // setDataProductos(dataProductos => {return [
                            //     ...result_,
                            //     ...dataProductos
                            // ]})

                            // setTransferImport([])
                            // close_importar()
                        }
                    }} sx={{background: "#3F3F3F", color: "white"}} type="button" value="Subir archivo"/>
                </Box>
            </Box>
    </Modal>)
}

const Exportar = ({b_exportar, setB_exportar}) => {
    const style_importar = {
        position: 'absolute',
        color: "black !important",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "70%",
        height: "80vh",
        maxHeight: "253px",
        maxWidth: "642px",
        // background: "#f1f1f1",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: "15px",
        borderRadius: "10px"
    };

    const TxtModal = styled('p')({
        color: "black",
        fontSize: "12px",
        margin: "0px",
        fontFamily: "mulish-bold"
    })

    const LblModal = styled('p')({
        color: "#626262",
        fontSize: "10px",
        margin: "0px",
        fontFamily: "mulish-regular"
    })

    const BtnModal = styled('input')({
        fontSize: "12px",
        borderRadius: "8px",
        outline: "none",
        border: "none",
        cursor: "pointer",
        padding: "8px 12px",
        position: "relative",
        fontFamily: "semibold"
    })

    const StitleModal = styled('p')({
        color: "#626262",
        fontSize: "12px",
        fontFamily: "semibold"
    })

    const close_exportar = () => setB_exportar(false)
    return(<Modal
            open={b_exportar}
            onClose={close_exportar}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style_importar}>
                <TxtModal>Exportar productos</TxtModal>
                <LblModal>Exporta tus productos y datos en archivo CSV. En caso de requerir actualizar la información de tus productos puedes utilizar este archivo.</LblModal>
                <Box>
                    <StitleModal>Exportar</StitleModal>
                    <CheckOne
                        values={[
                            "Todos los productos",
                            "Página actual",
                            "Productos seleccionados"
                        ]}
                        onChange={e=>{
                            console.log(e.target.value)
                        }}
                    />
                </Box>
                <Box sx={{
                    position: "absolute",
                    bottom: "10px",
                    right: "12px"
                }}>
                    <BtnModal sx={{marginRight: "6px", background: "#626262", color: "white"}} type="button" value="Cancelar"/>
                    <BtnModal sx={{background: "#3F3F3F", color: "white"}} type="button" value="Exportar productos"/>
                </Box>
            </Box>
    </Modal>)
}

const styleModal = {
    position: 'absolute',
    color: "black !important",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    height: "80vh",
    // background: "#f1f1f1",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
    borderRadius: "10px"
};

const ImagenURL = styled('img')({
    width: "180px",
    height: "180px",
    border: "1px solid #a3a0a0",
    borderRadius: "20px"
})

const btnMenu = {
    background: "#626262",
    color: "white",
    textTransform: 'none',
    padding: "10px !important",
    margin: "5px",
    fontSize: "12px",
    fontFamily: "semibold",
    // width: "60px",
    height: "30px"
}

const btnMenuActive = {
    background: "#EBEBEB",
    color: "#303030",
    textTransform: 'none',
    padding: "10px !important",
    margin: "5px",
    fontSize: "12px",
    fontFamily: "semibold",
    // width: "60px",
    height: "30px"
}
import React, { useReducer } from 'react';
import {VariantesContext} from '../index';
import variantesReducer from './variantesReducer';
import clientAxios from '../../config/axios'
import { 
    SELECCIONAR_VARIANTE,
    SELECCIONAR_COMBINACIONES
} from '../../types'

const VariantesState = props => {
    const initialState = {
        id_variante: ""
    }

    const [state, dispatch] = useReducer(variantesReducer, initialState)

    const seleccionar_variante = async id_variante => {
        dispatch({
            type: SELECCIONAR_VARIANTE,
            payload: id_variante
        })
    }

    const seleccionar_combinaciones = async grupo => {
        dispatch({
            type: SELECCIONAR_COMBINACIONES,
            payload: grupo
        })
    }

    const obtener_variante = async id_producto =>{
        const res = await clientAxios.get(`/api/variantes/obtener-variante/producto/${id_producto}`);
        
        if(res?.data?.success){
            
            return res.data.variantes
        }
    }
    
    const modificar_variante = async (id_variante, body) => {
        const res = await clientAxios.post(`/api/variantes/modificar/${id_variante}`, body);
        console.log(res)
    }

    return(
        <VariantesContext.Provider
            value={{
                id_variante: state.id_variante,
                grupo: state.grupo,
                seleccionar_variante,
                seleccionar_combinaciones,
                obtener_variante,
                modificar_variante
            }}
        >
            {props.children}
        </VariantesContext.Provider>
    )
}

export default VariantesState 
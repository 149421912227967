/* eslint-disable import/no-anonymous-default-export */
import { 
    MODIFICAR_PRELIMINARES,
    OBTENER_PRELIMINARES,
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case MODIFICAR_PRELIMINARES:
            let preliminar_modificado = action.payload, stage = state.preliminares;
            let filter_stage = stage.map(stage_item => {
                if(stage_item._id == preliminar_modificado._id){
                    return preliminar_modificado;
                }
                return stage_item
            })
            return {
                ...state,
                preliminares: filter_stage
            }
        case OBTENER_PRELIMINARES:
            return {
                ...state,
                preliminares: action.payload
            }
        default:
            return state
    }
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Mulish-Black.ttf'
import './fonts/Mulish-BlackItalic.ttf'
import './fonts/Mulish-Bold.ttf'
import './fonts/Mulish-BoldItalic.ttf'
import './fonts/Mulish-ExtraBold.ttf'
import './fonts/Mulish-ExtraBoldItalic.ttf'
import './fonts/Mulish-ExtraLight.ttf'
import './fonts/Mulish-ExtraLightItalic.ttf'
import './fonts/Mulish-Italic.ttf'
import './fonts/Mulish-ItalicW.ttf'
import './fonts/Mulish-Regular.ttf'
import './fonts/Mulish-SemiBold.ttf'
import './fonts/Mulish-SemiBoldItalic.ttf'
import './fonts/Mulish-W.ttf'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const heads = [
    {view: true, id: 's_handle',                                        size: 3, name:"Handle"},
    {view: true, id: 's_title',  /*id:"Title",*/                        size: 7, name:"Title"},
    {view: true, id: 's_body',/*id:"Body",*/                            size: 4, name:"Body"},
    {view: true, id: 's_vendor',/*id:"Vendor",*/                        size: 4, name:"Vendor"},
    {view: true, id: 's_product_category',/*id:"ProductCategory",*/     size: 4, name:"Product Category"},
    {view: true, id: 's_type',/*id:"Type",*/                            size: 4, name:"Type"},
    {view: true, id: 's_tags',/*id:"Tags",*/                            size: 4, name:"Tags"},
    {view: true, id: 'b_published',/*id:"Published",*/                  size: 4, name:"Published"},
    {view: true, id: "Option1name",                                     size: 4, name:"Option1 Name"},
    {view: true, id: "Option1value",                                    size: 4, name:"Option1 Value"},
    {view: true, id: "Option2name",                                     size: 4, name:"Option2 Name"},
    {view: true, id: "Option2value",                                    size: 4, name:"Option2 Value"},
    {view: true, id: "Option3name",                                     size: 4, name:"Option3 Name"},
    {view: true, id: "Option3value",                                    size: 4, name:"Option3 Value"},
    {view: true, id: "Option4name",                                     size: 4, name:"Option4 Name"},
    {view: true, id: "Option4value",                                    size: 4, name:"Option4 Value"},
    {view: true, id: "Option5name",                                     size: 4, name:"Option5 Name"},
    {view: true, id: "Option5value",                                    size: 4, name:"Option5 Value"},
    {view: true, id: 's_variant_sku',/*id:"VariantSKU",*/               size: 4, name:"Variant SKU"},
    {view: true, id: 'n_variant_grams',/*id:"VariantGrams",*/           size: 4, name:"Variant Grams"},
    {view: true, id: 's_variant_inventory_tracker',                     size: 4, name:"Variant Inventory Tracker"},
    {view: true, id: "VariantInventoryQty",                             size: 4, name:"Variant Inventory Qty"},
    {view: true, id: 's_variant_inventory_policy',                      size: 4, name:"Variant Inventory Policy"},
    {view: true, id: 's_variant_fulfillment_service',                   size: 4, name:"Variant Fulfillment Service"},
    {view: true, id: 'n_variant_price',                                 size: 4, name:"Variant Price"},
    {view: true, id: 'n_variant_compare_price',                         size: 4, name:"Variant Compare At Price"},
    {view: true, id: 'b_variant_requires_shipping',                     size: 4, name:"Variant Requires Shipping"},
    {view: true, id: 'b_variant_taxable',                               size: 4, name:"Variant Taxable"},
    {view: true, id: 's_variant_barcode',                               size: 4, name:"Variant Barcode"},
    {view: true, id: 's_image_src',                                     size: 4, name:"Image Src"},
    {view: true, id: 'n_image_position',                                size: 4, name:"Image Position"},
    {view: true, id: 's_image_alt_txt',                                 size: 4, name:"Image Alt Text"},
    {view: true, id: 'b_gift_card',                                     size: 4, name:"Gift Card"},
    {view: true, id: 's_seo_title',                                     size: 4, name:"SEO Title"},
    {view: true, id: 's_seo_description',                               size: 4, name:"SEO Description"},
    {view: true, id: "GoogleShoppingGoogleProductCategory",             size: 4, name:"Google Shopping / Google Product Category"},
    {view: true, id: "GoogleShoppingGender",                            size: 4, name:"Google Shopping Gender"},
    {view: true, id: "GoogleShoppingAgeGroup",                          size: 4, name:"Google Shopping Age Group"},
    {view: true, id: "GoogleShoppingMPN",                               size: 4, name:"Google Shopping MPN"},
    {view: true, id: "GoogleShoppingAdWordsGrouping",                   size: 4, name:"Google Shopping Ad Words Grouping"},
    {view: true, id: "GoogleShoppingAdWordsLabels",                     size: 4, name:"Google Shopping Ad Words Labels"},
    {view: true, id: "GoogleShoppingCondition",                         size: 4, name:"Google Shopping Condition"},
    {view: true, id: "GoogleShoppingCustomProduct",                     size: 4, name:"Google Shopping Custom Product"},
    {view: true, id: "GoogleShoppingCustomLabel0",                      size: 4, name:"Google Shopping Custom Label 0"},
    {view: true, id: "GoogleShoppingCustomLabel1",                      size: 4, name:"Google Shopping Custom Label 1"},
    {view: true, id: "GoogleShoppingCustomLabel2",                      size: 4, name:"Google Shopping Custom Label 2"},
    {view: true, id: "GoogleShoppingCustomLabel3",                      size: 4, name:"Google Shopping Custom Label 3"},
    {view: true, id: "GoogleShoppingCustomLabel4",                      size: 4, name:"Google Shopping Custom Label 4"},
    {view: true, id: 's_variant_image',                                 size: 4, name:"Variant Image"},
    {view: true, id: 'n_variant_weight_unit',                           size: 4, name:"Variant Weight Unit"},
    {view: true, id: 'n_variant_tax_code',                              size: 4, name:"Variant Tax Code"},
    {view: true, id:"CostPeritem",                                      size: 4, name:"Cost Per item"},
    {view: true, id:"PriceInternational",                               size: 4, name:"Price International"},
    {view: true, id:"CompareAtPriceInternational",                      size: 4, name:"Compare At Price International"},
    {view: true, id: 'i_status',                                        size: 4, name:"Status"},
    {view: true, id:"deleted",                                          size: 4, name:"Delete"}
]




// let headCells = [
//         id: 's_title',
//         id: 's_body',
//         id: 's_vendor',
//         id: 's_product_category',
//         id: 's_type',
//         id: 's_tags',
//         id: 'b_published',
//         id: 's_variant_sku',
//         id: 'n_variant_grams',
//         id: 's_variant_inventory_tracker',
//         id: 's_variant_inventory_policy',
//         id: 's_variant_fulfillment_service',
//         id: 'n_variant_price',
//         id: 'n_variant_compare_price',
//         id: 'b_variant_requires_shipping',
//         id: 'b_variant_taxable',
//         id: 's_variant_barcode',
//         id: 's_image_src',
//         id: 'n_image_position',
//         id: 's_image_alt_txt',
//         id: 'b_gift_card',
//         id: 's_seo_title',
//         id: 's_seo_description',
//         id: 's_variant_image',
//         id: 'n_variant_weight_unit',
//         id: 'n_variant_tax_code',
//         id: 'i_status',
//         id: 'id_inventario',
//         id: 'id_negocio',
//         id: 's_inventario',
//         id: 'i_canales_venta',
//         id: 'i_tiendas',
//         id: 'i_tipo',
//         id: 'modificateAt',
//         id: 'createAt',
//     }
// ];


import { Box, Grid, TextField, Typography, styled } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { ProductosContext, SideleftContext } from "../../../context"
import imgBkgLogo from '../../../img/logos/logo_w_letter.png'
import img_back from '../../../img/icons/Row-Left-White.png'
import CustomizedMenus from "./Columnas"

const Tedicion = styled('table')({
    borderCollapse: "separate",
    border: "1px solid #e2e2e2",
    width: "100%",
    borderRadius: "8px !important",
    "& th, td":{
        padding: "8px",
    },
    "& tr:nth-child(even)":{
        background: "#ffffff"},
    "& tr:hover":{
        background: "#FAFAFA",
        cursor: "pointer"
    },
    "& th":{
        padding: "0px",
        textAlign: "left",
        background: "#FAFAFA",
        color: "white",
    }
})
const Tdedicion = styled('td')({
    maxWidth: "180px !important",
    minWidth: "160px !important"
})
const Thedicion = styled('th')({})
const Tredicion = styled('tr')({
    background: "white"
})
const Ptitulo = styled('p')({
    fontSize: "12px",
    fontFamily: "mulish-bold",
    width: "max-content !important",
    margin: "5px 15px",
    background: ""
})
const TextInput = styled('input')({
    width: "100% !important",
    background: "transparent !important",
    border: "0px", 
    outline: "none",
    fontSize: "12px",
    padding: "5px 10px !important"
})

const CheckInput = styled('input')({
    fontSize: "12px"
})

const Pdescripcion = styled('p')({
    fontSize: "12px",
    fontFamily: "semibold",
    margin: "0px !important"
})

const ImgCustom = styled('img')({
    width: "30px",
    height: "30px",
    margin: "5px 0px"
})

const SelectInput = styled('select')({
    width: "120px",
    borderRadius: "8px",
    fontSize: "12px",
    padding: "3px 5px",
    cursor: "pointer",
    background: "transparent !important",
    outline: "none",
    border: "0px",
})

const OptionInput = styled('option')({
    fontSize: "12px !important",
})

const Productos_Masivos = ({productos_seleccionados = [], regresar}) => {
    const sideleftContext = useContext(SideleftContext);
    const { abrir_sideleft, abrir_menu } = sideleftContext;

    const productosContext = useContext(ProductosContext);
    const { modificar_productos } = productosContext;

    const [heads, setHeads] = useState({
        s_image_src:true,
        s_title:true,
        s_body:true,
        i_status:true,
        b_published:true,
        b_gift_card:true,
        b_variant_requires_shipping:true,
        b_variant_taxable:true,
        b_variantes:true,
        i_canales_venta:false,
        i_mercados:false,
        i_tiendas:false,
        i_tipo:false,
        n_cost_per_item:false,
        n_image_position:false,
        n_unit_measure_weight:false,
        n_unit_measure_weight_packaging:false,
        n_variant_compare_price:false,
        n_variant_country_origin:false,
        n_variant_grams:false,
        n_variant_price:false,
        n_variant_tax_code:false,
        n_variant_weight_unit:false,
        s_handle:false,
        s_image_alt_txt:false,
        s_inventario:false,
        s_product_category:false,
        s_product_node_category:false,
        s_product_type:false,
        s_seo_description:false,
        s_seo_title:false,
        s_tags:false,
        s_type:false,
        s_variant_barcode:false,
        s_variant_fulfillment_service:false,
        s_variant_image:false,
        s_variant_inventory_policy:false,
        s_variant_inventory_tracker:false,
        s_variant_sku:false,
        s_vendor: false,
    })

    const [th_heads,    setTH_heads]   = useState([])
    const [list_body,   setList_body]  = useState([])
    const [update,      setUpdate]     = useState([])
    const [array_save,  setArray_Save] = useState([])

    useEffect(()=>{
        abrir_sideleft(true)
        document.getElementById("bar_menu_contenedor").style.display = "none";
    },[])

    useEffect(()=>{
        console.log(productos_seleccionados)
        setArray_Save(productos_seleccionados)
    },[productos_seleccionados])

    const handleUpdate = (e, _id) => {
        setUpdate(item => {
            let filter_new      = item.filter(item_new => item_new._id == _id), array_items = []
            let all_products    = item.filter(item_new => item_new._id != _id)
            if(filter_new?.length > 0){
                array_items = [
                    ...all_products,
                    {
                        ...filter_new[0],
                        [e.target.name]: e.target.value
                    }
                ]
            }else{
                array_items = [
                    ...item,
                    {
                        _id,
                        [e.target.name]: e.target.value
                    }
                ]
            }
            return array_items
        })
    }

    const handleChecked = (e, _id) => {
        setUpdate(item => {
            let filter_new      = item.filter(item_new => item_new._id == _id), array_items = []
            let all_products    = item.filter(item_new => item_new._id != _id)
            if(filter_new?.length > 0){
                array_items = [
                    ...all_products,
                    {
                        ...filter_new[0],
                        [e.target.name]: e.target.checked
                    }
                ]
            }else{
                array_items = [
                    ...item,
                    {
                        _id,
                        [e.target.name]: e.target.checked
                    }
                ]
            }
            return array_items
        })
    }

    const handleSelect = (e, _id) => {
        setUpdate(item => {
            let filter_new      = item.filter(item_new => item_new._id == _id), array_items = []
            let all_products    = item.filter(item_new => item_new._id != _id)
            let type_val = 3
            
            switch(e.target.value){
                case "Activo":
                    type_val = 2
                    break;
                case "Borrador":
                    type_val = 1
                    break;
                default:
                    type_val = 3
                    break;
            }
            
            if(filter_new?.length > 0){
                array_items = [
                    ...all_products,
                    {
                        ...filter_new[0],
                        [e.target.name]: type_val
                    }
                ]
            }else{
                array_items = [
                    ...item,
                    {
                        _id,
                        [e.target.name]: type_val
                    }
                ]
            }
            return array_items
        })
    }

    useEffect(()=>{
        let all_keys = Object.keys(heads), array_body = []

        productos_seleccionados.map(producto_seleccionado => {
            let array_result_body = []
            all_keys.map(key_item => {
                let words = key_item.split(""), first_letter = null;
                if(words?.length>0){first_letter = words[0]}
                if(producto_seleccionado[key_item] != undefined && heads[`${key_item}`] == true){
                    array_result_body.push((<Tdedicion sx={{padding: "0px !important"}}>
                        {first_letter == "s" ? 
                            key_item == "s_image_src" ? 
                            producto_seleccionado[key_item] ? (<Box sx={{
                                display: "grid",
                                alignContent: "center",
                                justifyContent: "center"
                            }}>
                                <ImgCustom src={producto_seleccionado[key_item]}/>
                            </Box>)
                            : <Box sx={{
                                display: "grid",
                                alignContent: "center",
                                justifyContent: "center"
                            }}>
                                <Box sx={{
                                    width: "30px",
                                    height: "30px",
                                    border: "1px solid #dedede",
                                    borderRadius: "4px",
                                    margin: "3px 5px"
                                }}></Box>
                            </Box>:
                            (<TextInput name={key_item}
                                onChange={e => handleUpdate(e, producto_seleccionado._id)}
                                type="text"
                                defaultValue={producto_seleccionado[key_item]}
                            />):
                        first_letter == "b" ? 
                            (<div style={{display: "grid", justifyContent: "center"}}>
                                <CheckInput
                                    type="checkbox"
                                    name={key_item} onChange={e => handleChecked(e, producto_seleccionado._id)}
                                    defaultChecked={producto_seleccionado[key_item]}
                                />
                            </div>
                            ):
                        key_item == "i_status" ? (<div style={{display: "grid", justifyContent: "center"}}>
                            <SelectInput name={key_item} onChange={e=>handleSelect(e, producto_seleccionado._id)}>
                                <OptionInput selected={producto_seleccionado[key_item] == 1 ? true: false} key={1}>Borrador</OptionInput>
                                <OptionInput selected={producto_seleccionado[key_item] == 2 ? true: false} key={2}>Activo</OptionInput>
                                <OptionInput selected={producto_seleccionado[key_item] == 3 ? true: false} key={3}>Pendiente</OptionInput>
                            </SelectInput>
                            </div>):
                            first_letter == "i" || first_letter == "n" ? (<TextInput name={key_item}
                                onChange={e => handleUpdate(e, producto_seleccionado._id)}
                                type="number"
                                defaultValue={producto_seleccionado[key_item]}
                            />):
                        (<Pdescripcion>{producto_seleccionado[key_item]}</Pdescripcion>)}
                    </Tdedicion>))
                }
            })
            array_body.push(array_result_body)
        })

        
        setList_body(array_body)
    },[productos_seleccionados, heads])

    useEffect(()=>{
        let all_keys = Object.keys(heads), array_heads = []
        all_keys.map(key_item => {
            if(heads[`${key_item}`] == true){
                array_heads.push((<Thedicion><Ptitulo>{key_item}</Ptitulo></Thedicion>))
            }
        })

        setTH_heads(array_heads)
    },[])

    useEffect(()=>{
        let all_keys = Object.keys(heads), array_heads = []
        all_keys.map(key_item => {
            if(heads[`${key_item}`] == true){
                array_heads.push((<Thedicion><Ptitulo>{key_item}</Ptitulo></Thedicion>))
            }
        })

        setTH_heads(array_heads)
    },[heads])

    const guardar_masivo = async e => {
        if(update?.length > 0){
            let result = await modificar_productos(update)
            console.log(result)
            if(result?.status == 200){
                setUpdate([])
            }
        }else{
            console.log("No hay cambios")
            setUpdate([])
        }
    }

    return (<Box sx={{
        paddingRight: "30px !important",
        width: "fit-content",
        "& input":{
            accentColor: "#626262",
        }
    }}>
        <Box sx={{
            position: "fixed",
            top: "0px",
            left: "0px",
            zIndex: "1200",
            minHeight: "50px",
            display: "block",
            width: "100%",
            background: "#1A1E1D !important"
        }}>
            <Grid container sx={{
                height: "50px !important",
                marginLeft: "10px",
                "& p": { color: "white !important"}}}
            >
                <Grid item xs={6} sx={{display: "grid", alignContent: "center"}}>
                    <Box sx={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center"
                    }}>
                        <img src={imgBkgLogo} height="40px" width="35px"/>
                        <Box sx={{
                            marginLeft: "45px",
                            background: "#3F3F3F",
                            width: "33px",
                            height: "25px",
                            display: "grid",
                            justifyContent: "center",
                            alignContent: "center",
                            borderRadius: "8px",
                            cursor: "pointer"
                        }} onClick={e=>{
                            document.getElementById("bar_menu_contenedor").style.display = "block";
                            regresar()
                        }}>
                            <img src={img_back} height="12px" width="12px"/>
                        </Box>
                        <Typography sx={{
                            fontFamily: "mulish-extra-bold",
                            fontSize: "20px",
                            marginLeft: "25px"
                        }}>Editar Productos</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{height: "50px", justifyContent: "end"}}>
                    <Box sx={{display: "flex", alignItems: "center", height: "50px", justifyContent: "end"}}>
                        <Typography 
                            sx={{
                                fontSize: "12px",
                                fontFamily: "mulish-bold",
                                marginRight: "15px"
                            }}
                        >50 Productos</Typography>
                        <Typography 
                            sx={{
                                fontSize: "12px",
                                fontFamily: "mulish-bold",
                                marginRight: "15px"
                            }}
                        >86 SKU</Typography>
                        <Box sx={{
                            "& p":{
                                color: "black !important"
                            }
                        }}>
                            <CustomizedMenus heads={heads} setHeads={setHeads}/>
                        </Box>
                        {update?.length > 0 ? (<Box sx={{display: "flex"}}>
                            <Typography onClick={()=>{
                                setUpdate([])
                            }} sx={{
                                background: "#626262",
                                marginRight: "10px",
                                borderRadius: "8px",
                                fontSize: "12px",
                                fontFamily: "semibold",
                                padding: "5px 15px",
                                cursor: "pointer"
                            }}>
                                Cancelar
                            </Typography>
                            <Typography onClick={guardar_masivo} sx={{
                                background: "#3F3F3F",
                                borderRadius: "8px",
                                fontSize: "12px",
                                marginRight: "20px",
                                fontFamily: "semibold",
                                padding: "5px 15px",
                                cursor: "pointer"
                            }}>
                                Actualizar productos
                            </Typography>
                        </Box>):null}
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Tedicion className="table-radius-especial">
            <Tredicion>
                {th_heads.length > 0 ? th_heads.map(th_item=>(th_item)): null}
            </Tredicion>
            {list_body?.length > 0 ?
                list_body.map(item_body =>{
                    return(<Tredicion>
                        {item_body?.length>0?item_body.map(element => (element)):null}
                    </Tredicion>)
                }): null
            }
        </Tedicion>
    </Box>)
}

export default Productos_Masivos
export const FORMULARIO_MODULO  = 'FORMULARIO_MODULO';
export const OBTENER_MODULOS    = 'OBTENER_MODULOS';
export const AGREGAR_MODULO     = 'AGREGAR_MODULO';
export const VALIDAR_FORMULARIO = 'VALIDAR_FORMULARIO';
export const MODULO_ACTUAL      = 'MODULO_ACTUAL';
export const ELIMINAR_MODULO    = 'ELIMINAR_MODULO';
export const ABRIR_MODULO    = 'ABRIR_MODULO';

export const SECCIONES_MODULO   = 'SECCIONES_MODULO';
export const AGREGAR_SECCION    = 'AGREGAR_SECCION';
export const VALIDAR_SECCION    = 'VALIDAR_SECCION';
export const ELIMINAR_SECCION   = 'ELIMINAR_SECCION';
export const ESTADO_SECCION     = 'ESTADO_SECCION';
export const SECCION_ID         = 'SECCION_ID';
export const SECCION_ACTUAL     = 'SECCION_ACTUAL';

export const ACTUALIZAR_SECCION = 'ACTUALIZAR_SECCION';
export const LIMPIAR_SECCION    = 'LIMPIAR_SECCION';
export const ABRIR_SECCION      = 'ABRIR_SECCION';

export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';

export const REGISTRO_EXITOSO   = 'REGISTRO_EXITOSO';
export const REGISTRO_ERROR     = 'REGISTRO_ERROR';
export const OBTENER_USUARIO    = 'OBTENER_USUARIO';
export const LOGIN_EXITOSO      = 'LOGIN_EXITOSO';
export const LOGIN_ERROR        = 'LOGIN_ERROR';
export const CERRAR_SESION      = 'CERRAR_SESION';
export const VALIDACION_SESION  = 'VALIDACION_SESION';
export const GUARDAR_TEMA       = 'GUARDAR_TEMA';

export const VALIDAR_AJUSTES  = 'VALIDAR_AJUSTES';
export const MOSTRAR_OPCION  = 'MOSTRAR_OPCION';

export const ESTADO_NUEVO_USUARIO = 'ESTADO_NUEVO_USUARIO';

export const OBTENER_USUARIOS = "OBTENER_USUARIOS";

export const OBTENER_TEMPLATES = "OBTENER_TEMPLATES";

export const OBTENER_FORMATOS = "OBTENER_FORMATOS";
export const SELECCIONAR_FORMATO = "SELECCIONAR_FORMATO";

export const OBTENER_ZONAS = "OBTENER_ZONAS";

export const OBTENER_ITEMS = "OBTENER_ITEMS";
export const MOSTRAR_ITEM = "MOSTRAR_ITEM";
export const OBTENER_VISTAS_ITEMS = "OBTENER_VISTAS_ITEMS";

export const OBTENER_PROPIEDADES = "OBTENER_PROPIEDADES";

// FormItems
export const OBTENER_ITEMS_FORM = "OBTENER_ITEMS_FORM";
export const GUARDAR_ITEMS_FORM = "GUARDAR_ITEMS_FORM";

// Forms
export const OBTENER_FORMS = "OBTENER_FORMS";
export const GUARDAR_FORMS = "GUARDAR_FORMS";
export const SELECT_FORM = "SELECT_FORM";

// Clientes
export const GUARDAR_CLIENTE = "GUARDAR_CLIENTE";
export const OBTENER_CLIENTES = "OBTENER_CLIENTES";
export const OBTENER_CLIENTE = "OBTENER_CLIENTE";

// Inspector
export const GUARDAR_INSPECTOR = "GUARDAR_INSPECTOR";
export const OBTENER_INSPECTOR = "OBTENER_INSPECTOR";
export const OBTENER_INSPECTORES = "OBTENER_INSPECTORES";

// Conversations
export const OBTENER_CONVERSACIONES     = "OBTENER_CONVERSACIONES";
export const GUARDAR_CONVERSACIONES     = "GUARDAR_CONVERSACIONES";
export const SELECCIONAR_CONTACTO       = "SELECCIONAR_CONTACTO";
export const ENVIAR_MENSAJE             = "ENVIAR_MENSAJE";
export const GUARDAR_MENSAJE            = "GUARDAR_MENSAJE";

// Phones
export const OBTENER_PHONES             = "OBTENER_PHONES";
export const ULTIMA_CONVERSACION        = "ULTIMA_CONVERSACION";

// Contacts
export const GUARDAR_CONTACTOS          = "GUARDAR_CONTACTOS";
export const OBTENER_CONTACTOS          = "OBTENER_CONTACTOS";
export const OBTENER_CONTACTO_POR_NOMBRE= "OBTENER_CONTACTO_POR_NOMBRE";

// Styles
export const OBTENER_ESTILOS            = "OBTENER_ESTILOS";

// Configuraciones
export const OBTENER_CONFIGURACIONES    = "OBTENER_CONFIGURACIONES";
export const MODIFICAR_TEMA             = "MODIFICAR_TEMA";

// INVENTARIO
export const GUARDAR_INVENTARIO         = "GUARDAR_INVENTARIO";
export const OBTENER_INVENTARIO         = "OBTENER_INVENTARIO";
export const ELIMINAR_INVENTARIO        = "ELIMINAR_INVENTARIO";
export const OBTENER_INVENTARIO_PRODUCTOS = "OBTENER_INVENTARIO_PRODUCTOS";
export const OBTENER_PRODUCTOS          = "OBTENER_PRODUCTOS";
export const MODIFICAR_INVENTARIO       = "MODIFICAR_INVENTARIO";

export const GUARDAR_EVENTO             = "GUARDAR_EVENTO";
export const EJECUTAR_EVENTO            = "EJECUTAR_EVENTO";
export const ABRIR_MODAL                = "ABRIR_MODAL";
export const AGREGAR_DATA_BTN           = "AGREGAR_DATA_BTN";


export const GUARDAR_PRODUCTO           = "GUARDAR_PRODUCTO";
export const MODIFICAR_PRODUCTO         = "MODIFICAR_PRODUCTO";
export const GUARDAR_PRODUCTOS          = "GUARDAR_PRODUCTOS";

// RUTAS
export const SELECCIONAR_RUTA           = "SELECCIONAR_RUTA";
export const SELECCIONAR_SECCION        = "SELECCIONAR_SECCION";
export const NUEVO_PRODUCTO             = "NUEVO_PRODUCTO";

// VARIANTES
export const SELECCIONAR_VARIANTE       = "SELECCIONAR_VARIANTE";

// PRODUCTOS
export const SELECCIONAR_PRODUCTOS      = "SELECCIONAR_PRODUCTOS";
export const SELECCIONAR_COMBINACIONES  = "SELECCIONAR_COMBINACIONES";

// SUCURSALE 
export const OBTENER_SUCURSALES         = "OBTENER_SUCURSALES";
export const GUARDAR_SUCURSALES         = "GUARDAR_SUCURSALES";
export const SELECCIONAR_SUCURSAL       = "SELECCIONAR_SUCURSAL";

//LOADING
export const STATUS_LOAD                = "STATUS_LOAD";
export const SET_LOAD                   = "SET_LOAD";

export const TOTAL_INVENTARIO           = "TOTAL_INVENTARIO";
export const EXISTENCIAS_INVENTARIO     = "EXISTENCIAS_INVENTARIO";
export const TOTAL_VARIANTES            = "TOTAL_VARIANTES";


export const OBTENER_INVENTARIO_DETALLE = "OBTENER_INVENTARIO_DETALLE";

// SIDELEFT
export const MINIMIZAR_SIDELEFT         = "MINIMIZAR_SIDELEFT";
export const MINIMIZAR_MENUBAR          = "MINIMIZAR_MENUBAR";
export const SELECCIONAR_MODULO_MENU    = "SELECCIONAR_MODULO_MENU";

// COLECCIONES
export const OBTENER_COLECCIONES        = "OBTENER_COLECCIONES";
export const GUARDAR_COLECCION          = "GUARDAR_COLECCION";
export const EDITAR_COLECCION           = "EDITAR_COLECCION";
export const ELIMINAR_COLECCION         = "ELIMINAR_COLECCION";

// LOGS
export const OBTENER_LOGS               = "OBTENER_LOGS";
export const GUARDAR_LOGS               = "GUARDAR_LOGS";
export const ELIMINAR_LOGS              = "ELIMINAR_LOGS";
export const MODIFICAR_LOGS             = "MODIFICAR_LOGS";

// PRELIMINARES
export const OBTENER_PRELIMINARES       = "OBTENER_PRELIMINARES";
export const GUARDAR_PRELIMINARES       = "GUARDAR_PRELIMINARES";
export const ELIMINAR_PRELIMINARES      = "ELIMINAR_PRELIMINARES";
export const MODIFICAR_PRELIMINARES     = "MODIFICAR_PRELIMINARES";
export const COMENTARIOS_PRELIMINARES   = "COMENTARIOS_PRELIMINARES";

// NEGOCIOS
export const OBTENER_NEGOCIOS           = "OBTENER_NEGOCIOS";
export const SELECCIONAR_RUTA_NEGOCIO   = "SELECCIONAR_RUTA_NEGOCIO";

// MERCADOS
export const OBTENER_MERCADOS       = "OBTENER_MERCADOS";
export const GUARDAR_MERCADOS       = "GUARDAR_MERCADOS";
export const ELIMINAR_MERCADOS      = "ELIMINAR_MERCADOS";
export const MODIFICAR_MERCADOS     = "MODIFICARMERCADOS";


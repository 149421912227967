/* eslint-disable import/no-anonymous-default-export */
import {
    VALIDAR_AJUSTES,
    MOSTRAR_OPCION,
    OBTENER_CONVERSACIONES,
    OBTENER_CONFIGURACIONES,
    MODIFICAR_TEMA
} from '../../types';

export default (state, action) => {
    switch(action.type){
        case MOSTRAR_OPCION:
            return{
                ...state,
                option: action.payload
            }
        case OBTENER_CONFIGURACIONES:
            let config = action.payload
            return{
                ...state,
                settings: config.settings,
                options: config.options
            }
        case MODIFICAR_TEMA:
            return{
                ...state,
                viewSettings: action.payload
            }
        case OBTENER_CONVERSACIONES:
            return{
                ...state,
                settings: action.payload
            }
        case VALIDAR_AJUSTES:
            return{
                ...state,
                viewSettings: action.payload,
                option: action.payload ? state.option : null
            }
        default:
            return state;
    }
}
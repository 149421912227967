import React, { useReducer } from 'react';
import SectionContext from './sectionContext';
import SectionReducers from './sectionReducers';
import clientAxios from '../../../src/config/axios'

import { 
    SECCIONES_MODULO,
    AGREGAR_SECCION,
    VALIDAR_SECCION,
    ELIMINAR_SECCION,
    ESTADO_SECCION,
    SECCION_ACTUAL,
    ACTUALIZAR_SECCION,
    LIMPIAR_SECCION,
    ABRIR_SECCION,
    SELECCIONAR_SECCION,
    SECCION_ID
} from '../../types';


const SectionState = props => {
    const initialState = {
        sectionsModules: [],
        errorSection: false,
        seccionSeleccionada: null,
        id_seccion: null,
        sections: [
            // {name: 'CRM', section: 'CRM', route: '/admin/crm'},
            // {id:0,  i_status: 1, name: 'Inicio',         type: 'general',         route: '/'},
            {id:1,  i_status: 0, name: 'Estudio',        type: 'Studio',          route: '/studio'},
            {id:2,  i_status: 0, name: 'Finanzas',       type: 'Financial',       route: '/financial'},
            {id:3,  i_status: 0, name: 'Equipo',         type: 'People',          route: '/people'},
            {id:4,  i_status: 0, name: 'Tiendas',        type: 'Markets',         route: '/markets'},
            {id:5,  i_status: 1, name: 'Logistica',      type: 'Logistics',       route: '/logistics'},
            {id:6,  i_status: 1, name: 'Canales',        type: 'Multichannel',    route: '/channels'},
            {id:7,  i_status: 0, name: 'Analítica',      type: 'Analytics',       route: '/analytics'},
            {id:8,  i_status: 1, name: 'Clientes',       type: 'customers',       route: '/customers'},
            {id:9,  i_status: 0, name: 'Envíos',         type: 'Shipment',        route: '/shipment'},
            {id:10, i_status: 0, name: 'Lealtad',        type: 'Loyalty',         route: '/loyalty'},
        ],
        seccionesAbiertas: {}
    }

    const [state, dispatch] = useReducer(SectionReducers, initialState)

    // Crear las funciones

    // Obtener secciones del modulo

    const obtenerSecciones = async modulo =>{
        try{
            const res = await clientAxios.get('/api/sections', {params:{modulo}})
            dispatch({
                type: SECCIONES_MODULO,
                payload: res.data.secciones
            })
        } catch(err){
            console.log(err.status)

        }
    }

    const agregarSección = async section => {
        try{
            const res = await clientAxios.post('/api/sections', section)
            dispatch({
                type: AGREGAR_SECCION,
                payload: res
            })
        }catch(err){
            console.log(err)
        }
    }

    const validarSeccion = () => {
        dispatch({
            type: VALIDAR_SECCION
        })
    }

    const eliminarSeccion = id => {
        dispatch({
            type: ELIMINAR_SECCION,
            payload: id
        })
    }

    const abrirSeccion = (id, id_seccion=null) => {
        try{
            if(id_seccion){
                dispatch({
                    type: SECCION_ID,
                    payload: id_seccion
                })
            }
            dispatch({
                type: ABRIR_SECCION,
                payload: {id, open: state.seccionesAbiertas[`section-${id+1}`]}    
            })

        }catch(err){
            console.log(err)
        }
    }

    const cambiarEstado = async val => {
        try{
            await clientAxios.put(`/api/sections/${val._id}`, {modulo:val.modulo, estado: val.estado})
            dispatch({
                type: ESTADO_SECCION,
                payload: val
            })
        }catch(err){
            console.log(err)    
        }
    }

    const guardarSeccionActual = (seccion, id_seccion = null) => {
        if(id_seccion){
            dispatch({
                type: SECCION_ID,
                payload: id_seccion
            })    
        }
        dispatch({
            type: SECCION_ACTUAL,
            payload: seccion
        })
    }

    const modificaSeccion = seccion => {
        dispatch({
            type: ACTUALIZAR_SECCION,
            payload: seccion
        })
    }

    const seleccionarSeccion = seccion => {
        dispatch({
            type: SELECCIONAR_SECCION,
            payload: seccion
        })
    }

    // Elimina seccion seleccionada
    const limpiarSeccion = () => {
        dispatch({
            type: LIMPIAR_SECCION
        })
    }
    return(
        <SectionContext.Provider
            value={{
                sections: state.sections,
                id_seccion: state.id_seccion,
                sectionsModules: state.sectionsModules,
                errorSeccion: state.errorSection,
                estado: state.estado,
                seccionSeleccionada: state.seccionSeleccionada,
                seccionesAbiertas: state.seccionesAbiertas,
                abrirSeccion, 
                obtenerSecciones,
                agregarSección,
                validarSeccion,
                eliminarSeccion,
                cambiarEstado,
                guardarSeccionActual,
                modificaSeccion,
                limpiarSeccion,
                seleccionarSeccion
            }}
        >
            {props.children}
        </SectionContext.Provider>
    )

}

export default SectionState;

/* eslint-disable import/no-anonymous-default-export */
import { 
    OBTENER_MERCADOS
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case OBTENER_MERCADOS:
            return {
                ...state,
                mercados: action.payload
            }
        default:
            return state
    }
}
import React, { useReducer } from 'react';
import stylesContext from './stylesContext';
import stylesReducers from './stylesReducers';
import clientAxios from '../../config/axios'

import {
    OBTENER_ESTILOS,
} from '../../types';

const StylesState = props => {
    const initialState = {
        styles: []
    }

    const [state, dispatch] = useReducer(stylesReducers, initialState)

    const getStyles = async (options, settings) => {
        let styles = []

        try{
            if(settings){
                await settings.map(async setting=>{
                    if(setting.name === "General"){
                        let optionSetting = await options.filter(option => option.settingId === setting._id)
                        optionSetting.map(opSet => {
                            styles = {
                                ...styles,
                                [opSet.name]: opSet.value
                            }
                        })
                    }
                })
            }
            if(styles){
                dispatch({
                    type: OBTENER_ESTILOS,
                    payload: styles
                })
            }
        }catch(err){
            console.log(err)
        }
    }

    return(
        <stylesContext.Provider
            value={{
                styles: state.styles,
                getStyles
            }}
        >
            {props.children}
        </stylesContext.Provider>
    )
}

export default StylesState;
import { Box, Button, ButtonGroup, Divider, Grid, InputAdornment, styled, TextField, Typography } from "@mui/material"
import { BotonJCs } from "../../material"
import { useContext, useEffect, useRef, useState } from "react"
import Icon from "../../layout/Material/Icons"
import MenuComponente from '../../elements/MenuComponente'
import Detalle from "./Detalle"
import preliminaresContext from "../../../context/preliminares/preliminaresContext"
import EnhancedTable from "./Tabla"
import { useContainerDimensions } from "../../../functions"

const DivCotizaciones = styled('div')({})

const btnMenu = {
    background: "#626262",
    color: "white",
    textTransform: 'none',
    padding: "10px !important",
    margin: "5px",
    fontSize: "12px",
    fontFamily: "semibold",
    // width: "60px",
    height: "30px"
}

const btnMenuActive = {
    background: "#EBEBEB",
    color: "#303030",
    textTransform: 'none',
    padding: "10px !important",
    margin: "5px",
    fontSize: "12px",
    fontFamily: "semibold",
    height: "30px"
}

function createData(
    id,
    s_notes,
    s_name,
    d_date
) {
    return {
      id,
      s_notes,
      s_name,
      d_date
    };
  }

const Cotizaciones = (props) => {
    const PreliminaresContext = useContext(preliminaresContext);
    const { preliminares, obtenerPreliminares } = PreliminaresContext;

    const [b_importat, setB_importar] = useState(false)
    const [b_exportar, setB_exportar] = useState(false)
    const [verDetalle, setVerDetalle] = useState(false)
    const [rows, setRows] = useState([])
    const [seleccion, setSeleccion]   = useState(null)
    let id_negocio_ = localStorage.getItem("id_route")

    useEffect(()=>{
        if(id_negocio_){
            obtenerPreliminares(id_negocio_)
        }
    },[id_negocio_])

    useEffect(()=>{
        if(preliminares?.length > 0){
            let JSON_preliminar = preliminares.map((preliminar, i_preliminar) => {
                return createData(
                    i_preliminar,
                    preliminar.s_notes,
                    (<Typography  onClick={e=>{
                        setVerDetalle(true)
                        setSeleccion(preliminar)
                    }} sx={{
                        margin: "0px !important",
                        fontSize: "12px",
                        fontFamily: "semibold",
                        "&:hover": {
                            textDecoration: 'underline',
                        }
                    }}>
                        {preliminar.s_name}
                    </Typography>),
                    preliminar.d_date
                )
            })

            setRows(JSON_preliminar)
        }
    },[preliminares])

    useEffect(()=>{
        if(!verDetalle){setSeleccion(null)}
    },[verDetalle])

    const Side_Filter = (props) => {
        let {
            n_productos     = 0,
            n_existencia    = 0,
            n_totales       = 0,
            filterFunction  = e=>console.log("filter"),
            statusFilter    = e=>console.log("filter")
        } = props
    
        const [btnActivado, setBtnActivado] = useState(0);
        const [search, setSearch] = useState(false);
    
        useEffect(()=>{
            statusFilter(btnActivado)
        }, [btnActivado])
    
        return(<Box>
            <Box sx={{
                marginY: "20px",
                borderRadius: "8px",
                background: "white !important",
                width: "100%",
                boxShadow: "0px 2px 5px -4px black"
            }}>
                <Grid container>
                    {search ? (<Grid item xs={10} sx={{paddingLeft: "5px", display: "flex", justifyContent: "start", alignItems: "center"}}>
                        <Box sx={{width: "100%", "& input":{
                            outline: "none !important",
                            padding: "5px 2px !important",
                            fontSize: "12px",
                        }}}>
                            <TextField
                                onChange={filterFunction}
                                placeholder="Buscando en todos los productos"
                                sx={{
                                    width: "100%",
                                    outline: "none",
                                    margin: "12px 10px",
                                    background: "#FAFAFA",
                                    fontFamily: "semibold",
                                    "& .MuiOutlinedInput-root": {
                                        fontFamily: "'semibold'",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          borderColor: "#EBEBEB",
                                          borderWidth: "1px",
                                        },
                                      },
                                      // Class for the label of the input field
                                      "& .MuiInputLabel-outlined": {
                                        color: "#626262",
                                      },
                                }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon name="SearchLigth" sx={{height: "12px", width: "12px"}}/>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>):(<Grid item xs={10} sx={{paddingLeft: "5px", display: "flex", justifyContent: "start"}}>
                        <Box sx={{margin: "6px"}}>
                            <Typography sx={{fontSize: "12px", fontWeight: "bold"}}>
                                {n_productos}
                            </Typography>
                            <Typography sx={{
                                fontSize: "12px",
                                color: "#626262"
                            }}>
                                Cotizaciones
                            </Typography>
                        </Box>
                        {/* <Box sx={{margin: "6px"}}>
                            <Typography sx={{fontSize: "12px", fontWeight: "bold"}}>{n_totales}</Typography>
                            <Typography sx={{
                                fontSize: "12px",
                                color: "#626262"
                            }}>
                                SKU
                            </Typography>
                        </Box>
                        <Box sx={{margin: "6px"}}>
                            <Typography sx={{fontSize: "12px", fontWeight: "bold"}}>{n_existencia}</Typography>
                            <Typography sx={{
                                fontSize: "12px",
                                color: "#626262"
                            }}>
                                Proveedores
                            </Typography>
                        </Box> */}
                    </Grid>)}
                    <Grid item xs={2} sx={{display: "flex", justifyContent: "end"}}>
                        <Box sx={{margin: "6px", display: "flex", marginRight: "10px", alignItems: "center"}}>
                            <Box onClick={e=>{
                                    setSearch(!search)
                                }} sx={{
                                marginX: "5px",
                                background: "#EBEBEB",
                                width: "30px",
                                height: "30px",
                                display: "grid",
                                justifyContent: "center",
                                alignContent: "center",
                                borderRadius: "8px",
                                cursor: "pointer"
                            }}>
                                <Icon name="SearchDark" sx={{height: "12px", width: "12px"}}/>
                            </Box>
                            <Box sx={{
                                marginX: "5px",
                                background: "#EBEBEB",
                                width: "30px",
                                height: "30px",
                                display: "grid",
                                justifyContent: "center",
                                alignContent: "center",
                                borderRadius: "8px",
                                cursor: "pointer"
                            }}>
                                <MenuComponente/>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Divider color="#EBEBEB"/>
                <Box sx={{
                    marginLeft: "5px",
                    "& #filtro":{fontSize: "12px !important"},
                    "& button": {
                        borderRadius: "8px !important",
                    }
                }}>
                    <ButtonGroup variant="standard" aria-label="outlined button group">
                        <Button onClick={e => setBtnActivado(0)} sx={btnActivado == 0? btnMenu: btnMenuActive}>Todos</Button>
                        {/* <Button onClick={e => setBtnActivado(1)} sx={btnActivado == 1? btnMenu: btnMenuActive}>Borradores</Button>
                        <Button onClick={e => setBtnActivado(2)} sx={btnActivado == 2? btnMenu: btnMenuActive}>Activos</Button> */}
                        {/* <Button onClick={e => setBtnActivado(3)} sx={btnActivado == 3? btnMenu: btnMenuActive}>Archivados</Button> */}
                    </ButtonGroup>
                </Box>
            </Box> 
        </Box>)
    }

    const AreaButton = ({setB_importar}) => {
        return(<Box sx={{
            display: "grid",
            alignContent: "start",
            justifyContent: "end"
        }}>
            <Box sx={{display: "flex"}}>
                <BotonJCs onClick={e=>setB_exportar(true)}>Exportar</BotonJCs>
                {/* <BotonJCs sx={{marginLeft: "8px"}} onClick={e=>setB_importar(true)}>Importar</BotonJCs> */}
                <BotonJCs sx={{
                    marginLeft: "8px",
                    bgcolor: "#3F3F3F !important",
                    height: "fit-content"
                }} onClick={() => {
                    setVerDetalle(true)
                }}>Crear cotización</BotonJCs>
            </Box>
        </Box>)
    }

    const MenuSeccion = () => {
        return(
            <Box className='area-modulo' sx={{borderRadius: "5px"}}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography id="titulo-modulo" sx={[{
                            paddingBottom: '0px',
                            textAlign: 'start',
                            fontWeight: "bold",
                            color: "black !important"
                        }]} variant='h6'>Cotizaciones</Typography>
                        <Box sx={{"& p":{color: "#626262 !important", fontSize: "12px", margin: "0px !important"}}}>
                            <Box>
                                <Typography>Crea y administra los productos físicos, digitales, o servicios de tu negocio.</Typography> 
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{
                        textAlign: "end",
                        "& #btn-agregar":{
                            margin: "10px !important", padding: '5px 20px !important'
                        }
                    }}>
                        <AreaButton setB_importar={setB_importar} nombre_modulo="Productos"/>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const ref = useRef(null), { height } = useContainerDimensions(ref)

    return(<DivCotizaciones>
        {verDetalle ? (<Detalle data={seleccion} go_to_menu={e=> setVerDetalle(false)}/>): (<DivCotizaciones>
            <Box ref={ref}>
                <MenuSeccion/>
                <Side_Filter/>
            </Box>
            <EnhancedTable
                hx={height}
                loading={false}
                rows={rows}
                edicion_masiva={e=>console.log("ldklkadls")}
                headCells={[{
                    id: 'id',
                    numeric: true,
                    disablePadding: true,
                    label: '',
                    width: 250
                },{
                    id: 's_name',
                    numeric: false,
                    disablePadding: true,
                    label: 'folio',
                    width: 250
                },{
                    id: 's_notes',
                    numeric: true,
                    disablePadding: false,
                    label: 'notas',
                    minWidth: '250px'
                },{
                    id: 'd_date',
                    numeric: true,
                    align: "rigth",
                    disablePadding: false,
                    label: 'fecha',
                    width: 100
                }]}
            />
        </DivCotizaciones>)}
    </DivCotizaciones>)
}

export default Cotizaciones
import {
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Stack,
    styled,
    TextField,
    Typography
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { BotonJC, BotonJCs } from "../../material";
import { Add, AddToPhotos, ArrowBackIos, ArrowBackIosNew, ArrowForwardIos, Circle, Close, Delete, DeleteForever, DeleteOutline, DragIndicator, Edit, Favorite, FavoriteBorder, FiberManualRecord, PhotoCameraBack, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import {LoadingContext, ProductosContext, SucursalesContext, VariantesContext} from "../../../context/";
import inventarioContext from "../../../context/inventario/inventarioContext";
import Icon from "../../layout/Material/Icons";
// import Variante_Detalle from './Variantes/Detalle'
// import _, { keys } from 'lodash';
// import EnhancedTable from "./Tabla_Variantes";
// import Sucursal from "./Sucursal";
import { isObjectEmpty } from "../../functions";
import imgcomment from '../../../img/lavteg/lavteg_1.jpg'
import { convertMonths, useContainerDimensions } from "../../../functions";
import zIndex from "@mui/material/styles/zIndex";
import preliminaresContext from "../../../context/preliminares/preliminaresContext";
import contactsContext from "../../../context/contacts/contactsContext";
import Carousel from "./Carousel";
import { printFile } from "../../../functions/FileJsonToPdf";
import BasicModal from "./Modal";
import BotonDiseno from "./BotonDiseno";
import mercadosContext from "../../../context/mercados/mercadosContext";
import phonesContext from "../../../context/phones/phonesContext";
import clientsContext from "../../../context/clients/clientsContext";
import { flags_select } from "../../../img/country_flags";

const Letter = styled('p')({
    fontSize: "12px",
    fontFamily: "semibold",
    margin: "0px",
    color: "#787878"
})

const Imgcomment = styled('img')({
    height: "45px",
    width: "45px",
    borderRadius: "10px"
})

const General = ({no, setForm, form, setCotizaciones, cotizaciones}) => {
    const [search, setSearch] = useState("")
    const [lista, setLista] = useState([])
    const [agregados, setAgregados] = useState([])
    const [search_array, setSearch_Array] = useState([])
    const productosContext = useContext(ProductosContext);
    const {
        obtenerProductosPorPreliminar, 
        productos
    } = productosContext;

    useEffect(()=>{
        let filter = cotizaciones.filter(cotiz => cotiz?.n_page == no)
        if(filter?.length > 0){
            if(filter[0]?.items?.length > 0){
                console.log(filter[0])
            }
            setAgregados(filter[0]?.items?.length > 0 ? filter[0].items: [])
        }else{
            setAgregados([])
        }
    },[no])

    useEffect(()=>{ obtenerProductosPorPreliminar() },[])
    useEffect(async ()=>{
        
        let array_productos = [];
        let con_variantes   = await productos.filter(producto => producto?.variantes?.length > 0), 
        sin_variantes       = await productos.filter(producto => producto?.variantes?.length === 0);
        if(sin_variantes?.length > 0){array_productos = [...sin_variantes, ...array_productos]}
        if(con_variantes?.length > 0){
            con_variantes.map(arr_variante => {
                array_productos = [
                    ...array_productos,
                    ...arr_variante.variantes
                ]
                // arr_variante.variantes.map(variante_item => {
                // })
            })
        }

        setLista(await array_productos)
        setSearch_Array(await array_productos)
    },[productos])

    // useEffect(()=>{
    //     console.log(search_array)
    // },[search_array])

    useEffect(()=>{
        let cotiz = form?.cotizaciones?.length > 0 ? form.cotizaciones: []
        let filter = cotiz.filter(cotiz => cotiz?.n_page == no)
        if(filter?.length > 0){
            setAgregados(filter[0]?.items?.length > 0 ? filter[0].items: [])
        }
    },[cotizaciones, form])

    useEffect(()=>{
        let filter = search_array.filter(producto => producto.s_title.search(search.toLowerCase().split(' ').join(''))>-1)
        setLista(filter)
    },[search])

    useEffect(()=>{
        let total_suma = 0
        let sel_cotizacion = cotizaciones.filter(cotiz => cotiz?.n_page == no),
            n_descuento     = 0, 
            n_entrega       = 0, 
            n_impuesto      = 0

        if(sel_cotizacion?.length > 0){
            let props_cot = sel_cotizacion[0]
            n_descuento = props_cot?.n_descuento    ? props_cot.n_descuento:    0
            n_entrega   = props_cot?.n_entrega      ? props_cot.n_entrega:      0
            n_impuesto  = props_cot?.n_impuesto     ? props_cot.n_impuesto:     0
        }
        
        agregados.map(agregado => {
            let suma = agregado.n_cantidad*agregado.n_variant_price;
            total_suma = total_suma + suma
        })
    },[agregados])

    return(<Item>
        <Grid container>
            <Grid item xs={6}><Texto>Productos</Texto></Grid>
            <Grid item xs={6} sx={{
                display: "grid",
                alignContent: "center",
                justifyContent: "end",
                "& p":{
                    color: "rgba(0, 91, 211, 1) !important"
                }
            }}>
                <Texto sx={{
                    fontSize: "10px",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#2774da !important  "
                }}>Agregar articulo personalizado</Texto>
            </Grid>
        </Grid>
        <Box sx={{position: "relative", "& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Content>
                <Campo sx={{
                    width: "100%",
                    height: "21px",
                    outline: "none",
                    marginRight: "5px"
                }} value={search} onChange={e=>setSearch(e.target.value)}
                />
                <ButtonIconSearch>
                    <Icon name="SearchWhite" sx={{width: "12px", height:"12px"}}/>
                </ButtonIconSearch>
            </Content>
            {agregados?.length > 0 ? (<Box>
                <Box sx={{margin: "15px 10px", display: "flex"}}>         
                    <Grid container>
                        <Grid item xs={8}><Typography sx={{color: "black !important", fontFamily: "mulish-extra-bold", fontSize: "12px"}}>Producto</Typography></Grid>
                        <Grid item xs={2}><Typography sx={{color: "black !important", fontFamily: "mulish-extra-bold", fontSize: "12px", textAlign: "end"}}>Cantidad</Typography></Grid>
                        <Grid item xs={2}><Typography sx={{color: "black !important", fontFamily: "mulish-extra-bold", fontSize: "12px", textAlign: "end"}}>Total</Typography></Grid>
                    </Grid>
                </Box>
                {agregados.map((item, producto_key)=>{
                    let producto = item?.producto?.length > 0 ? item.producto[0]: item?.variante?.length > 0?item.variante[0]: {}
                    if(item?.n_cantidad == 0){
                        return(<Box></Box>)
                    }
                    return(<Box key={producto_key} sx={{margin: "15px 10px", display: "flex"}}>
                        <Box>
                            {producto?.s_variant_image ? 
                                (<Imgcomment src={producto.s_variant_image}/>): 
                                (<Box sx={{
                                    border: "1px solid #dad9d5",
                                    borderRadius:"5px",
                                    height: "35px",
                                    width: "35px",
                                    display: "grid",
                                    alignContent: "center",
                                    justifyContent: "center"
                                }}>
                                    <PhotoCameraBack/>
                                </Box>)
                            }
                        </Box>
                        <Box sx={{marginLeft: "10px", width: "100%"}}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography sx={{fontSize: "12px"}}>{producto?.s_title ? producto.s_title: ""}</Typography>
                                    <Typography sx={{fontSize: "12px"}}>SKU: {producto?.s_variant_sku ? producto.s_variant_sku: ""}</Typography>
                                </Grid>
                                <Grid item xs={2} sx={{
                                    "& input": {
                                        padding: "5px 0px",
                                        fontSize: "12px",
                                        textAlign: "end"
                                    }
                                }}>
                                    <TextField value={item?.n_cantidad ? item.n_cantidad: 0} onChange={e=>{
                                        let unit = parseInt(e.target.value)
                                        setForm(form_old => {
                                            return {
                                                ...form_old,
                                                cotizaciones: form_old?.cotizaciones?.length > 0 ? form_old.cotizaciones.map(item_producto => {
                                                    if(item_producto.n_page == no){
                                                            let items_old_producto = item_producto?.items?.length> 0 ? item_producto.items: []
                                                            let result_items = [];
                                                            result_items = items_old_producto.map((item_old_producto, id_item_old_producto) => {
                                                                if(id_item_old_producto == producto_key){
                                                                    if(unit == 0){
                                                                        return {
                                                                            ...item_old_producto,
                                                                            n_cantidad: unit,
                                                                            n_deleted: 1
                                                                        }
                                                                    }
                                                                    
                                                                    
                                                                    return {
                                                                        ...item_old_producto,
                                                                        n_cantidad: unit
                                                                    }
                                                                }
        
                                                                return item_old_producto
                                                                
                                                            })
        
                                                            return {
                                                                ...item_producto,
                                                                items: result_items
                                                            }
                                                        }
                                                    return item_producto
                                                }): []
                                            }
                                        })
                                    }} InputProps={{inputProps:{min:0}}} type="number" sx={{padding: "0px !important"}}/>
                                </Grid>
                                <Grid item xs={2} sx={{
                                    display: "grid",
                                    alignContent: "center"
                                }}>
                                    <Typography sx={{fontSize: "12px", textAlign: "end"}}>
                                        ${producto?.n_variant_price ? parseFloat(parseInt(producto.n_variant_price)*parseInt(item.n_cantidad)).toFixed(2): "0.00"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>)
                })
            }</Box>): null}
            {search != "" ? (<Box className="scroll-table-component-side-left" sx={{
                width: "100%",
                background: "white",
                marginY: "15px",
                position: "absolute",
                top: "10px",
                boxShadow: "0px 3px 10px -8px black",
                padding: "10px 15px",
                borderRadius: "8px",
                maxHeight: "300px",
                overflowY: "scroll",
                zIndex: 4
            }}>
                {lista?.length > 0 ? lista.map((item, item_key)=> {
                    return(<Box key={item_key} onClick={async ()=>{
                        if(cotizaciones?.length>0){
                            
                            setCotizaciones(cotizaciones_old => {
                                return cotizaciones_old.map(cotizacion_item_old => {
                                    let items_nuevos = []
                                    if(cotizacion_item_old?.n_page == no){
                                        if(cotizacion_item_old?.items?.length > 0){
                                            let items_existentes = cotizacion_item_old.items
                                            return {...cotizacion_item_old, items: items_nuevos = [
                                                ...items_existentes,
                                                {...item, n_cantidad: 1, producto: [item]}
                                            ]};
                                        }else{
                                            return {...cotizacion_item_old, items: items_nuevos = [
                                                {...item, n_cantidad: 1, producto: [item]}
                                            ]};
                                        }
                                    }else{
                                        return {...cotizacion_item_old};
                                    }
                                    
                                })
                            })
                        }else{
                            setCotizaciones([
                                ...cotizaciones,
                                {
                                    n_page: no,
                                    items: [
                                        {...item, producto: [item], n_cantidad: 1}
                                    ],
                                }
                            ])
                        }

                        let filter_assign = await lista.filter(item_lista => item_lista._id != item._id)
                        setLista(filter_assign)
                        setSearch("")
                    }} sx={{
                        display: "flex",
                        margin: "10px 0px",
                        "& :hover":{
                            background: "#f1f1f1",
                            cursor: "pointer"
                        }
                    }}>
                        <Imgcomment sx={{height: "30px", width: "30px"}} src={imgcomment}/>
                        <Box sx={{padding: "5px 10px", width: "100%"}}>
                            <Typography sx={{fontSize: "12px"}}>{item.s_title}</Typography>
                        </Box>
                    </Box>)
                }):null}
            </Box>):null}
        </Box>
    </Item>)
}

const Pagos = (props) => {
    const ContactsContext = useContext(contactsContext);
    const { contactos }   = ContactsContext;

    const [open, setOpen] = useState(0);
    const [typemodal, setTypemodal] = useState(0);
    const Tbl       = styled('table')({margin: "10px", width: "100%"})
    const Trtbl     = styled('tr')({"& p":{margin: "0px !important", padding: "0px 1important"}})
    const Tdtbl     = styled('td')({})
    const Labeltbl  = styled('p')({fontSize: "12px"})
    const Bodytbl   = styled('tbody')({})
    const Headtbl   = styled('thead')({})

    const {
        form,
        setForm = e => console.log("test"),
        page = 0,
        setPage = e => console.log(e),
        no = 0,
        letNoPage = e => console.log("No"),
        cotizaciones,
        setCotizaciones,
        // totalpago,
        // setTotalPago
    } = props;
    
    const agregar_descuento = value => {
        setOpen(value+1)
        setTypemodal(0)
    }

    const agregar_envio = value => {
        setOpen(value+1)
        setTypemodal(1)
    }

    const agregar_impuesto = value => {
        setOpen(value+1)
        setTypemodal(2)
    }

    const [cargo1, setCargo1] = useState([
        {name: "Subtotal",                  dis: false, desc: "", value: 0},
        {name: "Agregar descuento",         link: true, action: agregar_descuento, dis: true, desc: "", value: 0},
        {name: "Agregar envío o entrega",   link: true, action: agregar_envio, dis: true, desc: "", value: 0},
        {name: "Impuesto estimado",         link: true, action: agregar_impuesto,dis: true, desc: "No calculado", value: 0},
        {name: "Total",                     dis: true, value: 0}
    ])

    const [cargo2, setCargo2] = useState([
        {name: "Subtotal",                  dis: false, desc: "", value: 0},
        {name: "Agregar descuento",         link: true, action: agregar_descuento, dis: true, desc: "", value: 0},
        {name: "Agregar envío o entrega",   link: true, action: agregar_envio, dis: true, desc: "", value: 0},
        {name: "Impuesto estimado",         link: true, action: agregar_impuesto,dis: true, desc: "No calculado", value: 0},
        {name: "Total",                     dis: true, value: 0}
    ])

    const [cargo3, setCargo3] = useState([
        {name: "Subtotal",                  dis: false, desc: "", value: 0},
        {name: "Agregar descuento",         link: true, action: agregar_descuento, dis: true, desc: "", value: 0},
        {name: "Agregar envío o entrega",   link: true, action: agregar_envio, dis: true, desc: "", value: 0},
        {name: "Impuesto estimado",         link: true, action: agregar_impuesto,dis: true, desc: "No calculado", value: 0},
        {name: "Total",                     dis: true, value: 0}
    ])

    useEffect(()=>{
        let cotizaciones_pagos = form?.cotizaciones?.length > 0 ? form.cotizaciones: []
        const cargos_update = (array, cotizacion_pago) => {
            return array.map(array_item => {
                if(array_item?.name == "Agregar descuento"){
                    console.log(cotizacion_pago)
                    return {
                        ...array_item,
                        value: cotizacion_pago.n_descuento
                    }
                }
                if(array_item?.name == "Agregar envío o entrega"){
                    return {
                        ...array_item,
                        value: cotizacion_pago.n_entrega
                    }
                }
                if(array_item?.name == "Impuesto estimado"){
                    console.log(cotizacion_pago.n_impuesto)
                    return {
                        ...array_item,
                        value: cotizacion_pago.n_impuesto
                    }
                }
                if(array_item?.name == "Total"){
                    let items_cotizaciones = cotizacion_pago?.items?.length>0?cotizacion_pago.items:[]
                    let total_suma = 0
                    let n_descuento     = 0, 
                        n_entrega       = 0, 
                        n_impuesto      = 0

                    let props_cot = cotizacion_pago
                    console.log(props_cot)
                    n_descuento = props_cot?.n_descuento    ? props_cot.n_descuento:    0
                    n_entrega   = props_cot?.n_entrega      ? props_cot.n_entrega:      0
                    n_impuesto  = props_cot?.n_impuesto     ? props_cot.n_impuesto:     0
                    
                    
                    items_cotizaciones.map(agregado => {
                        let producto = agregado?.producto?.length > 0 ? agregado.producto[0]: agregado?.variante?.length > 0 ? agregado.variante[0]: {}
                        // if()
                        let suma = agregado.n_cantidad*producto.n_variant_price;
                        total_suma = total_suma + suma
                    })

                    return {
                        ...array_item,
                        value: total_suma - n_descuento - n_entrega - n_impuesto
                    }
                }
                if(array_item?.name == "Subtotal"){
                    let items_cotizaciones = cotizacion_pago?.items?.length>0?cotizacion_pago.items:[]
                    let total_suma = 0
                    
                    items_cotizaciones.map(agregado => {
                        let producto = agregado?.producto?.length > 0 ? agregado.producto[0]: agregado?.variante?.length > 0 ? agregado.variante[0]: {}
                        // if()
                        let suma = agregado.n_cantidad*producto.n_variant_price;
                        total_suma = total_suma + suma
                    })

                    return {
                        ...array_item,
                        value: total_suma
                    }
                }

                return array_item
            })
        }

        cotizaciones_pagos.map(cotizacion_pago => {
            if(cotizacion_pago?.n_page == 0){
                setCargo1(cargo1_old => cargos_update(cargo1_old, cotizacion_pago))
            }
            if(cotizacion_pago?.n_page == 1){
                setCargo2(cargo2_old => cargos_update(cargo2_old, cotizacion_pago))
            }
            if(cotizacion_pago?.n_page == 2){
                setCargo3(cargo3_old => cargos_update(cargo3_old, cotizacion_pago))
            }
        })
    },[form])

    const config_cargos = (id, name, value) => {
        switch(id){
            case 0:
                setCargo1(cargo_old_1 => {
                    return cargo_old_1.map(item_old_1 => {
                        if(item_old_1?.name == name){
                            return {
                                ...item_old_1,
                                value: value
                            }
                        }
                        return item_old_1
                    })
                })
                break;
            case 1:
                setCargo2(cargo_old_2 => {
                    return cargo_old_2.map(item_old_2 => {
                        if(item_old_2?.name == name){
                            return {
                                ...item_old_2,
                                value: value
                            }
                        }
                        return item_old_2
                    })
                })
                break;
            case 2:
                setCargo3(cargo_old_3 => {
                    return cargo_old_3.map(item_old_3 => {
                        if(item_old_3?.name == name){
                            return {
                                ...item_old_3,
                                value: value
                            }
                        }
                        return item_old_3
                    })
                })
                break;
            default:
                console.log("no accion")
                break;
        }
    }

    useEffect(()=>{
        if(cotizaciones?.length){
            cotizaciones.map((cotizacion, id_cotizacion) => {
                if(cotizacion?.items?.length > 0){
                    let items_cotizacion = cotizacion.items;
                    let subtotal = items_cotizacion.reduce((acumulador, actual) => acumulador + (parseInt(`${actual?.n_variant_price ? actual.n_variant_price: 0}`) * parseInt(`${actual?.n_cantidad ? actual.n_cantidad: 0}`)), 0);
                    config_cargos(id_cotizacion, "Subtotal", subtotal)
                }
            })
        }

    },[cotizaciones])
    const ref_items = useRef(null);
    const { width } = useContainerDimensions(ref_items);

    const elem = document.getElementById("ref_items");

    const agregar_pagina = e => {
        if(page < 2){
            console.log(page)
            setPage(page+1)
            let existe_pagina = cotizaciones.filter(cotizacion => cotizacion.n_page == (page+1))
            if(existe_pagina?.length == 0){
                setCotizaciones([
                    ...cotizaciones,
                    {
                        n_page: page+1
                    }
                ])
            }
            setTimeout(()=>{
                elem.scrollTo(width + 500, 0);
            }, 100)
        }
    }

    const BotonesFactura = ({no_cotizacion}) => {
        return(<Box sx={{display: "flex", justifyContent: "end"}}>
            <BotonJCs sx={{marginRight: "10px"}}>Cobrar Pago</BotonJCs>
            <BotonDiseno callback={tipo_diseno => {
                let filter_cliente = contactos.filter(contacto => contacto._id == form.id_cliente), 
                info_cliente = filter_cliente?.length > 0? filter_cliente[0]: null,
                
                lista_cotizaciones = form.cotizaciones,
                filter_cotizaciones = lista_cotizaciones.filter(item_cotizacion => item_cotizacion?.n_page == no_cotizacion),
                cotizacion_seleccionada = filter_cotizaciones?.length > 0 ? filter_cotizaciones[0]: null,
                lista_items = cotizacion_seleccionada?.items?.length > 0 ? cotizacion_seleccionada.items.map(item_cotizacion_seleccionada => {
                    let data_item = item_cotizacion_seleccionada, precio_unit = data_item?.n_variant_price ? data_item.n_variant_price: 0,
                    cantidad_producto = data_item?.n_cantidad ? data_item.n_cantidad: 0;
                    
                    return [
                        data_item?.n_cantidad ? data_item.n_cantidad: "",
                        data_item?.s_variant_sku ? data_item.s_variant_sku: "",
                        data_item?.s_title ? data_item.s_title: "",
                        `$ ${parseFloat(parseInt(precio_unit)).toFixed(2)}`,
                        `$ ${parseFloat(parseInt(precio_unit*cantidad_producto)).toFixed(2)}`
                    ]
                }):[]
                
                let data_por_cotizacion = [], subtotal_data_por_cotizacion = 0;
                if(no_cotizacion == 0){data_por_cotizacion = cargo1}
                if(no_cotizacion == 1){data_por_cotizacion = cargo1}
                if(no_cotizacion == 2){data_por_cotizacion = cargo1}

                let filter_per_item = data_por_cotizacion.filter(item_por_cotizacion => item_por_cotizacion?.name == "Subtotal")
                if(filter_per_item?.length > 0){
                    subtotal_data_por_cotizacion = filter_per_item[0].value
                }

                let n_descuento = parseInt(cotizacion_seleccionada?.n_descuento),
                n_entrega       = parseInt(cotizacion_seleccionada?.n_entrega),
                n_impuesto      = parseInt(cotizacion_seleccionada?.n_impuesto),
                n_subtotal      = parseInt(subtotal_data_por_cotizacion - (cotizacion_seleccionada?.n_descuento ? cotizacion_seleccionada.n_descuento: 0)),
                n_total         = n_subtotal - n_entrega - n_impuesto

                let fecha_propt = new Date(Date.now());
                let yyyy = fecha_propt.getFullYear(),
                mm = fecha_propt.getMonth() + 1, // Months start at 0!
                dd = fecha_propt.getDate(),
                eq_item = `${dd}/${convertMonths(mm)}/${yyyy}`;
                

                let propt = {
                    fecha: eq_item,
                    fecha_vencimiento: eq_item,
                    folio: form?.s_name ? form.s_name: "",
                    notas: form?.s_notes ? form.s_notes: "Sin notas del cliente",
                    nombre: `${info_cliente?.name ? info_cliente.name: ""} ${info_cliente?.lastName ? info_cliente.lastName: ""}`,
                    items: lista_items,
                    cliente: contactos?.length > 0 ? contactos.filter(contacto => contacto._id == form.id_cliente): null,
                    resumen_descuento: `$ ${parseFloat(n_descuento).toFixed(2)}`,
                    resumen_envio: `$ ${parseFloat(n_entrega).toFixed(2)}`,
                    resumen_impuesto: `$ ${parseFloat(n_impuesto).toFixed(2)}`,
                    resumen_subtotal: `$ ${parseFloat(n_subtotal).toFixed(2)}`,
                    resumen_total: `$ ${parseFloat(n_total).toFixed(2)}`,                    
                }

                switch(tipo_diseno){
                    case 1:
                        console.log(form)
                        return printFile(propt)
                    default:
                        return printFile(propt)


                }
            }}/>
        </Box>)
    }

    const ContentModalDescuentos = props => {
        const [value, setValue] = useState("0.00");

        return(<>
        <Box sx={{ 
            padding: "15px",
            borderRadius: "8px 8px 0px 0px",
            "& p":{color: "black", fontSize: "12px", fontFamily: "mulish-extra-bold"},
            background:"#f3f3f3",
            position:"relative"
        }}>
            <Typography>Agregar descuento</Typography>
            <Close onClick={e=>setOpen(false)} sx={{position: "absolute", right: 15, top: 15, fontSize: "18px", cursor: "pointer"}}/>
        </Box>
        <Box sx={{display: "flex", margin: "15px", alignItems: "center", "& input":{
            padding: "5px 8px",
            fontSize: "12px",
        }}}>
            <Typography sx={{color: "black", fontSize: "12px", minWidth: "130px"}}>Monto del descuento:</Typography>
            <FormControl fullWidth sx={{
                "& input":{padding: "0px"},
                "& p, div":{padding: "2px", fontSize: "12px", margin: "0px"},
            }}>
                <OutlinedInput
                    type="number"
                    inputProps={{min: 0}}
                    value={value}
                    onChange={e=>setValue(e.target.value)}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
            </FormControl>
        </Box>
        <Box sx={{display: "flex", justifyContent: "end", margin: "15px"}}>
            <BotonJC sx={{marginRight: "10px"}}>Cancelar</BotonJC>
            <BotonJCs onClick={e=> {
                function eliminarDiacriticos(texto) {
                    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
                }

                let pagina_seteada = open-1;
                const arrayfunction = array => {
                    return array.map(array_item => {
                        let str_array_item = array_item?.name?.toLowerCase().split(' ').join('')
                        if(eliminarDiacriticos(str_array_item) == "agregardescuento"){
                            return {
                                ...array_item,
                                value: parseInt(value)
                            }
                        }
                        
                        return array_item
                    })
                }

                setForm(form_old => {
                    let todas_cotizaciones = form_old?.cotizaciones?.length > 0 ? form_old.cotizaciones: [], 
                    form_select_cotizacion = todas_cotizaciones.filter(cotizacion => cotizacion.n_page == pagina_seteada);
                    
                    if(form_select_cotizacion?.length > 0){
                        return {
                            ...form_old,
                            cotizaciones: form_old.cotizaciones.map(item_form_old => {
                                console.log(item_form_old,item_form_old)
                                if(item_form_old.n_page == pagina_seteada){
                                    return {
                                        ...item_form_old,
                                        n_descuento: parseInt(value),
                                    }
                                }

                                return item_form_old
                            })
                        }
                    }

                    return form_old
                })

                if(pagina_seteada == 0){ setCargo1(cargos_old => arrayfunction(cargos_old))}
                if(pagina_seteada == 1){ setCargo2(cargos_old => arrayfunction(cargos_old))}
                if(pagina_seteada == 2){ setCargo3(cargos_old => arrayfunction(cargos_old))}
                setOpen(false)
            }}>Aplicar</BotonJCs>
        </Box>
    </>)}

    const ContentModalEnvios = props => {
        const [value, setValue] = useState("0.00");

        return(<>
        <Box sx={{ 
            padding: "15px",
            borderRadius: "8px 8px 0px 0px",
            "& p":{color: "black", fontSize: "12px", fontFamily: "mulish-extra-bold"},
            background:"#f3f3f3",
            position:"relative"
        }}>
            <Typography>Agregar envío o entrega</Typography>
            <Close onClick={e=>setOpen(false)} sx={{position: "absolute", right: 15, top: 15, fontSize: "18px", cursor: "pointer"}}/>
        </Box>
        <Box sx={{display: "flex", margin: "15px", alignItems: "center", "& input":{
            padding: "5px 8px",
            fontSize: "12px",
        }}}>
            <Typography sx={{color: "black", fontSize: "12px", minWidth: "130px"}}>Monto del descuento:</Typography>
            <FormControl fullWidth sx={{
                "& input":{padding: "0px"},
                "& p, div":{padding: "2px", fontSize: "12px", margin: "0px"},
            }}>
                <OutlinedInput
                    type="number"
                    inputProps={{min: 0}}
                    value={value}
                    onChange={e=>setValue(e.target.value)}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
            </FormControl>
        </Box>
        <Box sx={{display: "flex", justifyContent: "end", margin: "15px"}}>
            <BotonJC sx={{marginRight: "10px"}}>Cancelar</BotonJC>
            <BotonJCs onClick={e=> {
                let pagina_seteada = open-1;
                function eliminarDiacriticos(texto) {
                    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
                }

                const arrayfunction = array => {
                    return array.map(array_item => {
                        let str_array_item = array_item?.name?.toLowerCase().split(' ').join('')
                        if(eliminarDiacriticos(str_array_item) == "agregarenviooentrega"){
                            return {
                                ...array_item,
                                value: parseInt(value)
                            }
                        }
                        
                        return array_item
                    })
                }

                setForm(form_old => {
                    let todas_cotizaciones = form_old?.cotizaciones?.length > 0 ? form_old.cotizaciones: [], 
                    form_select_cotizacion = todas_cotizaciones.filter(cotizacion => cotizacion.n_page == pagina_seteada);
                    
                    if(form_select_cotizacion?.length > 0){
                        return {
                            ...form_old,
                            cotizaciones: form_old.cotizaciones.map(item_form_old => {
                                if(item_form_old.n_page == pagina_seteada){
                                    return {
                                        ...item_form_old,
                                        n_entrega: parseInt(value)
                                    }
                                }

                                return item_form_old
                            })
                        }
                    }

                    return form_old
                })

                if(pagina_seteada == 0){ setCargo1(cargos_old => arrayfunction(cargos_old))}
                if(pagina_seteada == 1){ setCargo2(cargos_old => arrayfunction(cargos_old))}
                if(pagina_seteada == 2){ setCargo3(cargos_old => arrayfunction(cargos_old))}
                setOpen(false)
            }}>Aplicar</BotonJCs>
        </Box>
    </>)}

    const ContentModalImpuestos = () => {
        const [value, setValue] = useState("0.00");

        return(<>
        <Box sx={{ 
            padding: "15px",
            borderRadius: "8px 8px 0px 0px",
            "& p":{color: "black", fontSize: "12px", fontFamily: "mulish-extra-bold"},
            background:"#f3f3f3",
            position:"relative"
        }}>
            <Typography>Agregar estimación de impuestos</Typography>
            <Close onClick={e=>setOpen(false)} sx={{position: "absolute", right: 15, top: 15, fontSize: "18px", cursor: "pointer"}}/>
        </Box>
        <Box sx={{display: "flex", margin: "15px", alignItems: "center", "& input":{
            padding: "5px 8px",
            fontSize: "12px",
        }}}>
            <Typography sx={{color: "black", fontSize: "12px", minWidth: "130px"}}>Generar impuesto:</Typography>
            <FormControl fullWidth sx={{
                "& input":{padding: "0px"},
                "& p, div":{padding: "2px", fontSize: "12px", margin: "0px"},
            }}>
                <Select style={{
                    borderRadius: "8px",
                    fontSize: "12px",
                    padding: "5px 10px"
                }} onChange={e=>{
                    setValue(e.target.value)
                }} value={value}>
                    <MenuItem value={1}>6%</MenuItem>
                    <MenuItem value={2}>8%</MenuItem>
                    <MenuItem value={3}>10%</MenuItem>
                    <MenuItem value={4}>13%</MenuItem>
                    <MenuItem value={5}>15%</MenuItem>
                    <MenuItem value={6}>16%</MenuItem>
                    <MenuItem value={7}>18%</MenuItem>
                </Select>
                {/* <OutlinedInput
                    type="number"
                    inputProps={{min: 0}}
                    value={value}
                    onChange={e=>setValue(e.target.value)}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                /> */}
            </FormControl>
        </Box>
        <Box sx={{display: "flex", justifyContent: "end", margin: "15px"}}>
            <BotonJC sx={{marginRight: "10px"}}>Cancelar</BotonJC>
            <BotonJCs onClick={e=> {
                let pagina_seteada = open-1;
                function eliminarDiacriticos(texto) {
                    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
                }

                const arrayfunction = array => {
                    return array.map(array_item => {
                        let str_array_item = array_item?.name?.toLowerCase().split(' ').join('')
                        if(eliminarDiacriticos(str_array_item) == "impuestoestimado"){
                            return {
                                ...array_item,
                                value: parseInt(value)
                            }
                        }
                        
                        return array_item
                    })
                }

                setForm(form_old => {
                    let percent = 0;
                    if(value == 1){percent = 0.06}
                    if(value == 2){percent = 0.08}
                    if(value == 3){percent = 0.10}
                    if(value == 4){percent = 0.13}
                    if(value == 5){percent = 0.15}
                    if(value == 6){percent = 0.16}
                    if(value == 7){percent = 0.18}
                    let todas_cotizaciones = form_old?.cotizaciones?.length > 0 ? form_old.cotizaciones: [], 
                    form_select_cotizacion = todas_cotizaciones.filter(cotizacion => cotizacion.n_page == pagina_seteada);
                    let cargo_arr = null

                    if(pagina_seteada == 0){ cargo_arr=cargo1 }
                    if(pagina_seteada == 1){ cargo_arr=cargo2 }
                    if(pagina_seteada == 2){ cargo_arr=cargo3 }
                    
                    let subtotal = cargo_arr.filter(item_array => item_array?.name == "Subtotal"), subtotal_subtr = 0;
                    if(subtotal?.length > 0){
                        subtotal_subtr = subtotal[0]?.value;
                    }

                    console.log(value, subtotal_subtr)
                    let valor_cambio = percent * parseInt(subtotal_subtr)

                    if(form_select_cotizacion?.length > 0){
                        return {
                            ...form_old,
                            cotizaciones: form_old.cotizaciones.map(item_form_old => {
                                if(item_form_old.n_page == pagina_seteada){
                                    return {
                                        ...item_form_old,
                                        n_impuesto: valor_cambio
                                    }
                                }

                                return item_form_old
                            })
                        }
                    }

                    return form_old
                })

                if(pagina_seteada == 0){ setCargo1(cargos_old => arrayfunction(cargos_old))}
                if(pagina_seteada == 1){ setCargo2(cargos_old => arrayfunction(cargos_old))}
                if(pagina_seteada == 2){ setCargo3(cargos_old => arrayfunction(cargos_old))}
                setOpen(false)
            }}>Aplicar</BotonJCs>
        </Box>
    </>)}

    const Factura = ({pagina, cargo_seleccionado = []}) => {
        let style_fact_add = {
            display: "block !important",
            textAlign: "start !important",
            maxWidth: "360px",
            minWidth: "360px",
            position: "relative",
            marginRight: pagina == 2 ? "0px":"20px", 
        },
        style_fact_normal = {
            display: "block !important",
            textAlign: "start !important",
            maxWidth: "530px",
            minWidth: "530px",
            marginRight: page == 0 ? "0px":"20px", 
            position: "relative"
        }

        return(<Item sx={pagina == 0? style_fact_normal: style_fact_add}>
            <Texto>Pago</Texto>
            <AreaFile>
                <Box style={{display: "flex"}}>
                    <Tbl>
                        <Headtbl></Headtbl>
                        <Bodytbl>
                            {cargo_seleccionado?.length > 0? cargo_seleccionado.map((cargo, i_cargo) => {
                                let accion = cargo?.action ? cargo?.action: ()=>console.log("Accion None")
                                return(<Trtbl key={i_cargo}>
                                <Tdtbl sx={{width: "40%"}}>
                                    {cargo?.link ? (<Labeltbl onClick={e=>accion(pagina)} sx={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        color: cargo?.dis ? "#c8c8c8 !important":"black"
                                    }}>{cargo?.name ? cargo.name: "--"}</Labeltbl>
                                    ):(<Labeltbl  sx={{
                                        color: cargo?.dis ? "#c8c8c8 !important":"black"
                                    }}>{cargo?.name ? cargo.name: "--"}</Labeltbl>)}
                                </Tdtbl>
                                <Tdtbl sx={{width: "50%"}}><Labeltbl>{cargo?.desc ? cargo.desc: "--"}</Labeltbl></Tdtbl>
                                <Tdtbl sx={{width: "10%", textAlign: "end"}}>
                                    <Labeltbl>${cargo?.value && typeof cargo?.value == "number" ? parseFloat(cargo.value).toFixed(2): parseFloat(0).toFixed(2)}</Labeltbl>
                                </Tdtbl>
                            </Trtbl>)}): null}
                            {/* <Trtbl>
                                <Tdtbl>
                                    <Box sx={{
                                        height: "40px",
                                        display: "grid",
                                        alignContent: "end",
                                        "& p":{
                                            fontFamily: "'mulish-extra-bold'"
                                        }
                                    }}><Labeltbl>Total</Labeltbl></Box>
                                </Tdtbl>
                                <Tdtbl>
                                    <Box sx={{
                                        height: "50px",
                                        display: "grid",
                                        alignContent: "end",
                                        "& p":{
                                            fontFamily: "'mulish-extra-bold'"
                                        }
                                    }}><Labeltbl></Labeltbl></Box>
                                </Tdtbl>
                                <Tdtbl>
                                    <Box sx={{
                                        height: "50px",
                                        display: "grid",
                                        alignContent: "end",
                                        justifyContent: "end",
                                        "& p":{
                                            fontFamily: "'mulish-extra-bold'"
                                        }
                                    }}><Labeltbl>${cargo?.value && typeof cargo?.value == "number" ? parseFloat(cargo.value).toFixed(2): parseFloat(0).toFixed(2)}</Labeltbl></Box>
                                </Tdtbl>
                            </Trtbl> */}
                        </Bodytbl>
                    </Tbl>
                </Box>
            </AreaFile>
            <Comentario>
                Agrega un producto para calcular el total y ver las opciones de pago.
            </Comentario>
            <BotonesFactura no_cotizacion={pagina}/>
            <Box sx={{display: "flex", justifyContent: "end", "& p": {color: "#2774da!important"}}}>
                <Typography onClick={agregar_pagina} sx={{
                    fontSize: "12px",
                    fontFamily: "semibold",
                    marginBottom: "10px",
                    cursor: "pointer",
                    textDecoration: "underline"
                }}>+ Agregar cotización</Typography>
            </Box>
            {no != pagina ? (<Box sx={{position: "absolute", top: 5, right: 10}}><Edit  sx={{
                cursor: "pointer"
            }} onClick={e=>{
                letNoPage(pagina)
                console.log("COTIZACIONES", cotizaciones)
            }}/></Box>):null}
        </Item>)
    }

    return(<Box>
        <BasicModal open={open} setOpen={setOpen}>
            {
                typemodal == 0 ? (<ContentModalDescuentos/>):
                typemodal == 1? (<ContentModalEnvios/>):
                typemodal == 2? (<ContentModalImpuestos/>):
            null}
        </BasicModal>
        <Box id="ref_items" ref={ref_items} sx={{display: "flex"}} className="carousel-scroll-bottom">
        {page >= 0 ? (<Factura pagina={0} cargo_seleccionado={cargo1}/>): null}
        {page >= 1 ? (<Factura pagina={1} cargo_seleccionado={cargo2}/>): null}
        {page >= 2 ? (<Factura pagina={2} cargo_seleccionado={cargo3}/>): null}
        </Box>
        {/* <Carousel>
            <Item sx={{display: "block !important", textAlign: "start !important", marginRight: "20px"}}>
                <Texto>Pago</Texto>
                <AreaFile>
                    <Box style={{display: "flex"}}>
                        <Tbl>
                            <Headtbl></Headtbl>
                            <Bodytbl>
                                {cargos?.length > 0? cargos.map((cargo, i_cargo) => (<Trtbl key={i_cargo}>
                                    <Tdtbl sx={{
                                        width: "40%",
                                        
                                    }}>
                                        <Labeltbl sx={{color: cargo?.dis ? "#c8c8c8 !important":"black"}}>{cargo?.name ? cargo.name: "--"}</Labeltbl>
                                    </Tdtbl>
                                    <Tdtbl sx={{width: "50%"}}><Labeltbl>{cargo?.desc ? cargo.desc: "--"}</Labeltbl></Tdtbl>
                                    <Tdtbl sx={{width: "10%", textAlign: "end"}}>
                                        <Labeltbl>${cargo?.value && typeof cargo?.value == "number" ? parseFloat(cargo.value).toFixed(2): parseFloat(0).toFixed(2)}</Labeltbl>
                                    </Tdtbl>
                                </Trtbl>)): null}
                                <Trtbl>
                                    <Tdtbl>
                                        <Box sx={{
                                            height: "40px",
                                            display: "grid",
                                            alignContent: "end",
                                            "& p":{
                                                fontFamily: "'mulish-extra-bold'"
                                            }
                                        }}><Labeltbl>Total</Labeltbl></Box>
                                    </Tdtbl>
                                    <Tdtbl>
                                        <Box sx={{
                                            height: "50px",
                                            display: "grid",
                                            alignContent: "end",
                                            "& p":{
                                                fontFamily: "'mulish-extra-bold'"
                                            }
                                        }}><Labeltbl></Labeltbl></Box>
                                    </Tdtbl>
                                    <Tdtbl>
                                        <Box sx={{
                                            height: "50px",
                                            display: "grid",
                                            alignContent: "end",
                                            justifyContent: "end",
                                            "& p":{
                                                fontFamily: "'mulish-extra-bold'"
                                            }
                                        }}><Labeltbl>${typeof totalpago == "number" ? parseFloat(totalpago).toFixed(2): parseFloat(0).toFixed(2)}</Labeltbl></Box>
                                    </Tdtbl>
                                </Trtbl>
                            </Bodytbl>
                        </Tbl>
                    </Box>
                </AreaFile>
                <Comentario>
                    Agrega un producto para calcular el total y ver las opciones de pago.
                </Comentario>
            </Item>
            <Item sx={{display: "block !important", textAlign: "start !important", maxWidth: "360px", minWidth: "360px", marginRight: "20px"}}>
                <Texto>Pago</Texto>
                <AreaFile>
                    <Box style={{display: "flex"}}>
                        <Tbl>
                            <Headtbl></Headtbl>
                            <Bodytbl>
                                {cargos?.length > 0? cargos.map((cargo, i_cargo) => (<Trtbl key={i_cargo}>
                                    <Tdtbl sx={{
                                        width: "40%",
                                        
                                    }}>
                                        <Labeltbl sx={{color: cargo?.dis ? "#c8c8c8 !important":"black"}}>{cargo?.name ? cargo.name: "--"}</Labeltbl>
                                    </Tdtbl>
                                    <Tdtbl sx={{width: "50%"}}><Labeltbl>{cargo?.desc ? cargo.desc: "--"}</Labeltbl></Tdtbl>
                                    <Tdtbl sx={{width: "10%", textAlign: "end"}}>
                                        <Labeltbl>${cargo?.value && typeof cargo?.value == "number" ? parseFloat(cargo.value).toFixed(2): parseFloat(0).toFixed(2)}</Labeltbl>
                                    </Tdtbl>
                                </Trtbl>)): null}
                                <Trtbl>
                                    <Tdtbl>
                                        <Box sx={{
                                            height: "40px",
                                            display: "grid",
                                            alignContent: "end",
                                            "& p":{
                                                fontFamily: "'mulish-extra-bold'"
                                            }
                                        }}><Labeltbl>Total</Labeltbl></Box>
                                    </Tdtbl>
                                    <Tdtbl>
                                        <Box sx={{
                                            height: "50px",
                                            display: "grid",
                                            alignContent: "end",
                                            "& p":{
                                                fontFamily: "'mulish-extra-bold'"
                                            }
                                        }}><Labeltbl></Labeltbl></Box>
                                    </Tdtbl>
                                    <Tdtbl>
                                        <Box sx={{
                                            height: "50px",
                                            display: "grid",
                                            alignContent: "end",
                                            justifyContent: "end",
                                            "& p":{
                                                fontFamily: "'mulish-extra-bold'"
                                            }
                                        }}><Labeltbl>${typeof totalpago == "number" ? parseFloat(totalpago).toFixed(2): parseFloat(0).toFixed(2)}</Labeltbl></Box>
                                    </Tdtbl>
                                </Trtbl>
                            </Bodytbl>
                        </Tbl>
                    </Box>
                </AreaFile>
                <Comentario>
                    Agrega un producto para calcular el total y ver las opciones de pago.
                </Comentario>
            </Item>
            <Item sx={{display: "block !important", textAlign: "start !important", maxWidth: "360px", minWidth: "360px",}}>
                <Texto>Pago</Texto>
                <AreaFile>
                    <Box style={{display: "flex"}}>
                        <Tbl>
                            <Headtbl></Headtbl>
                            <Bodytbl>
                                {cargos?.length > 0? cargos.map((cargo, i_cargo) => (<Trtbl key={i_cargo}>
                                    <Tdtbl sx={{
                                        width: "40%",
                                        
                                    }}>
                                        <Labeltbl sx={{color: cargo?.dis ? "#c8c8c8 !important":"black"}}>{cargo?.name ? cargo.name: "--"}</Labeltbl>
                                    </Tdtbl>
                                    <Tdtbl sx={{width: "50%"}}><Labeltbl>{cargo?.desc ? cargo.desc: "--"}</Labeltbl></Tdtbl>
                                    <Tdtbl sx={{width: "10%", textAlign: "end"}}>
                                        <Labeltbl>${cargo?.value && typeof cargo?.value == "number" ? parseFloat(cargo.value).toFixed(2): parseFloat(0).toFixed(2)}</Labeltbl>
                                    </Tdtbl>
                                </Trtbl>)): null}
                                <Trtbl>
                                    <Tdtbl>
                                        <Box sx={{
                                            height: "40px",
                                            display: "grid",
                                            alignContent: "end",
                                            "& p":{
                                                fontFamily: "'mulish-extra-bold'"
                                            }
                                        }}><Labeltbl>Total</Labeltbl></Box>
                                    </Tdtbl>
                                    <Tdtbl>
                                        <Box sx={{
                                            height: "50px",
                                            display: "grid",
                                            alignContent: "end",
                                            "& p":{
                                                fontFamily: "'mulish-extra-bold'"
                                            }
                                        }}><Labeltbl></Labeltbl></Box>
                                    </Tdtbl>
                                    <Tdtbl>
                                        <Box sx={{
                                            height: "50px",
                                            display: "grid",
                                            alignContent: "end",
                                            justifyContent: "end",
                                            "& p":{
                                                fontFamily: "'mulish-extra-bold'"
                                            }
                                        }}><Labeltbl>${typeof totalpago == "number" ? parseFloat(totalpago).toFixed(2): parseFloat(0).toFixed(2)}</Labeltbl></Box>
                                    </Tdtbl>
                                </Trtbl>
                            </Bodytbl>
                        </Tbl>
                    </Box>
                </AreaFile>
                <Comentario>
                    Agrega un producto para calcular el total y ver las opciones de pago.
                </Comentario>
            </Item>
        </Carousel> */}
    </Box>)
}

const Cronologia = ({logs, id_preliminar}) => {
    const [comment, setComment] = useState("");
    const [cronologia, setCronologia] = useState([])

    const PreliminaresContext = useContext(preliminaresContext);
    const { crear_comentario, eliminar_log } = PreliminaresContext;

    useEffect(()=>{
        if(logs?.length > 0){
            setCronologia(logs)
        }
    },[logs])

    const onComment = async e => {
        let res_comment = await crear_comentario({id_preliminar, comment})
        console.log(res_comment)
        let Today = Date.now()
        setCronologia([
            ...cronologia,
            {
                d_date: Today,
                n_comment: 1,
                s_description: comment,
                usuario: {
                    name: "Milton Ramos"
                }
            }
        ])

        setComment("")
    }
    
    const eliminar_comentario = async data_item => {
        if(data_item?._id){
            let result_delete = await eliminar_log(data_item._id);
            if(result_delete){
                setCronologia(cronologia_old => {
                    let filter_cronologia_old = cronologia_old.filter(item_cronologia_old => item_cronologia_old?._id != data_item._id)
                    return filter_cronologia_old
                })
            }
        }
    }

    return(<Box>
        <Texto sx={{
            margin: "0px",
            marginTop: "15px",
            marginLeft: "15px"
        }}>Cronología</Texto>
        <Item sx={{
            marginTop: "8px",
            padding: "0px !important"
        }}>
            <Box sx={{padding: "12px 17px", display: "flex"}}>
                <Imgcomment src={imgcomment}/>
                <TextField
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    placeholder="Deja un comentario"
                    sx={{
                        "& fieldset": { border: 'none' },
                        width: "100%",
                        outline: "none",
                        border: "none !important"
                    }}
                    inputProps={{
                        // disableUnderline: true,
                        style: {
                            fontSize: "12px",
                            fontFamily: "semibold",
                            height: "13px",
                            border: "none"
                    }}}
                ></TextField>
            </Box>
            <Box sx={{
                background: "#f7f7f7",
                borderRadius: "0px 0px 8px 8px"
            }}>
                <Grid container>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6} sx={{
                        padding: "12px 17px",
                        display: "grid",
                        justifyContent: "end"
                    }}>
                        <BotonJCs onClick={onComment}>Publicar</BotonJCs>
                    </Grid>
                </Grid>
            </Box>
        </Item>
        <Box sx={{ 
            borderLeft: "4px solid #e3e3e3",
            marginLeft: "40px",
            paddingTop: "20px",
            position: "relative",
        }}>
            {cronologia?.length > 0 ? cronologia.map((item, i_item)=>{
                let fecha_str, yyyy = "0000", 
                    mm         = "00",
                    dd         = "00",
                    hh         = "00",
                    min        = "00",
                    min_double = "00",
                    fecha_subs = "00 00 0000",
                    date_late  = null,
                    eq_late = "a", eq_item = "b"
                if(item?.d_date){
                    fecha_str = new Date(item.d_date);
                    yyyy = fecha_str.getFullYear();
                    mm = fecha_str.getMonth() + 1; // Months start at 0!
                    dd = fecha_str.getDate();
                    eq_item = `${yyyy}-${mm}-${dd}`

    
                    hh = fecha_str.getHours()
                    min = fecha_str.getMinutes()
                    min_double = parseInt(min) < 10 ? `0${min}`: min
                    fecha_subs = `${dd} de ${convertMonths(mm)} del ${yyyy}`

                    let item_late = cronologia[i_item != 0 ? i_item - 1: 0],
                    item_cron = item_late?.d_date ? item_late.d_date: 0, yyyy_l, mm_l, dd_l;
                    
                    if(item_cron){
                        let fecha_str_late = new Date(item_cron);
                        yyyy_l             = fecha_str_late.getFullYear();
                        mm_l               = fecha_str_late.getMonth() + 1;
                        dd_l               = fecha_str_late.getDate();
                        eq_late            = `${yyyy_l}-${mm_l}-${dd_l}`
                    }

                    if(i_item == 0 || eq_item != eq_late){
                        date_late = true
                    }else{
                        date_late = null
                    }
                }

                if(item?.n_comment){
                    return(<Box key={i_item} sx={{
                        background: "white",
                        left: "-40px",
                        position: "relative",
                        borderRadius: "8px",
                        margin: "20px 0px",
                        width: "calc(100% + 40px)",
                        boxShadow: "0px 2px 9px -7px black",
                        position: "relative"
                    }}>
                        <Box sx={{padding: "10px", display: "flex"}}>
                            <Imgcomment src={imgcomment}/>
                            <Box>
                                <Box sx={{display: "flex"}}>
                                    <Letter sx={{marginLeft: "10px", fontFamily: "mulish-extra-bold"}}>Usuario</Letter>
                                    <Letter sx={{marginLeft: "5px", fontSize: "11px"}}>{hh}:{min_double}</Letter>
                                </Box>
                                <Letter sx={{marginLeft: "10px"}}>
                                    {item?.s_description ? item.s_description: ""}
                                </Letter>
                            </Box>
                        </Box>
                        <DeleteOutline onClick={e=>eliminar_comentario(item)} sx={{
                            position: "absolute",
                            right: 3,
                            top: 6,
                            fontSize: "18px",
                            cursor: "pointer",
                            color: "#939393 !important"
                        }}/>
                    </Box>)
                }else{
                    return(<Box key={i_item}>
                        <Box sx={{marginLeft: "33px"}}>
                            {date_late ? (<Letter sx={{
                                marginTop: "35px",
                                fontFamily: "mulish-extra-bold !important"
                            }}>{fecha_subs}</Letter>): null} 
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0px"
                        }}>
                            <Box sx={{
                                background: "#c7c7c7",
                                borderRadius: "4px",
                                height: "15px",
                                width: "15px",
                                position: "relative",
                                left: "-9px",
                                margin: "8px 0px"
                            }}/>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Letter sx={{marginLeft: "20px"}}>
                                        {item?.s_description ? item.s_description:null}
                                    </Letter>
                                </Grid>
                                <Grid item xs={2}>
                                    <Letter sx={{marginLeft: "20px"}}>
                                        {hh}:{min_double}
                                    </Letter>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>)
                }
            }):null}
        </Box>
    </Box>)
}

const Notas = ({s_notas, set_s_notas}) => {
    const [editar, setEditar] = useState(false)

    const txtareastyle = {
        resize: "none",
        fontSize: "12px !important",
        width: "100%",
        border: "1px solid #DAD9D5",
        borderRadius: "8px",
        padding: "0px !important",
        marginBottom: "10px",
        outline: "none"
    }

    return(<Item>
        <Box sx={{padding: "5px 2px"}}>
            <Grid container>
                <Grid item xs={8} sx={{"& p":{margin: "0px"}, display: "grid", alignContent: "center"}}>
                    <Texto>Notas</Texto>
                </Grid>
                <Grid item xs={4} sx={{
                    display: "grid",
                    alignContent: "center",
                    justifyContent: "end"
                }}>
                    <Edit sx={{cursor: "pointer"}} onClick={e=>setEditar(!editar)}/>
                </Grid>
            </Grid>
            
            {editar ? (<TextField
                multiline
                rows={2}
                // maxRows={4}
                onChange={e=>set_s_notas(e.target.value)}
                value={s_notas}
                sx={txtareastyle}
            />):(<Letter>{s_notas ? s_notas: "No hay ninguna nota"}</Letter>)}
        </Box>
    </Item>)
}

const CheckComponent = ({check = false, children}) => {
    return(<Box sx={{
        width: "100%",
        display: "inline-flex",
        alignItems: "center",
        margin: "0px",
        padding: "0px",
        "& span":{
            padding: "0px",
            margin: "0px",
        }
    }}>
        {!check ? (<RadioButtonUnchecked/>): (<Circle/>)}
        <Texto sx={{margin: "0px",
            padding: "0px",
            marginLeft: "10px"
        }}>{children}</Texto>
    </Box>)
}

const Clientes = ({cotizaciones, id_cliente, set_id_cliente = _id => console.log(_id)}) => {
    const [search, setSearch]               = useState("")
    const [cliente, setCliente]             = useState(null)
    const [lista,  setLista]                = useState([])
    const [cliente_obj,  setCliente_obj]    = useState([])
    const [open, setOpen]                   = useState(false)

    const ContactsContext                   = useContext(contactsContext);
    const { contactos, obtenerContactoPreliminar, guardarContacto } = ContactsContext;

    useEffect(()=>{
        const obtener_paises = async () => {
            let paises_ = await flags_select([
                "mx",
                "us",
                "br",
                "cn",
                "ar"
            ]);
            setPaises(paises_)
        }

        obtener_paises()
    },[])

    useEffect(()=>{
        setLista(contactos)
    },[contactos])

    useEffect(()=>{
        let filter = contactos.filter(contacto => `${contacto?.name ? contacto.name.toLowerCase(): ""}${contacto?.lastName ? contacto.lastName.toLowerCase():""}`.search(search.toLowerCase().split(' ').join(''))>-1)
        setLista(filter)
    },[search])

    useEffect(()=>{
        set_id_cliente(cliente)
        let cliente_obj_filter = lista.filter(item_lista => item_lista._id == cliente)
        if(cliente_obj_filter?.length > 0){
            setCliente_obj(cliente_obj_filter)
        }
    },[cliente])

    useEffect(()=>{
        if(id_cliente){
            set_id_cliente(id_cliente)
            let cliente_obj_filter = lista.filter(item_lista => item_lista._id == id_cliente)
            if(cliente_obj_filter?.length > 0){
                setCliente_obj(cliente_obj_filter)
            }
        }
    },[id_cliente])

    useEffect(()=>{obtenerContactoPreliminar()},[])

    const eliminar_cliente = e => {
        setCliente(null)
        setCliente_obj([])
        set_id_cliente(null)
    }

    const [paises, setPaises]   = useState([]);
    const [nuevoCliente, setNuevoCliente] = useState({
        s_pais: "mx"
    })
    const [datos, setDatos] = useState([
        {name: "name",              txt: "Nombre"},
        {name: "lastName",          txt: "Apellido"},
        {name: "s_nombre_empresa",  txt: "Nombre de la empresa"},
        {name: "s_calle",           txt: "Calle y Número"},
        {name: "s_colonia",         txt: "Colonia"},
        {name: "n_codigo_postal",   txt: "Código Postal"},
        {name: "s_ciudad",          txt: "Cuidad"},
        // {name: "s_pais",            txt: "País"},
        {name: "email",           txt: "Correo"},
        {name: "s_phone",           txt: "Teléfono"},
    ])

    const onchange_nuevo_cliente = e => {
        setNuevoCliente({
            ...nuevoCliente,
            [e.target.name]: e.target.value
        })
    }

    return(<Item>
        <BasicModal setOpen={setOpen} open={open}>
            <>
            <Box sx={{ 
            padding: "15px",
            borderRadius: "8px 8px 0px 0px",
            "& p":{color: "black", fontSize: "12px", fontFamily: "mulish-extra-bold"},
            background:"#f3f3f3",
            position:"relative"
        }}>
            <Typography>Agregar Cliente</Typography>
            <Close onClick={e=>setOpen(false)} sx={{position: "absolute", right: 15, top: 15, fontSize: "18px", cursor: "pointer"}}/>
        </Box>
        {datos?.length > 0? datos.map(dato => {
            return(<Box sx={{display: "flex", margin: "15px", alignItems: "center", "& input":{
                padding: "5px 8px",
                fontSize: "12px",
            }}}>
                <Typography sx={{color: "black", fontSize: "12px", minWidth: "130px"}}>{dato.txt}:</Typography>
                <FormControl fullWidth sx={{
                    "& input":{padding: "0px"},
                    "& p, div":{padding: "2px", fontSize: "12px", margin: "0px"},
                }}>
                    <OutlinedInput name={dato.name} onChange={onchange_nuevo_cliente} type="text"/>
                </FormControl>
            </Box>)
        }):null}
        <Box sx={{display: "flex", margin: "15px", alignItems: "center", "& input":{
                padding: "5px 8px",
                fontSize: "12px",
            }}}>
                <Typography sx={{color: "black", fontSize: "12px", minWidth: "130px"}}>País:</Typography>
                <Box sx={{
                    width: "100%",
                    marginBottom: "10px",
                    "& p": {color: "#626262 !important"},
                    "& .MuiSelect-select": {
                        margin: "0px !important",
                        padding: "2px 10px !important",
                        fontSize: "12px !important"
                    }
                }}>
                    <Select value={nuevoCliente.s_pais} onChange={e => setNuevoCliente({...nuevoCliente, s_pais: e.target.value})} fullWidth displayEmpty sx={sx_op}>
                        <MenuItem sx={sx_op} value="">Selecciona un país</MenuItem>
                        {paises.map(flag => {
                            return (<MenuItem sx={sx_op} value={`${flag.clave}`}>
                                <img
                                    src={flag.src}
                                    style={{
                                        width: "15px",
                                        marginRight: "5px",
                                        marginLeft: "10px",
                                        height: "10px"
                                    }}
                                />
                                {flag.nombre}
                            </MenuItem>)
                        })}
                    </Select>
                </Box>
            </Box>
        
        <Box sx={{display: "flex", justifyContent: "end", margin: "15px"}}>
            <BotonJC sx={{marginRight: "10px"}}>Cancelar</BotonJC>
            <BotonJCs onClick={async e=>{
                let contacto_nuevo = await guardarContacto(nuevoCliente)
                setCliente(contacto_nuevo?._id ? contacto_nuevo._id: null)
                setOpen(false)
            }}>Guardar</BotonJCs>
        </Box>
            </>
        </BasicModal>
        <Box><Texto>Clientes</Texto></Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px", position: "relative"}}>
            <Content>
                <Campo value={search} sx={{
                    width: "100%",
                    height: "21px",
                    outline: "none",
                    marginRight: "5px"
                }} onChange={e=>{
                    setSearch(e.target.value)}
                }/>
                <ButtonIconSearch onClick={e=> console.log(cotizaciones)}>
                    <Icon name="SearchWhite" sx={{width: "12px", height:"12px"}}/>
                </ButtonIconSearch>
            </Content>
            {cliente_obj?.length > 0 ? (<Divider sx={{marginTop: "10px"}}/>):null}
            {cliente_obj?.length > 0 ? (<Box sx={{
                    display: "flex",
                    margin: "5px 0px",
                    position: "relative"
                }}>
                    <Imgcomment sx={{height: "30px", width: "30px", margin: "10px 0px"}} src={imgcomment}/>
                    <Box sx={{padding: "5px 10px", width: "100%"}}>
                            <Typography sx={{fontSize: "12px"}}>{cliente_obj[0].name} {cliente_obj[0].lastName}</Typography>
                            <Typography sx={{fontSize: "12px"}}>{cliente_obj[0]?.s_email ? cliente_obj[0].s_email: cliente_obj[0]?.email ? cliente_obj[0].email: ""}</Typography>
                    </Box>
                    <Close onClick={eliminar_cliente} sx={{position: "absolute", top: "0px", right: "0px", cursor: "pointer"}}/>
                </Box>): null}
            {search != "" ? (<Box sx={{
                width: "100%",
                background: "white",
                marginY: "15px",
                position: "absolute",
                top: "10px",
                boxShadow: "0px 3px 10px -8px black",
                padding: "10px 15px",
                borderRadius: "8px",
                zIndex: 10
            }}>
                {lista?.length > 0 ? lista.map((item, item_key)=> {
                    return(<Box key={item_key} onClick={()=>{
                        setSearch("")
                        setCliente(item?._id ? item._id: null)
                        console.log("Seleccionado")
                    }} sx={{
                        display: "flex",
                        margin: "10px 0px",
                        "& :hover":{
                            background: "#f1f1f1",
                            cursor: "pointer"
                        }
                    }}>
                        <Imgcomment sx={{height: "30px", width: "30px"}} src={imgcomment}/>
                        <Box sx={{padding: "5px 10px", width: "100%"}}>
                            <Typography sx={{fontSize: "12px"}}>{item.name} {item.lastName}</Typography>
                            <Typography sx={{fontSize: "12px"}}>{item?.s_email ? item.s_email: item?.email ? item.email: ""}</Typography>
                        </Box>
                    </Box>)
                }):null}
            </Box>):null}
            <Typography sx={{
                fontSize: "12px",
                textDecoration: "underline", 
                color: "#2774da !important",
                cursor: "pointer",
                textAlign: "end"
            }} onClick={e=>setOpen(true)}>+ Agregar cliente</Typography>
        </Box>
    </Item>)
}

const Mercados = () => {
    // const [lista, setLista] = useState([
    //     "México",
    //     "Barbados",
    //     "Belice",
    //     "Colombia",
    //     "Costa Rica",
    //     "Ecuador",
    //     "El Salvador",
    //     "Granada",
    //     "Guatemala",
    //     "Guyana",
    //     "Haití",
    //     "Honduras",
    //     "Islas Británicas",
    //     "Islas Caimán",
    //     "Islas del Mar Caribe",
    //     "Islas Francesas",
    //     "Jamaica",
    //     "Nicaragua",
    //     "Panamá",
    //     "Perú",
    //     "Puerto Rico",
    //     "República Dominicana",
    //     "Surinam",
    //     "Trinidad y Tobago",
    //     "Venezuela"
    // ])

    const [lista, setLista] = useState([])
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(0)
    const [mercado, setMercado] = useState({
        n_convert: 0,
        n_convert_base: 0
    })

    const MercadosContext = useContext(mercadosContext);
    const { mercados, obtener_mercados, modificar_mercado } = MercadosContext;

    useEffect(()=>{
        obtener_mercados()
    },[])

    useEffect(()=>{
        setLista(mercados)
    }, [mercados])

    return(<Item sx={{paddingBottom: "15px !important"}}>
        <BasicModal open={open} setOpen={setOpen}>
            <Box sx={{ 
                padding: "15px",
                borderRadius: "8px 8px 0px 0px",
                "& p":{color: "black", fontSize: "12px", fontFamily: "mulish-extra-bold"},
                background:"#f3f3f3",
                position:"relative"
            }}>
                <Typography>Editar Valor de Moneda</Typography>
                <Close onClick={e=>setOpen(false)} sx={{position: "absolute", right: 15, top: 15, fontSize: "18px", cursor: "pointer"}}/>
            </Box>
            <Grid container>
                <Grid item xs={6}>
                    <Box sx={{display: "flex", margin: "15px", alignItems: "center", "& input":{
                        padding: "5px 8px",
                        fontSize: "12px",
                    }}}>
                        <Typography sx={{
                            color: "black",
                            fontSize: "12px",
                            minWidth: "130px",
                            textAlign: "right",
                            marginRight: "10px"
                        }}>Moneda base</Typography>
                        <FormControl fullWidth sx={{
                            "& input":{padding: "0px"},
                            "& p, div":{padding: "2px", fontSize: "12px", margin: "0px"},
                        }}>
                            <OutlinedInput
                                type="number"
                                inputProps={{min: 0}}
                                value={mercado.n_convert}
                                onChange={e=>setMercado({...mercado, n_convert: e.target.value})}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{display: "flex", margin: "15px", alignItems: "center", "& input":{
                        padding: "5px 8px",
                        fontSize: "12px",
                    }}}>
                        <Typography sx={{
                            color: "black",
                            fontSize: "12px",
                            minWidth: "130px",
                            textAlign: "right",
                            marginRight: "10px"
                        }}>Moneda cambio:</Typography>
                        <FormControl fullWidth sx={{
                            "& input":{padding: "0px"},
                            "& p, div":{padding: "2px", fontSize: "12px", margin: "0px"},
                        }}>
                            <OutlinedInput
                                type="number"
                                inputProps={{min: 0}}
                                value={mercado.n_convert_base}
                                onChange={e=>setMercado({...mercado, n_convert_base: e.target.value})}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{display: "flex", justifyContent: "end", margin: "15px"}}>
                <BotonJC sx={{marginRight: "10px"}}>Cancelar</BotonJC>
                <BotonJCs onClick={async e=>{
                    let pass = await modificar_mercado(mercado)
                    if(pass){
                        setOpen(false)
                    }
                }}>Aplicar</BotonJCs>
            </Box>
        </BasicModal>
        <Texto>Mercados</Texto>
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                // value={value}
                // onChange={handleChange}
            >
                {lista.map((item, key_item) => {
                    return(<Box onClick={e=>{
                        setMercado(item)   
                        setOpen(true)
                    }}>
                        <FormControlLabel value={item?._id ? item._id: 0} control={<Radio />} label={item?.s_nombre ? item?.s_nombre: "MERCADO"}/>
                    </Box>)
                })}

                {/* {lista.map((item, key_item) => {
                    return(<Box sx={{margin: "0px", padding: "0px"}} key={key_item}>
                        <Checkbox
                            sx={{
                                margin: "0px", padding: "0px"
                            }}
                            icon={<CheckComponent check={false}>{item}</CheckComponent>}
                            checkedIcon={<CheckComponent check={true}>{item}</CheckComponent>}
                        />
                    </Box>
                    )
                })} */}
            </RadioGroup>
        </FormControl>
    </Item>)
}

const Etiquetas = ({form, setForm}) => {
    const [etiquetas, setEtiquetas] = useState([])
    const [etiqueta, setEtiqueta] = useState("")

    useEffect(()=>{
        setEtiquetas(form?.a_etiquetas?.length > 0 ? form?.a_etiquetas: [])
    },[form])

    return(<Item>
        <Texto>Etiquetas</Texto>
        <Campo sx={{
            width: "100%",
            height: "21px",
            outline: "none",
            marginRight: "5px",
            marginBottom: "15px"
        }} value={etiqueta} onChange={e=>setEtiqueta(e.target.value)} onKeyPress={e=>{
            if(e?.key == "Enter"){
                // setEtiquetas([
                //     ...etiquetas,
                //     etiqueta
                // ])
                let etiquetas_old = form?.a_etiquetas?.length > 0 ? form.a_etiquetas: []
                setForm({
                    ...form,
                    a_etiquetas: [
                        ...etiquetas_old,
                        etiqueta
                    ]
                })
                setEtiqueta("")
            }
        }}/>
        <Box sx={{marginBottom: "15px"}}>
            <Stack sx={{display: "block"}} direction="row" spacing={1}>
                {etiquetas?.length > 0 ? etiquetas.map((etiqueta, i_etiqueta) => (
                    <Chip sx={{marginY: "5px !important"}} key={i_etiqueta} label={etiqueta} onDelete={e=>{
                        setForm({
                            ...form,
                            a_etiquetas: etiquetas.filter((etiqueta_f, i_etiqueta_f)=>i_etiqueta_f!=i_etiqueta)
                        })
                    }}/>
                )): null}
            </Stack>
        </Box>
    </Item>)
}

const Botones = ({mt = "25px", form, go_to_menu=()=>console.log("MENU"), nuevo = true}) => {
    const PreliminaresContext = useContext(preliminaresContext);
    const { editar_preliminar, guardar_preliminar, obtenerPreliminares } = PreliminaresContext;
    const guardado_aut = async () =>{ 
        if(nuevo){
            let result_save = await guardar_preliminar(form);
            let id_negocio_ = await localStorage.getItem("id_route")
            if(result_save){
                go_to_menu()
                obtenerPreliminares(id_negocio_)
            } 
        }else{
            let id_negocio_ = await localStorage.getItem("id_route")

            console.log(form)
            let result_edit = editar_preliminar({
                id_negocio: id_negocio_,
                ...form
            });
            if(result_edit){
                go_to_menu();
            }
        }
    }
    
    return(<Box sx={{
        display: "flex",
        justifyContent: "end",
        alignContent: "center",
        alignItems: "center",
        marginTop: mt,
        minHeight: "40px",
    }}>
        <BotonJCs onClick={()=>{go_to_menu()}} sx={{display: "grid", alignContent: "center", marginRight: "5px", height:"25px"}}>
                Descartar
            </BotonJCs>
            <BotonJCs onClick={guardado_aut}>Guardar</BotonJCs>
    </Box>)
}

const Content = styled('div')({
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "100%"
})

const ButtonIconSearch = styled('div')({
    background: "#3F3F3F",
    width: "29px",
    height: "21px",
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    borderRadius: "8px"
    
})

const Row = styled(Box)({
    display: "flex", 
    justifyContent: "center"
})

const Campo = styled("input")({
    display: "flex", 
    justifyContent: "center",
    width: "100%",
    borderRadius: "8px",
    height: "28px",
    border: "1px solid #DAD9D5",
    fontSize: "12px",
    padding: "5px 10px"
})

const Item = styled(Box)({
    background: "white", 
    padding: "2px 15px !important", 
    borderRadius: "8px",
    marginTop: "15px",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.1) !important",
    "& span":{
        fontSize: "12px !important",
        fontFamily: "semibold"
    },
    "& svg": {
        width: "15px !important"
    },
    "& label": {
        height: "25px"
    }
})

const Col = styled(Box)(props=>({
    width: props?.w ? props.w : 100, 
    marginRight: 20
}))

const Texto = styled('p')(props => ({
    fontSize: 12,
    fontFamily: props?.family ? props.family: "mulish-bold"
}))

const AreaFile = styled('div')({
    display: "grid",
    alignContent: "start",
    // justifyContent: "center",
    // padding: "50px 0px",
    borderRadius: "8px",
    border: "1px solid #DAD9D5"
})

const Comentario = styled("p")({
    marginBottom: "15px",
    fontSize: "12px",
    color: "#626262 !important"
})

const BotonAtras = styled('div')({
    background: "#626262",
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    width: "33px",
    height: "25px",
    borderRadius: "8px",
    "& .arrow-letter":{
        color: "white !important",
        fontSize: "12px"
    }
})

const Cotizacion_Detalle = ({data=null, sel_producto = false, go_to_menu = ()=>console.log("Menu")}) => {
    const [cambios, setCambios]                 = useState("loading");
    const [form, setForm]                       = useState({})
    const [combinaciones, setCombinaciones]     = useState([])
    const [no, setNo]                           = useState(0)
    const [long, setLong]                       = useState(0)
    const [pages, setPages]                     = useState([])
    const [cotizaciones, setCotizaciones]       = useState([])

    useEffect(()=>{
        setForm({
            ...form,
            cotizaciones: cotizaciones
        })
    },[cotizaciones])

    useEffect(()=>{
        if(data){
            if(data?.cotizaciones?.length > 0){
                setLong(data.cotizaciones.length-1)
                let data_cot = data.cotizaciones;
                let num_max = Math.max(...data_cot.map(o => o.n_page)), Tpages = num_max;
                let array_pages = []
                for(let iterator=0;iterator<Tpages; iterator++){
                    array_pages.push({})
                }

                setPages(array_pages)
                
                let cot_map = data_cot.map(item_data_cot => {
                    if(item_data_cot?.items?.length > 0){
                        let items_pages = item_data_cot.items;
                        let result_map_items = items_pages.map(item_pages => {
                            let producto_array = {}
                            if(item_pages?.producto?.length > 0){
                                producto_array = item_pages.producto[0]
                                let keys_producto = Object.keys(producto_array);
                                if(keys_producto?.length > 0){
                                    let existe_key = keys_producto.filter(key_producto => key_producto == "_id")
                                    if(existe_key?.length>0){
                                        delete producto_array._id
                                    }
                                }
                            }

                            return {
                                ...item_pages,
                                ...producto_array
                            }
                        })
                        return{
                            ...item_data_cot,
                            items: result_map_items
                        }

                    }

                    return item_data_cot
                });

                setCotizaciones(cot_map)
            }
            setForm(data)
        }
    },[data])

    let id_negocio_ = localStorage.getItem("id_route")
    
    useEffect(()=>{
        console.log(id_negocio_)
        setForm({...form, id_negocio: id_negocio_})
    },[id_negocio_])

    const Titulo = ({texto = "Nuevo Producto"}) => {
        return(<Box sx={{
            display: "flex",
            justifyContent: "start",
            alignContent: "center",
            maxHeight: "40px",
            minHeight: "40px"
        }}>
            <BotonAtras
                sx={{alignSelf: "center", cursor: "pointer"}}
                onClick={()=>{
                    setForm({})
                    go_to_menu()
                }}
            ><ArrowBackIosNew className="arrow-letter"/></BotonAtras>
            <Box sx={{
                position: "relative",
                display: "grid",
                alignItems: "center",
            }}>
                <Texto sx={{
                    marginLeft: "15px",
                    fontSize: "20px",
                    fontFamily: "mulish-extra-bold",
                    width: "max-content",
                    position: "absolute",
                    margin: "0px",
                    left: "15px"
                }}>{texto}</Texto>
            </Box>
        </Box>)
    }

    const ref = useRef(null);
    const { width } = useContainerDimensions(ref)
    const [totalpago, setTotalPago] = useState(0)

    return(<Box ref={ref}>
        <Row sx={{marginBottom: "30px"}}>
        <Col w={250}>
            <Titulo texto={!data ? "Nueva Cotización": data.s_name}/>
                <Notas 
                    set_s_notas={value=>{
                        setForm({
                            ...form,
                            s_notes: value
                        })
                    }}
                    s_notas={form.s_notes}
                />
                <Clientes cotizaciones={cotizaciones} set_id_cliente={value => {
                    setForm({
                        ...form,
                        id_cliente: value
                    })
                }} id_cliente={form?.id_cliente ? form.id_cliente: null}/>
                <Etiquetas form={form} setForm={setForm}/>
                <Mercados/>
            </Col>
            <Col w={550} sx={{paddingRight: "20px"}}>
                <Botones
                    form={form}
                    cambios={cambios}
                    combinaciones={combinaciones}
                    go_to_menu={go_to_menu}
                    mt="0px"
                    nuevo={data ? false: true}
                />
                <Box>
                    <General
                        setCotizaciones={setCotizaciones}
                        cotizaciones={cotizaciones}
                        no={no}
                        setForm={setForm}
                        form={form}
                        totalpago={totalpago}
                        setTotalPago={setTotalPago}
                    />
                    <Box sx={{position: "relative"}}>
                        <Pagos
                            totalpago={totalpago}
                            setTotalPago={setTotalPago}
                            setCotizaciones={setCotizaciones}
                            setForm={setForm}
                            form={form}
                            no={no}
                            hx={width}
                            letNoPage={value => setNo(value)}
                            setPage={setLong}
                            page={long}
                            cotizaciones={cotizaciones}
                        />
                    </Box>
                </Box>
                {data ? (<Cronologia id_preliminar={form?._id ? form._id: null} logs={form?.cronologia ? form.cronologia: []}/>):null}
            </Col>
        </Row>
    </Box>)
    
}

export default Cotizacion_Detalle;

const sx_op = {
    fontSize: "12px !important",
    fontFamily: "semibold",
    display: "flex",
    padding: "3px 5px",
    alignItems: "center"
}
import { Box, Divider, Grid } from "@mui/material"
import { Row, Col, Texto, Titulo, Canales, Mercados, Estadisticas, Item, Campo, TextArea, Seleccionar, BotonAdd, Comentario, CheckComponent, ContainerCheck, SearchBar, CHECK } from "../../../elementos"
import { BotonJC, BotonJCs } from "../../material"
import { useContext, useEffect, useState } from "react"
import { ColeccionesContext, ProductosContext } from "../../../context"

const Detalle = ({regresar = ()=>console.log("back"), array = null}) => {
    const coleccionesContext = useContext(ColeccionesContext);
    const { guardar_coleccion, editar_coleccion } = coleccionesContext;

    const [data, setData] = useState({
        a_canales:      [],
        a_mercados:     [],
        a_productos:    [],
        i_tipo:         0
    })

    useEffect(()=>{
        if(array?._id){
            setData(array)
        }
    }, [array])

    const Botones = ({btnBack}) => {
        return(<Box sx={{
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            alignItems: "center",
            justifyItems: "end",
            minHeight: "40px",
        }}>
            <BotonJCs sx={{marginRight: "5px"}}>Cancelar</BotonJCs>
            <BotonJC onClick={async e=>{
                if(!array){
                    let result_save = await guardar_coleccion(data)
                    if(result_save){
                        regresar()
                    }else{
                        console.log("Error al guardar")
                    }
                }else{
                    if(array?._id){
                        let result_save = await editar_coleccion(data, array?._id)
                        if(result_save){
                            regresar()
                        }else{
                            console.log("error al editar")
                        }
                    }
                }
            }}>Guardar</BotonJC>
        </Box>)
    }

    const handleChangeCampo = (name, value) => {
        if(name){
            setData(item=>{
                item[name] = value
                return item
            })
        }
    }

    const General = ({onChange}) => {
        return(<Item>
            <Texto>General</Texto>
            <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Nombre</Texto>
                <Campo
                    name="s_nombre"
                    defaultValue={data.s_nombre}
                    placeholder="Nombre de la colección o grupo de productos"
                    onChange={onChange}
                />
                {/* <Campo value={s_title} onChange={set_title} placeholder="Nombre del producto"/> */}
    
                <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px", marginTop: "15px"}}>Descripción</Texto>
                <TextArea
                    defaultValue={data.s_descripcion}
                    onChange={onChange}
                    name="s_descripcion"
                    rows="8"
                    cols="50"
                    placeholder="Text Description"
                />
                {/* <TextArea value={s_body} onChange={set_body} rows="8" cols="50" placeholder="Text Description"/> */}
            </Box>
        </Item>)
    }

    
    const Plantilla = ({onChange, value}) => {
        return(<Item>
            <Texto>Plantilla del tema</Texto>
            <Seleccionar name="pets" id="pet-select">
                <option>Plantilla de colección predeterminado</option>
            </Seleccionar>
        </Item>)
    }

    const Adicionales = ({datos = [], onChange, value }) => {
        return(<Item sx={{marginBottom: "30px"}}>
            <Texto>Campos Adicionales</Texto>
            <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                {datos.map((dato, key_dato) =>{
                    return(<Row sx={{marginTop: "25px"}} key={key_dato}>
                        <Col w={250}>
                            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>
                                {dato?.titulo ? dato.titulo: "Nombre del campo Adicional"}
                            </Texto>
                        </Col>
                        <Col w={320}>
                            <Box>
                                {dato?.contenido?.length > 0 ? dato.contenido.map((campo, i) => (<Box>
                                    <Campo sx={{marginTop: i != 0 ? "15px": "0px"}} value={campo}/>
                                </Box>)): null}
                                <Box sx={{textAlign: "right"}}><BotonAdd>+ Agregar</BotonAdd></Box>
                            </Box>
                        </Col>
                    </Row>)
                })}
            </Box>
        </Item>)
    }

    const Motoresdebusqueda = ({onChange}) => {
        return(<Item>
            <Texto sx={{marginBottom: "0px !important"}}>Motores de búsqueda</Texto>
            <Comentario sx={{margintop: "0px !important"}}>Aumenta la relevancia de tus productos en los motores de búsqueda agregando una descripción.</Comentario>
            <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Título de la página</Texto>
                    
                <Campo
                    defaultValue={data?.s_titulo_pagina ? data.s_titulo_pagina: ""}
                    onChange={onChange}
                    name="s_titulo_pagina"
                    placeholder="Nombre del producto"
                />
            </Box>
            <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Meta descripción</Texto>
                <TextArea
                    onChange={onChange}
                    defaultValue={data?.s_desc_motor_busqueda ? data.s_desc_motor_busqueda: ""}
                    name="s_desc_motor_busqueda"
                    rows="8"
                    cols="50"
                    placeholder="Descripción del producto"
                />
            </Box>
            <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Identificador de URL</Texto>
                <Campo
                    defaultValue={data?.s_handle ? data.s_handle: ""}
                    placeholder=""
                    name="s_handle"
                    onChange={onChange}
                />
                {/* <Campo value={s_handle} onChange={set_s_handle} placeholder=""/> */}
            </Box>
        </Item>)
    }

    const TipoColeccion = ({onChange, onChangeArray}) => {
        const productosContext = useContext(ProductosContext);
        const { productos } = productosContext;

        useEffect(()=>{
            setItems(productos)
        },[productos])

        useEffect(()=>{
            if(data?.a_productos){
                let test_array = productos.map(item_rest=>{
                    let filter_array_result = data.a_productos.filter(item => item._id == item_rest._id)

                    console.log(filter_array_result)
                    if(filter_array_result.length>0){
                        return false
                    }else{
                        return item_rest
                    }

    
                })
                
                let result_filtering = test_array.filter(f_array_res => f_array_res != false)
                
                setItems(result_filtering)
                setRest(result_filtering)
                setAdd(data.a_productos)
                setAdd_confirm(data.a_productos)
            }
            if(data?.i_tipo || data?.i_tipo == 0){
                setTipo(data.i_tipo)
            }

            let array_result = productos.filter(p => p.s_title.search(data.s_title) > -1)
        },[data])

        const [tipo, setTipo]               = useState(0)
        const [items, setItems]             = useState([])
        const [add, setAdd]                 = useState([])
        const [add_confirm, setAdd_confirm] = useState([])
        const [rest, setRest]               = useState([])

        useEffect(()=>{
            setRest(item_rest=>{
                let filter_array_result = items.map(item => {
                    let filter_add_rest = add.filter(add_item => add_item._id == item._id)
                    if(filter_add_rest.length>0){
                        return false
                    }else{
                        return item
                    }
                })

                return filter_array_result.filter(f_array_res => f_array_res != false)
            })
        },[add])

        return(<Item sx={{padding: "0px !important"}}>
            <Box sx={{
                padding: "2px 15px !important",
                margin: "0px !important"
            }}>
                <Texto sx={{marginBottom: "0px !important"}}>Tipo de colección</Texto>
                <ContainerCheck
                    changeOption={e=>{
                        onChange(e)
                        setTipo(parseInt(e.target.value))
                    }}
                    labels={["Manual","Automatizada"]}
                    desc={[
                        "Agrega los productos de manera manual a esta colección seleccionando uno por uno.",
                        "Crea una colección automatizada en base a las condiciones establecidas. Los productos se agregarán de manera automática."
                    ]}
                />
            </Box>
            <Divider/>
            {tipo > 0 ? null:(
            <Box>
                <Box sx={{padding: "2px 15px !important"}}>
                    <p style={{
                        fontFamily: "semibold",
                        fontSize: "12px"
                    }}>Selecciona los productos físicos, digitales o servicios que se agregarán a esta colección:</p>
                    <SearchBar fnsearch={e=>{
                        let array_result = productos.filter(p => p.s_title.search(e.target.value) > -1)
                        setItems(array_result)
                    }}/>
                </Box>
                <Box>
                    {items?.length > 0 ? items.map(item=>
                        (<Box sx={{
                            borderTop: "1px solid #dedede",
                            borderBottom: "1px solid #dedede",
                            padding: "6px 15px !important",
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center"
                        }}>
                            <CHECK type="checkbox" onClick={e=>{
                                let rest_array      = add.filter(item_add => item_add._id != item._id)
                                if(e.target.checked){
                                    setAdd([
                                        ...add,
                                        item
                                    ])                                    
                                }else{
                                    setAdd([...rest_array])
                                }

                            }}/>
                            <Box sx={{margin: "0px 15px"}}>
                                <Box sx={{width: "25px !important", height: "25px", border: "1px solid #dedede", borderRadius: "4px"}}></Box>
                            </Box>
                            <Box sx={{width: "100%", maxWidth: "540px", wordWrap: "break-word", fontSize: "12px", fontFamily: "semibold"}}>
                                {item.s_title}
                            </Box>
                        </Box>)
                    ): (<Box sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center"
                    }}>
                        <Texto sx={{color: "#a2a2a2 !important"}}>Sin Resultados</Texto>
                    </Box>)}
                </Box>
                <Divider/>
                <Box sx={{padding: "2px 15px !important"}}>
                    <p style={{
                        fontFamily: "semibold",
                        fontSize: "12px"
                    }}>Productos seleccionados:</p>
                    {add_confirm?.length>0?add_confirm.map((element, i_element)=>(<Box>
                        <Grid container sx={{
                            borderTop: i_element == 0? "1px solid #dedede": "0px",
                            borderBottom: "1px solid #dedede",
                        }}>
                            <Grid item xs={10}>
                                <Box sx={{
                                    padding: "6px 15px !important",
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Texto>{i_element+1}.-</Texto>
                                    <Box sx={{margin: "0px 15px"}}>
                                        <Box sx={{width: "25px !important", height: "25px", border: "1px solid #dedede", borderRadius: "4px"}}></Box>
                                    </Box>
                                    <Box sx={{width: "100%", maxWidth: "540px", wordWrap: "break-word", fontSize: "12px", fontFamily: "semibold"}}>
                                        {element.s_title}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                    </Box>)):(<Box sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center",
                        "& p": {color: "#a2a2a2 !important"}
                    }}>
                        <Texto>0 Productos Seleccionados</Texto>
                    </Box>)}

                </Box>
                <Box sx={{padding: "10px 15px !important", display: "flex", justifyContent: "end"}}>
                    <BotonJC sx={{marginRight: "10px"}}>Cancelar</BotonJC>
                    <BotonJCs onClick={e=>{
                        setItems(rest)
                        setAdd_confirm(add)
                        onChangeArray(add)
                    }}>Agregar</BotonJCs>
                </Box>
            </Box>
            )}
        </Item>)
    }


    return(<Box>
        <Row>
            <Col w={304}>
                <Titulo texto={array ? "Editar colección": "Crear colección"} btnBack={regresar}/>
                <Canales/>
                <Mercados/>
                <Estadisticas/>
            </Col>
            <Col w={642}>
                <Botones />
                <General onChange={e=> handleChangeCampo(e.target.name, e.target.value)}/>
                <TipoColeccion
                    onChangeArray={array=>{
                        handleChangeCampo("a_productos", array)
                    }}
                    onChange={e=>{handleChangeCampo("i_tipo", parseInt(e.target.value))}}
                />
                <Motoresdebusqueda
                    onChange={e=>{handleChangeCampo(e.target.name, e.target.value)}}
                />
                <Plantilla/>
                <Adicionales
                    datos={[
                        {contenido: ["Text 1"]},
                        {contenido: ["Text 2"]}
                    ]}
                />
            </Col>
        </Row>
    </Box>)
}

export default Detalle
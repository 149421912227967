/* eslint-disable import/no-anonymous-default-export */
import { 
    MINIMIZAR_SIDELEFT,
    MINIMIZAR_MENUBAR,
    SELECCIONAR_MODULO_MENU
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case MINIMIZAR_SIDELEFT:
            return{
                ...state,
                minimizar: action.payload
            }
        case MINIMIZAR_MENUBAR:
            return{
                ...state,
                open_menu: action.payload
            }
        case SELECCIONAR_MODULO_MENU:
            return{
                ...state,
                modulo_seleccionado: action.payload
            }
        default:
            return state
    }
}
import React, { useReducer } from 'react';
import logsReducer from './logsReducer';
import { LogsContext } from '..';
import { OBTENER_LOGS } from '../../types'
import axios from 'axios';


const LogsState = props => {
    const initialState = {
        logs: []
    }
 
    const [state, dispatch] = useReducer(logsReducer, initialState);
    
    const obtenerLogs = async (fecha, palabra_clave) => {
        try{
            let gamalavteg = axios.create({baseURL: "https://gama.lavteg.com"})
            gamalavteg.defaults.headers.common['authorization'] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1pbHRvbnJhbW9zQGxhdm9pZy5uZXQiLCJ0eXBlIjoidXNlciIsImlhdCI6MTcyMTY3OTQxNiwiZXhwIjozMTcyNjYxMjE4MTZ9.hXAVVJKbM8LyfyhW-8HqPDUFhAWnSLE6g2_SP1ODuQ0";
            const res = await gamalavteg.get(`/api/logs`);
            let data = res?.data ? res.data: []
            if(data?.code == 200){
                let logs_result = data?.logs?.length > 0 ? data.logs: [];

                dispatch({
                    type: OBTENER_LOGS,
                    payload: logs_result
                })
            }
        }catch(err){

        }
    }

    return(
        <LogsContext.Provider value={{
            logs: state.logs,
            obtenerLogs
        }}>
            {props.children}
        </LogsContext.Provider>
    )
}

export default LogsState;

import MenuStatic from './material/MenuStatic';
import {read, utils } from 'xlsx';

import { 
    Box, Grid, TextField, Typography, styled, Button, Modal
} from "@mui/material";
import React, { useContext, useEffect } from 'react';
import moduleContext from '../context/modules/moduleContext';
import stylesContext from '../context/styles/stylesContext';
import settingsContext from '../context/settings/settingsContext';
import AuthContext from '../context/authentication/authContext';
import Conversations from './customers/Conversations';
import Phones from './customers/Phones';
import Contacts from './customers/Contacts';
import Estadisticas from './customers/Estadisticas';
import Inventario from "./logistica/Inventario";
import Productos from "./logistica/Productos";
import Leads from './customers/Leads';
import { Bloque } from './material';
import { useState } from 'react';
import inventarioContext from '../context/inventario/inventarioContext';
import { heads } from "./logistica/dist";
import { Close } from '@mui/icons-material';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import sectionContext from '../context/sections/sectionContext';
import Variante_detalle from './logistica/Variante_detalle';
import { ProductosContext } from '../context';
import Sucursales from './logistica/Sucursales';
import Colecciones from './canales/Colecciones/index';
import Cotizaciones from './customers/Cotizaciones/index';


const Home = (props) => {
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [actualizar, setActualizar] = useState(false)

    const productosContext = useContext(ProductosContext);
    const { seleccionar_producto } = productosContext;

    const styleContext = useContext(stylesContext);
    const {  getStyles } = styleContext;

    const SectionContext = useContext(sectionContext);
    const {  seccionSeleccionada, id_seccion } = SectionContext;

    const settingContext = useContext(settingsContext);
    const { viewSettings, settings, options, getSettings } = settingContext;
    
    const modulesContext = useContext(moduleContext);
    const { module, nuevoProducto } = modulesContext;

    const authToken = useContext(AuthContext);
    const { usuarioAutenticado, usuario } = authToken;

    const InventarioContext = useContext(inventarioContext);
    const {
        abrirModal, 
        modalView, 
        inventario_modificado, 
        actualizar_inventario, 
        guardarProductos 
    } = InventarioContext;

    const [themeSelecto, setThemeSelecto] = useState(0);
    const [textoBtn, setTextoBtn] = useState("Agregar");

    const [theme, setTheme] = useState({color: "black !important"});
    const [view, setView] = useState(false);
    const [module_route, setModuleRoute] = useState(null);

    useEffect(()=>{ getStyles(options, settings) },[settings, options]);
    let {subsection, business} = useParams()

    let {section} = useParams()

    useEffect(()=>{
        usuarioAutenticado();
        getSettings();

        if(!localStorage.getItem("id_route")){
            localStorage.setItem("id_route", 1)
        }
        if(localStorage.getItem("module_route")){
            setModuleRoute(localStorage.getItem("module_route"))
        }
    }, [])

    useEffect(()=>{
        setTheme({color: viewSettings == 1 ? "black !important": "white !important"});
    }, [usuario])

    const Options = ({options, name, id}) => {
        return(<TextField
          select
          label={name}
          id={`${id}`}
          sx={{
            "& svg, div":{ color: "black !important"},
            "& fieldset":{ borderRadius: "12px"},
            color: "black !important",
          }}
          SelectProps={{
            native: true,
          }}
          focused
          size='small'
        >
          <option value="" selected disabled hidden>Elegir...</option>
          {/* <option style={{color: "#bdbdbd !important"}}>Elegir item</option> */}
          {options.map((option, i) => (
          <option key={`key_${i}_option`} value={option._id} style={{color: "black !important"}}>
            {option.s_title}
          </option>
        ))}</TextField>)
    }

    const AreaButton = ({children, nombre_modulo}) => {
        return(<Box>{children}</Box>)
    }
    
    useEffect(()=>{
      if(usuario?.theme) setThemeSelecto(usuario?.theme)
    },[usuario])

    useEffect(()=>{
        if(module){
            if(module.length > 0){
                let nombre = module[0].name; 
                switch(nombre.toLowerCase()){
                    case "inventario":
                        abrirModal(view)
                        break;
                    case "productos":
                        abrirModal(view)
                        break;
                    default:
                        break;
                }
            }
        }
    },[view])

    useEffect(()=>{setView(modalView)},[modalView])

    return(<Box>
        <Modal
            open={open}
            onClose={()=>setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleModal}>
                <Box sx={{"& svg": {color: "black !important"}}}>
                    <Grid container>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6} sx={{display: "grid", alignContent: "end", justifyContent: "end"}}>
                            <Close sx={{marginTop: "10px", marginRight: "10px", cursor: "pointer"}} onClick={()=>setOpen(false)}/>
                        </Grid>
                    </Grid>
                    <Typography sx={{color: "black !important", margin: "10px 20px"}}>Se encontraron {data.length} productos nuevos ¿Desea agregarlos?</Typography>
                    <Box sx={{margin: "0px 30px", marginBottom: "10px"}}>
                        {data.length > 0 ? data.map((producto, key_producto) => {
                            return(<Box key={key_producto} sx={{display: "flex", alignItems: "center"}}>
                                <Typography sx={{color: "black !important"}}>- {producto.Title ? producto.Title: "Sin Titulo"}</Typography>
                                <Close sx={{marginLeft: "10px", fontSize: "15px"}}/>
                            </Box>)
                        }): null}
                    </Box>
                    <Grid container>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6} sx={{display: "grid", alignContent: "end", justifyContent: "end"}}>
                            <Button variant="contained" sx={{...styleBtnModule, marginBottom: "10px", marginRight: "10px"}} 
                                onClick={()=>{
                                    let array_data = [];
                                    data.map((producto, i) =>{
                                        let keys_producto = Object.keys(producto)
                                        keys_producto.map(key_producto => {
                                            let head_key = heads.filter(head => head.name == key_producto);
                                            if(head_key.length > 0){
                                                array_data[i] = {
                                                    ...array_data[i],
                                                    createAt: Date.now(),
                                                    [head_key[0].id]: producto[key_producto]
                                                }
                                            }
                                        })
                                    })

                                    guardarProductos(array_data)
                                }}>Agregar</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Modal>
        <MenuStatic moduleOpen={true}>
            {module?.length > 0 && module ? (<Box className='area-modulo' sx={areaModulo}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography id="titulo-modulo" sx={[tituloModulo, theme, {display: 
                            module[0].name == "Detalle" || 
                            module[0].name == "Variantes" ||
                            module[0].name == "Inventario" ||
                            module[0].name == "Productos" ||
                            module[0].name == "Cotizaciones" ||
                            module[0].name == "Colecciones" ||
                            module[0].name == "Sucursales" ? "none": "block"}]} variant='h6'>{module[0].name}</Typography>
                        <Box sx={{"& p":{color: "#626262 !important", fontSize: "12px", margin: "0px !important"}}}>
                            {/* {
                                module.length > 0 ? 
                                    module[0].name == "Inventario" ? (<Box>
                                        <Typography>Gestiona las cantidades de inventario de tus productos físicos.</Typography> 
                                    </Box>): 
                                    null
                                :(<Box sx={{paddingBottom: "15px !important"}}/>)
                            } */}
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{
                        textAlign: "end",
                        "& #btn-agregar":{
                            margin: "10px !important", padding: '5px 20px !important'
                        }
                    }}>
                        <AreaButton nombre_modulo={module[0].name}/>
                    </Grid>
                </Grid>

                <Bloque bkg={viewSettings ? "transparent": viewSettings}>
                    {
                        module[0].name == "Conversaciones"?         (<Conversations />):
                        module[0].name == "Cotizaciones"?           (<Cotizaciones />):
                        module[0].name == "Centro de contactos"?    (<Contacts />):
                        module[0].name == "Directorio"?             (<Phones />):
                        module[0].name == "Solicitudes"?            (<Leads />):
                        module[0].name == "Estadísticas"?           (<Estadisticas />):
                        module[0].name == "Inventario"?             (<Inventario />):
                        module[0].name == "Productos"?              (<Productos reenviar={props.history}/>):
                        module[0].name == "Colecciones"?            (<Colecciones/>):
                        module[0].name == "Variantes"?              (<Variante_detalle/>):
                        module[0].name == "Sucursales"?             (<Sucursales/>):
                        (<Box></Box>)
                    }
                </Bloque>
            </Box>): (<Box>
                {id_seccion ? 
                    id_seccion == 6 ? (<Box sx={{
                        marginTop: "10px"
                    }}><Productos reenviar={props.history}/></Box>): 
                    id_seccion == 5 ? (<Box sx={{
                        marginTop: "10px"
                    }}>
                        <Inventario/>
                    </Box>): 
                null: business && subsection == "sucursales" ? `${business}-${subsection}` :null}
            </Box>)}
        </MenuStatic>
    </Box>)
}

export default Home;

const areaModulo = {
    borderRadius: "5px"
}

const tituloModulo={
    paddingBottom: '0px',
    textAlign: 'start',
    fontWeight: "bold"
}

const FileInput = styled('input')({display: 'none'})
const TxtFile = styled('label')({
  border: '1px solid #ccc',
  borderRadius: '10px',
  margin: '10px 0px',
  display: 'inline-block',
  padding: '6px 12px',
  cursor: 'pointer'
})

const styleBtnModule = {
    color: "#fff",
    boxShadow: "0px 2px 5px -4px black",
    backgroundColor: "#3f3f3f !important",
    fontSize: "12px",
    borderRadius: "8px",
    textTransform: "none"
}

const styleBtnImpExpModule = {
    color: "#fff",
    boxShadow: "0px 2px 5px -4px black",
    backgroundColor: "#626262 !important",
    fontSize: "12px",
    borderRadius: "8px",
    textTransform: "none"
}

const styleBtnModuleSecond = {
    padding: "5px 20px !important",
    color: "black !important",
    boxShadow: "0px 2px 5px -4px black",
    backgroundColor: "#626262 !important",
    fontSize: "13px",
    borderRadius: "8px",
    color: "white !important"
    // textTransform: "none"
}

const styleModal = {
    position: 'absolute',
    color: "black !important",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    height: "fit-content",
    // background: "#f1f1f1",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    // boxShadow: 24,
    // p: 4,
    borderRadius: "10px"
  };

import React, { useReducer } from 'react';
import eventosReducer from './eventosReducer';
import eventosContext  from './eventosContext';
import clientAxios from '../../config/axios';
import { 
    GUARDAR_EVENTO
} from '../../types'

const EventosState = props => {
    const initialState = {
        agregar: ""
    }

    const [state, dispatch] = useReducer(eventosReducer, initialState)
    
    const fnAgregar = async (id, cb = () => console.log("Default")) => {  
        let evento = state.agregar;  
        if(!evento.id || evento.id != id){
            let getElement = document.querySelector("#btn-agregar")
            const dogFound = new CustomEvent(id, { detail: { name: "dog" },});
            // getElement.addEventListener(id, e => cb(e));
            // if(getElement) getElement.addEventListener("click", e => { getElement.dispatchEvent(dogFound) });

            dispatch({ type: GUARDAR_EVENTO, 
                payload: id
            })
        }
    }
    
    return(
        <eventosContext.Provider value={{
            agregar: state.agregar,
            fnAgregar,
        }}>
            {props.children}
        </eventosContext.Provider>
    )
}

export default EventosState;

import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Skeleton } from '@mui/material';
import Icon from '../../layout/Material/Icons';

// const rows = [
//   createData(1, 'Cupcake', 305, 3.7, 67, 4.3),
//   createData(2, 'Donut', 452, 25.0, 51, 4.9),
//   createData(3, 'Eclair', 262, 16.0, 24, 6.0),
//   createData(4, 'Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData(5, 'Gingerbread', 356, 16.0, 49, 3.9),
//   createData(6, 'Honeycomb', 408, 3.2, 87, 6.5),
//   createData(7, 'Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData(8, 'Jelly Bean', 375, 0.0, 94, 0.0),
//   createData(9, 'KitKat', 518, 26.0, 65, 7.0),
//   createData(10, 'Lollipop', 392, 0.2, 98, 0.0),
//   createData(11, 'Marshmallow', 318, 0, 81, 2.0),
//   createData(12, 'Nougat', 360, 19.0, 9, 37.0),
//   createData(13, 'Oreo', 437, 18.0, 63, 4.0),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const hdlabel = {
        fontSize: "12px !important",
        fontFamily: "mulish-extra-bold"
    }
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{
      bgcolor: "rgb(250, 250, 250) !important",
      "& th":{
        bgcolor: "rgb(250, 250, 250) !important",
      }
    }}>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            style={{
                transform: "scale(0.7)",
            }}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              fontSize: "12px",
              fontFamily: "'semibold'"
            }}
            key={headCell.id}
            // align={headCell.numeric ? 'right' : headCell?.align ? headCell.align: 'left'}
            align={headCell.align ? headCell.align : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              // active={orderBy === headCell.id}
              // direction={orderBy === headCell.id ? order : 'asc'}
              // onClick={createSortHandler(headCell.id)}
            > */}
              {headCell.label}

              {/* {headCell.hideOrder ? null: orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            {/* </TableSortLabel> */}

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({rows=[], headCells=[], loading=true, hx=0, click_per_item=e=>console.log("on click!")}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('s_title');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [items, setItems] = useState([0,1,2,3,4,5,6,7,8,9]);

  useEffect(()=>{
    console.log(rows)
    if(rows?.length > 0){
        setTimeout(()=>{
            setRowsPerPage(51)
            setRowsPerPage(50)
        },100)
    }
  },[rows])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  const Loading_form = (id=0) => {
    return(<TableRow
        hover
        // onClick={(event) => handleClick(event, id)}
        role="checkbox"
        // aria-checked={isItemSelected}
        tabIndex={-1}
        key={id}
        // selected={isItemSelected}
        sx={{ cursor: 'pointer' }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            style={{
                transform: "scale(0.7)",
            }}
            color="primary"
            // checked={isItemSelected}
            inputProps={{
              'aria-labelledby': "2938klkd",
            }}
          />
        </TableCell>
        <TableCell align='left' component="th" id={`test-${id}`} scope="row" padding="none">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="center">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="center">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="center">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="left">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="left">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
      </TableRow>)
  }

  const style_label = {
    fontSize: "12px !important",
    fontFamily: "'semibold'",
    padding: "0px !important",
    "& svg":{
        fontSize: "17px !important",
        color: "#DAD9D5 !important"
    }
  }

  return (
    <Box sx={{ width: '100%', height: "100%" }}>
      <Paper sx={{ width: '100%', borderRadius: "8px"}}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer
          sx={{ height: `calc(100vh - ${hx + 180}px) !important`, borderRadius: "8px"}}
          className="scroll-table-component-side-left"
        >
          <Table
            stickyHeader 
            aria-label="sticky table"
            sx={{ minWidth: 350 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
                headCells={headCells}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
            />
            <TableBody>
                {loading ? items.map(item=>(<Loading_form id={item}/>)):
                visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => {
                      click_per_item(row)
                      handleClick(event, row.id)
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{
                      cursor: 'pointer',
                      "&.Mui-selected": {
                        background: "#EBEBEB !important"
                      }
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Box sx={{
                        width: "32px",
                        height: "32px",
                        border: "1px solid #EBEBEB",
                        borderRadius: "4px",
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center",
                        marginBottom: "4.5px",
                        marginTop: "4.5px"
                      }}>
                        {row.id_tipo == 0?   (<Icon sx={{
                          height: "12px",
                          width: "12px"
                        }} name="Warehouse"/>):
                          row.id_tipo == 1? (<Icon sx={{
                            height: "12px",
                            width: "12px"
                          }} name="Fullfillment"/>):
                          row.id_tipo == 2? (<Icon sx={{
                            height: "12px",
                            width: "12px"
                          }} name="Point"/>):
                          row.id_tipo == 3? (<Icon sx={{
                            height: "12px",
                            width: "12px"
                          }} name="Office"/>):
                          row.id_tipo == 4? (<Icon sx={{
                            height: "12px",
                            width: "12px"
                          }} name="Location"/>):
                        null}
                      </Box>



                      {/* <Checkbox
                        style={{
                            transform: "scale(0.7)",
                        }}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      /> */}
                    </TableCell>
                    <TableCell sx={style_label} align='left' component="th" id={labelId} scope="row" padding="none">
                      {row.nombre}
                    </TableCell>
                    <TableCell sx={style_label} align="left">{row.tipo}</TableCell>
                    <TableCell sx={style_label} align="center">{row.almacenamiento}</TableCell>
                    <TableCell sx={style_label} align="center">{row.preparacion}</TableCell>
                    <TableCell sx={style_label} align="center">{row.retiro}</TableCell>
                    <TableCell sx={style_label} align="center">{row.pais}</TableCell>
                    <TableCell sx={style_label} align="left">{row.direccion}</TableCell>
                  </TableRow>
                );
              })}
            
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 150, 200]}
          component="div"
          labelRowsPerPage=""
          labelDisplayedRows={()=> {
            return ''
          }}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            hidden: true,
            sx:{
              display: "none"
            }
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}
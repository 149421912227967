/* eslint-disable import/no-anonymous-default-export */
import {
    SECCIONES_MODULO,
    AGREGAR_SECCION,
    VALIDAR_SECCION,
    ELIMINAR_SECCION,
    ESTADO_SECCION,
    SECCION_ACTUAL,
    ACTUALIZAR_SECCION,
    LIMPIAR_SECCION,
    ABRIR_SECCION
} from '../../types/index'

export default (state, action) => {
    switch(action.type){
        default:
            return state;
    }
}
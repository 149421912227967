import React, {useContext, useEffect} from 'react';
import {  
    Toolbar, IconButton, Box, Menu, MenuItem, Avatar,
    ListItemIcon, Divider, Typography, Tooltip, Grid,
    InputBase, FormControl, Select, styled
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Logout, Notifications } from '@mui/icons-material';
import AuthContext from '../../context/authentication/authContext';
import SettingsContext from '../../context/settings/settingsContext';
import { useState } from 'react';
import settingsContext from '../../context/settings/settingsContext';
import imgBkgLogo from '../../img/logos/logo_w.png'
import imgBkgLogo2 from '../../img/logos/logo_w_h.png'
import Icon from './Material/Icons';

const ListItemImage = styled('img')({
  width: '70%',
  maxWidth: '90px',
  maxHeight: "40px",
  textAlign: 'center'
})

const ToolbarMui = ({setOpen, open}) => {
    const authContext = useContext(AuthContext);
    const { usuario, negocios, cerrarSesion } = authContext;

    const SettingContext = useContext(settingsContext);
    const { viewSettings } = SettingContext;

    const handleDrawerOpen = () => { setOpen(true) };
    const logOut = () => {
      localStorage.clear()
      cerrarSesion()
    }
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [themeSelecto, setThemeSelecto] = useState(0);
    const [letterTheme, setLetterTheme] = useState({color: "var(--dark-color-letter)"});
    const [borderTheme, setBorderTheme] = useState({borderColor: "var(--dark-color-letter)"});
    
    useEffect(()=>{
        setThemeSelecto(viewSettings)
        setLetterTheme({color: "var(--light-color-letter)"})
        setBorderTheme({borderColor: "var(--light-color-letter)"})

        document.getElementById('toolbar-container').classList.add(!viewSettings ? 'dark-theme': 'light-theme');
        document.getElementById('toolbar-container').classList.remove(!viewSettings ? 'light-theme': 'dark-theme');

        // document.getElementById('toolbar-container').classList.remove(viewSettings  ?  'dark-theme': 'light-theme');
    },[viewSettings])

    const handleClose = () => { setAnchorEl(null) };

    return(
        <Toolbar
          id="toolbar-container"
          className={`hrDrawerBottom`}
          sx={{ 
            width: "100%",
            border: "0px !important",
            minHeight: "50px !important",
            background: "#1A1E1D !important",
            boxShadow: 'none',
            padding: "0px !important",
            paddingLeft: "10px !important"
          }}
        >
          <Grid container sx={{marginLeft: "10px"}}>
            <Grid item xs={3} sx={{display: "grid", alignContent: "center"}}>
              <ListItemImage src={process.env.REACT_APP_STORE != "Lavteg"? imgBkgLogo2: imgBkgLogo}/>
            </Grid>
            <Grid item xs={1} sx={{display: "grid", alignContent: "center"}}></Grid>
            <Grid item xs={4} sx={{
              // background: "#3f3f3f !important",
              // borderRadius: "8px",
              // paddingLeft: "15px",
              // maxHeight: "32px"
            }}>
              {/* <Grid container>
                <Grid item xs={1} sx={{display: "grid", justifyContent: "end"}}>
                  <IconButton type="button" sx={{ p: '5', textAlign: "end"}} aria-label="search">
                    <Icon name="SearchLigth"/>
                  </IconButton>
                </Grid>
                <Grid item xs={11}>
                  <InputBase
                    sx={{ "& input":{
                      color: "#FFFFFF !important",
                      fontFamily: "'mulish-regular'",
                      fontSize: "12px"
                    }, color: letterTheme, flex: 1, height: "100%", width: "100%" }}
                    placeholder="Buscar canales, clientes, documentos y más..."
                    inputProps={{ 'aria-label': 'search google maps', color: letterTheme }}
                  />
                </Grid>
              </Grid> */}
            </Grid>
            {/* <Grid item xs={1} sx={{display: "grid", alignContent: "center"}}></Grid> */}
            <Grid item xs={4}>
              <Box sx={{display: 'flex', width: '100%'}}>
                <Box sx={{width: '10%'}}>
                  <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      sx={{ mr: 2, ...(open && { display: 'none' }) }}
                  >
                      <MenuIcon />
                  </IconButton>
                </Box>
                  <Box sx={{
                      width: '100%',
                      marginRight: "10px",
                      display:'flex',
                      "& p, path":{
                        color: "white !important",
                        fontSize: "12px"
                      },
                      justifyContent: 'end',
                      alignItems: "center"
                  }}>
                    {/*<>
                      <Box sx={iconToolbar}>
                        <Icon name="Notifications" sx={{marginLeft: "1px !important"}}/>
                        <MapsUgc sx={{fontSize: "16px"}}/> 
                      </Box>
                      <Box sx={iconToolbar}>
                        <Notifications sx={{fontSize: "18px"}}/>
                      </Box>
                    </>*/}
                    <AccountMenu
                      negocios={negocios}
                      usuario={{...usuario, viewSettings}}
                      logOut={logOut}
                      themeSelecto={themeSelecto}
                    />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
    )
}

export default ToolbarMui

const AccountMenu = ({negocios=[], usuario = {name: "Default"}, logOut = ()=> console.log("logout"), themeSelecto = 0}) => {
  const settingsContext = useContext(SettingsContext);
  const { viewSettings, modificarTema } = settingsContext;

  // const authContext = useContext(AuthContext);
  // const { usuario } = authContext;

  const [name_business, setName] = useState("")
  const id_route = localStorage.getItem("id_route")
  const [business, setBusiness] = useState([])

  useEffect(()=>{
    if(negocios?.length > 0){
      setBusiness(negocios)
    }
  }, [negocios])

  useEffect(()=>{
    let long_arr = business?.length> 0 ? business.length: 0
    if(id_route && long_arr > 0){
      let seleccionado = business.filter(b_ => b_.id == id_route)

      if(seleccionado?.length > 0){
        let labelBusiness = seleccionado[0].name,
        labelSinEspacios = labelBusiness.replace(/\s/g, '')
        let sinCaracteres = labelSinEspacios.replace(/[^\w]/gi, '')

        console.log("SELECCIONADO", seleccionado)

        setName(seleccionado[0]?.name ? seleccionado[0].name: "Error Route-")

      }else{
        seleccionado = business
        localStorage.setItem("id_route", seleccionado[0].id)
        console.log("SELECCIONADO NO", seleccionado)

        setName(seleccionado[0]?.name ? seleccionado[0].name: seleccionado[0]?.s_nombre ? seleccionado[0].s_nombre: "Error Route-")
      }
    }
    // business[id_route]?.name == "Lavoig_net" ? "Lavoig.net": business[id_route].name
  },[business])
  // },[id_route])


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => { setAnchorEl(null) };

  const accionTema = () => {
    modificarTema(viewSettings ? viewSettings: 0)
    setAnchorEl(null);
  }

  const letterBusiness = (nombre) => {
    let arr_nombre = nombre.split(' '), str_letter = "";
    if(arr_nombre?.length > 0){
      if(arr_nombre?.length > 1){
        let str_1 = arr_nombre[0], str_1_letter = str_1.charAt(0)
        let str_2 = arr_nombre[1], str_2_letter = str_2.charAt(0)
        return `${str_1_letter.toUpperCase()}${str_2_letter.toUpperCase()}`
      }
      str_letter = arr_nombre[0];

      let firstCrt = str_letter.charAt(0), lastCrt = str_letter.charAt(str_letter.length - 1);
      return `${firstCrt.toUpperCase()}${lastCrt.toUpperCase()}`
    }
    console.log(arr_nombre)
    return "DT"
  }


  const seleccionarNegocio = e => {
    console.log(e.target.value)
    let seleccion = business.filter(busin => busin.id == e.target.value)
    if(seleccion?.length > 0){
      let labelBusiness = seleccion[0].name,
      labelSinEspacios = labelBusiness.replace(/\s/g, '')
      let sinCaracteres = labelSinEspacios.replace(/[^\w]/gi, '')
  
      console.log(sinCaracteres)
      // labelSinCaracteres = labelSinEspacios.replace(/[^a-zA-Z0-9 ]/g, '')
      localStorage.setItem("route", sinCaracteres.toLowerCase())
      localStorage.setItem("id_route", e.target.value)
      window.location.replace(`/business/${sinCaracteres.toLowerCase()}`);
    }else{
      console.log("ERROR ROUTE")
    }
  }

  useEffect(() => {
    modificarTema(usuario?.theme ? usuario.theme: 0);
  },[])

    return (
      <React.Fragment>
        <Box sx={{ 
          display: 'flex',
          width: "fit-content",
          // maxWidth: "165px",
          alignItems: 'center',
          textAlign: 'center',
          background: "#3f3f3f",
          marginRight: "10px",
          borderRadius: "8px"
        }}>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{
                marginRight: "8px",
                color: "#08306B !important",
                background: "#92BFFF",
                width: "20px !important",
                height: "20px !important",
                fontSize: "9px",
                fontFamily: "mulish-bold"
                }}>{letterBusiness(name_business)}</Avatar>
              <Typography sx={{marginRight: "10px", "& p": {color: "white !important"}}}>
                {name_business}
              </Typography>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          sx={{padding: "0px !important"}}
          PaperProps={{
            elevation: 0,
            sx: {
              width: "250px",
              overflow: 'visible',
              "& span, p, li":{ fontSize: "12px !important" },
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom'}}
        >
            <Box sx={{
                textAlign: 'Left',
                paddingY: '10px',
                paddingTop: "0px !important",
                width: "100%"
            }}>
              <Typography component={'span'} variant={'body2'}
                sx={{
                color: "black",
                marginLeft: "17px",
                marginBottom: "10px"
                }}>Negocios</Typography>
              <Box className='scrollChat' sx={{maxHeight: "163px"}}>
                {business?.length > 0 ? business.map((b, i) => {
                  return(<MenuItem
                  onClick={seleccionarNegocio}
                  key={b?.id || b?.id == 0 ? b.id:i} className="full-black-color"
                  value={b?.id || b?.id == 0 ? b.id:i}
                  selected
                >
                    <Avatar sx={{
                      marginRight: "8px",
                      color: "#08306B !important",
                      background: "#92BFFF",
                      width: "20px !important",
                      height: "20px !important",
                      fontSize: "9px",
                      fontFamily: "mulish-bold"
                    }}>{letterBusiness(b?.name == "Lavoig_net" || b?.s_nombre == "Lavoig_net"? "Lavoig.net": b?.name ? b.name: b?.s_nombre ? b.s_nombre: "No name")}</Avatar>
                    {b?.name == "Lavoig_net" || b?.s_nombre == "Lavoig_net"? "Lavoig.net": b?.name ? b.name: b?.s_nombre ? b.s_nombre: "No name"}
                  </MenuItem>)}) : null}
              </Box>
            </Box>
          <Divider />
            <MenuItem sx={{
              padding: "5px 6px",
              minHeight: "10px !important",
              maxHeight: "25px !important",
            }} className='item-menu-options' onClick={()=>{
                logOut()
                handleClose()
            }}>
            <ListItemIcon>
              <Logout sx={{color: "black !important", fontSize: "15px !important", marginLeft: "10px"}} />
            </ListItemIcon>
              Cerrar Sesión
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }


const iconToolbar = {
  background: "#3f3f3f",
  width: "25px",
  height: "25px",
  borderRadius: "50px",
  marginRight: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "& path":{
    color: "white !important"
  }
}
import React, { useReducer } from 'react';
import loadingReducer from './loadingReducer';
import {LoadingContext} from '../index'
import clientAxios from '../../config/axios';
import {
    SET_LOAD,
    STATUS_LOAD
} from '../../types'

const LoadingState = props => {
    const initialState = {
        loading: {}
    };
    const [state, dispatch] = useReducer(loadingReducer, initialState)

    const set_loading = status =>{
        dispatch({
            type: SET_LOAD,
            payload: status
        })
    }

    return(
        <LoadingContext.Provider value={{
            loading: state.loading,
            set_loading
        }}>
            {props.children}
        </LoadingContext.Provider>
    )
}

export default LoadingState;

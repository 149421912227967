/* eslint-disable import/no-anonymous-default-export */
import { 
    EDITAR_COLECCION,
    GUARDAR_COLECCION,
    OBTENER_COLECCIONES,
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case OBTENER_COLECCIONES:
            return{
                ...state,
                colecciones: action.payload
            }
        case GUARDAR_COLECCION:
            return{
                ...state,
                colecciones: [
                    ...state.colecciones,
                    action.payload,
                ]
            }
        case EDITAR_COLECCION:
            let array_colecciones = state.colecciones, sel_coleccion = action.payload, 
            filter_colecciones = array_colecciones.map(item_coleccion => {
                if(item_coleccion._id == sel_coleccion._id){
                    return sel_coleccion
                }
                return item_coleccion
            })

            console.log(filter_colecciones)
            return{
                ...state,
                colecciones: filter_colecciones
            }
        default:
            return state
    }
}
/* eslint-disable import/no-anonymous-default-export */
import { 
    SET_LOAD
} from "../../types"


export default (state, action) => {
    switch(action.type){
        case SET_LOAD:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    ...action.payload
                }
            }
        default:
            return state
    }
}
import React, {useEffect, useState, } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import iconFilter from "../../img/icons/Filer-Options-Dark-Gray-2.png"
import { Box, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtros, setFiltros] = useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
    setFiltros([
        {label: "Nombre",       value: "nombre"},
        {label: "SKU",          value: "sku"},
        {label: "Precio",       value: "precio"},
        {label: "Proveedor",    value: "proveedor"},
        {label: "Inventario",   value: "inventario"},
        {label: "Creación",     value: "creacion"},
        {label: "Modificación", value: "modificacion"},
    ])
  },[])
  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
            height: "30px",
            width: "30px",
            minWidth: "15px"
        }}
      >
        <img src={iconFilter} width="16px" height="16px"/>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
            "& ul": {minWidth: "400px !important"}
            // sx={{
            // }}
        }}
      >
        <Typography sx={{
            fontSize: "12px",
            fontFamily: "mulish-bold",
            marginLeft: "10px",
            marginRight: "30px",
            color: "#303030"
        }}>Ordenar por</Typography>
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                sx={{
                    "& label, span, svg": {
                        fontSize: "12px !important",
                        fontFamily: "semibold"
                    }
                }}
            >
                {filtros.length > 0 ? filtros.map((filtro, i_filtro) => {
                    return(<FormControlLabel
                        sx={controlStyle}
                        key={i_filtro}
                        value={filtro.value}
                        label={filtro.label}
                        control={<Radio sx={optionStyle}/>}
                    />)
                }): null}
            </RadioGroup>
            <Divider sx={{marginY: "10px"}}/>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                sx={{
                    "& label, span, svg": {
                        fontSize: "12px !important",
                        fontFamily: "semibold"
                    }
                }}
            >
                <FormControlLabel sx={controlStyle} value="menormayor"   label="De menor a mayor"  control={<Radio sx={optionStyle}/>} />
                <FormControlLabel sx={controlStyle} value="mayormenor"   label="De mayor a menor"  control={<Radio sx={optionStyle}/>} />
            </RadioGroup>
        </FormControl>
      </Menu>
    </Box>
  );
}

const optionStyle = {
    width: "15px",
    height: "15px",
    marginRight: "5px",
    color: "#626262",
    '&.Mui-checked': { color: "#626262" }
}

const controlStyle = { marginLeft: "10px"}
import React, { useReducer } from 'react';
import preliminaresReducer from './preliminaresReducer';
import PreliminaresContext  from './preliminaresContext';
import clientAxios from '../../config/axios';
import { 
    COMENTARIOS_PRELIMINARES,
    GUARDAR_PRELIMINARES,
    MODIFICAR_PRELIMINARES,
    OBTENER_PRELIMINARES
} from '../../types'

const PreliminaresState = props => {
    const initialState = {
        preliminares: []
    }
    const [state, dispatch] = useReducer(preliminaresReducer, initialState)

    const obtenerPreliminares = async (id_negocio) => {
        console.log(id_negocio)
        try{
            const res = await clientAxios.post('/api/preliminares/get', {id_negocio: id_negocio})
            if(res?.data?.success){
                dispatch({
                    type: OBTENER_PRELIMINARES, 
                    payload: res?.data?.preliminares ? res.data.preliminares: []
                })
            }
        } catch(err){
            console.log(err)
        }
    }

    const guardar_preliminar = async nueva_preliminar => {
        try{
            const res = await clientAxios.post('/api/preliminares', nueva_preliminar)
            if(res?.data?.success){
                dispatch({
                    type: GUARDAR_PRELIMINARES, 
                    payload: res?.data?.preliminares ? res.data.preliminares: []
                })

                return true
            }else{ return false; }
        }catch(err){
            console.log(err)
            return false;
        }
    }

    const editar_preliminar = async info => {
        try{
            const res = await clientAxios.put('/api/preliminares', info)
            if(res?.data?.success){
                dispatch({
                    type: MODIFICAR_PRELIMINARES, 
                    payload: info
                })

                return true
            }else{ return false; }
        }catch(err){
            console.log(err)
            return false;
        }
    }

    const crear_comentario = async data_comment => {
        try{
            const res = await clientAxios.post('/api/preliminares/comment', data_comment)
            if(res?.data?.success){
                console.log(res.data)
                // dispatch({
                //     type: COMENTARIOS_PRELIMINARES, 
                //     payload: res.data
                // })

                return true
            }else{ return false; }
        }catch(err){
            console.log(err)
        }
    }

    const eliminar_log = async _id => {
        try{
            const res = await clientAxios.delete(`/api/preliminares/eliminar_log/${_id}`,)
            if(res?.data?.success){
                console.log(res.data)
                // dispatch({
                //     type: COMENTARIOS_PRELIMINARES, 
                //     payload: res.data
                // })

                return true
            }else{ return false; }
        }catch(err){
            console.log(err)
            return false
        }
    }
    
    return(
        <PreliminaresContext.Provider value={{
            preliminares: state.preliminares,
            guardar_preliminar,
            obtenerPreliminares,
            crear_comentario,
            editar_preliminar,
            eliminar_log
        }}>
            {props.children}
        </PreliminaresContext.Provider>
    )
}

export default PreliminaresState;

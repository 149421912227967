import * as React from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem
} from '@mui/material';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const BasicMenu = ({name, options = [], children, activeModule }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {children ? children : 'default'}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          options.map((option, i) => {
            if(option.name)return (
              <MenuItem key={i} onClick={()=>{
                // eslint-disable-next-line no-unused-expressions
                option.fn ? option.fn() : null;
                handleClose()
              }}>
                {option.name}
              </MenuItem>
            )
          })
        }
      </Menu>
    </Box>
  );
}

export default BasicMenu

import { Box } from "@mui/material";
import React, { useContext, useEffect } from 'react';
import moduleContext from '../../context/modules/moduleContext';
import stylesContext from '../../context/styles/stylesContext';
import settingsContext from '../../context/settings/settingsContext';
import AuthContext from '../../context/authentication/authContext';
import { useState } from 'react';
import inventarioContext from '../../context/inventario/inventarioContext';

export const Menu = (props) => {
    let {children} = props;
    const styleContext = useContext(stylesContext);
    const {  getStyles } = styleContext;

    const settingContext = useContext(settingsContext);
    const { settings, options, getSettings } = settingContext;

    const authToken = useContext(AuthContext);
    const { usuarioAutenticado, usuario } = authToken;

    const [exist, setExist] = useState(9999);

    useEffect(()=>{ getStyles(options, settings) },[settings, options]);
    useEffect(()=>{
        usuarioAutenticado();
        getSettings();
    }, [])
    
    useEffect(()=>{
        if(usuario == false){
            setExist(usuario == false ? 0: usuario ? 1: 9999)
        }
    },[usuario])

    useEffect(()=>{
        if(exist == 0){ window.location.href = `/login` }
    },[exist])

    return(<Box>{children}</Box>)
}

import "./new_account.css"
import React, {useContext, useEffect, useState} from 'react';
import alertsContext from '../../context/alerts/alertsContext';
import AuthContext from '../../context/authentication/authContext';
import { Alert, Box, styled, TextField, Typography } from '@mui/material';
 
const NewAccount = props => {
    // Extraer los valores del context
    const alertContext = useContext(alertsContext);
    const { alert, mostrarAlerta } = alertContext;

    const authContext = useContext(AuthContext);
    const { mensaje, registrarUsuario, autenticado } = authContext;

    useEffect(()=>{
        if(autenticado){
            props.history.push('/modules')
        }

        if(mensaje){
            mostrarAlerta(mensaje.msg, 'error')
            return;
        }
    }, [mensaje, autenticado, props.history])

    // State para iniciar sesión
    const [user, saveUser] = useState({
        nombre:'',
        email:'',
        password:'',
        confirmar:'',
        ruta: ''
    })

    const {nombre, email, password, confirmar, ruta} = user;

    const onChange = e => {
        saveUser({
            ...user,
            [e.target.name] : e.target.value
        })
    }

    const onSubmit = async e =>{
        e.preventDefault();

        // Validar que no haya campos vacios
        if(nombre.trim() === '' || email.trim() === '' || password.trim() === '' || confirmar.trim() === ''){
            mostrarAlerta('Todos los campos son obligatorios', 'error')
            return;
        }

        // Password minimo de 6 caracteres
        if(password.length < 6){
            mostrarAlerta('La contraseña debe ser de al menos 6 caracteres', 'error')
            return;
        }

        // Los 2 password deben ser iguales
        if(password !== confirmar){
            mostrarAlerta('La contraseña no coincide', 'error')
            return;
        }
        // Pasarlo al action
        registrarUsuario({
            nombre,
            email,
            password,
            ruta
        })
    }

    return(<Box className="bloque" sx={{
        background: `${process.env.REACT_APP_STORE != "Lavteg" ? "#121212":"#0b0f19"} !important`
    }}>
        <Box className="bloque-sigin" sx={{backfaceVisibility: "white !important"}}>
            {/* <Box className={`alerta ${alert.category}`}> <h3>{alert.msg}</h3> </Box> */}
            <Box className="contenedor-form sombra-dark">
                <Typography sx={{marginBottom: "20px", fontFamily: "mulish-bold", textAlign: "center"}} variant="h5">Registrate</Typography>
                <form onSubmit={onSubmit}>
                    <Box sx={{
                        marginBottom: "30px"
                    }}>
                        <Box className="inputs-box">
                            <Typography>Nombre</Typography>
                            <TextField
                                type="text"
                                id="nombre"
                                name="nombre"
                                value={nombre}
                                fullWidth
                                onChange={onChange}
                                variant="standard" // <== changed this
                                // margin="normal"
                                required
                                autoFocus
                                InputProps={{
                                    disableUnderline: true, // <== added this
                                }}
                            />
                        </Box>
                        <Box className="inputs-box">
                            <Typography>Email</Typography>
                            <TextField 
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                fullWidth
                                onChange={onChange}
                                variant="standard" // <== changed this
                                // margin="normal"
                                required
                                autoFocus
                                InputProps={{
                                    disableUnderline: true, // <== added this
                                }}
                            />
                        </Box>
                        <Box className="inputs-box">
                            <Typography>Nombre del Negocio</Typography>
                            <TextField 
                                type="text"
                                id="ruta"
                                name="ruta"
                                value={ruta}
                                fullWidth
                                onChange={onChange}
                                variant="standard" // <== changed this
                                // margin="normal"
                                required
                                autoFocus
                                InputProps={{

                                    disableUnderline: true, // <== added this
                                }}
                            />
                        </Box>
                        <Box className="inputs-box">
                            <Typography>Password</Typography>
                            <TextField 
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                fullWidth
                                onChange={onChange}
                                variant="standard" // <== changed this
                                // margin="normal"
                                required
                                autoFocus
                                InputProps={{
                                    disableUnderline: true, // <== added this
                                }}
                            />
                        </Box>
                        <Box className="inputs-box">
                            <Typography>Confirmar Password</Typography>
                            <TextField 
                                type="password"
                                id="confirmar"
                                name="confirmar"
                                value={confirmar}
                                fullWidth
                                onChange={onChange}
                                variant="standard" // <== changed this
                                required
                                autoFocus
                                InputProps={{
                                    disableUnderline: true, // <== added this
                                }}
                            />
                        </Box>
                        {alert ? (<Alert severity={alert?.category ? alert.category: "error"}>
                            {alert.msg}
                        </Alert>): null}
                    </Box>
                    <Box className="contenedor-btn-sigin">
                        <TextField
                            type="submit"
                            fullWidth
                            className="btn-sigin"
                            value="Crear cuenta"
                            sx={{
                                borderRadius: "8px",
                                backgroundColor:`${process.env.REACT_APP_STORE != "Lavteg" ? "#121212":"#0b0f19"} !important`
                            }}
                            variant="standard" // <== changed this
                            required
                            autoFocus
                            InputProps={{
                                disableUnderline: true, // <== added this
                            }}
                        />
                    </Box>
                </form>
            </Box>
        </Box>
    </Box>
    )
}

export default NewAccount;
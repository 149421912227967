import React, { useReducer } from 'react';
import mercadosReducer from './mercadosReducer';
import MercadosContext from './mercadosContext';
import clientAxios from '../../config/axios';
import { OBTENER_MERCADOS } from '../../types'

const MercadosState = props => {
    const initialState = {
        mercados: []
    }
    const [state, dispatch] = useReducer(mercadosReducer, initialState)

    const obtener_mercados = async () => {
        try{
            const result = await clientAxios.get(`/api/mercados`)
            console.log(result)
            dispatch({
                type: OBTENER_MERCADOS,
                payload: result.data.mercados
            })
            return result.data.mercados
        }catch(err){
            console.log(err)
        }
    }

    const modificar_mercado = async mercado => {
        try{
            let mercados = state.mercados;
            const result = await clientAxios.put(`/api/mercados`, mercado)
            // console.log(mercado)
            // console.log(result)
            if(result?.status == 200){
                dispatch({
                    type: OBTENER_MERCADOS,
                    payload: mercados.map(mercado_seleccionado => {
                        if(mercado_seleccionado?._id == mercado?._id){
                            return mercado
                        }
                        return mercado_seleccionado
                    })
                })
                return true
            }
            return false;
            // return result.data.mercados
        }catch(err){
            console.log(err)
        }
    }

    return(
        <MercadosContext.Provider value={{
            mercados: state.mercados,
            obtener_mercados,
            modificar_mercado
        }}>
            {props.children}
        </MercadosContext.Provider>
    )
}

export default MercadosState;

/* eslint-disable import/no-anonymous-default-export */
import {
    OBTENER_ESTILOS
} from '../../types';

export default (state, action) => {
    switch(action.type){
        case OBTENER_ESTILOS:
            return{
                ...state,
                styles: action.payload
            }
        default:
            return state;
    }
}
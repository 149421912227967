/* eslint-disable import/no-anonymous-default-export */
import {
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
    VALIDACION_SESION,
    OBTENER_NEGOCIOS,
    SELECCIONAR_RUTA_NEGOCIO
} from '../../types'

export default (state, action) => {
    switch(action.type){
        case OBTENER_NEGOCIOS:
            return{
                ...state,
                negocios: action.payload,
            }
        case SELECCIONAR_RUTA_NEGOCIO:
            console.log("dkjskdk")
            return state
            // let negocio_seleccionado = action.payload;
            
            // return{
            //     ...state,
            //     negocio_seleccionado: negocio_seleccionado
            // }
        case OBTENER_USUARIO:
            return{
                ...state,
                usuario: action.payload,
                autenticado: true,
                loading: false
            }
        case LOGIN_EXITOSO:
        case REGISTRO_EXITOSO:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                autenticado: true,
                mensaje: null,
                loading: false
            }
        case CERRAR_SESION:
            window.location.href = '/login';
            localStorage.removeItem('token');
            localStorage.removeItem("id_route");
            localStorage.removeItem('route');
            return {
                ...state,
                token: null,
                usuario: null,
                autenticado: null,
                mensaje: action.payload,
                loading: false
            }
        case LOGIN_ERROR:
        case VALIDACION_SESION:
            console.log("VALIDACION ===========>")
            let validacion = action.payload;
            if(validacion?.success){
                // let id_route = localStorage.getItem("id_route")
                localStorage.setItem("id_route", 1)
                localStorage.setItem("route", "/testing")
                window.location.href = `/business${validacion.route}`;
            }
            
            return {
                ...state,
                validacionToken: action.payload
            }
        case REGISTRO_ERROR:
            localStorage.removeItem("route");
            localStorage.removeItem("id_route");
            localStorage.removeItem('token')
            return {
                ...state,
                token: null,
                usuario: null,
                autenticado: null,
                mensaje: action.payload,
                loading: false
            }
        default:
            return state;
    }
}
import React, { useReducer } from 'react';
import {SucursalesContext} from '../index';
import sucursalesReducer from './sucursalesReducer';
import clientAxios from '../../config/axios'
import {
    MODULO_ACTUAL,
    OBTENER_SUCURSALES,
    SELECCIONAR_SUCURSAL
} from '../../types'

const SucursalesState = props => {
    const initialState = {
        sucursal: null,
        sucursales: []
    }

    const [state, dispatch] = useReducer(sucursalesReducer, initialState)
    
    const guardar_sucursal = async sucursal => {
        const res = await clientAxios.post(`/api/sucursales/nueva-sucursal`, sucursal);

        if(res?.data?.success){
            if(res?.data?.sucursal){
                dispatch({
                    type: OBTENER_SUCURSALES,
                    payload: [
                        res.data.sucursal,
                        ...state.sucursales
                    ]
                })
            }else{
                dispatch({
                    type: OBTENER_SUCURSALES,
                    payload: [
                        sucursal,
                        ...state.sucursales
                    ]
                })
            }
            return res.data.msg
        }else{
            return false
        }
    }

    const editar_sucursal = async (sucursal, _id) => {
        const res = await clientAxios.post(`/api/sucursales/editar-sucursal/${_id}`, sucursal);

        if(res?.data?.success){
            console.log("DATA RESULT =============>")
            let sucursales_old = state.sucursales
            let sucursales_new = sucursales_old.map(suc_old => {
                if(suc_old._id == _id){
                    return sucursal
                }
                return suc_old
            })

            dispatch({
                type: OBTENER_SUCURSALES,
                payload: sucursales_new
            })

            return res.data.msg
        }else{
            return false
        }
    }

    const obtener_sucursales = async (id_negocio) => {
        const res = await clientAxios.get(`/api/sucursales/obtener-sucursales/${id_negocio}`);

        if(res?.data?.success){
            dispatch({
                type: OBTENER_SUCURSALES,
                payload: res.data.sucursales
            })
        }else{
            return false
        }
    }

    const seleccionar_sucursal = async sucursal => {
        dispatch({
            type: SELECCIONAR_SUCURSAL,
            payload: sucursal
        })
    }

    return(
        <SucursalesContext.Provider
            value={{
                sucursal: state.sucursal,
                sucursales: state.sucursales,
                obtener_sucursales,
                guardar_sucursal,
                editar_sucursal,
                seleccionar_sucursal
            }}
        >
            {props.children}
        </SucursalesContext.Provider>
    )
}

export default SucursalesState 
const ConvertDate = date => {
    var d = new Date(date);
    var today = new Date();
    
    let year = d.getFullYear();
    let month = d.getMonth()+1;
    let day = d.getDate();

    let todayYear = today.getFullYear();
    let todayMonth = today.getMonth()+1;
    let todayDay = today.getDate();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    
    let dateToday = todayYear*365 + todayMonth*31 + todayDay
    let dateLast = year*365 + month*31 + day

    if(dateLast<dateToday){
        return day+'/'+month+'/'+year
    }else{
        let todayHour = today.getHours();
        let todayMinute = today.getMinutes();
        return todayHour+':'+todayMinute
    }
}

export default ConvertDate;
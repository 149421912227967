import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable'
import hc_logo from '../img/logos/hc_pdf_header.png'

export const printFile = (data={}) => {
    const PDF = new jsPDF(), m_y = 12.4, m_x = 12.7;
    const bolder_txt = ({txt = "", size=8, dis_x = 0, dis_y = 0}) => {
        PDF.setFontSize(size);
        PDF.setFont(undefined, 'bold');
        PDF.text(txt, m_x + dis_x, m_y + dis_y);    
    }

    const normal_txt = ({txt = "", size=8, dis_x = 0, dis_y = 0}) => {
        PDF.setFontSize(size);
        PDF.setFont(undefined, 'normal');
        PDF.text(txt, m_x + dis_x, m_y + dis_y);    
    }

    let json = {
        // fecha: "31/Agosto/2024",
        // folio: "D98",
        // fecha_vencimiento: "31/Agosto/2024",
        // nombre: "Jonathan Huerta",
        // empresa: "South Baja Constructora",
        // calle: "Blvd. Monte Real 18",
        // colonia: "Monte Real",
        // cp: 23444,
        // ciudad: "San José del Cabo",
        // pais: "México",
        // correo: "compras@sb-constructora.com, eec.huerta@hotmail.com",
        // tel: "55 3847 1939",
        // notas: "Sin notas del cliente",
        // condiciones_envio: "En inventario",
        // tiempo_entrega: "2-4 días hábiles",
        // envio: "Incluido",
        // modo_envio: "Express",
        // forma_pago: "100% Contado",
        comentarios: [
            "*Para pedidos nacionales los precios son en pesos mexicanos",
            "*Precios sujetos a cambio sin previo aviso",
            "*La compañía de logística y envíos es externa a la empresa",
            "*Considerar que su mercancía no viaja asegurada",
            "*Los tiempos de entrega son estimados y están sujetos a disponibilidad y factores externos",
            "*La mercancía viaja por cuenta y riesgo del destinatario",
            "*La aceptación de esta orden implica conocer y aceptar nuestras políticas comerciales",
            "*Para pedidos personalizados, fabricación o importación no hay cambios ni devoluciones"
        ],
        pedidos_internacionales: [
            "*El tipo de cambio se toma del Diario Oficial de la Federación (DOF) el mismo día del pago",
            "*Los precios son en dólares americanos",
            "*Los precios no incluyen gastos arancelarios ni impuestos propios del país",
            "*La mercancía está sujeta a inspección por parte de las autoridades aduaneras",
            "*Para pedidos internacionales no hay cambios ni devoluciones"
        ],
        nacional: {
            nombre_comercial: "Hardware Collection",
            razon: "HARDWARE COLLECTION SA DE CV",
            rfc: "HCO051013SH2",
            moneda: "MXN",
            banco: "Banorte",
            direccion_banco: "Río Mississippi 125, Colonia Del Valle, San Pedro Garza García, Nuevo León México C.P. 66220",
            sucursal_bancaria: "0123",
            cuenta: "0 4 7 5 0 0 0 2 7 3",
            clabe: "0 7 2 5 8 0 0 0 4 7 5 0 0 0 2 7 3 4"
        },
        internacional: {
            nombre_comercial: "Hardware Collection",
            razon: "HARDWARE COLLECTION SA DE CV",
            rfc: "HCO051013SH2",
            moneda: "USD",
            banco: "Banorte",
            direccion_banco: "Río Mississippi 125, Colonia Del Valle, San Pedro Garza García, Nuevo León México C.P. 66220",
            sucursal_bancaria: "0123",
            cuenta: "0 4 2 1 1 6 2 9 3 6",
            clabe: "0 7 2 5 8 0 0 0 4 2 1 1 6 2 9 3 6 8",
            swift: "M E N O M X M T X X X"
        },
        // items: [
        //     [2, "ST.M+.70.C.S.SS", "System M+ con Top Pivot 70 mm 70 mm Class C / Cuadrada / Acero Inoxidable 2 Puertas de 1.55 m de ancho y 100kg de peso", "$ 39,449.00 MXN", "$ 78,898.00"],
        //     [2, "ST.M+.70.C.S.SS", "System M+ con Top Pivot 70 mm 70 mm Class C / Cuadrada / Acero Inoxidable 2 Puertas de 1.55 m de ancho y 100kg de peso", "$ 39,449.00 MXN", "$ 78,898.00"],
        //     [2, "ST.M+.70.C.S.SS", "System M+ con Top Pivot 70 mm 70 mm Class C / Cuadrada / Acero Inoxidable 2 Puertas de 1.55 m de ancho y 100kg de peso", "$ 39,449.00 MXN", "$ 78,898.00"],
        // ],
        // resumen_descuento:  "$ 3,944.90",
        // resumen_subtotal:	"$ 74,953.10",
        // resumen_envio:	    "$ 1,664.00",
        // resumen_impuesto:	"$ 11,992.50",
        // resumen_total:	    "$ 88,609.60"

    }

    json = {
        ...json,
        ...data
    }

    let cliente = data?.cliente?.length > 0 ? data.cliente[0]: null

    console.log(cliente, data)

    const generateHeader = async () =>{
        PDF.addImage(hc_logo, 'png', m_x, m_y, 32, 11.4)
    }

    const generateFooter = () =>{
        PDF.setTextColor(111, 111, 111);
        PDF.setFontSize(8);
        PDF.text("Hardware Collection. Calzada San Pedro 108 Local B Del Valle  66220, San Pedro Garza García, Nuevo León, México.", 33, 280);
        PDF.text("support@hardwarecollectionofficial.com", 88, 284);
        PDF.setTextColor(0, 0, 0);
    }

    const generatePrimerPage = async () =>{
        bolder_txt({txt:"Cotización", dis_y: 18})
        PDF.text("Fecha:", m_x, m_y + 27);
        normal_txt({txt: json.fecha, dis_x: 10, dis_y: 27})
        bolder_txt({txt:"Factura no.", dis_y: 31})
        normal_txt({txt: json.folio, dis_x: 16, dis_y: 31})
        bolder_txt({txt:"Fecha de vencimiento:", dis_y: 35})
        normal_txt({txt: json.fecha_vencimiento, dis_x: 31, dis_y: 35})        
        
        bolder_txt({txt:"Para:", dis_y: 46})
        bolder_txt({txt:"Nombre:", dis_y: 50})
        normal_txt({txt: `${cliente?.name ? cliente.name: ""} ${cliente?.lastName ? cliente.lastName: ""}`, dis_x: 13, dis_y: 50})

        bolder_txt({txt:"Nombre de la empresa:", dis_y: 54})
        normal_txt({txt: `${cliente?.s_nombre_empresa ? cliente.s_nombre_empresa: ""}`, dis_x: 32, dis_y: 54})

        bolder_txt({txt:"Calle y Número:", dis_y: 58})
        normal_txt({txt: `${cliente?.s_calle ? cliente.s_calle: ""}`, dis_x: 22, dis_y: 58})

        bolder_txt({txt:"Colonia:", dis_y: 62})
        normal_txt({txt: `${cliente?.s_colonia ? cliente.s_colonia: ""}`, dis_x: 12, dis_y: 62})

        bolder_txt({txt:"Código Postal y Ciudad:", dis_y: 66})
        normal_txt({txt: `${cliente?.n_codigo_postal ? cliente.n_codigo_postal: ""} ${cliente?.s_ciudad ? cliente.s_ciudad: ""}`, dis_x: 33, dis_y: 66})

        bolder_txt({txt:"País:", dis_y: 70})
        normal_txt({txt: `${cliente?.s_pais ? cliente.s_pais: ""}`, dis_x: 8, dis_y: 70})

        bolder_txt({txt:"Correo:", dis_y: 74})
        normal_txt({txt: `${cliente?.s_email ? cliente.s_email: cliente?.email ? cliente.email: ""}`, dis_x: 11, dis_y: 74})

        bolder_txt({txt:"Número:", dis_y: 78})
        normal_txt({txt: `${cliente?.s_phone ? cliente.s_phone: ""}`, dis_x: 13, dis_y: 78})

        autoTable(PDF, {
            head: [[
                'Empresa',
                'Modo de envío',
                'Condiciones de envío',
                "Fecha de entrega",
                "Condiciones de pago",
                "Fecha de vencimiento"
            ]],
            startY: 95,
            theme: "grid",
            styles: {
                fontSize: 8
            },
            headStyles:{
                fillColor: "#000000",
                lineColor: "#ffffff",
                lineWidth: 0.5
            },
            body: [[
                json.empresa, 
                json.modo_envio,
                json.condiciones_envio,
                json.fecha,
                json.forma_pago,
                json.fecha_vencimiento
            ]],
        })

        let style_json = {}

        if(json?.items?.length > 0){
            style_json = {
                [json.items.length]: {
                    
                }
            }
        }

        autoTable(PDF, {
            head: [[
                'Cantidad',
                'N° de elemento',
                'Descripción',
                "Precio Unitario",
                "Subtotal",
            ]],
            startY: 115,
            theme: "grid",
            styles: { fontSize: 8 },
            columnStyles:{
              0:{
                cellWidth: 18,
                halign: "center"
              },
              3:{
                cellWidth: 35,
                halign: "center"
              },
              4:{
                cellWidth: 35,
                halign: "center"
              },
            },
            headStyles:{
                fillColor: "#000000",
                lineColor: "#ffffff",
                lineWidth: 0.5,
                halign: "center"
            },
            footStyles:{
                halign: "end",
                fillColor: "#ffffff",
                textColor: "#4D4D4D",
                halign: "end"
            },
            body: json.items
        })

        autoTable(PDF, {
            head: [[
                'Cantidad',
                'N° de elemento',
                'Descripción',
                "Precio Unitario",
                "Subtotal",
            ]],
            startY: 115,
            theme: "grid",
            styles: { fontSize: 8 },
            columnStyles:{
              0:{
                cellWidth: 18,
                halign: "center"
              },
              3:{
                cellWidth: 35,
                halign: "center"
              },
              4:{
                cellWidth: 35,
                halign: "center"
              },
            },
            headStyles:{
                fillColor: "#000000",
                lineColor: "#ffffff",
                lineWidth: 0.5,
                halign: "center"
            },
            footStyles:{
                halign: "end",
                fillColor: "#ffffff",
                textColor: "#4D4D4D",
                halign: "end"
            },
            body: json.items
        })

        const finalY =  PDF?.lastAutoTable?.finalY ? PDF.lastAutoTable.finalY: 0;

        autoTable(PDF, {
            startY: parseInt(finalY) + 1,
            margin:{left: 126},
            theme: "grid",
            styles: { fontSize: 8 },
            tableWidth: 35,
            columnStyles:{
              0:{
                halign: "right",
                lineColor: "#ffffff",
                fontStyle: "bold"
              }
            },
            headStyles:{
                fillColor: "#000000",
                lineColor: "#ffffff",
                lineWidth: 0.5,
                halign: "center"
            },
            body: [
                ["Descuento"],
                ["Subtotal"],
                ["Envio"],
                ["Impuesto"],
                ["Total"],
            ]
        })

        autoTable(PDF, {
            startY: finalY,
            margin:{left: 161},
            theme: "grid",
            styles: { 
                fontSize: 8,
            },
            tableWidth: 35,
            columnStyles:{
              0:{
                halign: "center"
              }
            },
            headStyles:{
                fillColor: "#000000",
                lineColor: "#ffffff",
                lineWidth: 0.5,
                halign: "center"
            },
            body: [
                [json?.resumen_descuento? json.resumen_descuento: `$ 0.00`],
                [json?.resumen_subtotal? json.resumen_subtotal: `$ 0.00`],
                [json?.resumen_envio? json.resumen_envio: `$ 0.00`],
                [json?.resumen_impuesto? json.resumen_impuesto: `$ 0.00`],
                [json?.resumen_total? json.resumen_total: `$ 0.00`],
            ]
        })

        let finalY_last =  PDF?.lastAutoTable?.finalY ? PDF.lastAutoTable.finalY: 0;
         
        if(finalY_last > 170){
            generateFooter();
            PDF.addPage();
            finalY_last = m_x;
        }

        bolder_txt({txt:"Notas:", dis_y: finalY_last})
        normal_txt({txt: json.notas, dis_y: finalY_last + 4})

        const m_condiciones = finalY_last + 16;
        bolder_txt({txt:"Condiciones:", dis_y: m_condiciones})
        bolder_txt({txt:"Condiciones de envío:", dis_y: m_condiciones + 4})
        normal_txt({txt: json.condiciones_envio, dis_x: 31, dis_y: m_condiciones + 4})

        bolder_txt({txt:"Tiempo de entrega:", dis_y: m_condiciones + 8})
        normal_txt({txt: json.tiempo_entrega, dis_x: 27, dis_y: m_condiciones + 8})

        bolder_txt({txt:"Envío:", dis_y: m_condiciones + 12})
        normal_txt({txt: json.envio, dis_x: 10, dis_y: m_condiciones + 12})

        bolder_txt({txt:"Forma de pago:", dis_y: m_condiciones + 16})
        normal_txt({txt: json.forma_pago, dis_x: 22, dis_y: m_condiciones + 16})

        let m_comentarios = finalY_last + 40;
        bolder_txt({txt:"Comentarios:", dis_y: m_comentarios})
        const array_comentarios = json?.comentarios?.length > 0 ? json.comentarios: []

        array_comentarios.map((item_comentario, i_item_comentario)=> {
            normal_txt({txt: item_comentario, dis_y: m_comentarios + ((i_item_comentario+1) * 4)})
        })
        generateFooter();

        if(finalY_last > 150){
            PDF.addPage();
            generateSegundaPage();
        }else{
            generateSegundaPage(m_comentarios + (4*array_comentarios.length));
        }
    }

    const generateSegundaPage = async (m_top_second = 0) =>{
        const m_top_peidos = 5 + m_top_second;
        const array_pedidos = json?.pedidos_internacionales?.length > 0 ? json.pedidos_internacionales: [];

        bolder_txt({txt:"Pedidos Internacionales:", dis_y: m_top_peidos})
        array_pedidos.map((item_comentario, i_item_comentario)=> {
            normal_txt({txt: item_comentario, dis_y: m_top_peidos + ((i_item_comentario+1) * 4)})
        })

        let espacio_pedidos = array_pedidos?.length ? (array_pedidos.length * 4) + 15 + m_top_peidos: 15 + m_top_peidos;

        bolder_txt({txt:"Datos Bancarios:", dis_y: espacio_pedidos})
        
        const bank_lines = ({data_bank, title = "", space  = 0}) => {
            bolder_txt({txt:`${title}:`, dis_y: space + 7})
            bolder_txt({txt:"Nombre Comercial:", dis_y: space + 11})
            normal_txt({txt: data_bank?.nombre_comercial ? data_bank.nombre_comercial: "", dis_x: 27, dis_y: space + 11})
    
            bolder_txt({txt:"Razón Social:", dis_y: space + 15})
            normal_txt({txt: data_bank?.razon ? data_bank.razon: "", dis_x: 20, dis_y: space + 15})
    
            bolder_txt({txt:"RFC:", dis_y: space + 19})
            normal_txt({txt: data_bank?.rfc ? data_bank.rfc: "", dis_x: 8, dis_y: space + 19})
    
            bolder_txt({txt:"Moneda:", dis_y: space + 23})
            normal_txt({txt: data_bank?.moneda ? data_bank.moneda: "", dis_x: 12, dis_y: space + 23})
    
            bolder_txt({txt:"Banco:", dis_y: space + 27})
            normal_txt({txt: data_bank?.banco ? data_bank.banco: "", dis_x: 11, dis_y: space + 27})
    
            bolder_txt({txt:"Dirección del Banco:", dis_y: space + 31})
            normal_txt({txt: data_bank?.direccion_banco ? data_bank.direccion_banco: "", dis_x: 29, dis_y: space + 31})
    
            bolder_txt({txt:"Sucursal Bancaria:", dis_y: space + 35})
            normal_txt({txt: data_bank?.sucursal_bancaria ? data_bank.sucursal_bancaria: "", dis_x: 27, dis_y: space + 35})
    
            bolder_txt({txt:"Cuenta:", dis_y: space + 39})
            normal_txt({txt: data_bank?.cuenta ? data_bank.cuenta: "", dis_x: 12, dis_y: space + 39})
    
            bolder_txt({txt:"Clabe:", dis_y: space + 43})
            normal_txt({txt: data_bank?.clabe ? data_bank.clabe: "", dis_x: 10, dis_y: space + 43})

            if(data_bank?.swift){
                bolder_txt({txt:"SWIFT:", dis_y: space + 47})
                normal_txt({txt: data_bank.swift, dis_x: 11, dis_y: space + 47})
            }
        }
        

        bank_lines({data_bank: json.nacional, title: "Nacional", space: espacio_pedidos})
        bank_lines({data_bank: json.internacional, title: "Internacional", space: espacio_pedidos + 50})
    }

    generateHeader();
    generatePrimerPage();
    generateFooter();

    setTimeout(()=>{
        PDF.save("factura.pdf");
    },1000)
}


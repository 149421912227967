import { ArrowBackIosNew, Circle, RadioButtonUnchecked } from "@mui/icons-material"
import { Box, Checkbox, InputAdornment, TextField, styled } from "@mui/material"
import { useEffect, useState } from "react"
import Icon from "../components/layout/Material/Icons"

const DIVTABLE = styled('div')({
    overflowY: "scroll",
    height: "250px",
    boxShadow: "0px 0px 3px 1px #e1e1e1",
    borderRadius: "8px",
    background: "white",
    "& table td, table th":{
        border: "0px"
    }
})
const TABLE = styled('table')({
    width: "100%"
})
const TH    = styled('th')({
    fontSize: "12px",
    padding: "5px 10px",
    fontFamily: "mulish-extra-bold"
})
const TR    = styled('tr')({
    fontSize: "12px",
    padding: "5px 10px",
    fontFamily: "semibold"
})
const TD    = styled('td')({
    fontSize: "12px",
    padding: "5px 10px",
    fontFamily: "semibold"
})
const TBODY = styled('tbody')({
    background: "white"
})

const THEAD = styled('thead')({
    background: "#FAFAFA",
    position: "sticky",
    top: "0px"
})

export const CHECK = styled('input')({
    accentColor: "#626262"
})

export const M_Table = ({hx=0, heads=[], body=[], onClick}) => {
    useEffect(()=>{
        console.log(hx)
    },[hx])
    return(<DIVTABLE sx={{
        height: `calc(100vh - ${hx+120}px) !important`,
    }} className="scroll-table-component">
        <TABLE>
            <THEAD>
                <TR>
                    <TH sx={{width: "30px"}}>
                        <CHECK type="checkbox" onClick={e=>{
                            let elements = document.getElementsByName("check_body_content")
                            if(e.target.checked){
                                for(var i=0; i<elements.length; i++){  
                                    if(elements[i].type=='checkbox')  
                                        elements[i].checked=true;
                                } 
                            }else{
                                for(var i=0; i<elements.length; i++){  
                                    if(elements[i].type=='checkbox')  
                                        elements[i].checked=false;
                                }
                            }
                        }}/>
                    </TH>
                    {heads?.length > 0 ? heads.map((head, i_head) => <TH key={i_head}>{head}</TH>):null}
                </TR>
            </THEAD>
            <TBODY id="body_all_content">
                {body?.length > 0 ? body.map((columns, i_columns)=>{
                    return(<TR key={i_columns}>
                        <TD sx={{width: "30px"}}><CHECK name="check_body_content" type="checkbox"/></TD>
                        {columns?.length > 0 ? columns.map((cell, i_cell)=>(<TD key={i_cell}>{cell}</TD>)):null}
                    </TR>)
                }):null}
            </TBODY>
        </TABLE>
    </DIVTABLE>)
}

export const Row = styled(Box)({
    display: "flex", 
    justifyContent: "center"
})

export const Col = styled(Box)(props=>({
    width: props?.w ? props.w : 100, 
    marginRight: 20
}))

const BotonAtras = styled('div')({
    background: "#626262",
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    width: "33px",
    height: "25px",
    borderRadius: "8px",
    "& .arrow-letter":{
        color: "white !important",
        fontSize: "12px"
    }
})

export const Texto = styled('p')(props => ({
    fontSize: 12,
    fontFamily: props?.family ? props.family: "mulish-bold"
}))

export const Titulo = ({texto = "Default", btnBack = e => {console.log("back")}}) => {
    return(<Box sx={{
        display: "flex",
        justifyContent: "start",
        alignContent: "center",
        maxHeight: "40px",
        minHeight: "40px"
    }}>
        <BotonAtras sx={{alignSelf: "center", cursor: "pointer"}} onClick={btnBack}>
            <ArrowBackIosNew className="arrow-letter"/>
        </BotonAtras>
        <Box sx={{
            position: "relative",
            display: "grid",
            alignItems: "center",
        }}>
            <Texto sx={{
                marginLeft: "15px",
                fontSize: "20px",
                fontFamily: "mulish-extra-bold",
                width: "max-content",
                position: "absolute",
                margin: "0px",
                left: "15px"
            }}>{texto}</Texto>
        </Box>
    </Box>)
}

export const Item = styled(Box)({
    background: "white", 
    padding: "2px 15px !important", 
    borderRadius: "8px",
    marginTop: "15px",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.1) !important",
    "& span":{
        fontSize: "12px !important",
        fontFamily: "semibold"
    },
    "& svg": {
        width: "15px !important"
    },
    "& label": {
        height: "25px"
    }
})

export const CheckComponent = ({check = false, children}) => {
    return(<Box sx={{
        width: "100%",
        display: "inline-flex",
        alignItems: "center",
        margin: "0px",
        padding: "0px",
        "& span":{
            padding: "0px",
            margin: "0px",
        }
    }}>
        {!check ? (<RadioButtonUnchecked/>): (<Circle/>)}
        <Texto sx={{margin: "0px",
            padding: "0px",
            marginLeft: "10px"
        }}>{children}</Texto>
    </Box>)
}

export const TextArea = styled('textarea')(props => ({
    width: "100%",
    border: "1px solid #DAD9D5",
    fontSize: "12px",
    padding: "5px 10px",
    borderRadius: "0px 0px 8px 8px"
}))

export const Seleccionar = styled('select')({
    width: "100%",
    marginBottom: "10px",
    border: "1px solid #DAD9D5",
    padding: "5px 10px",
    borderRadius: "8px",
    fontSize: "12px"
})

export const BotonAdd = styled('button')({
    background: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#08306B",
    fontFamily: "semibold",
    fontSize: "12px"
})

export const Comentario = styled("p")({
    marginBottom: "15px",
    fontSize: "12px",
    color: "#626262 !important"
})

export const ContainerCheck = ({labels = [], desc=[], changeOption}) => {
    function selectOnlyThis(e){
        console.log(e.target.value)
        changeOption(e)
        var myCheckbox = document.getElementsByName("myCheckbox");
        
        Array.prototype.forEach.call(myCheckbox,function(el){
            el.checked = false;
        });
        e.target.checked = true;
    }

    return(<Box sx={{
        fontSize: "12px",
        fontFamily: "semibold",
        marginTop: "10px",
        "& p":{
            color: "#626262 !important"
        }
    }}>
        {labels?.length > 0 ? labels.map((label, num_label)=>(<><CHECK
                defaultChecked={num_label == 0? true: false}
                sx={{marginRight: "5px"}}
                type="checkbox"
                value={num_label}
                onClick={selectOnlyThis}
                name="myCheckbox"
            />{label}
                <br/>
                <p style={{
                    fontSize: "10px",
                    fontFamily: "mulish-regular",
                    marginTop: "2px",
                    marginLeft: "18px",
                    color: "#626262 !important"

                }}>
                    {desc[num_label]}
                </p>
                <br/>
        </>)):null}
    </Box>)
}

export const Campo = styled("input")({
    display: "flex", 
    justifyContent: "center",
    width: "100%",
    borderRadius: "8px",
    height: "28px",
    border: "1px solid #DAD9D5",
    fontSize: "12px",
    padding: "5px 10px"
})

export const Canales = () => {
    const [lista, setLista] = useState([
        "Sitio Web",
        "Punto de ventas",
        "Facebook",
        "Instagram",
        "Google",
        "Youtube",
        "Pinterest",
        "TikTok",
        "LinkedIn",
        "Twitter",
        "Twitch",
        "Threads",
        "Amazon",
        "Mercado Libre",
        "Linio",
        "Wish",
        "Claro Shop",
        "Walmart",
        "Elektra",
        "Liverpool",
        "Coppel",
        "Sears",
        "Otros"
    ])
    return(<Item>
        <Texto>Canales</Texto>
        {lista.map((item, key_item) => {
            return(<Box sx={{margin: "0px", padding: "0px"}} key={key_item}>
                <Checkbox
                    sx={{
                        margin: "0px", padding: "0px"
                    }}
                    icon={<CheckComponent check={false}>{item}</CheckComponent>}
                    checkedIcon={<CheckComponent check={true}>{item}</CheckComponent>}
                />
            </Box>
            )
        })}
    </Item>)
}

export const Mercados = () => {
    const [lista, setLista] = useState([
        "México",
        "Barbados",
        "Belice",
        "Colombia",
        "Costa Rica",
        "Ecuador",
        "El Salvador",
        "Granada",
        "Guatemala",
        "Guyana",
        "Haití",
        "Honduras",
        "Islas Británicas",
        "Islas Caimán",
        "Islas del Mar Caribe",
        "Islas Francesas",
        "Jamaica",
        "Nicaragua",
        "Panamá",
        "Perú",
        "Puerto Rico",
        "República Dominicana",
        "Surinam",
        "Trinidad y Tobago",
        "Venezuela"
    ])
    return(<Item>
        <Texto>Mercados</Texto>
        {lista.map((item, key_item) => {
            return(<Box sx={{margin: "0px", padding: "0px"}} key={key_item}>
                <Checkbox
                    sx={{
                        margin: "0px", padding: "0px"
                    }}
                    icon={<CheckComponent check={false}>{item}</CheckComponent>}
                    checkedIcon={<CheckComponent check={true}>{item}</CheckComponent>}
                />
            </Box>
            )
        })}
    </Item>)
}

export const Estadisticas = () => {
    return(<Item>
        <Texto>Estadísticas</Texto>
        <Texto family="semibold">
            El resumen estadístico se mostrará cuando el
            producto comience a tener ventas
        </Texto>
    </Item>)
}

export const SearchBar = ({fnsearch}) => {
    return(<>
        <Box sx={{width: "100%", "& input":{
            outline: "none !important",
            padding: "5px 2px !important",
            fontSize: "12px",
        }}}>
            <TextField
                onChange={fnsearch}
                placeholder="Buscando en todos los productos"
                sx={{
                    width: "100%",
                    outline: "none",
                    margin: "0px",
                    marginBottom: "12px",
                    background: "#FAFAFA",
                    fontFamily: "semibold",
                    "& .MuiOutlinedInput-root": {
                        fontFamily: "'semibold'",
                        "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EBEBEB",
                        borderWidth: "1px",
                        },
                    },
                    // Class for the label of the input field
                    "& .MuiInputLabel-outlined": {
                        color: "#626262",
                    },
                }}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Icon name="SearchLigth" sx={{height: "12px", width: "12px"}}/>
                    </InputAdornment>
                    ),
                }}
            />
        </Box>
    </>)
}

export const CheckOne = ({values, orientacion="vertical", onChange=e=>console.log("change")}) => {
    const [select, setSelect] = useState(0)

    const on_click = e =>{
        setSelect(e.target.value)
        onChange(e)
    }
    
    return(<Box sx={{display: orientacion == "vertical" ? "block": orientacion == "horizontal"? "flex": "block"}}>
        {values?.length > 0 ? values.map((val, i_val) => {
            return(<Box>
                <label style={{
                    cursor: "pointer",
                    fontFamily: "semibold",
                    fontSize: "12px",
                    color: "#626262"
                }}>
                    <input
                        style={{
                            cursor: "pointer",
                            accentColor: "#626262"
                        }}
                        checked={i_val == select ? true: false} onClick={on_click}
                        type="checkbox"
                        value={i_val}
                    />
                    <b style={{
                        marginLeft: "10px"
                    }}>{val}</b>
                </label>
            </Box>)
        }):null}        
    </Box>)
}

/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Box,
    Typography,
    Modal,
    Avatar,
    Stack,
    Grid,
    Paper,
    Button,
    Badge,
    TextField,
    Menu,
    MenuItem
} from '@mui/material';
import Icon from '../layout/Material/Icons';
import image from '../../img/chatImage.png';
import MenuContainer from '../layout/Material/Menu'
import convertDate from '../layout/Material/Date';
import io from 'socket.io-client';
import Flow from '../layout/Flow';
import contactsContext from '../../context/contacts/contactsContext';
import AuthContext from '../../context/authentication/authContext';
import conversationsContext from '../../context/conversations/conversationsContext';
import phonesContext from '../../context/phones/phonesContext';
import settingsContext from '../../context/settings/settingsContext';
import eventosContext from '../../context/eventos/eventosContext';
import moduleContext from '../../context/modules/moduleContext';

let url_entorno = process.env.REACT_APP_ENTORNO == "DESARROLLO" ? process.env.REACT_APP_BACKEND_URL_LOCAL: process.env.REACT_APP_BACKEND_URL_PROD
const socket = io(url_entorno)

const Conversations = () =>{
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    let chatContainer = document.getElementById(`chatContainer`);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        border: '1px solid rgb(45, 55, 72)',
        boxShadow: 24,
        p: 4,
    };
    
    const [openModal, setOpenModal] = useState(false);
    const [contact, saveContact] = useState({
        name: '',
        lastName: '',
        phoneId: '',
        email: ''
    });
    const handleOpen = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const [inbox, saveAction] = useState({
        msg: ''
    })

    const {msg} = inbox;
    const {
        name,
        lastName,
        email
    } = contact;

    const conversationContext = useContext(conversationsContext);
    let { 
        selectContact,
        conversations,
        contactSelected,
        sendMsg,
        guardarMsg
    } = conversationContext;
    
    const phoneContext = useContext(phonesContext);
    const { phones, obtenerTelefonos, guardarTelefono } = phoneContext;

    const SettingContext = useContext(settingsContext);
    const { viewSettings } = SettingContext;


    const contactContext = useContext(contactsContext);
    const { contactos, guardarContacto, obtenerContactos } = contactContext;

    const EventosContext = useContext(eventosContext);
    const { fnAgregar } = EventosContext;

    const ModuleContext = useContext(moduleContext);
    const { module } = ModuleContext;

    useEffect(()=> {
        const receiveMessage = async (message) => {
            await guardarMsg(message)
            // socket.emit("userId", usuario._id)
            // console.log(usuario)
        }
        socket.on('message', receiveMessage)

        // return () => {
        //     socket.off('message', receiveMessage)
        // }

        if(module.length > 0) fnAgregar(module[0]._id, ()=>console.log("hello world converstions"));

    },[])

    useEffect(()=> {
        const reajustWindowChat = async (message) => {
            if(await chatContainer){
                console.log(await chatContainer.scrollHeight)
                chatContainer.scroll(0, await chatContainer.scrollHeight)
            }else{
                chatContainer = await document.getElementById(`chatContainer`);
                if(chatContainer){
                    chatContainer.scroll(0, await chatContainer.scrollHeight)
                }
            }
        }

        reajustWindowChat()
    },[contactSelected])

    useEffect(()=>{
        obtenerTelefonos();
        obtenerContactos();
    }, []);

    const onChange = e => {
        e.preventDefault()
        saveAction({
            ...inbox,
            [e.target.name]: e.target.value
        })
    }

    let onClick = async e => {
        e.preventDefault();
        if(msg){
            let validate = sendMsg(await msg);
            if(await validate) {
                saveAction({msg: ''})
                chatContainer = await document.getElementById(`chatContainer`);
                let heightChat = await chatContainer.scrollHeight
                chatContainer.scroll(0, heightChat)

                console.log('down', chatContainer.scrollHeight)
            }
            // conversations = ...conversations
        }
    }
    
    const seleccionarContacto = phone => {
        let elements = {selected: '', single: ''}
        if(contactSelected){
            elements.selected = document.getElementById(`contact-${contactSelected._id}`);
            elements.selected.classList.remove("select-contact-phone");
        }
        elements.single = document.getElementById(`contact-${phone._id}`);
        elements.single.classList.add("select-contact-phone");
        selectContact(phone)
    }

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: 'transparent',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        fontSize: '9px',
        textAlign: 'center',
        color: 'white',
        boxShadow: 'none'
    }));

    const HeaderP = styled('p')(({ theme }) => ({
        color: 'white',
        marginLeft: '8px',
        marginBottom: '10px',
        fontWeight: 'bold'
    }));

    const ImageChat = styled('img')(({ theme }) => ({
        width: '40%',
        margin: 'auto',
        display: 'block',
        alignSelf: 'center'
    }));

    const Search = styled('input')(({ theme }) => ({
        backgroundColor: 'transparent !Important',
        font: 'inherit',
        letterSpacing: 'inherit',
        color: 'darkgray',
        border: '0px',
        boxSizing: 'content-box',
        height: '1.4375em',
        margin: 0,
        WebkitTapHighlightColor: 'transparent',
        display: 'block',
        minWidth: 0,
        width: '100%',
        animationName: 'mui-auto-fill-cancel',
        animationDuration: '10ms',
        padding: '10.5px 14px 10.5px 12px'
    }));

    const saveContactFunction = async () =>{
        if(contactSelected._id){
            await guardarContacto(contactSelected._id, contact)
            await guardarTelefono(contactSelected._id)
        }
    }

    return(
        <Box sx={{
            height: 'auto',
            minHeight: '75vh'
        }}>
            {contactSelected ? (<Flow/>):(<Box></Box>)}
            <Box sx={{
                backgroundColor: 'white !important',
                borderRadius: "8px",
                display: 'flex',
                height: '75vh'
            }}>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography 
                            id="modal-modal-title"
                            variant="h6"
                            sx={{margin: '10px 0px', textAlign: 'center'}}
                            component="h2"
                        >
                            Guardar Contacto
                        </Typography>
                        <TextField
                            className='field-login' 
                            id="nombre"
                            type="nombre"
                            name="nombre"
                            label="Nombre"
                            sx={{margin: '10px 0px'}}
                            value={name}
                            onChange={async e=>saveContact({
                                ...contact,
                                name: await e.target.value
                            })}
                            variant="outlined" fullWidth
                        />
                        <TextField
                            className='field-login' 
                            id="apellido"
                            type="apellido"
                            name="apellido"
                            label="Apellido"
                            sx={{margin: '10px 0px'}}
                            value={lastName}
                            onChange={async e=>saveContact({
                                ...contact,
                                lastName: await e.target.value
                            })}
                            variant="outlined" fullWidth
                        />
                        <TextField
                            className='field-login' 
                            sx={{margin: '10px 0px'}}
                            id="email"
                            type="email"
                            name="email"
                            label="Email"
                            value={email}
                            onChange={
                                async e=>  saveContact({
                                ...contact,
                                email: await e.target.value
                            })}
                            variant="outlined" fullWidth
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Button 
                                    variant="contained"
                                    color="error"
                                    sx={{margin: '10px 0px'}}
                                    onClick={handleCloseModal}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                            <Grid item xs={4}>
                                <Button 
                                    variant="contained"
                                    color="secondary"
                                    sx={{margin: '10px 0px'}}
                                    onClick={saveContactFunction}
                                >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Box sx={{width: '340px !important', borderRight: '1px solid rgb(45, 55, 72)'}}>
                    <Box sx={{width: '100%', display: 'grid',padding: '0px 10px'}}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Badge sx={{ width: 'fit-content' }} className='iconSearch' badgeContent={4} color="primary">
                                    <HeaderP>
                                        Messages
                                    </HeaderP>
                                </Badge>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: "flex",
                                    background: "#e7e7e7",
                                    borderRadius: "5px",
                                    paddingLeft: "10px",
                                    fontSize: "12px !important"
                                }}>
                                    <Icon  name='Search' sx={{
                                        alignSelf: 'center',
                                        fontSize: "18px !important"
                                    }}/>
                                    <Search sx={{alignSelf: 'center', padding: "5px"}} className='searchField' placeholder='Buscar conversación'/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='scrollChat' sx={{
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        height: '60%'
                    }}>
                        {phones ? phones.map((phone, i) => {
                            let contactName;
                            if(phone.isSaved){
                                let dir = contactos.filter(contacto => contacto.phoneId === phone._id ) 
                                if(dir.length>0){
                                    contactName = `${dir[0].name} ${dir[0].lastName}`
                                }
                            }

                            return(
                                <Button 
                                    id={`contact-${phone._id}`}
                                    sx={{
                                        width: '100% !important',
                                        borderBottom: '1px solid #272c38',
                                        padding: "0px !important"
                                    }} 
                                    key={i}
                                    onClick={async ()=> phone.number ? await seleccionarContacto(phone) : ""}
                                >
                                    <Grid sx={{
                                    }} container>
                                        <Grid item xs={2} sx={{
                                            paddingLeft: '0px !important', 
                                            alignSelf: 'center',
                                            display: "grid",
                                            justifyContent: "end"
                                        }}>
                                            <Stack direction="row" spacing={2}>
                                                <Avatar sx={{
                                                    width: "30px", 
                                                    height: "30px",
                                                    fontSize: "20px"}} 
                                                    alt="Remy Sharp" 
                                                    src="/static/images/avatar/1.jpg"
                                                >{contactName ? contactName.charAt(0): "L"}</Avatar>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={10} sx={{paddingLeft: '0px !important'}}>
                                            <Grid container>
                                                <Box sx={{width: "70%"}}>
                                                    <Item sx={{textAlign: "start !important"}}>
                                                        {phone.isSaved ?
                                                            (<Typography style={{
                                                                textTransform: 'none', 
                                                                fontSize: "12px",
                                                                fontFamily: "Segoe UI,Helvetica Neue,Helvetica,Lucida Grande,Arial,Ubuntu,Cantarell,Fira Sans,sans-serif"
                                                            }}>{contactName}</Typography>) 
                                                        :(<Typography style={{
                                                            fontSize: "12px",
                                                            fontFamily: "Segoe UI,Helvetica Neue,Helvetica,Lucida Grande,Arial,Ubuntu,Cantarell,Fira Sans,sans-serif"
                                                        }}>{phone.number}</Typography>)}
                                                    </Item>
                                                </Box>
                                                <Box sx={{textAlign: "end !important"}}>
                                                    <Typography style={{
                                                        font: "inheri",
                                                        fontSize: "10px",
                                                        fontFamily: "Segoe UI,Helvetica Neue,Helvetica,Lucida Grande,Arial,Ubuntu,Cantarell,Fira Sans,sans-serif"
                                                    }}>{convertDate(phone.createAt)}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Button>
                            )
                        }): (<Item>No hay contactos</Item>)}
                    </Box>
                </Box>
                {contactSelected ? (
                    <Box sx={{width: '-webkit-fill-available'}}>
                        <Box sx={{
                            borderBottom: '1px solid #272c38',
                            display: 'flex'
                        }}>
                            <Box sx={{width: '10%', alignSelf: 'center'}}>
                                <Icon sx={{marginLeft: '15px'}} name='MenuOpen'/>
                            </Box>
                            <Box sx={{width: '75%', alignSelf: 'center'}}>
                                <Typography sx={{margin: '0px !important'}}>{contactSelected.name}</Typography>
                            </Box>
                            <Box sx={{width: '15%', alignSelf: 'center', textAlign: 'end'}}>
                                <MenuContainer activeModal={true} options={[
                                    {
                                        name: 'Guardar Contacto',
                                        fn: () => {handleOpen()}
                                    },
                                    {name: 'Eliminar', fn: () => console.log('test')}
                                ]}>
                                    <Icon name='ThreePoints'/>
                                </MenuContainer>
                            </Box>
                        </Box>
                        <Box id="chatContainer" className='scrollStyle body-chat' sx={{
                            height: '77%',
                            borderBottom: '1px solid #272c38'
                        }}>
                            {conversations ?
                                (conversations.map((conversation, i) => {
                                    let num = contactSelected.number;
                                    num = num.length > 12 ? num.slice(0,2) + num.slice(3,num.length) : num
                                    if(conversation.phone === num){
                                        return(
                                            <Box key={i}>
                                                <Box sx={{
                                                    textAlign: 
                                                        conversation.type !== 'receiver' ? 
                                                        'end' : 'start'
                                                }}>
                                                    <Typography className={`${conversation.type}-${viewSettings ? "light": "dark"}`}>
                                                        {conversation.msg}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )
                                    }else{ return null}
                                })):
                                null
                            }
                        </Box>
                        <Box sx={{display: 'flex'}}>
                            <Box sx={{width: '15%', display: 'flex', alignSelf: 'center'}}>
                                <Icon name='AttachFile' sx={{fontSize: '20px', margin: '0px 5px'}}/>
                                <Icon name='Image' sx={{fontSize: '20px', margin: '0px 5px'}}/>
                                <Icon name='SentimentSatisfied' sx={{fontSize: '20px', margin: '0px 5px'}}/>
                            </Box>
                            <Box sx={{width: '70%', padding: '0px 2%'}}>
                                <input
                                    type="text"
                                    id='msg'
                                    name='msg'
                                    className='inputMessage'
                                    sx={{alignSelf: 'center'}}
                                    placeholder='Escribe un mensaje aquí'
                                    value={msg}
                                    onChange={onChange}
                            />
                            </Box>
                            <Box sx={{
                                width: '10%',
                                padding: '0px 2%',
                                alignSelf: 'center'
                            }}>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <Icon name='ThreePoints'/>
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    <MenuItem onClick={handleClose}>My account</MenuItem>
                                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                                </Menu>
                            </Box>
                            <Box sx={{width: '5%', textAlign: 'end', marginRight: 2}}>
                                <Button onClick={onClick} variant="text" sx={{height: '100%'}}>
                                    <Icon name="Send"/>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                ):(
                    <Box sx={{width: '-webkit-fill-available', display: 'flex'}}>
                        <ImageChat src={image}/>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default Conversations;
import React, { useReducer } from 'react';
import {ProductosContext} from '../index';
import productosReducer from './productosReducer';
import clientAxios from '../../config/axios'
import { 
    GUARDAR_INVENTARIO,
    OBTENER_PRODUCTOS,
    SELECCIONAR_PRODUCTOS,
    TOTAL_VARIANTES,
} from '../../types'

const ProductosState = props => {
    const initialState = {
        producto_seleccionado: "",
        n_variantes: 0,
        productos: []
    }

    const [state, dispatch] = useReducer(productosReducer, initialState)

    const seleccionar_producto = async producto_seleccionado => {
        console.log(producto_seleccionado)
        dispatch({
            type: SELECCIONAR_PRODUCTOS,
            payload: producto_seleccionado
        })
    }

    const modificar_producto = async (_id, body) => {
        const res = await clientAxios.post(`/api/productos/modificar/${_id}`, body);

        if(res?.data?.success){
            let productos = await state.productos;
            let existe_producto_ = productos.filter(producto_ => producto_._id == _id);
            let actualizar_producto = await productos.map(producto => {
                if(producto._id == _id){
                    return body
                }else{
                    return producto
                }
                
            })
            
            if(existe_producto_?.length > 0){
                dispatch({
                    type: OBTENER_PRODUCTOS,
                    payload: actualizar_producto
                })

                let productos_totales = await actualizar_producto;
                if(productos_totales?.length > 0){
                    let suma_result = await productos_totales.map(producto_totales => {
                        if(producto_totales.b_variantes && producto_totales?.a_grupo_variantes?.length > 0){
                            let suma_variantes = producto_totales?.a_grupo_variantes.map(a_variante => {
                                return a_variante?.valores?.length ? a_variante?.valores.length: 0
                            })

                            let total = suma_variantes.reduce((a, b) => a * b);
                            return total
                        }

                        return 1
                    })

                    
                    dispatch({
                        type: TOTAL_VARIANTES,
                        payload: suma_result.reduce((a, b) => a + b, 0)
                    })
                }

                return res.data
            }else{
                let productos_totales = await [
                    {
                        ...body,
                        _id: _id
                    },
                    ...productos
                ];

                if(productos_totales?.length > 0){
                    let suma_result = await productos_totales.map(producto_totales => {
                        if(producto_totales.b_variantes && producto_totales?.a_grupo_variantes?.length > 0){
                            let suma_variantes = producto_totales?.a_grupo_variantes.map(a_variante => {
                                return a_variante?.valores?.length ? a_variante?.valores.length: 0
                            })

                            let total = suma_variantes.reduce((a, b) => a * b);
                            return total
                        }

                        return 1
                    })

                    
                    dispatch({
                        type: TOTAL_VARIANTES,
                        payload: suma_result.reduce((a, b) => a + b, 0)
                    })
                }

                dispatch({
                    type: OBTENER_PRODUCTOS,
                    payload: productos_totales
                })

                return res.data

            }
            
            
            
            return res.data
        }else{
            return false
        }
    }
    

    const actualizar_productos = async (_id, producto) => {
        console.log("NEW PRODUCT =>")
        let arr_productos = await [
            producto,
            ...state.productos
        ]
        
        dispatch({
            type: OBTENER_PRODUCTOS,
            payload: arr_productos
        })
    }

    const modificar_productos = async (array) => {
        const res = await clientAxios.post(`/api/productos/modificar_masivo`, array);
        let productos_editados = await state.productos, editados = await productos_editados.map(producto_editado => {
            let filter_ = array.filter(item => item._id == producto_editado._id);

            if(filter_?.length > 0){
                return filter_[0]
            }
            return producto_editado
        });

        dispatch({
            type: OBTENER_PRODUCTOS,
            payload: editados.filter(no_eliminados => no_eliminados.deleted == 0)
        })

        return false
    }

    const guardarInventario = async nuevoInventario => {
        console.log("Nueva existencia")
        try{
            const res = await clientAxios.post('/api/inventory', nuevoInventario);
            if(res.data.success){
                let inventario = []
                if(state.inventario){
                    inventario = await state.inventario
                }
                dispatch({
                    type: GUARDAR_INVENTARIO, 
                    payload: [...inventario, ...nuevoInventario]
                })
            } else {
                return res.data
            }
        }catch(err){
            // window.location.href = '/login';
            console.log(err)
        }
    }

    const guardar_productos_masivos = async productos => {
        const res = await clientAxios.post(`/api/variantes/agregar-variantes`, productos);
        productos.map(producto => {
            let id_negocio = producto.id_negocio
            guardarInventario([{
                id_producto:        producto.id_producto_padre,
                s_name:             producto.s_title,
                id_negocio: id_negocio,
                i_status :          1,
                i_entrante:         0,
                i_markets :         0,
                i_sales_channels:   0,
                i_stock :           0
            }])
        })

        if(res?.data?.success){
            return res.data.msg
        }else{
            return false
        }
    }

    const guardar_todos_productos = async productos => {
        const res = await clientAxios.post(`/api/productos/guardado_masivo`, productos);

        if(res?.data?.success){
            let data = res.data.data
            return data
        }else{
            console.log(res)
            return false
        }
    }

    const obtenerProductos = async () => {
        try{
            let id_negocio = localStorage.getItem("id_route")
            const res = await clientAxios.post('/api/productos/obtener-productos', {id_negocio: id_negocio});
            let data = res.data;
            
            if(data.success){
                dispatch({
                    type: OBTENER_PRODUCTOS,
                    payload: data.productos
                })

                let productos_totales = await data.productos;
                if(productos_totales?.length > 0){
                    let suma_result = await productos_totales.map(producto_totales => {
                        if(producto_totales.b_variantes && producto_totales?.a_grupo_variantes?.length > 0){
                            let suma_variantes = producto_totales?.a_grupo_variantes.map(a_variante => {
                                return a_variante?.valores?.length ? a_variante?.valores.length: 0
                            })

                            let total = suma_variantes.reduce((a, b) => a * b);
                            return total
                        }

                        return 1
                    })

                    
                    dispatch({
                        type: TOTAL_VARIANTES,
                        payload: suma_result.reduce((a, b) => a + b, 0)
                    })
                }
            }
        } catch(err){
            // window.location.href = '/login';
            console.log(err)
        }
    }

    const obtenerProductosPorPreliminar = async () => {
        try{
            let id_negocio = localStorage.getItem("id_route")
            const res = await clientAxios.post('/api/productos/productos-por-preliminar', {id_negocio: id_negocio});

            let data = res.data;
            if(data.success){
                dispatch({
                    type: OBTENER_PRODUCTOS,
                    payload: data.productos
                })

                dispatch({
                    type: TOTAL_VARIANTES,
                    payload: data.n_variantes
                })
            }
        } catch(err){
            // window.location.href = '/login';
            console.log(err)
        }
    }

    return(
        <ProductosContext.Provider
            value={{
                producto_seleccionado: state.producto_seleccionado,
                productos: state.productos,
                n_variantes: state.n_variantes,
                seleccionar_producto,
                modificar_producto,
                actualizar_productos,
                obtenerProductos,
                guardar_productos_masivos,
                modificar_productos,
                obtenerProductosPorPreliminar,
                guardar_todos_productos
            }}
        >
            {props.children}
        </ProductosContext.Provider>
    )
}

export default ProductosState 
import { Box, Checkbox, CircularProgress, FormControlLabel, Grid, Skeleton, TextField, Typography, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BotonJCs } from "../../material";
import { AddToPhotos, ArrowBackIosNew, Circle, Delete, DeleteForever, DragIndicator, Favorite, FavoriteBorder, FiberManualRecord, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import {LoadingContext, ProductosContext, SucursalesContext, VariantesContext} from "../../../context/";
import inventarioContext from "../../../context/inventario/inventarioContext";
import Icon from "../../layout/Material/Icons";
import Variante_Detalle from './Variantes/Detalle'
import _, { keys } from 'lodash';
import EnhancedTable from "./Tabla_Variantes";
import Sucursal from "./Sucursal";
import { isObjectEmpty } from "../../functions";

const General = ({s_title, set_title, s_body, set_body}) => {
    // const [s_title, sets_title] = useState("")
    // const [s_body,  sets_body] = useState("")

    // useEffect(async ()=>{
    //     // sets_title(form.s_title)
    //     // sets_body(form.s_body)
    // },[form])

    return(<Item>
        <Texto>General</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Nombre</Texto>
            <Campo value={s_title} onChange={set_title} placeholder="Nombre del producto"/>

            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px", marginTop: "15px"}}>Descripción</Texto>
            <TextArea
                value={s_body}
                onChange={set_body}
                sx={{resize: "none"}}
                rows="8"
                cols="50"
                placeholder="Text Description"
            />
        </Box>
    </Item>)
}

const Multimedia = () => {
    return(<Item>
        <Texto>Multimedia</Texto>
        <AreaFile>
            <Box style={{display: "flex"}}>
                <Boton>Subir</Boton>
                <Boton>Subir desde URL</Boton>
            </Box>
        </AreaFile>
        <Comentario>
            Puedes subir imágenes, videos o modelos 3D de realidad aumentada,
            asi como formatos PNG, JPG o GIF. Te recomendamos utilizar 
            formato multimedia cuadrado.
        </Comentario>
    </Item>)
}

const Estado = ({nuevo=false, i_status, setForm}) => {
    useEffect(()=>{
        console.log(i_status)
    },[])

    useEffect(()=>{
        console.log(i_status)
    },[i_status])

    return(<Item>
        <Texto>Estado</Texto>
        {i_status ? (<Seleccionar onChange={setForm} name="i_status" id="i_status" value={i_status}>
            <option value={1}>Borrador</option>
            <option value={2}>Activo</option>
        </Seleccionar>):nuevo? (<Seleccionar onChange={setForm} name="i_status" id="i_status">
            <option value={1}>Activo</option>
            <option value={2}>Borrador</option>
        </Seleccionar>): (<Skeleton variant="text" sx={{ fontSize: '12px' }} />)}
    </Item>)
}


const CheckComponent = ({check = false, children}) => {
    return(<Box sx={{
        width: "100%",
        display: "inline-flex",
        alignItems: "center",
        margin: "0px",
        padding: "0px",
        "& span":{
            padding: "0px",
            margin: "0px",
        }
    }}>
        {!check ? (<RadioButtonUnchecked/>): (<Circle/>)}
        <Texto sx={{margin: "0px",
            padding: "0px",
            marginLeft: "10px"
        }}>{children}</Texto>
    </Box>)
}

const Canales = () => {
    const [lista, setLista] = useState([
        "Sitio Web",
        "Punto de ventas",
        "Facebook",
        "Instagram",
        "Google",
        "Youtube",
        "Pinterest",
        "TikTok",
        "LinkedIn",
        "Twitter",
        "Twitch",
        "Threads",
        "Amazon",
        "Mercado Libre",
        "Linio",
        "Wish",
        "Claro Shop",
        "Walmart",
        "Elektra",
        "Liverpool",
        "Coppel",
        "Sears",
        "Otros"
    ])
    return(<Item>
        <Texto>Canales</Texto>
        {lista.map((item, key_item) => {
            return(<Box sx={{margin: "0px", padding: "0px"}} key={key_item}>
                <Checkbox
                    sx={{
                        margin: "0px", padding: "0px"
                    }}
                    icon={<CheckComponent check={false}>{item}</CheckComponent>}
                    checkedIcon={<CheckComponent check={true}>{item}</CheckComponent>}
                />
            </Box>
            )
        })}
    </Item>)
}


const Mercados = () => {
    const [lista, setLista] = useState([
        "México",
        "Barbados",
        "Belice",
        "Colombia",
        "Costa Rica",
        "Ecuador",
        "El Salvador",
        "Granada",
        "Guatemala",
        "Guyana",
        "Haití",
        "Honduras",
        "Islas Británicas",
        "Islas Caimán",
        "Islas del Mar Caribe",
        "Islas Francesas",
        "Jamaica",
        "Nicaragua",
        "Panamá",
        "Perú",
        "Puerto Rico",
        "República Dominicana",
        "Surinam",
        "Trinidad y Tobago",
        "Venezuela"
    ])
    return(<Item>
        <Texto>Mercados</Texto>
        {lista.map((item, key_item) => {
            return(<Box sx={{margin: "0px", padding: "0px"}} key={key_item}>
                <Checkbox
                    sx={{
                        margin: "0px", padding: "0px"
                    }}
                    icon={<CheckComponent check={false}>{item}</CheckComponent>}
                    checkedIcon={<CheckComponent check={true}>{item}</CheckComponent>}
                />
            </Box>
            )
        })}
    </Item>)
}

const Estadisticas = () => {
    return(<Item>
        <Texto>Estadísticas</Texto>
        <Texto family="semibold">
            El resumen estadístico se mostrará cuando el
            producto comience a tener ventas
        </Texto>
    </Item>)
}

const IdeEsp = (props) => {
    let {
        b_variant_requires_shipping,
        b_customs_agent,
        set_b_customs_agent,
        set_b_product_service,
        s_harmonized_system_code,
        s_customs_agent,
        set_s_harmonized_system_code,
        set_s_customs_agent,
        b_specifications,
        set_b_specifications,
        b_product_service,
        n_unit_measure_weight,
        n_unit_measure_weight_packaging,
        set_n_unit_measure_weight,
        set_n_unit_measure_weight_packaging,
        set_b_variant,
        sku="",
        setSKU,
        barcode,
        setBarcode,
        s_variant_country_origin,
        set_s_variant_country_origin
    } = props;
    return(<Item>
        <Texto>Identificación y especificaciones</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>SKU</Texto>
            <Campo value={sku} onChange={setSKU} placeholder=""/>
        </Box>

        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Código de barras (UPC, EAN, GTIN, MPN, ISBN, etc)</Texto>
            <Campo value={barcode} onChange={setBarcode} placeholder=""/>
        </Box>

        <Texto sx={{marginTop: "35px"}}>Especificaciones</Texto>
        <FormControlLabel
            label="Este es un producto físico o artículo"
            control={<Checkbox checked={b_specifications}
            onChange={set_b_specifications}/>}
        />
        <Row sx={{justifyContent: "start", marginBottom: "5px"}}>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Peso unitario</Texto>
                <CampoPrecios
                    value={n_unit_measure_weight} 
                    Moneda="kg"
                    SignoMoneda=""
                    onChange={set_n_unit_measure_weight}
                />
            </Col>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Peso con embalaje</Texto>
                <CampoPrecios
                    value={n_unit_measure_weight_packaging}
                    onChange={set_n_unit_measure_weight_packaging}
                    Moneda="kg"
                    SignoMoneda=""
                />
            </Col>
        </Row>
        <Comentario>
            El peso unitario es el peso del producto físico o artículo y el peso con embalaje 
            es el peso del producto que se toma en la paquetería sumando el embalaje.
        </Comentario>

        <Texto sx={{marginTop: "35px"}}>Información aduanal</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>País o región de origen</Texto>
            {/* <Campo onChange={set_n_variant_country_origin} value={n_variant_country_origin} placeholder=""/> */}
            <Campo
                type="text"
                value={s_variant_country_origin}
                onChange={set_s_variant_country_origin}
                placeholder=""
            />
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Código de sistema armonizado (SA)</Texto>
            <Campo value={s_harmonized_system_code} onChange={set_s_harmonized_system_code} placeholder=""/>
        </Box>
        <FormControlLabel
            label="Requieres de un agente aduanal para este producto físico o artículo"
            control={<Checkbox checked={b_customs_agent}
            onChange={set_b_customs_agent}/>}
        />
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Agente aduanal</Texto>
            <Campo value={s_customs_agent} onChange={set_s_customs_agent} placeholder=""/>
        </Box>
    </Item>)
}

const Sucursales = ({form, setSucursalesGuardadas, sucursales_guardadas, lista=[]}) => {
    const sucursalesContext = useContext(SucursalesContext);
    const { obtener_sucursales, sucursales } = sucursalesContext;

    const InventarioContext = useContext(inventarioContext);
    const { obtenerInventarioDetalle, actualizar_inventario, inventario_detalle } = InventarioContext;

    const [sucursales_, setSucursales] = useState({})
    const [listSucursales_, setlistSucursales] = useState([])
    const [datos, setDatos] = useState(lista)
    const [open, setOpen] = useState(false)

    const guardar_sucursales_lista = () => {
        setSucursalesGuardadas(datos)
        if(inventario_detalle?.length > 0){
            let inventario_result = inventario_detalle[0]
            actualizar_inventario([{
                ...inventario_result,
                a_sucursales: datos
            }])
        }
        setOpen(false)
    }

    useEffect(()=>{
        if(inventario_detalle?.length > 0){
            let inventario_sucursales = inventario_detalle[0], a_sucursales = inventario_sucursales?.a_sucursales
            if(a_sucursales?.length > 0 && a_sucursales){
                setDatos(a_sucursales)
            }else setDatos([])

            setSucursalesGuardadas(a_sucursales)
        }
    },[inventario_detalle])

    useEffect(()=>{
        let id_negocio = localStorage.getItem("id_route")
        obtener_sucursales(id_negocio)
    },[])
    
    useEffect(()=>{
        if(form){
            if(form?._id){
                obtenerInventarioDetalle(form._id)

            }
        }

        // setCkEnvios(form?.b_variant_requires_shipping ? true: false)
    },[form])

    useEffect(()=>{
        let lista_sic = sucursales.map(sucursal => {
            let array_suc_exist = datos.filter(d_sucursal => d_sucursal._id == sucursal._id)
            let suc_exist = array_suc_exist[0], exist = false
            if(suc_exist?.is_active){
                exist = suc_exist?.is_active ? true: false
            }
            return [
                (<Box sx={{
                    "& p, span":{
                        fontSize: "12px"
                    },
                    "& svg":{
                        width: "20px",
                        color: "black"
                    },
                    "& label":{
                        height: "24px"
                    }

                }}>
                    <FormControlLabel
                        sx={{ fontSize: "12px !important" }}
                        label={`${sucursal.s_nombre}, ${sucursal.s_calle_num}, ${sucursal.s_ciudad}, ${sucursal.s_pais}`}
                        onChange={e=>{
                            setDatos(suc_old => {
                                let array_suc = []
                    
                                array_suc = suc_old
                                let is_array_suc = array_suc.filter(a_s => a_s._id == sucursal._id)
                                let is_not_array_suc = array_suc.filter(a_s => a_s._id != sucursal._id)
                    
                                if(is_array_suc?.length > 0){
                                    array_suc = [
                                        ...is_not_array_suc,
                                        {
                                            _id: sucursal._id,
                                            is_active: e?.target?.checked ? true: false,
                                            i_stock: 0,
                                            n_comprometido:0,
                                            n_cost_per_item:0,
                                            n_disponibilidad:0,
                                            i_entrante: 0
                                        }
                                    ]
                                }else{
                                    array_suc = [
                                        ...suc_old,
                                        {
                                            _id: sucursal._id,
                                            is_active: e?.target?.checked ? true: false,
                                            i_stock: 0,
                                            n_comprometido:0,
                                            n_cost_per_item:0,
                                            n_disponibilidad:0,
                                            i_entrante: 0
                                        }
                                    ]
                                }
                    
                                return array_suc
                            })
                        }}
                        control={<Checkbox
                            checked={exist}
                            sx={{ fontSize: "12px !important" }}
                        />}
                    />
                </Box>)
            ]
        })

        setlistSucursales(lista_sic)
    },[sucursales, datos])

    useEffect(()=>{
        let array_sucursales_nuevas = []
        sucursales_guardadas.map(suc_g => {
            let array_sucursales = sucursales.filter(suc_ => suc_._id == suc_g._id)
            if(array_sucursales?.length > 0){
                let seleccion = array_sucursales[0]
                array_sucursales_nuevas.push({
                    _id: seleccion._id,
                    s_nombre: seleccion.s_nombre,
                    is_active: suc_g.is_active,
                    b_almacenamiento: suc_g.b_almacenamiento,
                    b_preparacion: suc_g.b_preparacion,
                    b_retiro: suc_g.b_retiro
                })
            }
        })
        setSucursales(array_sucursales_nuevas)
    },[sucursales_guardadas])

    return(<Item>
        <Texto>Sucursales e inventario</Texto>
        
        <Tabla_Sucursal
            encabezados={["Nombre", "Almacenar", "Rastrear", "Existencias"]} 
            datos={sucursales_}
        />

        <Sucursal
            open={open}
            setOpen={setOpen}
            datos={listSucursales_}
            save={guardar_sucursales_lista}
        />
        {/* <Boton sx={{marginBottom: "15px"}}>+ Agregar sucursal 2</Boton> */}
    </Item>)
}

const Envios = ({b_variant_requires_shipping, set_b_variant_requires_shipping}) => {
    return(<Item>
        <Texto>Envíos</Texto>
        <Box sx={{
            "& input[type='checkbox']": {
                accentColor: "black !important"
            },
            marginBottom: "10px"
        }}>
            <FormControlLabel
                label="Este producto físico o artículo tiene envío gratis"
                control={<Checkbox checked={b_variant_requires_shipping}
                onChange={set_b_variant_requires_shipping}/>}
            />
        </Box>
        {/* <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto>Embalajes</Texto>
            <Dropdown>
                <Opcion>Nombre del embalaje guardado</Opcion>
            </Dropdown>
        </Box> */}
    </Item>)
}

const CatOrg = (props) => {
    let {
        s_product_node_category,
        set_s_product_node_category,
        s_product_type,
        set_s_product_type,
        s_manufacturers,
        set_s_manufacturers,
        set_s_brands,
        s_brands,
        s_supplier_primary,
        set_s_supplier_primary
    } = props
    return(<Item>
        <Texto>Categorización y organización</Texto>
        <Row sx={{justifyContent: "start", marginBottom: "5px"}}>
            <Col w={260}>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Nodo</Texto>
                    <Campo onChange={set_s_product_node_category} value={s_product_node_category} placeholder="Nombre del Nodo"/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Tipo de producto</Texto>
                    <Campo value={s_product_type} onChange={set_s_product_type} placeholder="Nombre del tipo de producto"/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Proveedor principal</Texto>
                    <Campo value={s_supplier_primary} onChange={set_s_supplier_primary} placeholder="Nombre del proveedor principal" sx={{background: "#EBEBEB"}}/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Fabricante</Texto>
                    <Campo value={s_manufacturers} onChange={set_s_manufacturers} placeholder="Nombre del Fabricante"/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Marca</Texto>
                    <Campo value={s_brands} onChange={set_s_brands} placeholder="Nombre de la Marca"/>
                </Box>
            </Col>
            <Col w={160}/>
        </Row>
    </Item>)
}

const ColeccionesEt = ({colecciones = [], etiquetas = []}) => {
    return(<Item>
        <Texto>Colecciones y etiquetas</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Row>
                <Col w={250}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Colecciones</Texto>
                    <Campo placeholder=""/>
                </Col>
                <Col w={320}>
                    <Box sx={{display: "flex", flexWrap: "wrap"}}>
                        {colecciones.map(coleccion => {
                            return(<Itemce>{coleccion}</Itemce>)
                        })}
                    </Box>
                </Col>
            </Row>
            <Row sx={{marginTop: "25px"}}>
                <Col w={250}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Etiquetas</Texto>
                    <Campo placeholder=""/>
                </Col>
                <Col w={320}>
                    <Box sx={{display: "flex"}}>
                        {etiquetas.map(etiqueta => {return(<Itemce>{etiqueta}</Itemce>)})}
                    </Box>
                </Col>
            </Row>
        </Box>
    </Item>)
}

const Motoresdebusqueda = ({
    set_s_handle,
    s_handle,
    set_s_seo_title,
    s_seo_title,
    s_seo_description,
    set_s_seo_description
}) => {
    return(<Item>
        <Texto sx={{marginBottom: "0px !important"}}>Motores de búsqueda</Texto>
        <Comentario sx={{margintop: "0px !important"}}>Aumenta la relevancia de tus productos en los motores de búsqueda agregando una descripción.</Comentario>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Título de la página</Texto>
            <Campo value={s_seo_title} onChange={set_s_seo_title} placeholder="Título de la página"/>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Meta descripción</Texto>
            <TextArea value={s_seo_description} onChange={set_s_seo_description} rows="8" cols="50" placeholder="Descripción del producto"/>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Identificador de URL</Texto>
            <Campo value={s_handle} onChange={set_s_handle} placeholder=""/>
        </Box>
    </Item>)
}

const Marketing = (props) => {
    let {
        set_b_gender,
        b_gender,
        s_detailed_segmentation, 
        set_s_detailed_segmentation,
        n_range_max_age,
        n_range_min_age,
        set_n_range_max_age,
        set_n_range_min_age,
    } = props;
    
    const [rangos, setRangos] = useState([
        18, 19, 20, 21, 22,
        23, 24, 25, 26, 27,
        28, 29, 30, 31, 32,
        33, 34, 35, 36, 37,
        38, 39, 40, 41, 42,
        43, 44, 45, 46, 47,
        48, 49, 50, 51, 52,
        53, 54, 55, 56, 57,
        58, 59, 60, 61, 62,
        63, 64, 65
    ])

    return(<Item sx={{marginBottom: "20px"}}>
        <Texto>Marketing</Texto>
        <Comentario sx={{margintop: "0px !important"}}>Define la segmentación de marketing de tu producto.</Comentario>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Edad</Texto>
            <Row sx={{width: "30%"}}>
                <Dropdown sx={{background: "#EBEBEB"}} onChange={set_n_range_min_age}>
                    {rangos.map((edad,i) => 
                        (<Opcion selected={n_range_min_age == i ? "selected": ""} value={i}>{edad==65?"+": ""}{edad}</Opcion>)
                    )}
                </Dropdown>
                <Dropdown sx={{background: "#EBEBEB"}} onChange={set_n_range_max_age}>
                    {rangos.map((edad,i) => 
                        (<Opcion selected={n_range_max_age == i ? "selected": ""} value={i}>{edad==65?"+": ""}{edad}</Opcion>)
                    )}
                </Dropdown>
            </Row>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Sexo</Texto>
            <Row sx={{width: "30%"}}>    
                <Dropdown sx={{background: "#EBEBEB"}} onChange={e=>{
                    set_b_gender(data=>{
                        return {
                            ...data,
                            b_gender: parseInt(e.target.value)
                        }
                    })
                }}>
                    <Opcion selected={b_gender == 1 ? "selected": ""} value={1}>Masculino</Opcion>
                    <Opcion selected={b_gender == 0 ? "selected": ""} value={0}>Femenino</Opcion>
                </Dropdown>
            </Row>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Segmentación detallada</Texto>
            <Campo value={s_detailed_segmentation} onChange={set_s_detailed_segmentation} placeholder="Agregar datos demográficos, intereses o comportamientos"/>
        </Box>
    </Item>)
}

const Plantilla = () => {
    return(<Item>
        <Texto>Plantilla del tema</Texto>
        <Seleccionar name="pets" id="pet-select">
            <option>Plantilla de producto predeterminado</option>
        </Seleccionar>
    </Item>)
}

const Adicionales = ({datos = []}) => {
    return(<Item>
        <Texto>Campos Adicionales</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            {datos.map((dato, key_dato) =>{
                return(<Row sx={{marginTop: "25px"}} key={key_dato}>
                    <Col w={250}>
                        <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>
                            {dato?.titulo ? dato.titulo: "Nombre del campo Adicional"}
                        </Texto>
                    </Col>
                    <Col w={320}>
                        <Box>
                            {dato?.contenido?.length > 0 ? dato.contenido.map((campo, i) => (<Box>
                                <Campo sx={{marginTop: i != 0 ? "15px": "0px"}} value={campo}/>
                            </Box>)): null}
                            <Box sx={{textAlign: "right"}}><BotonAdd>+ Agregar</BotonAdd></Box>
                        </Box>
                    </Col>
                </Row>)
            })}
        </Box>
    </Item>)
}

const ListaValores = ({guardarCombinaciones, go_to_variant, lista = [], variantes=[], data_variantes}) => {
    const [combinaciones, setCombinaciones] = useState([])
    const [_combinaciones, _setCombinaciones] = useState([])
    const [_combinacionesTotales, _setCombinacionesTotales] = useState([])

    const variantesContext = useContext(VariantesContext);
    const { seleccionar_variante, seleccionar_combinaciones } = variantesContext;

    useEffect(()=>{
        if(lista.length > 0){
            let grupos  = lista;
            let auxCombinacionesFinal = []
            let auxCombinacionesTemporal = []
            
            /* Lista items primer grupo, deposito en auxiliar que
            utilizaré para formar las combinaciones */
            for(const item of grupos[0]){
                auxCombinacionesFinal.push(item)
            }
            
            const generarCombinaciones = ()=>{
                /* Recorro todos los elementos desde la posición 1 de grupos hasta
                el final, y en cada grupo concateno con los de la primer lista */
                for (let indice = 1; indice < grupos.length; indice++) {
                    auxCombinacionesTemporal=[]
                    for (const item of auxCombinacionesFinal) {
                        
                        for(const elem of grupos[indice]) {
                            auxCombinacionesTemporal.push(item + " / " + elem)
                        }
                    }
                    //Igualo la lista y la vació arriba para seguir usando la temporal
                    auxCombinacionesFinal = auxCombinacionesTemporal
                }
                return auxCombinacionesFinal
            }
            let __combinacionesGeneradas = generarCombinaciones()
            guardarCombinaciones(__combinacionesGeneradas)
            _setCombinaciones(__combinacionesGeneradas)
            _setCombinacionesTotales(__combinacionesGeneradas)
        }
    },[lista])

    useEffect(()=>{
        if(_combinaciones?.length > 0){
            let combinaciones_lista = []
            _combinaciones.map((combinacion_generada, i_combinacion_generada) => {
                let info = data_variantes[i_combinacion_generada], info_sku = "-", n_variant_price = 0
                
                if(info?.s_variant_sku){ info_sku = info.s_variant_sku}
                if(info?.n_variant_price){ n_variant_price = info.n_variant_price}

                combinaciones_lista = [
                    ...combinaciones_lista,
                    [
                        (<CheckBox/>), 
                        (<ImagenNotFound/>), 
                        (<Vinculo onClick={()=>{
                            let grupos = combinacion_generada.split(" / ")
                            let grupo_array = []

                            grupos.map((val_variante, i_val_variante) => {
                                if(variantes[i_val_variante]?.tipo){
                                    grupo_array.push({
                                        categoria: variantes[i_val_variante].tipo,
                                        value: val_variante
                                    })
                                }else{
                                    console.log(variantes[i_val_variante])
                                }
                            })
    
                            seleccionar_combinaciones(grupo_array)
                            seleccionar_variante(combinacion_generada)
                            go_to_variant(i_combinacion_generada)
                            // seleccionarModulo(process.env.REACT_APP_ENTORNO == "DESARROLLO"?  process.env.REACT_APP_LOCAL_VARIANTES: process.env.REACT_APP_PROD_VARIANTES)
                        }}>{combinacion_generada}</Vinculo>),
                        "-",
                        n_variant_price,
                        info_sku,
                        n_variant_price
                    ]
                ]
            })
            setCombinaciones(combinaciones_lista)
        }
    },[_combinaciones])

    const ImagenNotFound = () => {
        return(<Box sx={{
            height: "32px",
            width: "32px",
            border: "1px solid #EBEBEB",
            display: "grid",
            justifyContent: "center",
            borderRadius: "3px",
            alignContent: "center"
        }}>
            <AddToPhotos/>
        </Box>)
    }

    return(<Box>
        <Box sx={{
            "& p": {padding: "0px !important", margin: "0px !important"},
            background: "#FAFAFA !important",
            padding: "5px 15px",
            alignItems: "center",
            display: "flex"
        }}>
            <Texto sx={{minWidth: "130px"}}>
                Total {combinaciones.length} Variante{lista.length > 1 ? "s": null}
            </Texto>
            <Content>
                <Campo sx={{width: "180px", height: "21px", outline: "none", marginRight: "5px"}} onChange={e=>{
                    if(e.target.value != ""){
                        let word = e.target.value
                        let words_ = _combinacionesTotales.filter(_c => _c.toLowerCase().search(word.toLowerCase()) > -1)
                        console.log(words_)
                        _setCombinaciones([...words_])
                    }else{
                        _setCombinaciones(_combinacionesTotales)
                    }
                }}></Campo>
                <ButtonIconSearch>
                    <Icon name="SearchWhite" sx={{width: "12px", height:"12px"}}/>
                </ButtonIconSearch>
            </Content>
        </Box>
        <EnhancedTable data={combinaciones}/>
        {/* <Tabla
            tabla_sx={{
                margin: "0px",
                borderTop: "1px solid #EBEBEB",
            }}
            encabezados_sx={{
                // border: "1px solid #EBEBEB",
                background: "#FAFAFA !important",
            }}
            encabezados={["", "", "Nombre", "SKU", "Precio", "Inventario"]} 
            datos={combinaciones}
        /> */}
    </Box>)
}

const Botones = ({cambios = false, combinaciones =[], mt = "25px", form, isNotnuevo, go_to_menu=()=>console.log("MENU")}) => {
    const [copia, setCopia] = useState(null)
    const InventarioContext = useContext(inventarioContext);
    const { guardarProducto } = InventarioContext;

    const loadingContext = useContext(LoadingContext);
    const { set_loading, loading } = loadingContext;

    const productosContext = useContext(ProductosContext);
    const { modificar_producto, guardar_productos_masivos, actualizar_productos } = productosContext;

    const guardado_aut = async data =>{
        let id_producto_padre = false, id_negocio = 0,
        producto_padre = await guardarProducto(data)
        actualizar_productos(id_producto_padre, producto_padre)
        id_producto_padre = producto_padre._id
        id_negocio = producto_padre.id_negocio

        if(!producto_padre?.b_variantes){
            set_loading({
                ...loading,
                guardar_productos: false
            })

            go_to_menu()
        }else{
            go_to_menu()
        }
    }

    const modificacion_aut = async data => {
        let id_producto_padre = false, id_negocio = 0,
        status_modificar = await modificar_producto(data._id, data)
        id_producto_padre = data._id
        id_negocio = data.id_negocio

        console.log("modificacion 3")

        if(status_modificar){
            set_loading({
                ...loading,
                guardar_productos: false
            })

            go_to_menu()
        }

        return id_producto_padre
    }

    useEffect(async ()=>{
        if(isObjectEmpty(copia) == false){
            guardado_aut(copia)
        }
    },[copia])

    if(isNotnuevo){
        return(<Box sx={{
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            alignItems: "center",
            marginTop: mt,
            minHeight: "40px",
        }}>
            {!cambios ? cambios == "loading"? null :(<>
                <BotonJCs onClick={()=>{go_to_menu()}} sx={{display: "grid", alignContent: "center", marginRight: "5px", height:"25px"}}>
                    Descartar
                </BotonJCs>
                <BotonJCs disabled={loading?.guardar_productos ? true: false} onClick={async ()=>{
                    set_loading({
                        ...loading,
                        guardar_productos: true
                    })
                    let id_producto_padre = false, id_negocio = 0
                    if(!isNotnuevo){
                        guardado_aut(form)
                    }else{
                        if(form?._id){
                            id_producto_padre = await modificacion_aut(form)
                        }
                    }

                    if(id_producto_padre && combinaciones?.length > 0){
                        let array__ = await combinaciones.map((combinacion, i_combinacion) =>{
                            let datos_variantes = form.datos_variantes
                            

                            if(datos_variantes?.length > 0){
                                let filtro_variantes = datos_variantes.filter(variante => variante.n_index == i_combinacion)
                                if(filtro_variantes?.length>0){
                                    let result_obj = filtro_variantes[0]
                                    return {
                                        ...result_obj,
                                        "id_producto_padre" : id_producto_padre,
                                        "s_title"           : `${combinacion}`,
                                        "id_negocio"        : id_negocio,
                                        "n_index"           : i_combinacion,
                                        
                                    }
                                }
                            }
                            return {
                                "id_producto_padre" :   id_producto_padre,
                                "s_title"           :   `${combinacion}`,
                                "id_negocio"        :   id_negocio,
                                "n_index"           :   i_combinacion
                            }
                        })

                        let array__modificados = await array__.filter(a=> a?._id)
                        let array__nuevos = await array__.filter(a=> !a?._id)
                        let ids__ = array__modificados.map(a_m => (a_m._id)), all_ids = []

                        let variantes = await guardar_productos_masivos(array__nuevos)

                        if(variantes?.length > 0){
                            set_loading({
                                ...loading,
                                guardar_productos: false
                            })

                            go_to_menu()
                        }

                        if(variantes?.length > 0){
                            let nuevas_variantes = await variantes.map((variante_, n_index) => (variante_._id))
                            all_ids = [
                                ...ids__, 
                                ...nuevas_variantes
                            ]
                            console.log("modificacion 4")

                            modificar_producto(id_producto_padre, {a_grupo_ids_variantes: all_ids})
                        }
                    }

                }} sx={{display: "grid", alignContent: "center", height:"25px"}}>{
                    loading?.guardar_productos ? 
                    (<Box sx={{
                        "& span": {
                            width: "15px !important",
                            height: "15px !important",
                            display: "grid"
                        }
                    }}>
                        <CircularProgress color="inherit" width="15px"/>
                    </Box>): "Guardar"
                }</BotonJCs>
            </>):(<BotonJCs onClick={async ()=>{
                let details = Object.keys(form).filter(objKey =>
                    objKey !== '_id').reduce((newObj, key) =>{
                        newObj[key] = form[key];
                        return newObj;
                    }, {}
                );

                if(form?.s_title){
                    details.s_title = `${form.s_title} - copia`
                }

                setCopia(details)
            }} sx={{display: "grid", alignContent: "center", height:"25px"}}>
                    Duplicar
                </BotonJCs>)
            }
            
        </Box>)
    }else{
        return(<Box sx={{
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            alignItems: "center",
            marginTop: mt,
            minHeight: "40px",
        }}>
            <BotonJCs onClick={()=>{go_to_menu()}} sx={{display: "grid", alignContent: "center", marginRight: "5px", height:"25px"}}>
                    Descartar
                </BotonJCs>
                <BotonJCs disabled={loading?.guardar_productos ? true: false} onClick={async ()=>{
                    set_loading({
                        ...loading,
                        guardar_productos: true
                    })
                    let id_producto_padre = false, id_negocio = 0
                    if(!isNotnuevo){
                        let producto_padre = await guardarProducto(form)
                        actualizar_productos(id_producto_padre, producto_padre)
                        id_producto_padre = producto_padre._id
                        id_negocio = producto_padre.id_negocio

                        if(!producto_padre?.b_variantes){
                            set_loading({
                                ...loading,
                                guardar_productos: false
                            })

                            go_to_menu()
                        }
                    }else{
                        if(form._id){
                            console.log("modificacion 1")
                            let status_modificar = await modificar_producto(form._id, form)
                            id_producto_padre = form._id
                            id_negocio = form.id_negocio
                            if(status_modificar){
                                set_loading({
                                    ...loading,
                                    guardar_productos: false
                                })

                                go_to_menu()
                            }

                        }
                    }

                    if(id_producto_padre && combinaciones?.length > 0){
                        let array__ = await combinaciones.map((combinacion, i_combinacion) =>{
                            let datos_variantes = form.datos_variantes
                            

                            if(datos_variantes?.length > 0){
                                let filtro_variantes = datos_variantes.filter(variante => variante.n_index == i_combinacion)
                                if(filtro_variantes?.length>0){
                                    let result_obj = filtro_variantes[0]
                                    return {
                                        ...result_obj,
                                        "id_producto_padre" : id_producto_padre,
                                        "s_title"           : `${combinacion}`,
                                        "id_negocio"        : id_negocio,
                                        "n_index"           : i_combinacion,
                                        
                                    }
                                }
                            }
                            return {
                                "id_producto_padre" :   id_producto_padre,
                                "s_title"           :   `${combinacion}`,
                                "id_negocio"        :   id_negocio,
                                "n_index"           :   i_combinacion
                            }
                        })

                        let array__modificados = await array__.filter(a=> a?._id)
                        let array__nuevos = await array__.filter(a=> !a?._id)
                        let ids__ = array__modificados.map(a_m => (a_m._id)), all_ids = []

                        let variantes = await guardar_productos_masivos(array__nuevos)

                        if(variantes?.length > 0){
                            set_loading({
                                ...loading,
                                guardar_productos: false
                            })

                            go_to_menu()
                        }

                        if(variantes?.length > 0){
                            let nuevas_variantes = await variantes.map((variante_, n_index) => (variante_._id))
                            all_ids = [
                                ...ids__, 
                                ...nuevas_variantes
                            ]
                            console.log("modificacion 2")

                            modificar_producto(id_producto_padre, {...form, a_grupo_ids_variantes: all_ids})
                        }
                    }

                }} sx={{display: "grid", alignContent: "center", height:"25px"}}>{
                    loading?.guardar_productos ? 
                    (<Box sx={{
                        "& span": {
                            width: "15px !important",
                            height: "15px !important",
                            display: "grid"
                        }
                    }}>
                        <CircularProgress color="inherit" width="15px"/>
                    </Box>): "Guardar"
                }</BotonJCs>            
        </Box>)
    }
}

const Content = styled('div')({
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "100%"
})

const ButtonIconSearch = styled('div')({
    background: "#3F3F3F",
    width: "29px",
    height: "21px",
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    borderRadius: "8px"
    
})

const Variantes = ({go_to_variant, existen_variantes=false, setExistencia, form, setForm, guardarCombinaciones}) => {
    const [variantes, setVariantes] = useState([])
    const [guardar, setGuardar]     = useState(true)
    const [valores, setValores]     = useState([])
    const [nuevo_item, setNuevo]    = useState("")
    const [datos, setDatos]         = useState({
        tipo: "",
        valores: []
    })

    const variante_onChange = (valor, id) => {
        setVariantes(variantes.map((old_variante, id_old_variante) => {
            if (id_old_variante === id) {
              return { ...old_variante, tipo: valor };
            } else {
              return old_variante;
            }
        }));
    }

    const valores_onChange = (valor, id_variante, id_valor, eliminar=false) => {
        setVariantes(variantes.map((old_variante, id_old_variante) => {
            if(id_old_variante === id_variante){
                let old_valores = old_variante?.valores && old_variante?.valores?.length > 0 ? old_variante.valores: []
                if(old_valores.length > 0){
                    let new_valores = old_valores.map((old_valor, id_old_valor) => {
                        if(id_old_valor == id_valor){
                            old_valor = valor
                        }
                        return old_valor
                    })
                    return {...old_variante, valores: [...new_valores]};
                }
                return old_valores
            } else {
              return old_variante;
            }
        }));
    }

    const editar_variante = (id_variante) => {
        setVariantes(variantes.map((old_variante, id_old_variante) => {
            if (id_old_variante === id_variante) {
              return { ...old_variante, edicion: !old_variante?.edicion };
            } else {
              return old_variante;
            }
        }));
    }

    useEffect(async ()=>{
        setVariantes(form.a_grupo_variantes)
    },[form])

    useEffect(()=>{
        let nuevos_valores = []
        if(variantes?.length > 0){
            variantes.map(variante__ => {
                nuevos_valores = [
                    ...nuevos_valores,
                    [...variante__.valores]
                ]
                setValores([...nuevos_valores])
                setForm({
                    ...form,
                    a_grupo_variantes: variantes
                })
            })
        }
    },[variantes])

    const handleChange = e => {
        setForm({
            ...form,
            b_variantes: !e.target.checked ? 0: 1
        })
        // let logic_checked = form?.b_variantes ? true: false, value_checked = e.target.checked;
        // if(e.target.checked == logic_checked){value_checked = !e.target.checked}

        // setForm({
        //     ...form,
        //     b_variantes: value_checked ? 1: 0
        // })

        
        // setExistencia(validacion => {return !validacion})
    }

    return(<Item>
        <Texto>Variantes</Texto>
        <Box sx={{
            marginBottom: "15px",
            "& p": {
                display: "flex",
                alignItems: "center",
                margin: "0px"
            }
        }}>
            <FormControlLabel
                label="Agregar opciones y variaciones del producto"
                control={<Checkbox checked={form?.b_variantes ? true: false} onChange={handleChange}/>}
            />
        </Box>
        {existen_variantes ? (
            <Box>
                {variantes?.length > 0 ? variantes.map((variante, index) => {
                    let valores = variante?.valores ? variante.valores: [];

                    return(<Grid container sx={{
                        marginBottom: "10px",
                        borderBottom: "1px solid #EBEBEB",
                        paddingBottom: "10px"
                    }}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={1} sx={{
                                    display: "grid",
                                    alignContent: "center"
                                }}><DragIndicator/></Grid>
                                <Grid item xs={8}>
                                    {variante?.edicion ? (<Grid container>
                                        <Grid item xs={12}>
                                            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Nombre de la opción</Texto>
                                            <Campo onChange={e=>{ variante_onChange(e.target.value, index)}} key={`${index}_nombre`} placeholder="" value={variante.tipo}/>
                                        </Grid>

                                        <Grid item xs={12} sx={{marginTop: "15px"}}>
                                            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Valores de la opción</Texto>
                                            {variante?.valores && variante.valores.length > 0 ? variante.valores.map((valor, v_i)=><Box sx={{
                                                display: "flex",
                                                alignItems: "center"
                                            }}>
                                                <Campo
                                                    onChange={e => valores_onChange(e.target.value, index, v_i)}
                                                    key={`${variante.tipo}-${v_i}`}
                                                    placeholder=""
                                                    value={valor}
                                                />
                                                <DeleteForever onClick={()=>{
                                                    setVariantes(variantes.map((old_variante, id_old_variante) => {
                                                        if(id_old_variante === index){
                                                            let old_valores = old_variante?.valores && old_variante?.valores?.length > 0 ? old_variante.valores: []
                                                            let new_valores = old_valores.filter((o_v, o_v_index)=>o_v_index!=id_old_variante)
                                                            return {...old_variante, valores: [...new_valores]};
                                                        }
                                                        return(old_variante)

                                                    }));
                                                }} sx={{
                                                    cursor: "pointer"
                                                }}/>
                                            </Box>):null}
                                        </Grid>
                                    </Grid>):(<Box>
                                        <Texto sx={{margin: "0px", marginBottom: "6px"}}>{variante?.tipo ? variante.tipo: null}</Texto>
                                        <Box sx={{display: "flex", flexWrap: "wrap"}}>
                                            {valores.length > 0 ? valores.map((valor, i) => 
                                                (<Chip sx={{marginLeft: i!=0?"5px": "0px"}}><Typography sx={{margin: "0px !important"}}>{valor}</Typography></Chip>)
                                            ): null}
                                        </Box>
                                    </Box>)}
                                </Grid>
                                <Grid item xs={3} sx={{justifyContent: "end", alignItems: "center", display: "flex" }}>
                                    <BotonJCs onClick={()=>editar_variante(index)}>{variantes[index]?.edicion ? "Guardar": "Editar"}</BotonJCs>
                                    <DeleteForever sx={{marginLeft: "10px", cursor: "pointer"}} onClick={e=>{
                                        setVariantes(variantes_delete =>{
                                            if(variantes_delete?.length > 1){
                                                let array_save = variantes_delete.filter((v_d, i_v_d) => i_v_d != index)
                                                return array_save
                                            }
                                            return variantes_delete
                                        })
                                        // console.log(index)
                                    }}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>)
                }): (<Box sx={{"& p": {color: "#626262 !important"}, display: "grid", alignContent: "center", justifyContent: "center", margin: "20px 0px"}}>
                    <Texto>Sin variantes</Texto>
                </Box>)}

                {!guardar ? (<Box>
                    <Box>
                        <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Nombre de la opción</Texto>
                        <Campo value={datos.tipo} onChange={e=>{
                            setDatos({...datos, tipo: e.target.value})
                        }} />
                    </Box>
                    <Box sx={{marginTop: "15px"}}>
                        <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Valores de la opción</Texto>
                        {datos.valores.length > 0 ? datos.valores.map((dato, i)=>{
                            return(<Box sx={{display: "flex", 
                                "& svg": {
                                    color: i == 0 ? "#dddddd !important": "black<"
                                }
                            }}>
                                <Campo
                                    value={dato}
                                    sx={{marginTop: i != 0 ? "5px": "0px", marginRight: "10px"}} 
                                    onChange={e=>{
                                        console.log(e.target.value)
                                    }}
                                />
                                <Delete onClick={()=>{
                                    if(i != 0) {
                                        setDatos(old_datos => {
                                            let delete_datos = old_datos.valores.filter((old_dato, old_index) => old_index != i)
                                            
                                            console.log(delete_datos)
                                            return {...datos, valores: delete_datos}
                                        })
                                    }else{
                                        console.log("no delete")
                                    }
                                }} sx={{
                                    cursor: "pointer"
                                }}/>
                            </Box>)
                        }): null}
                        <Grid container>
                            <Grid item xs={12} sx={{display: "flex", marginTop: "5px"}}>
                                <Campo value={nuevo_item} sx={{marginRight: "10px"}} onChange={e=>{
                                    setNuevo(e.target.value)
                                }} />
                                <BotonJCs onClick={()=>{
                                    setDatos({...datos, valores: [...datos.valores, nuevo_item]})
                                    setNuevo("")
                                }} sx={{padding: "3px 20px"}}>Agregar</BotonJCs>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>): null}
                <Box sx={{
                    display: "grid",
                    justifyContent: "start"
                }}>
                    {guardar ? 
                        (<BotonAdd onClick={()=>{setGuardar(false)}} sx={{marginY: "10px"}}>+ Agregar otra opción</BotonAdd>):
                        (<Box>
                            <Boton onClick={()=>{setGuardar(true)}} sx={{marginY: "10px"}}>Cancelar</Boton>
                            <Boton onClick={()=>{
                                if(datos.tipo != "" && datos.valores.length > 0){
                                    console.log(variantes)
                                    if(variantes && variantes.length > 0){
                                        setVariantes([...variantes, datos])
                                    }else{
                                        setVariantes([datos])
                                    }
                                    setDatos({tipo: "", valores: []})
                                    setNuevo("")
                                    setGuardar(true)
                                }
                            }} sx={{marginY: "10px"}}>Agregar</Boton>
                        </Box>)
                    }
                </Box>
                <Box sx={{
                    marginBottom: "20px", marginTop: "10px"
                }}>
                    <ListaValores
                        go_to_variant={go_to_variant}
                        guardarCombinaciones={guardarCombinaciones}
                        variantes={variantes}
                        lista={valores}
                        data_variantes={form?.datos_variantes ? form.datos_variantes: []}
                    />
                </Box>
            </Box>
        ): null}
    </Item>)
}

const BotonAdd = styled('button')({
    background: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#08306B",
    fontFamily: "semibold",
    fontSize: "12px"
})

const Row = styled(Box)({
    display: "flex", 
    justifyContent: "center"
})

const Campo = styled("input")({
    display: "flex", 
    justifyContent: "center",
    width: "100%",
    borderRadius: "8px",
    height: "28px",
    border: "1px solid #DAD9D5",
    fontSize: "12px",
    padding: "5px 10px"
})

const Dropdown = styled("select")({
    width: "100%",
    borderRadius: "8px",
    fontSize: "12px",
    padding: "8px",
    color: "#626262 !important",
    border: "1px solid #DAD9D5"
})

const Opcion = styled("option")({
    color: "#626262 !important"
})

const Item = styled(Box)({
    background: "white", 
    padding: "2px 15px !important", 
    borderRadius: "8px",
    marginTop: "15px",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.1) !important",
    "& span":{
        fontSize: "12px !important",
        fontFamily: "semibold"
    },
    "& svg": {
        width: "15px !important"
    },
    "& label": {
        height: "25px"
    }
})

const Col = styled(Box)(props=>({
    width: props?.w ? props.w : 100, 
    marginRight: 20
}))

const Texto = styled('p')(props => ({
    fontSize: 12,
    fontFamily: props?.family ? props.family: "mulish-bold"
}))

const TextArea = styled('textarea')(props => ({
    width: "100%",
    border: "1px solid #DAD9D5",
    fontSize: "12px",
    padding: "5px 10px",
    borderRadius: "0px 0px 8px 8px"
}))

const Seleccionar = styled('select')({
    width: "100%",
    marginBottom: "10px",
    border: "1px solid #DAD9D5",
    padding: "5px 10px",
    borderRadius: "8px",
    fontSize: "12px"
})

const AreaFile = styled('div')({
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    padding: "50px 0px",
    borderRadius: "8px",
    border: "1px dashed #DAD9D5"
})

const Boton = styled('button')({
    background: "#EBEBEB !important",
    border: "none",
    cursor: "pointer",
    margin: "5px",
    borderRadius: "8px",
    padding: "8px 15px",
    fontSize: "12px",
    fontFamily: "semibold"
})

const Comentario = styled("p")({
    marginBottom: "15px",
    fontSize: "12px",
    color: "#626262 !important"
})

const TextoCampos = styled('div')({
    display: "flex",
    "& p, input": {
        color: "#626262 !important"
    }
})

const ContenedorSigno = styled('div')({
    position: "relative"
})

const TextoPrecio = styled('p')({
    margin: "0px !important",
    padding: "0px 10px !important",
    paddingTop: "2px !important",
    height: "28px",
    background: "#EBEBEB",
    borderRadius: "8px 0px 0px 8px",
    border: "1px solid #DAD9D5"
})

const InputPrecio = styled('input')({
    width: "90%",
    fontSize: "12px",
    padding: "0px 10px",
    borderRadius: "0px 8px 8px 0px",
    height: "28px",
    border: "1px solid #DAD9D5",
    paddingLeft: "20px",
    paddingRight: 0
})

const SignoPrecio = styled('i')({
    position: "absolute",
    display: "block",
    transform: "translate(0, -55%)",
    top: "50%",
    pointerEvents: "none",
    width: "28px",
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "semibold",
    color: "#626262 !important",
    fontStyle: "normal"
})

const TablaEstilo       = styled('table')({
    width: "100%",
    margin:"10px 0px"
})
const ColumnaTabla      = styled('tr')({
    "& td": { color: "#626262 !important"},
    "& p": { margin: "0px !important" },

})
const CeldaTabla        = styled('td')({
    fontSize: "12px",
    fontFamily: "semibold",
    height: "20px !important",
    "& input":{
        height: "16px !important"
    }
})
const EncabezadoTabla   = styled('th')({
    fontSize: "12px",
    fontFamily: "semibold"
})

const Itemce = styled('p')({
    background: "#EBEBEB",
    width: "fit-content",
    padding: "5px 10px",
    margin: "5px",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "semibold" 
})

const BotonAtras = styled('div')({
    background: "#626262",
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    width: "33px",
    height: "25px",
    borderRadius: "8px",
    "& .arrow-letter":{
        color: "white !important",
        fontSize: "12px"
    }
})

const Chip = styled('div')({
    background: "#EBEBEB!important",
    border: "none",
    // margin: "5px",
    width: "fit-content",
    borderRadius: "8px",
    padding: "5px 12px",
    fontFamily: "semibold",
    "& p": {
        fontSize: "12px",
        color: "#626262 !important"
    }
})

const Tabla = ({tabla_sx={}, col_enc_sx={}, encabezados = [], datos = [], encabezados_sx = {}}) =>{
    return(<TablaEstilo sx={tabla_sx}>
        <ColumnaTabla sx={col_enc_sx}>
            {encabezados.length > 0 ? encabezados.map((encabezado, i) => (
                <EncabezadoTabla sx={[encabezados_sx, {textAlign: i == 0 ? "left": "center"}]}>{encabezado}</EncabezadoTabla>
            )): null}
        </ColumnaTabla>
        {datos.length > 0 ? datos.map(columnas => {
            return(<ColumnaTabla>
                {columnas.length > 0 ? columnas.map((columna,i ) => (<CeldaTabla sx={{textAlign: i == 0 ? "left": "center"}}>
                    {columna}
                </CeldaTabla>)): null}
            </ColumnaTabla>)
        }): null}
    </TablaEstilo>)
}

const Tabla_Sucursal = ({tabla_sx={}, col_enc_sx={}, encabezados = [], datos = [], encabezados_sx = {}}) =>{
    return(<TablaEstilo sx={tabla_sx}>
        <ColumnaTabla sx={col_enc_sx}>
            {encabezados.length > 0 ? encabezados.map((encabezado, i) => (
                <EncabezadoTabla sx={[encabezados_sx, {textAlign: i == 0 ? "left": "center"}]}>{encabezado}</EncabezadoTabla>
            )): null}
        </ColumnaTabla>
        {datos.length > 0 ? datos.map(columna => {
            if(columna?.is_active){
                return(<ColumnaTabla>
                    <CeldaTabla>
                        {columna?.s_nombre? columna.s_nombre: null}
                    </CeldaTabla>
                    <CeldaTabla  sx={{
                        textAlign: "center",
                        "& input":{
                            padding: "0px !important",
                            maxWidth: "80px",
                            textAlign: "center",
                            fontSize: "12px"
                        }
                    }}>
                        <input type="checkbox" disabled checked={columna?.b_almacenamiento ? true: false}/>
                    </CeldaTabla>
                    <CeldaTabla  sx={{
                        textAlign: "center",
                        "& input":{
                            padding: "0px !important",
                            maxWidth: "80px",
                            textAlign: "center",
                            fontSize: "12px"
                        }
                    }}>
                        <input type="checkbox" disabled checked={columna?.b_retiro ? true: false}/>
                    </CeldaTabla>
                    <CeldaTabla sx={{textAlign: "center"}}>
                        No rastredo
                    </CeldaTabla>
                </ColumnaTabla>)
            }
        }): null}
    </TablaEstilo>)
}

const CheckBox = ({children, onChange, onClick=e=>console.log("onclick")}) => {
    const ComponentCheckbox = styled('input')({
        marginRight: "5px"
    })
    const LabelCheckbox = styled('p')({})

    return(<Box sx={{"& p": {fontSize: "12px", color: "#626262 !important"}}}>
        <LabelCheckbox>
            <ComponentCheckbox onClick={onClick} onChange={onChange} type="checkbox"/>{children}
        </LabelCheckbox>
    </Box>)
}

const CampoPrecios = ({disabled=false, value=0, Moneda="MXN", SignoMoneda="$", onChange=e=>console.log(e.target.value)}) => {
    return(<TextoCampos>
        <TextoPrecio>{Moneda}</TextoPrecio>
        <ContenedorSigno>
            <SignoPrecio>{SignoMoneda}</SignoPrecio>
            <InputPrecio
                disabled={disabled}
                sx={{
                    background: disabled ? "#EBEBEB": "white"
                }}
                onChange={onChange}
                value={value}
                placeholder="0"
                type="number"
                step="10.00"
                min="0"
            />
        </ContenedorSigno>
    </TextoCampos>)
}

const Vinculo = styled("button")({
    cursor: "pointer",
    background: "transparent",
    border: "none"
})

const Precios = (props) => {
    let {
        setPrecio,
        price = 0,
        tax=1001,
        price_compare = 0,
        groups_vendor=[],
        set_groups_vendor = e => console.log("dkkdj")
    } = props

    let [supplier_1, setSupplier_1] = useState({})
    let [supplier_2, setSupplier_2] = useState({})
    let [supplier_3, setSupplier_3] = useState({})

    useEffect(()=>{
        set_groups_vendor(form => {
            return {
                ...form,
                a_vendors_groups: [
                    supplier_1,
                    supplier_2,
                    supplier_3,
                ]
            }
            
        })
    },[supplier_1, supplier_2, supplier_3])

    useEffect(()=>{
        if(groups_vendor?.length > 0){
            let filter_suppllier_1 = groups_vendor.filter(vendor => vendor?.supplier_primary)
            let filter_suppllier_2 = groups_vendor.filter(vendor => vendor?.supplier_secondary)
            let filter_suppllier_3 = groups_vendor.filter(vendor => vendor?.supplier_third)

            if(filter_suppllier_1.length > 0) setSupplier_1(filter_suppllier_1[0])
            if(filter_suppllier_2.length > 0) setSupplier_2(filter_suppllier_2[0])
            if(filter_suppllier_3.length > 0) setSupplier_3(filter_suppllier_3[0])
        }
    }, [groups_vendor])

    return(<Item>
        <Texto>Precios, costos y márgenes</Texto>
        <Row sx={{justifyContent: "start", marginBottom: "5px"}}>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Precio de venta</Texto>
                <CampoPrecios value={price} onChange={e => {
                    setPrecio(data=>{
                        return {
                            ...data,
                            n_variant_price: parseInt(`${e.target.value}`)
                        }
                    })
                }}/>
            </Col>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Precio comparativo o promocional</Texto>
                <CampoPrecios value={price_compare} onChange={e=>{
                    // setPriceCompare(e.target.value)
                    setPrecio(data=>{
                        return {
                            ...data,
                            n_variant_compare_price: parseInt(`${e.target.value}`)
                        }
                    })
                }}/>
            </Col>
        </Row>
        <Row sx={{justifyContent: "start", marginBottom: "15px", "& option": {color: "#626262 !important"}}}>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Impuestos</Texto>
                {/* {tax == 1001 ? null: ()} */}
                <Dropdown sx={{background: "#EBEBEB"}} onChange={e=>{
                        setPrecio(data=>{
                            return {
                                ...data,
                                b_variant_taxable: parseInt(e.target.value) ? true: false
                            }
                        })
                    }}>
                        <Opcion selected={tax == 1 ? "selected": ""} value={1} >Con impuestos</Opcion>
                        <Opcion selected={tax == 0 ? "selected": ""} value={0} >Sin impuestos</Opcion>
                    </Dropdown>
            </Col>
            {/* <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Tasa</Texto>
                <Dropdown>
                    <Opcion>Ver mercados</Opcion>
                </Dropdown>
            </Col> */}
        </Row>
        {/* <Box sx={{marginTop: "30px", marginBottom: "15px"}}>
            <Row sx={{justifyContent: "start"}} id="tird_vendor">
                <Col w={220}>
                    <Texto sx={{fontFamily:"semibold"}}>
                        Costo del producto sin impuestos
                    </Texto>
                    <CampoPrecios value={supplier_1?.product_without_tax ? supplier_1.product_without_tax: 0} onChange={e => setSupplier_1({
                        ...supplier_1,
                        product_without_tax: e.target.value
                    })}/>
                </Col>
                <Col w={150}>
                    <Texto sx={{fontFamily:"semibold"}}>Ganancia bruta</Texto>
                    <CampoPrecios disabled={true} value="0.00"/>
                </Col>
                <Col w={90}>
                    <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                    <Campo value="00.00%" disabled sx={{background:"#EBEBEB"}}/>
                </Col>
                <Col w={150}>
                    <Texto sx={{fontFamily:"semibold"}}>Proveedor Primario</Texto>
                    <Campo value={supplier_1?.supplier_primary ? supplier_1.supplier_primary: ""} onChange={e => setSupplier_1({
                        ...supplier_1,
                        supplier_primary: e.target.value
                    })}/>
                </Col>
            </Row>
            <Row sx={{justifyContent: "start"}} id="tird_vendor">
                <Col w={220}>
                    <Texto sx={{fontFamily:"semibold"}}>
                        Costo del producto sin impuestos
                    </Texto>
                    <CampoPrecios value={supplier_2?.product_without_tax ? supplier_2.product_without_tax: 0} onChange={e => setSupplier_2({
                        ...supplier_2,
                        product_without_tax: e.target.value
                    })}/>
                </Col>
                <Col w={150}>
                    <Texto sx={{fontFamily:"semibold"}}>Ganancia bruta</Texto>
                    <CampoPrecios disabled={true} value="0.00"/>
                </Col>
                <Col w={90}>
                    <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                    <Campo value="00.00%" disabled sx={{background:"#EBEBEB"}}/>
                </Col>
                <Col w={150}>
                    <Texto sx={{fontFamily:"semibold"}}>Proveedor Secundario</Texto>
                    <Campo value={supplier_2?.supplier_secondary ? supplier_2.supplier_secondary: ""} onChange={e => setSupplier_2({
                        ...supplier_2,
                        supplier_secondary: e.target.value
                    })}/>
                </Col>
            </Row>
            <Row sx={{justifyContent: "start"}} id="tird_vendor">
                <Col w={220}>
                    <Texto sx={{fontFamily:"semibold"}}>
                        Costo del producto sin impuestos
                    </Texto>
                    <CampoPrecios value={supplier_3?.product_without_tax ? supplier_3.product_without_tax: 0} onChange={e => setSupplier_3({
                        ...supplier_3,
                        product_without_tax: e.target.value
                    })}/>
                </Col>
                <Col w={150}>
                    <Texto sx={{fontFamily:"semibold"}}>Ganancia bruta</Texto>
                    <CampoPrecios disabled={true} value="0.00"/>
                </Col>
                <Col w={90}>
                    <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                    <Campo value="00.00%" disabled sx={{background:"#EBEBEB"}}/>
                </Col>
                <Col w={150}>
                    <Texto sx={{fontFamily:"semibold"}}>Proveedor Terciario</Texto>
                    <Campo value={supplier_3?.supplier_third ? supplier_3.supplier_third: ""} onChange={e => setSupplier_3({
                        ...supplier_3,
                        supplier_third: e.target.value
                    })}/>
                </Col>
            </Row>
            
        </Box> */}
        {/* {group_vendor.i_vendor_3 ? (
        ):null} */}
    </Item>)
}

const Producto_Detalle = ({sel_producto = false, go_to_menu = ()=>console.log("Menu")}) => {
    const [verDetalleVariante, setVer] = useState(false);
    const [id_variante,     setIdVariante]      = useState("");
    const [index_variante,  setIndexVariante]   = useState(0);
    const [cambios, setCambios]                 = useState("loading");
    const [toCompare, setToCompare]             = useState({});
    const [ckEnvios, setCkEnvios]               = useState(false);
    
    const [sucursales_guardadas, setSucursalesGuardadas] = useState([])

    const [existen_variantes, setExistencia] = useState(false)
    const [form, setForm]                    = useState({
        i_status: 2,
        createAt: Date.now(),
        modificateAt: Date.now(),
    })
    const [combinaciones, setCombinaciones]                    = useState([])
    const variantesContext = useContext(VariantesContext);
    const { obtener_variante } = variantesContext;

    const set_variables = (name="", value="") =>{
        setForm(old_form=>{
            return{
                ...old_form,
                [name]: value
            }
        })
    }

    const Titulo = ({texto = "Nuevo Producto"}) => {
        return(<Box sx={{
            display: "flex",
            justifyContent: "start",
            alignContent: "center",
            maxHeight: "40px",
            minHeight: "40px"
        }}>
            <BotonAtras
                sx={{alignSelf: "center", cursor: "pointer"}}
                onClick={()=>{
                    setForm({})
                    go_to_menu()
                }}
            ><ArrowBackIosNew className="arrow-letter"/></BotonAtras>
            <Box sx={{
                position: "relative",
                display: "grid",
                alignItems: "center",
            }}>
                <Texto sx={{
                    marginLeft: "15px",
                    fontSize: "20px",
                    fontFamily: "mulish-extra-bold",
                    width: "max-content",
                    position: "absolute",
                    margin: "0px",
                    left: "15px"
                }}>{texto}</Texto>
            </Box>
        </Box>)
    }

    useEffect(async ()=>{
        if(sel_producto){
            setExistencia(sel_producto?.b_variantes == 1 ? true: false)
            let variantes_result = await obtener_variante(sel_producto._id)
            setForm({
                ...await sel_producto,
                datos_variantes: variantes_result
            })
            setToCompare({
                ...await sel_producto,
                datos_variantes: variantes_result
            })
        }
    },[sel_producto])

    // useEffect(()=>{
    //     let validacion_existe = form?.b_variantes ? true: false
    //     // setForm({
    //     //     ...form,
    //     //     b_variantes: parseInt(`${existen_variantes ? 1: 0}`)
    //     // })
    //     // if(validacion_existe){
    //     //     if(form?.a_grupo_variantes && form?.a_grupo_variantes?.length > 0){
    //     //         setForm({
    //     //             ...form,
    //     //             a_grupo_variantes: []
    //     //         })
    //     //         setToCompare({
    //     //             ...form,
    //     //             a_grupo_variantes: []
    //     //         })
    //     //     }
    //     // }
    // },[existen_variantes])

    useEffect(()=>{
        set_variables('b_variant_requires_shipping', ckEnvios ? 1: 0)
    },[ckEnvios])

    useEffect(()=>{
        const isObjectEmpty = (objectName) => {
            return Object.keys(objectName).length === 0 && objectName.constructor === Object;
        }

        setExistencia(form.b_variantes ? true: false)

        if(!isObjectEmpty(form) && !isObjectEmpty(toCompare)){
            if(_.isEqual(toCompare, form)){
                setCambios(true)
            }else{
                setCambios(false)
            }
        }

        if(form?.b_variant_requires_shipping){
            setCkEnvios(true)
        }

        console.log(form)
    },[form, toCompare])

    if(!verDetalleVariante){
        return(<Box>
            <Row>
                <Col w={304}>
                    <Titulo texto={!sel_producto ? "Nuevo Producto": sel_producto.s_title}/>
                    <Estado nuevo={!sel_producto ? true: false} i_status={form?.i_status ? form.i_status: false} setForm={e=>{
                        console.log(e.target.value)
                        setForm({
                            ...form,
                            i_status: parseInt(e.target.value)
                        })
                    }}/>
                    <Canales/>
                    <Mercados/>
                    <Estadisticas/>
                </Col>
                <Col w={642}>
                    <Botones
                        cambios={cambios}
                        combinaciones={combinaciones}
                        go_to_menu={go_to_menu}
                        form={form}
                        mt="0px"
                        isNotnuevo={sel_producto ? true: false}
                    />
                    <General set_title={e=>{setForm({
                            ...form,
                            s_title: e.target.value
                        })}} 
                        s_title={form?.s_title ? form.s_title: ""}
                        set_body={e=>{setForm({
                            ...form,
                            s_body: e.target.value
                        })}}
                        s_body={form?.s_body ? form.s_body: ""}
                    />
                    {/* <Multimedia/> */}
                    <Variantes
                        go_to_variant={index=>{
                            setVer(true)
                            setIndexVariante(index)
                            if(form?.a_grupo_ids_variantes?.length > 0){
                                let ids = form.a_grupo_ids_variantes

                                setIdVariante(ids[index])
                            }
                            window.scroll({top: 0});
                        }}
                        form={form}
                        setForm={setForm}
                        existen_variantes={existen_variantes}
                        setExistencia={setExistencia}
                        guardarCombinaciones={combinaciones=>setCombinaciones(combinaciones)}
                    />
                    {existen_variantes ? null: (<Box>
                        <Precios
                            tax={form?.b_variant_taxable ? 1:0}
                            groups_vendor={form?.a_vendors_groups?.length > 0 ? form.a_vendors_groups: []}
                            set_groups_vendor={setForm}
                            price={form?.n_variant_price ? form?.n_variant_price: 0}
                            price_compare={form?.n_variant_compare_price ? form?.n_variant_compare_price: 0}
                            setPrecio={setForm}
                        />
                        <IdeEsp
                            sku={form.s_variant_sku}
                            b_customs_agent={form?.b_customs_agent ? form.b_customs_agent: false}
                            set_b_customs_agent={e=>set_variables('b_customs_agent', e.target.checked)}
                            s_harmonized_system_code={form?.s_harmonized_system_code ? form.s_harmonized_system_code: ""}
                            s_customs_agent={form?.s_customs_agent ? form.s_customs_agent: ""}
                            set_s_harmonized_system_code={e=>set_variables('s_harmonized_system_code', e.target.value)}
                            set_s_customs_agent={e=>set_variables('s_customs_agent', e.target.value)}
                            b_specifications={form?.b_specifications ? form.b_specifications: false}
                            set_b_specifications={e=>{set_variables('b_specifications', e.target.checked)}}
                            setSKU={e=>{set_variables('s_variant_sku', e.target.value)}}
                            barcode={form.s_variant_barcode}
                            setBarcode={e=>{set_variables('s_variant_barcode', e.target.value)}}
                            set_b_variant={e=>{
                                console.log()
                                let check_val = e.target.checked
                                // console.log(check_val)

                                // setForm(old_form=>{
                                //     return{
                                //         ...old_form,
                                //         b_specifications: check_val
                                //     }
                                // })
                                set_variables('b_specifications', check_val)
                            }}
                            n_unit_measure_weight={form?.n_unit_measure_weight}
                            set_n_unit_measure_weight={e=>{
                                set_variables('n_unit_measure_weight', parseInt(`${e.target.value}`))
                            }}
                            n_unit_measure_weight_packaging={form?.n_unit_measure_weight_packaging}
                            set_n_unit_measure_weight_packaging={e=>{
                                set_variables('n_unit_measure_weight_packaging', parseInt(`${e.target.value}`))
                            }}
                            s_variant_country_origin={form?.s_variant_country_origin}
                            set_s_variant_country_origin={e=>{
                                set_variables('s_variant_country_origin', `${e.target.value}`)
                            }}
                        />
                        <Sucursales
                            lista={sucursales_guardadas}
                            form={form}
                            sucursales_guardadas={sucursales_guardadas}
                            setSucursalesGuardadas={datos=>{
                                setSucursalesGuardadas(datos)
                            }}
                        />
                        <Envios
                            b_variant_requires_shipping={ckEnvios} 
                            set_b_variant_requires_shipping={e=> {
                                setCkEnvios(e.target.checked)
                            }}
                        />
                    </Box>)}
                    <CatOrg
                        set_s_product_node_category={e=>{set_variables('s_product_node_category', e.target.value)}}
                        set_s_product_type={e=>{set_variables('s_product_type', e.target.value)}}
                        set_s_manufacturers={e=>{set_variables('s_manufacturers', e.target.value)}}
                        set_s_supplier_primary={e=>{set_variables('s_supplier_primary', e.target.value)}}
                        s_product_node_category={form?.s_product_node_category}
                        s_product_type={form?.s_product_type}
                        s_manufacturers={form?.s_manufacturers}
                        s_brands={form?.s_brands}
                        s_supplier_primary={form?.s_supplier_primary ? form.s_supplier_primary: ""}
                        set_s_brands={e=>{set_variables('s_brands', e.target.value)}}
                    />
                    {/* <ColeccionesEt colecciones={[
                            "Colección 1",
                            "Colección 2",
                            "Colección 3",
                            "Colección 4",
                            "Colección 5"
                        ]}
                        etiquetas={[
                            "Etiqueta 1",
                            "Etiqueta 2"
                        ]}
                    />
                    <Motoresdebusqueda
                        set_s_handle={e=>{setForm({...form, s_handle: e.target.value})}}
                        s_handle={form.s_handle}
                        set_s_seo_title={e=>{setForm({...form, s_seo_title: e.target.value})}}
                        s_seo_title={form.s_seo_title}
                        s_seo_description={form.s_seo_description}
                        set_s_seo_description={e=>{setForm({...form, s_seo_description: e.target.value})}}

                    /> */}
                    <Marketing
                        set_n_range_max_age={e => set_variables('n_range_max_age', e.target.value)}
                        n_range_max_age={form?.n_range_max_age ? form.n_range_max_age: 0}
                        set_n_range_min_age={e => set_variables('n_range_min_age', e.target.value)}
                        n_range_min_age={form?.n_range_min_age ? form.n_range_min_age: 0}
                        set_b_gender={setForm}
                        b_gender={form?.b_gender ? form.b_gender: 0}
                        set_s_detailed_segmentation={e => set_variables('s_detailed_segmentation', e.target.value)}
                        s_detailed_segmentation={form?.s_detailed_segmentation ? form.s_detailed_segmentation: ""}
                    />
                    {/* <Plantilla/>
                    <Adicionales
                        datos={[
                            {contenido: ["Text 1"]},
                            {contenido: ["Text 2"]}
                        ]}
                    />
                    {!cambios ? (<Botones
                        combinaciones={combinaciones}
                        go_to_menu={go_to_menu}
                        form={form}
                        mt="0px"
                        isNotnuevo={sel_producto ? true: false}
                    />): (<Box sx={{height: "37px !important"}} />)} */}
                </Col>

            </Row>
        </Box>)
    }else{

        return(<Variante_Detalle
            id={id_variante}
            valores={form?.datos_variantes?.length > 0 ? form.datos_variantes: []}
            go_to_variant={e=>{setVer(false)}}
            n_index={index_variante}
            guardar_variante={setForm}
        />)
    }
}

export default Producto_Detalle;
import React, { useReducer } from 'react';
import settingsContext from './settingsContext';
import settingsReducers from './settingsReducers';

import {
    VALIDAR_AJUSTES,
    MOSTRAR_OPCION,
    OBTENER_CONFIGURACIONES,
    MODIFICAR_TEMA
} from '../../types';
import clientAxios from '../../config/axios';

const SettingsState = props => {
    const initialState = {
        viewSettings: 0,
        settings: [],
        option: null
    }

    const [state, dispatch] = useReducer(settingsReducers, initialState)

    const validarAjustes = validate => {
        dispatch({
            type: VALIDAR_AJUSTES,
            payload: validate
        })
    }

    const selectOption = desc =>{
        dispatch({
            type: MOSTRAR_OPCION,
            payload: desc
        })
    }

    const getSettings = async () => {
        try{
            const res = await clientAxios.get('/api/settings')
            dispatch({
                type: OBTENER_CONFIGURACIONES,
                payload: {
                    options: res.data.options,
                    settings: res.data.settings
                }
            })
        }catch(err){ console.log(err)}
    }

    const modificarTema = async (tema) =>{
        const res = await clientAxios.get('/api/auth/theme/modify')
        let temasuccess = await res.data.code;

        if(temasuccess == 200){
            dispatch({
                type: MODIFICAR_TEMA,
                payload: tema ? 0 : 1
            })
        }
    }

    return(
        <settingsContext.Provider
            value={{
                viewSettings: state.viewSettings,
                settings: state.settings,
                options: state.options,
                selectOption,
                modificarTema,
                getSettings,
                validarAjustes
            }}
        >
            {props.children}
        </settingsContext.Provider>
    )
}

export default SettingsState;
import { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import img_col from '../../../img/icons/Colums-Add-Dark-Gray-1.png'
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { Box, Typography } from '@mui/material';

const StyledMenu = styled((props) => (
  <Menu
    className='scroll-table-component'
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 280,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function CustomizedMenus({heads, setHeads}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [keys, setKeys]         = useState([]);
    const [a_heads, setA_heads]   = useState([]);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        let all_keys = Object.keys(heads);
        setKeys(all_keys?.length > 0 ? all_keys: [])
        setA_heads(all_keys?.length > 0 ? all_keys: [])
    },[heads])

    const InputSearch = styled('input')({
            outline: "none !important",
            border: "1px solid #ebebeb !important",
            borderRadius: "8px",
            fontSize: "12px",
            width: "260px",
            height: "30px",
            margin: "10px 10px",
            padding: "0px 10px"
    })

    const LabelCheck = styled('label')({
        marginLeft: "15px",
        cursor: "pointer",
        fontFamily: "semibold",
        fontSize: "12px",
        color: "#626262"
    })

    const InputCheck = styled('input')({
        background: "#626262",
        accentColor: "#626262",
    })


    const check_item = key_name =>{
        setHeads({
            ...heads,
            [key_name]: !heads[key_name]
        })
    }

    const search_letter = e =>{
        let array_result = a_heads.filter(a_head => a_head.search(e.target.value) > -1)
        // setKeys(array_result)
    }

  return (
    <div>
        <Typography sx={{
            background: "#EBEBEB",
            marginRight: "10px",
            borderRadius: "8px",
            fontSize: "12px",
            fontFamily: "semibold",
            padding: "5px 15px 5px 10px",
            cursor: "pointer"
        }} onClick={handleClick}>
            <img src={img_col} height="14px" width="14px" style={{
                verticalAlign: "sub",
                marginRight: "3px"
            }}/>
            Columnas
        </Typography>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <InputSearch onChange={search_letter} type='text' placeholder='Buscar columnas'/>
        <Divider sx={{ my: 0.5 }} />
            {keys?.length > 0? keys.map((key_name, i) => {
                return(<Box key={`key_col_${i}`}
                    sx={{
                        "& label":{
                            color: "black !important",
                            fontSize: "12px"
                        },
                        "& input":{ marginRight: "10px !important"}
                    }}
                >
                    <LabelCheck key={i}>
                        <InputCheck key={i} onChange={e=>{check_item(key_name)}} type="checkbox" defaultChecked={heads[key_name]}/>
                        {key_name}
                    </LabelCheck>
                </Box>)
            }):null}
      </StyledMenu>
    </div>
  );
}
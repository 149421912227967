import React, {useReducer} from 'react'
import authReducer from './authReducer'
import AuthContext from './authContext'
import clientAxios  from '../../config/axios'
import tokenAuth from '../../config/tokenAuth'

import {
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
    VALIDACION_SESION,
    OBTENER_NEGOCIOS,
    SELECCIONAR_RUTA_NEGOCIO
} from '../../types'

const AuthState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        autenticado: null,
        usuario: null,
        mensaje: null,
        loading: true,
        negocios: [],
        validacionToken: -1,
        ruta_seleccionada: 0,
        // rutas: []
    }

    const [ state, dispatch ] = useReducer(authReducer, initialState)

    const registrarUsuario = async data => {
        try{
            const result = await clientAxios.post('/api/auth/createNewUser', data)

            console.log(result)
            dispatch({
                type: REGISTRO_EXITOSO,
                payload: result.data
            })

            usuarioAutenticado()
        } catch(err){
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alert
            })
        }
    }

    const usuarioAutenticado = async () => {
        const token = localStorage.getItem('token');
        localStorage.setItem("route", 'testing');

        if(token){
            // TODO: funcion mandar token por headers
            tokenAuth(token);
        }

        try{
            const result = await clientAxios.get('/api/auth');
            console.log("RESULTADO AUTH", result)
            dispatch({
                type: OBTENER_USUARIO,
                payload: result?.data?.user ? result.data.user: false
            })

            dispatch({
                type: OBTENER_NEGOCIOS,
                payload: result?.data?.negocios ? result.data.negocios: false
            })
        } catch(err){
            console.log(err)
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }

            dispatch({
                type: OBTENER_USUARIO,
                payload: false
            })

            dispatch({
                type: OBTENER_NEGOCIOS,
                payload: []
            })

            if(!err.response.data.hide){
                dispatch({
                    type: LOGIN_ERROR,
                    payload: alert
                })
            }
        }
    }

    const validarSesion = async route => {
        try{
            const result = await clientAxios.get('/api/auth/validar-sesion');
            let success = result?.data?.success ? result.data.success: false; 
            dispatch({
                type: VALIDACION_SESION,
                payload: {success, route: route ? `${route}`: "/testing"}
            })
        } catch(err){
            console.log("RESULT ERROR", err)

            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }

            if(!err.response.data.hide){
                dispatch({
                    type: LOGIN_ERROR,
                    payload: alert
                })
            }
        }
    }

    const login = async user => {
        try{
            const result = await clientAxios.post('/api/auth', user);
            
            dispatch({
                type: LOGIN_EXITOSO,
                payload: result.data
            })
            
            usuarioAutenticado()
        } catch(err){
            const alert = {
                msg: err.response.data.msg,
                alert: 'alerta-error'
            }
            dispatch({
                type: REGISTRO_ERROR,
                payload: alert
            })
        }
    }

    const cerrarSesion = () => {
        dispatch({
            type: CERRAR_SESION
        })
    }

    const obtener_negocios = async id => {
        let negocios_ = state?.negocios?.length > 0 ? state.negocios: [];
        let negocio_select = await negocios_.filter(negocio_=> negocio_.id == parseInt(id))
        if(negocio_select?.length > 0){
            return negocio_select[0]?.ruta ? negocio_select[0].ruta: "sinnombre"
        }else{
            return ""
        }
    }

    return(
        <AuthContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                validacionToken: state.validacionToken,
                usuario: state.usuario,
                mensaje: state.mensaje,
                loading: state.loading,
                negocios: state.negocios,
                negocio_seleccionado: state.negocio_seleccionado,
                rutas: state.rutas,
                obtener_negocios,
                registrarUsuario,
                validarSesion,
                usuarioAutenticado,
                login,
                cerrarSesion
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;
import React, { useReducer } from 'react';
import {SideleftContext} from '../index';
import sideleftReducer from './sideleftReducer';
import { 
    MINIMIZAR_MENUBAR,
    MINIMIZAR_SIDELEFT,
    SELECCIONAR_MODULO_MENU
} from '../../types'

const SideleftState = props => {
    const initialState = {
        minimizar: false,
        open_menu: false,
        modulo_seleccionado: null
    }

    const [state, dispatch] = useReducer(sideleftReducer, initialState)

    const abrir_sideleft = async open => {
        dispatch({
            type: MINIMIZAR_SIDELEFT,
            payload: open
        })
    }

    const abrir_barmenu = async open => {
        dispatch({
            type: MINIMIZAR_MENUBAR,
            payload: open
        })
    }

    const seleccionar_modulo_menu = async nombre => {
        dispatch({
            type: SELECCIONAR_MODULO_MENU,
            payload: nombre
        })
    }

    return(
        <SideleftContext.Provider
            value={{
                minimizar: state.minimizar,
                open_menu: state.open_menu,
                modulo_seleccionado: state.modulo_seleccionado,
                abrir_sideleft,
                abrir_barmenu,
                seleccionar_modulo_menu
            }}
        >
            {props.children}
        </SideleftContext.Provider>
    )
}

export default SideleftState 
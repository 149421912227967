import React, {useContext, useEffect} from 'react';
import {  
    Toolbar, Box, Typography, Grid, styled
} from '@mui/material';
import { useState } from 'react';
import settingsContext from '../../context/settings/settingsContext';
import imgBkgLogo from '../../img/logos/logo_w_letter.png'
import img_back from '../../img/icons/Row-Left-White.png'

const ListItemImage = styled('img')({
  width: '30%',
  maxWidth: '30px',
  maxHeight: "40px",
  textAlign: 'center'
})

const ToolbarMui_Secondary = ({setOpen, open}) => {
    const SettingContext = useContext(settingsContext);
    const { viewSettings } = SettingContext;
    const [themeSelecto, setThemeSelecto] = useState(0);
    const [letterTheme, setLetterTheme] = useState({color: "var(--dark-color-letter)"});
    const [borderTheme, setBorderTheme] = useState({borderColor: "var(--dark-color-letter)"});
    
    useEffect(()=>{
        setThemeSelecto(viewSettings)
        setLetterTheme({color: "var(--light-color-letter)"})
        setBorderTheme({borderColor: "var(--light-color-letter)"})

        document.getElementById('toolbar-container').classList.add(!viewSettings ? 'dark-theme': 'light-theme');
        document.getElementById('toolbar-container').classList.remove(!viewSettings ? 'light-theme': 'dark-theme');

        // document.getElementById('toolbar-container').classList.remove(viewSettings  ?  'dark-theme': 'light-theme');
    },[viewSettings])

    return(
        <Toolbar
          id="toolbar-container"
          className={`hrDrawerBottom`}
          sx={{ 
            width: "100%",
            border: "0px !important",
            minHeight: "50px !important",
            background: "#1A1E1D !important",
            boxShadow: 'none',
            padding: "0px !important",
            paddingLeft: "10px !important"
          }}
        >
          <Grid container sx={{marginLeft: "10px"}}>
            <Grid item xs={6} sx={{display: "grid", alignContent: "center"}}>
                <Box sx={{display: "flex", "& p": {color: "white !important"}}}>
                    <ListItemImage src={imgBkgLogo}/>
                    <Box sx={{
                        marginLeft: "70px",
                        background: "#3F3F3F",
                        width: "33px",
                        height: "25px",
                        display: "grid",
                        justifyContent: "center",
                        alignContent: "center",
                        borderRadius: "8px",
                        cursor: "pointer"
                    }}>
                        <img src={img_back} height="12px" width="12px"/>
                    </Box>
                    <Typography sx={{
                        fontFamily: "mulish-extra-bold",
                        fontSize: "20px",
                        marginLeft: "25px"
                    }}>Editar Productos</Typography>
                </Box>
            </Grid>
            <Grid item xs={6}>
              <Box id="content-bar-menu"></Box>
            </Grid>
          </Grid>
        </Toolbar>
    )
}

export default ToolbarMui_Secondary

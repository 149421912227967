import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BotonJCs } from "../../../material";
import { ArrowBackIosNew, DragIndicator, Favorite, FavoriteBorder } from "@mui/icons-material";
import moduleContext from "../../../../context/modules/moduleContext";
import { SucursalesContext, VariantesContext } from "../../../../context";
import inventarioContext from "../../../../context/inventario/inventarioContext";
import Sucursal from "../Sucursal";

const ID_PRODUCTOS_DEV = "65468dba8700459b6f0760a4"

const General = ({variantes=[]}) => {
    return(<Item>
        <Texto>General</Texto>
        {variantes.map(variante => {
            return(<Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>
                        {variante.categoria}
                    </Texto>
                    <Campo value={variante.value}/>
                </Box>
            </Box>)
        })}
    </Item>)
}

const Multimedia = () => {
    return(<Item>
        <Texto>Multimedia</Texto>
        <AreaFile>
            <Box style={{display: "flex"}}>
                <Boton>Subir</Boton>
                <Boton>Subir desde URL</Boton>
            </Box>
        </AreaFile>
        <Comentario>
            Puedes subir imágenes, videos o modelos 3D de realidad aumentada,
            asi como formatos PNG, JPG o GIF. Te recomendamos utilizar 
            formato multimedia cuadrado.
        </Comentario>
    </Item>)
}

const Precios = (props) => {
    let {
        n_variant_price,
        n_variant_compare_price,
        set_n_variant_price,
        set_n_variant_compare_price
    } = props
    return(<Item>
        <Texto>Precios, costos y márgenes</Texto>
        <Row sx={{justifyContent: "start", marginBottom: "5px"}}>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Precio de venta</Texto>
                <CampoPrecios value={n_variant_price} onChange={set_n_variant_price}/>
            </Col>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Precio comparativo o promocional</Texto>
                <CampoPrecios value={n_variant_compare_price} onChange={set_n_variant_compare_price}/>
            </Col>
        </Row>
        <Row sx={{justifyContent: "start", marginBottom: "15px", "& option": {color: "#626262 !important"}}}>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Impuestos</Texto>
                <Dropdown sx={{background: "#EBEBEB"}}>
                    <Opcion>Cobrar</Opcion>
                </Dropdown>
            </Col>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Tasa</Texto>
                <Dropdown>
                    <Opcion>Ver mercados</Opcion>
                </Dropdown>
            </Col>
        </Row>
        <Row sx={{justifyContent: "start", marginTop: "30px"}}>
            <Col w={220}>
                <Texto sx={{fontFamily:"semibold"}}>Costo del producto sin impuestos</Texto>
                <Campo value="00.00%" disabled sx={{background:"#EBEBEB"}}/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Ganancia bruta</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={90}>
                <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                <Campo value="00.00%" disabled sx={{background:"#EBEBEB"}}/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Proveedor Primario</Texto>
                <Campo/>
            </Col>
        </Row>
        <Row sx={{justifyContent: "start"}}>
            <Col w={220}>
                <Texto sx={{fontFamily:"semibold"}}>Costo del producto sin impuestos</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Ganancia bruta</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={90}>
                <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                <Campo value="00.00%" disabled sx={{background:"#EBEBEB"}}/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Proveedor Secundario</Texto>
                <Campo/>
            </Col>
        </Row>
        <Row sx={{justifyContent: "start", marginBottom: "15px"}}>
            <Col w={220}>
                <Texto sx={{fontFamily:"semibold"}}>Costo del producto sin impuestos</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Ganancia bruta</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={90}>
                <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                <Campo value="00.00%" disabled sx={{background:"#EBEBEB"}}/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Proveedor Terciario</Texto>
                <Campo/>
            </Col>
        </Row>
    </Item>)
}

const Estado = () => {
    return(<Item>
        <Texto>Estado</Texto>
        <Seleccionar name="pets" id="pet-select">
            <option>Activo</option>
            <option>Borrador</option>
        </Seleccionar>
    </Item>)
}

const Canales = () => {
    const [lista, setLista] = useState([
        "Sitio Web",
        "Punto de ventas",
        "Facebook",
        "Instagram",
        "Google",
        "Youtube",
        "Pinterest",
        "TikTok",
        "LinkedIn",
        "Twitter",
        "Twitch",
        "Threads",
        "Amazon",
        "Mercado Libre",
        "Linio",
        "Wish",
        "Claro Shop",
        "Walmart",
        "Elektra",
        "Liverpool",
        "Coppel",
        "Sears",
        "Otros"
    ])
    return(<Item>
        <Texto>Canales</Texto>
        {/* <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
            >
                {lista.map(item => {
                    return(<FormControlLabel 
                        value={item}
                        control={<Radio />}
                        label={item}
                    />)
                })}
            </RadioGroup>
        </FormControl> */}
        {lista.map(item => {
            return(<Checkbox {...item} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />)
        })}
    </Item>)
}

const Mercados = () => {
    const [lista, setLista] = useState([
        "México",
        "Barbados",
        "Belice",
        "Colombia",
        "Costa Rica",
        "Ecuador",
        "El Salvador",
        "Granada",
        "Guatemala",
        "Guyana",
        "Haití",
        "Honduras",
        "Islas Británicas",
        "Islas Caimán",
        "Islas del Mar Caribe",
        "Islas Francesas",
        "Jamaica",
        "Nicaragua",
        "Panamá",
        "Perú",
        "Puerto Rico",
        "República Dominicana",
        "Surinam",
        "Trinidad y Tobago",
        "Venezuela"
    ])
    return(<Item>
        <Texto>Mercados</Texto>
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
            >
                {lista.map(item => {
                    return(<FormControlLabel 
                        value={item}
                        control={<Radio />}
                        label={item}
                    />)
                })}
            </RadioGroup>
        </FormControl>
    </Item>)
}

const Estadisticas = () => {
    return(<Item>
        <Texto>Estadísticas</Texto>
        <Texto family="semibold">
            El resumen estadístico se mostrará cuando el
            producto comience a tener ventas
        </Texto>
    </Item>)
}

const IdeEsp = (props) => {
    let {
        s_variant_sku,
        s_variant_barcode,
        set_s_variant_sku,
        set_s_variant_barcode
    } = props
    return(<Item>
        <Texto>Identificación y especificaciones</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>SKU</Texto>
            <Campo value={s_variant_sku} onChange={set_s_variant_sku} placeholder=""/>
        </Box>

        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Código de barras (UPC, EAN, GTIN, MPN, ISBN, etc)</Texto>
            <Campo value={s_variant_barcode} onChange={set_s_variant_barcode} placeholder=""/>
        </Box>

        <Texto sx={{marginTop: "35px"}}>Especificaciones</Texto>
        <CheckBox>Este es un producto físico o artículo</CheckBox>

        <Row sx={{justifyContent: "start", marginBottom: "5px"}}>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Peso unitario</Texto>
                <CampoPrecios Moneda="kg" SignoMoneda=""/>
            </Col>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Peso con embalaje</Texto>
                <CampoPrecios Moneda="kg" SignoMoneda=""/>
            </Col>
        </Row>
        <Comentario>
            El peso unitario es el peso del producto físico o artículo y el peso con embalaje 
            es el peso del producto que se toma en la paquetería sumando el embalaje.
        </Comentario>

        <Texto sx={{marginTop: "35px"}}>Información aduanal</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>País o región de origen</Texto>
            <Campo placeholder=""/>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Código de sistema armonizado (SA)</Texto>
            <Campo placeholder=""/>
        </Box>
        <CheckBox>Requieres de un agente aduanal para este producto físico o artículo</CheckBox>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Agente aduanal</Texto>
            <Campo placeholder=""/>
        </Box>
    </Item>)
}

const Tabla_Sucursal = ({tabla_sx={}, col_enc_sx={}, encabezados = [], datos = [], encabezados_sx = {}}) =>{
    return(<TablaEstilo sx={tabla_sx}>
        <ColumnaTabla sx={col_enc_sx}>
            {encabezados.length > 0 ? encabezados.map((encabezado, i) => (
                <EncabezadoTabla sx={[encabezados_sx, {textAlign: i == 0 ? "left": "center"}]}>{encabezado}</EncabezadoTabla>
            )): null}
        </ColumnaTabla>
        {datos.length > 0 ? datos.map(columna => {
            if(columna?.is_active){
                return(<ColumnaTabla>
                    <CeldaTabla>
                        {columna?.s_nombre? columna.s_nombre: null}
                    </CeldaTabla>
                    <CeldaTabla  sx={{
                        textAlign: "center",
                        "& input":{
                            padding: "0px !important",
                            maxWidth: "80px",
                            textAlign: "center",
                            fontSize: "12px"
                        }
                    }}>
                        <input type="checkbox" disabled checked={columna?.b_almacenamiento ? true: false}/>
                    </CeldaTabla>
                    <CeldaTabla  sx={{
                        textAlign: "center",
                        "& input":{
                            padding: "0px !important",
                            maxWidth: "80px",
                            textAlign: "center",
                            fontSize: "12px"
                        }
                    }}>
                        <input type="checkbox" disabled checked={columna?.b_retiro ? true: false}/>
                    </CeldaTabla>
                    <CeldaTabla sx={{textAlign: "center"}}>
                        No rastredo
                    </CeldaTabla>
                </ColumnaTabla>)
            }
        }): null}
    </TablaEstilo>)
}

const Sucursales = ({form, setSucursalesGuardadas, sucursales_guardadas=[], lista=[], onChange}) => {
    const sucursalesContext = useContext(SucursalesContext);
    const { obtener_sucursales, sucursales } = sucursalesContext;

    const InventarioContext = useContext(inventarioContext);
    const { obtenerInventarioDetalleVariante, actualizar_inventario, inventario_detalle } = InventarioContext;

    const [sucursales_, setSucursales] = useState({})
    const [listSucursales_, setlistSucursales] = useState([])
    const [datos, setDatos] = useState(lista)
    const [open, setOpen] = useState(false)
    const [isEmpty, setIsEmpty] = useState(null)

    const guardar_sucursales_lista = () => {
        console.log(inventario_detalle)
        setSucursalesGuardadas(datos)
        if(inventario_detalle?.length > 0){
            let inventario_result = inventario_detalle[0]
            console.log(inventario_result)
            actualizar_inventario([{
                ...inventario_result,
                a_sucursales: datos
            }])
        }
        setOpen(false)
    }

    useEffect(()=>{
        console.log(inventario_detalle)
        if(inventario_detalle?.length > 0){
            let inventario_sucursales = inventario_detalle[0], a_sucursales = inventario_sucursales?.a_sucursales
            if(a_sucursales?.length > 0 && a_sucursales){
                setDatos(a_sucursales)
            }else setDatos([])

            setSucursalesGuardadas(a_sucursales)
        }
    },[inventario_detalle])

    useEffect(()=>{
        let id_negocio = localStorage.getItem("id_route")
        obtener_sucursales(id_negocio)
    },[])
    
    useEffect(()=>{
        if(form){
            if(form?._id){
                if(isEmpty != form._id){
                    setIsEmpty(form._id)
                }
            }
        }
    },[form])

    useEffect(()=>{
        if(isEmpty){
            let id_negocio = localStorage.getItem("id_route")
            obtener_sucursales(id_negocio)

            obtenerInventarioDetalleVariante(form._id, {...form, id_negocio: id_negocio})
        }
    },[isEmpty])

    useEffect(()=>{
        let lista_sic = sucursales.map(sucursal => {
            let array_suc_exist = datos.filter(d_sucursal => d_sucursal._id == sucursal._id)
            let suc_exist = array_suc_exist[0], exist = false
            if(suc_exist?.is_active){
                exist = suc_exist?.is_active ? true: false
            }
            return [
                (<Box sx={{
                    "& p, span":{
                        fontSize: "12px"
                    },
                    "& svg":{
                        width: "20px",
                        color: "black"
                    },
                    "& label":{
                        height: "24px"
                    }

                }}>
                    <FormControlLabel
                        sx={{ fontSize: "12px !important" }}
                        label={`${sucursal.s_nombre}, ${sucursal.s_calle_num}, ${sucursal.s_ciudad}, ${sucursal.s_pais}`}
                        onChange={e=>{
                            setDatos(suc_old => {
                                let array_suc = []
                    
                                array_suc = suc_old
                                let is_array_suc = array_suc.filter(a_s => a_s._id == sucursal._id)
                                let is_not_array_suc = array_suc.filter(a_s => a_s._id != sucursal._id)
                    
                                if(is_array_suc?.length > 0){
                                    array_suc = [
                                        ...is_not_array_suc,
                                        {
                                            _id: sucursal._id,
                                            is_active: e?.target?.checked ? true: false,
                                            i_stock: 0,
                                            n_comprometido:0,
                                            n_cost_per_item:0,
                                            n_disponibilidad:0,
                                            i_entrante: 0
                                        }
                                    ]
                                }else{
                                    array_suc = [
                                        ...suc_old,
                                        {
                                            _id: sucursal._id,
                                            is_active: e?.target?.checked ? true: false,
                                            i_stock: 0,
                                            n_comprometido:0,
                                            n_cost_per_item:0,
                                            n_disponibilidad:0,
                                            i_entrante: 0
                                        }
                                    ]
                                }
                    
                                return array_suc
                            })
                        }}
                        control={<Checkbox
                            checked={exist}
                            sx={{ fontSize: "12px !important" }}
                        />}
                    />
                </Box>)
            ]
        })

        setlistSucursales(lista_sic)
    },[sucursales, datos])

    useEffect(()=>{
        let array_sucursales_nuevas = []
        sucursales_guardadas.map(suc_g => {
            let array_sucursales = sucursales.filter(suc_ => suc_._id == suc_g._id)
            if(array_sucursales?.length > 0){
                let seleccion = array_sucursales[0]
                array_sucursales_nuevas.push({
                    _id: seleccion._id,
                    s_nombre: seleccion.s_nombre,
                    is_active: suc_g.is_active,
                    b_almacenamiento: suc_g.b_almacenamiento,
                    b_preparacion: suc_g.b_preparacion,
                    b_retiro: suc_g.b_retiro
                })
            }
        })
        setSucursales(array_sucursales_nuevas)
    },[sucursales_guardadas])

    return(<Item>
        <Texto>Sucursales e inventario</Texto>
        
        <Tabla_Sucursal
            encabezados={["Nombre", "Almacenar", "Rastrear", "Existencias"]} 
            datos={sucursales_}
        />

        <Sucursal
            open={open}
            setOpen={setOpen}
            datos={listSucursales_}
            save={guardar_sucursales_lista}
        />

        {/* <Boton sx={{marginBottom: "15px"}}>+ Agregar sucursal 2</Boton> */}
    </Item>)
}

// const Sucursales = () => {
//     const sucursalesContext = useContext(SucursalesContext);
//     const { obtener_sucursales, sucursales } = sucursalesContext;

//     const [sucursales_, setSucursales] = useState([])

//     useEffect(()=>{
//         let id_negocio = localStorage.getItem("id_route")
//         obtener_sucursales(id_negocio)
//     },[])

//     useEffect(()=>{
//         let result_suc = sucursales.map(sucursal => {
//             return [
//                 `${sucursal.s_nombre} ${sucursal.s_calle_num}, ${sucursal.s_ciudad}, ${sucursal.s_pais}`,
//                 (<CheckBox/>),
//                 (<CheckBox/>),
//                 "No rastreado"
//             ]
//         })


//         setSucursales(result_suc)
//     },[sucursales])

//     return(<Item>
//         <Texto>Sucursales e inventario</Texto>
//         <Tabla
//             encabezados={["Nombre", "Almacenar", "Rastrear", "Existencias"]} 
//             datos={sucursales_}
//         />

//         <Boton sx={{marginBottom: "15px"}}>+ Agregar sucursal</Boton>
//     </Item>)
// }

const Envios = () => {
    return(<Item>
        <Texto>Envíos</Texto>
        <CheckBox>Este producto físico o artículo tiene envío gratis</CheckBox>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto>Embalajes</Texto>
            <Dropdown>
                <Opcion>Nombre del embalaje guardado</Opcion>
            </Dropdown>
        </Box>
    </Item>)
}

const CatOrg = () => {
    return(<Item>
        <Texto>Categorización y organización</Texto>
        <Row sx={{justifyContent: "start", marginBottom: "5px"}}>
            <Col w={260}>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Nodo</Texto>
                    <Campo placeholder="Nombre del Nodo"/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Tipo de producto</Texto>
                    <Campo placeholder="Nombre del tipo de producto"/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Proveedor principal</Texto>
                    <Campo placeholder="Nombre del proveedor principal" sx={{background: "#EBEBEB"}}/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Fabricante</Texto>
                    <Campo placeholder="Nombre del Fabricante"/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Marca</Texto>
                    <Campo placeholder="Nombre de la Marca"/>
                </Box>
            </Col>
            <Col w={160}/>
        </Row>
    </Item>)
}

const ColeccionesEt = ({colecciones = [], etiquetas = []}) => {
    return(<Item>
        <Texto>Colecciones y etiquetas</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Row>
                <Col w={250}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Colecciones</Texto>
                    <Campo placeholder=""/>
                </Col>
                <Col w={320}>
                    <Box sx={{display: "flex", flexWrap: "wrap"}}>
                        {colecciones.map(coleccion => {
                            return(<Itemce>{coleccion}</Itemce>)
                        })}
                    </Box>
                </Col>
            </Row>
            <Row sx={{marginTop: "25px"}}>
                <Col w={250}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Etiquetas</Texto>
                    <Campo placeholder=""/>
                </Col>
                <Col w={320}>
                    <Box sx={{display: "flex"}}>
                        {etiquetas.map(etiqueta => {return(<Itemce>{etiqueta}</Itemce>)})}
                    </Box>
                </Col>
            </Row>
        </Box>
    </Item>)
}

const Motoresdebusqueda = () => {
    return(<Item>
        <Texto sx={{marginBottom: "0px !important"}}>Motores de búsqueda</Texto>
        <Comentario sx={{margintop: "0px !important"}}>Aumenta la relevancia de tus productos en los motores de búsqueda agregando una descripción.</Comentario>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Título de la página</Texto>
            <Campo placeholder="Nombre del producto"/>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Meta descripción</Texto>
            <TextArea rows="8" cols="50" placeholder="Descripción del producto"/>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Identificador de URL</Texto>
            <Campo placeholder=""/>
        </Box>
    </Item>)
}

const Marketing = () => {
    const [rangos, setRangos] = useState([18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,65])

    return(<Item>
        <Texto>Marketing</Texto>
        <Comentario sx={{margintop: "0px !important"}}>Define la segmentación de marketing de tu producto.</Comentario>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Edad</Texto>
            <Row sx={{width: "30%"}}>    
                <Seleccionar sx={{borderRadius: "8px 0px 0px 8px"}}>
                    {rangos.map((edad,i) => (<option>{edad}</option>))}
                </Seleccionar>
                <Seleccionar sx={{borderRadius: "0px 8px 8px 0px"}}>
                    {rangos.map((edad,i) => (<option selected={i==rangos.length-1? true: false}>{edad}</option>))}
                </Seleccionar>
            </Row>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Sexo</Texto>
            <Row sx={{width: "30%"}}>    
                <Seleccionar>
                    <option>Masculino</option>
                    <option>Femenino</option>
                </Seleccionar>
            </Row>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Segmentación detallada</Texto>
            <Campo placeholder="Agregar datos demográficos, intereses o comportamientos"/>
        </Box>
    </Item>)
}

const Plantilla = () => {
    return(<Item>
        <Texto>Plantilla del tema</Texto>
        <Seleccionar name="pets" id="pet-select">
            <option>Plantilla de producto predeterminado</option>
        </Seleccionar>
    </Item>)
}

const Adicionales = ({datos = []}) => {
    return(<Item>
        <Texto>Campos Adicionales</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            {datos.map(dato =>{
                return(<Row sx={{marginTop: "25px"}}>
                    <Col w={250}>
                        <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>
                            {dato?.titulo ? dato.titulo: "Nombre del campo Adicional"}
                        </Texto>
                    </Col>
                    <Col w={320}>
                        <Box>
                            {dato?.contenido?.length > 0 ? dato.contenido.map((campo, i) => (<Box>
                                <Campo sx={{marginTop: i != 0 ? "15px": "0px"}} value={campo}/>
                            </Box>)): null}
                            <Box sx={{textAlign: "right"}}><BotonAdd>+ Agregar</BotonAdd></Box>
                        </Box>
                    </Col>
                </Row>)
            })}
        </Box>
    </Item>)
}

const Botones = ({ guardar_variante, mt = "25px", go_to_variant}) => {
    const modulesContext = useContext(moduleContext);
    const { moduloActual, module } = modulesContext;
    const seleccionarModulo = async id => moduloActual(id);

    return(<Box sx={{
        display: "flex",
        justifyContent: "end",
        alignContent: "center",
        alignItems: "center",
        marginTop: mt,
        // maxHeight: "40px",
        minHeight: "40px",
    }}>
        <BotonJCs onClick={()=>{
            go_to_variant(false)
            // seleccionarModulo(ID_PRODUCTOS_DEV)
        }} sx={{display: "grid", alignContent: "center", marginRight: "5px", height:"25px"}}>Cancelar</BotonJCs>
        <BotonJCs
            onClick={guardar_variante}
            sx={{display: "grid", alignContent: "center", height:"25px"}}
        >
            Guardar
        </BotonJCs>
    </Box>)
}

const Titulo = ({texto = "Nuevo Producto", go_to_variant}) => {
    return(<Box sx={{
        display: "flex",
        justifyContent: "start",
        alignContent: "center",
        maxHeight: "40px",
        minHeight: "40px"
    }}>
        <BotonAtras
            sx={{alignSelf: "center", cursor: "pointer"}}
            onClick={()=>{
                go_to_variant(true)
            }}
        ><ArrowBackIosNew className="arrow-letter"/></BotonAtras>
        <Box sx={{
            position: "relative",
            display: "grid",
            alignItems: "center",
        }}>
            <Texto sx={{
                marginLeft: "15px",
                fontSize: "20px",
                fontFamily: "mulish-extra-bold",
                width: "max-content",
                position: "absolute",
                margin: "0px",
                left: "15px"
            }}>{texto}</Texto>
        </Box>
    </Box>)
}

const BotonAdd = styled('button')({
    background: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#08306B",
    fontFamily: "semibold",
    fontSize: "12px"
})

const Row = styled(Box)({
    display: "flex", 
    justifyContent: "center"
})

const Campo = styled("input")({
    display: "flex", 
    justifyContent: "center",
    width: "100%",
    borderRadius: "8px",
    height: "28px",
    border: "1px solid #DAD9D5",
    fontSize: "12px",
    padding: "5px 10px"
})

const Dropdown = styled("select")({
    width: "100%",
    borderRadius: "8px",
    fontSize: "12px",
    padding: "8px",
    color: "#626262 !important",
    border: "1px solid #DAD9D5"
})

const Opcion = styled("option")({
    color: "#626262 !important"
})

const Item = styled(Box)({
    background: "white", 
    padding: "2px 15px !important", 
    borderRadius: "8px",
    marginTop: "15px",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.1) !important",
    "& span":{
        fontSize: "12px !important",
        fontFamily: "semibold"
    },
    "& svg": {
        width: "15px !important"
    },
    "& label": {
        height: "25px"
    }
})

const Col = styled(Box)(props=>({
    width: props?.w ? props.w : 100, 
    marginRight: 20
}))

const Texto = styled('p')(props => ({
    fontSize: 12,
    fontFamily: props?.family ? props.family: "mulish-bold"
}))

const TextArea = styled('textarea')(props => ({
    width: "100%",
    border: "1px solid #DAD9D5",
    fontSize: "12px",
    padding: "5px 10px",
    borderRadius: "0px 0px 8px 8px"
}))

const Seleccionar = styled('select')({
    width: "100%",
    marginBottom: "10px",
    border: "1px solid #DAD9D5",
    padding: "5px 10px",
    borderRadius: "8px",
    fontSize: "12px"
})

const AreaFile = styled('div')({
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    padding: "50px 0px",
    borderRadius: "8px",
    border: "1px dashed #DAD9D5"
})

const Boton = styled('button')({
    background: "#EBEBEB !important",
    border: "none",
    cursor: "pointer",
    margin: "5px",
    borderRadius: "8px",
    padding: "8px 15px",
    fontSize: "12px",
    fontFamily: "semibold"
})

const Comentario = styled("p")({
    marginBottom: "15px",
    fontSize: "12px",
    color: "#626262 !important"
})

const TextoCampos = styled('div')({
    display: "flex",
    "& p, input": {
        color: "#626262 !important"
    }
})

const ContenedorSigno = styled('div')({
    position: "relative"
})

const TextoPrecio = styled('p')({
    margin: "0px !important",
    padding: "0px 10px !important",
    paddingTop: "2px !important",
    height: "28px",
    background: "#EBEBEB",
    borderRadius: "8px 0px 0px 8px",
    border: "1px solid #DAD9D5"
})

const InputPrecio = styled('input')({
    width: "90%",
    fontSize: "12px",
    padding: "0px 10px",
    borderRadius: "0px 8px 8px 0px",
    height: "28px",
    border: "1px solid #DAD9D5",
    paddingLeft: "20px",
    paddingRight: 0
})

const SignoPrecio = styled('i')({
    position: "absolute",
    display: "block",
    transform: "translate(0, -55%)",
    top: "50%",
    pointerEvents: "none",
    width: "28px",
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "semibold",
    color: "#626262 !important",
    fontStyle: "normal"
})

const TablaEstilo       = styled('table')({
    width: "100%",
    margin:"10px 0px"
})
const ColumnaTabla      = styled('tr')({
    "& td": { color: "#626262 !important"},
    "& p": { margin: "0px !important" },

})
const CeldaTabla        = styled('td')({
    fontSize: "12px",
    fontFamily: "semibold"
})
const EncabezadoTabla   = styled('th')({
    fontSize: "12px",
    fontFamily: "semibold"
})

const Itemce = styled('p')({
    background: "#EBEBEB",
    width: "fit-content",
    padding: "5px 10px",
    margin: "5px",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "semibold" 
})

const BotonAtras = styled('div')({
    background: "#626262",
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    width: "33px",
    height: "25px",
    borderRadius: "8px",
    "& .arrow-letter":{
        color: "white !important",
        fontSize: "12px"
    }
})

const Tabla = ({encabezados = [], datos = []}) =>{
    return(<TablaEstilo>
        <ColumnaTabla>
            {encabezados.length > 0 ? encabezados.map((encabezado, i) => (
                <EncabezadoTabla sx={{textAlign: i == 0 ? "left": "center"}}>{encabezado}</EncabezadoTabla>
            )): null}
        </ColumnaTabla>
        {datos.length > 0 ? datos.map(columnas => {
            return(<ColumnaTabla>
                {columnas.length > 0 ? columnas.map((columna,i ) => (<CeldaTabla sx={{textAlign: i == 0 ? "left": "center"}}>
                    {columna}
                </CeldaTabla>)): null}
            </ColumnaTabla>)
        }): null}
    </TablaEstilo>)
}

const CheckBox = ({children}) => {
    const ComponentCheckbox = styled('input')({
        marginRight: "5px"
    })
    const LabelCheckbox = styled('p')({})

    return(<Box sx={{"& p": {fontSize: "12px", color: "#626262 !important"}}}>
        <LabelCheckbox>
            <ComponentCheckbox type="checkbox" value="second_checkbox"/>{children}
        </LabelCheckbox>
    </Box>)
}

const CampoPrecios = ({value=0, onChange, Moneda="MXN", SignoMoneda="$"}) => {
    return(<TextoCampos>
        <TextoPrecio>{Moneda}</TextoPrecio>
        <ContenedorSigno>
            <SignoPrecio>{SignoMoneda}</SignoPrecio>
            <InputPrecio value={value} onChange={onChange} placeholder="0" type="number" step="10.00" min="0"></InputPrecio>
        </ContenedorSigno>
    </TextoCampos>)
}

const Variante_Detalle = ({valores, go_to_variant, guardar_variante, id="", n_index=0}) => {
    const [sucursales_guardadas, setSucursalesGuardadas] = useState([])
    const [variante_form, setVariantForm] = useState({
        createAt: Date.now(),
        modificateAt: Date.now()
    })
    const variantesContext = useContext(VariantesContext);
    const { id_variante, grupo, modificar_variante  } = variantesContext;

    useEffect(()=>{
        let existen_valores = valores.filter(valores => valores.n_index == n_index)

        if(existen_valores?.length > 0){
            setVariantForm(existen_valores[0])
        }
    },[valores])

    const set_variables = (name="", value="") =>{
        guardar_variante(datos=>{
            if(datos?.datos_variantes?.length > 0){
                let datos_variantes = datos.datos_variantes
                let datos_filtrados = datos_variantes.filter(dato_variante =>dato_variante.n_index == n_index)
                let resto_datos = datos_variantes.filter(dato_variante =>dato_variante.n_index != n_index)
                
                if(datos_filtrados?.length > 0){
                    let result_filtrado = datos_filtrados[0]
                    return {
                        ...datos,
                        datos_variantes: [
                            ...resto_datos,
                            {
                                ...result_filtrado,
                                [name]: value
                            }
                        ]
                    }
                }else{
                    return {
                        ...datos,
                        datos_variantes: [
                            ...resto_datos,
                            {
                                n_index: n_index,
                                [name]: value
                            }
                        ]
                    }
                }
            }else{
                return {
                    ...datos,
                    datos_variantes: [{
                        n_index: n_index,
                        [name]: value
                    }]
                }
            }

            return datos
        })
        if(name){
            setVariantForm({
                ...variante_form,
                [name]: value
            })
        }
    }

    return(<Box>
        <Row>
            <Col w={304}>
                <Titulo texto={id == "" ? `Nueva variante - ${id_variante}`: id_variante} go_to_variant={go_to_variant}/>
                <Estadisticas/>
            </Col>
            <Col w={642}>
                <Botones guardar_variante={()=>{
                    if(id == ""){
                        console.log(valores)
                        console.log(variante_form)
                    }else{
                        modificar_variante(variante_form._id, variante_form)
                        go_to_variant(true)
                    }
                }} go_to_variant={go_to_variant} mt="0px"/>
                <General variantes={grupo}/>
                <Multimedia/>
                <Precios
                    n_variant_price={variante_form.n_variant_price}
                    n_variant_compare_price={variante_form.n_variant_compare_price}
                    
                    set_n_variant_price={e=>set_variables("n_variant_price", e.target.value)}
                    set_n_variant_compare_price={e=>set_variables("n_variant_compare_price", e.target.value)}
                />
                <IdeEsp
                    s_variant_sku={variante_form.s_variant_sku}
                    s_variant_barcode={variante_form.s_variant_barcode}

                    set_s_variant_sku={e=>set_variables("s_variant_sku", e.target.value)}
                    set_s_variant_barcode={e=>set_variables("s_variant_barcode", e.target.value)}
                />
                <Sucursales
                    onChange={setVariantForm}
                    lista={sucursales_guardadas}
                    form={variante_form}
                    sucursales_guardadas={sucursales_guardadas}
                    setSucursalesGuardadas={datos=>{
                        console.log(datos)
                        setSucursalesGuardadas(datos)
                    }}
                />
                <Envios/>
                <Adicionales
                    datos={[
                        {contenido: ["Text 1"]},
                        {contenido: ["Text 2"]}
                    ]}
                />

                {id == "" ? null:(<Botones guardar_variante={()=>{
                    guardar_variante(variante_form)
                }} go_to_variant={go_to_variant}mt="0px"/>)}
            </Col>

        </Row>
    </Box>)
}

export default Variante_Detalle;
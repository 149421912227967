import React, { useReducer } from 'react';
import conversationsReducer from './conversationsReducer';
import conversationsContext  from './conversationsContext';
import clientAxios from '../../config/axios';
import { 
    GUARDAR_CONVERSACIONES,
    SELECCIONAR_CONTACTO,
    ENVIAR_MENSAJE,
    OBTENER_CONVERSACIONES,
    ULTIMA_CONVERSACION,
    GUARDAR_MENSAJE
} from '../../types'

const ConversationsState = props => {
    const initialState = {
        contactSelected: null,
        conversations:[],
        contacts: [],
        lastConversation: null,
        message: null
    }
    const [state, dispatch] = useReducer(conversationsReducer, initialState)
    
    const obtenerConversaciones = async number => {
        try{
            const res = await clientAxios.post('/api/conversations/getMessages', {number: number})
            let data = res.data.conversations
            dispatch({
                type: OBTENER_CONVERSACIONES, 
                payload: data
            })

            let lengthData = data.length

            data.map((msg, i) => {
                if(lengthData == (i+1)){
                    dispatch({
                        type: ULTIMA_CONVERSACION, 
                        payload: msg.createAt
                    })
                }
            })
        } catch(err){
            if(err.code === 401){
                console.log('refresh')
            }
            console.log(err)
        }
    }

    const sendMsg = async message => {
        console.log(state.contactSelected.number)
        try{
            if(message){
                dispatch({
                    type: ENVIAR_MENSAJE,
                    payload: message
                })

                const res = await clientAxios.post('/api/conversations/send', {
                    to: state.contactSelected.number,
                    text: message
                })


                if(!res.err){
                    let newMsg = res.data

                    if(newMsg.success){
                        dispatch({
                            type: GUARDAR_MENSAJE,
                            payload: await newMsg.data
                        })
                    }
                    return true
                }
            }else{
                return {
                    success: false,
                    msg: 'Falta adjuntar mensaje.'
                }
            }
        }catch(error){
            if(error.code === 401){
                console.log('refresh')
                console.log(error)
            }
        }
    }

    const selectContact = async contactSelect => {
        await obtenerConversaciones(contactSelect.number)
        try{
            dispatch({
                type: SELECCIONAR_CONTACTO,
                payload: contactSelect
            })
            return{
                id: contactSelect._id,
                success: 'Contacto seleccionado'
            }
        }catch(err){
            console.log(err)
        }
    }

    
    return(
        <conversationsContext.Provider value={{
            contacts: state.contacts,
            conversations: state.conversations,
            contactSelected: state.contactSelected,
            lastConversation: state.ConversationsState,
            selectContact,
            obtenerConversaciones,
            sendMsg
            // guardarCliente,
            // obtenerClientes
        }}>
            {props.children}
        </conversationsContext.Provider>
    )
}

export default ConversationsState;

import { Close, DeleteForever } from "@mui/icons-material";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material"
import React, { useState } from "react";
import { useEffect } from "react";

const Variantes = ({setForm, form}) => {
    const [variantes, setVariantes] = useState([])
    const [view, setView] = useState(false)

    useEffect(()=>{
        console.log(view)
    },[view])

    return(<Box>
        {variantes.length > 0 ? (<Grid container sx={{width: "100%"}}>
            {variantes.map((variante, i) => {
                return(<Grid xs={12} sx={{"& input":{color: "black !important"}, display: "flex", marginTop: i == 0 ? "0px": "20px"}} item key={i}>
                    <Box>
                        <TextField label="Nombre de la opción" size="small" id={`s_variant_option_${i+1}_value`} sx={{width: "100%"}} onChange={e => {
                            let variantes_ = variantes.filter((v,i_) => i_ != i);
                            setVariantes([
                                ...variantes_,
                                {
                                    ...variante,
                                    [e.target.id]: e.target.value
                                }
                            ])
                            setForm({
                                ...form,
                                [e.target.id]: e.target.value
                            })
                        }}/>
                        <TextField label="Valor" size="small" id={`s_variant_option_${i+1}_name`}   sx={{width: "100%", marginTop: "10px "}} onChange={e => {
                            let variantes_ = variantes.filter((v,i_) => i_ != i);
                            setVariantes([
                                ...variantes_,
                                {
                                    ...variante,
                                    [e.target.id]: e.target.value
                                }
                            ])
                            setForm({
                                ...form,
                                [e.target.id]: e.target.value
                            })
                        }}/>
                        <Divider sx={{marginTop: "20px"}}/>
                    </Box>
                    <Close onClick={()=>{
                        let eliminarVariante = variantes.filter((v, i_) => i_ != i);
                        setVariantes(eliminarVariante)
                    }} sx={{cursor: "pointer", color: "#9e9e9e !important", marginLeft: "20px"}}/>
                </Grid>)
            })}
        </Grid>): null}
        <Box sx={{marginTop: "20px", display: "grid", justifyContent: "end"}}>
            <Typography 
                onClick={()=> {setVariantes([...variantes,{ name: "", value: "" }])}}
                sx={{ cursor: "pointer", color: "#1976d2 !important"}}
            >+ Agregar Variante</Typography>
        </Box>
    </Box>)
}

export default Variantes;
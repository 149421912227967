import React, { useReducer } from 'react';
import contactsReducer from './contactsReducer';
import ContactsContext  from './contactsContext';
import clientAxios from '../../config/axios';
import { 
    GUARDAR_CONTACTOS,
    OBTENER_CONTACTO_POR_NOMBRE,
    OBTENER_CONTACTOS
} from '../../types'

const ContactsState = props => {
    const initialState = {
        contactos: []
    }
    const [state, dispatch] = useReducer(contactsReducer, initialState)

    const obtenerContactos = async () => {
        try{
            const res = await clientAxios.get('/api/contacts')
            dispatch({
                type: OBTENER_CONTACTOS, 
                payload: res.data.contacts
            })
        } catch(err){
            console.log(err)
        }
    }

    const obtenerContactoPreliminar = async name => {
        try{
            const res = await clientAxios.get('/api/contacts/byName', {name: name})
            if(res?.data?.success){
                dispatch({
                    type: OBTENER_CONTACTO_POR_NOMBRE,
                    payload: res?.data?.contacts ? res.data.contacts: []
                })
            }
        } catch(err){
            console.log(err)
        }
    }

    const guardarContacto = async (contacto, id = null) => {
        try{
            if(id){ contacto.phoneId = id }
            const res = await clientAxios.post('/api/contacts', contacto)

            if(res?.data?.contacto){
                dispatch({
                    type: GUARDAR_CONTACTOS, 
                    payload: await res.data.contacto
    
                })
    
                return res.data.contacto
            }

            return null
        } catch(err){
            console.log(err)
        }
    }
    
    return(
        <ContactsContext.Provider value={{
            contactos: state.contactos,
            obtenerContactoPreliminar,
            guardarContacto,
            obtenerContactos
        }}>
            {props.children}
        </ContactsContext.Provider>
    )
}

export default ContactsState;

import { Box, Grid, Typography } from "@mui/material"
import { 
    Chart as ChartJS, 
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title, 
    Tooltip, 
    Legend 
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";

const Estadisticas = () => {
    ChartJS.register(ArcElement, Tooltip, Legend);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const dataDoughnut = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
          {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
    };

    const options = {
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Horizontal Bar Chart',
          },
        },
      };
      
      const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
      
    const dataBar = {
        labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: [12,20,25,38,91,66,41],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Dataset 2',
            data: [0,10,35,18,41,96,11],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };

    return(<Box>
        <Grid container>
            <Grid item xs={4}>
                <Doughnut data={dataDoughnut} />
            </Grid>
            <Grid item xs={4}>
                <Bar options={options} data={dataBar}/>
            </Grid>
            <Grid item xs={4}>
            </Grid>
        </Grid>
    </Box>)
}

export default Estadisticas;
import { OBTENER_LOGS } from "../../types"

export default (state, action) => {
    switch(action.type){
        case OBTENER_LOGS:
            return {
                ...state,
                logs: action.payload
            }
        default:
            return;
    }
}
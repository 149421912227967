import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from './components/auth/Login';
import NewAccount from './components/auth/NewAccount';
import Admin from './components'

import AlertState from './context/alerts/alertState';
import AuthState from './context/authentication/authState';

import ModuleState from './context/modules/moduleState'
import SectionState from './context/sections/sectionState';

import tokenAuth from './config/tokenAuth';
// validar sesion con ruta privada
import RoutePrivate from './components/routes/routePrivate';
import SettingsState from './context/settings/settingsState';

import NewUserState from './context/newUsers/newUserState';
import UsersState from './context/users/usersState';
import TemplateState from './context/templates/templateState';

import FormatoState from './context/formatos/formatoState';
import ZonaState from './context/zonas/zonaState';

import ItemsState from './context/items/itemsState';
import PropertyState from './context/properties/propertyState';
import FormItemsState from './context/formItems/formItemsState';
import ClientsState from './context/clients/clientsState';
import FormsState from './context/forms/formsState';
import VariantesState from './context/variantes/variantesState';
import ProductosState from './context/productos/productosState';

import ConversationsState from './context/conversations/conversationsState';

import PhonesState from './context/phones/phonesState';
import ContactsState from './context/contacts/contactsState';
import StylesState from './context/styles/stylesState';
import InventarioState from './context/inventario/inventarioState';
import { ThreeCircles } from 'react-loader-spinner';
import { Box } from '@mui/material';
import AuthContext from './context/authentication/authContext';
import { useContext } from 'react';
import EventosState from './context/eventos/eventosStates';
import RutasState from './context/rutas/rutasState';
import SucursalesState from './context/sucursales/sucursalesStates';
import LoadingStates from './context/loading/loadingStates';
import SideleftStates from './context/sideleft/sideleftStates';
import ColeccionesState from './context/colecciones/coleccionesState';
import { Logs } from './components/calzadogama/Logs';
import LogsState from './context/logs/logsStates';
import PreliminaresState from './context/preliminares/preliminaresState';
import MercadosState from './context/mercados/mercadosStates';

const token = localStorage.getItem('token');
// const route = localStorage.getItem('id_route');
if(token){ tokenAuth(token) }

const Error404 = ({...props}) => {
  const authContext = useContext(AuthContext);
  const { validarSesion, validacionToken } = authContext;

  useEffect(()=>{
    validarSesion();
  },[])

  useEffect(() =>{
    if(validacionToken > -1){
      const route = localStorage.getItem('route');
      setTimeout(() => {window.location.href = `/business/${validacionToken ? route ? route: "testing": "login"}`}, 1000);
    }else{
      setTimeout(() => {window.location.href = `/login`}, 1000);
    }
  },[validacionToken])

  return(<Box sx={{
    display: 'grid',
    justifyItems: 'center',
    alignContent: "center",
    textAlign: 'center',
    height: "100vh"
  }}>
    <ThreeCircles
      height="250"
      width="250"
      color="#0b0f19"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="three-circles-rotating"
      outerCircleColor=""
      innerCircleColor=""
      middleCircleColor=""
    />
  </Box>)
}

function App() {
  return(<ModuleState>
    <AuthState>
      <RutasState>
        <MercadosState>
          <LogsState>
            <SideleftStates>
              <LoadingStates>
                <PreliminaresState>
                  <SucursalesState>
                    <ProductosState>
                      <VariantesState>
                        <StylesState>
                          <SectionState>
                            <AlertState>
                              <NewUserState>
                                <UsersState>
                                  <ZonaState>
                                    <FormatoState>
                                      <Router>
                                        <Switch>
                                          <SettingsState>
                                            <TemplateState>
                                              <ItemsState>
                                                <FormItemsState>
                                                  <PropertyState>
                                                    <ClientsState>
                                                      <PhonesState>
                                                        <InventarioState>
                                                          <ColeccionesState>
                                                            <ConversationsState>
                                                              <ContactsState>
                                                                <FormsState>
                                                                  <EventosState>
                                                                    <Switch>
                                                                      <Route exact path={`/login`} component={Login}/>
                                                                      <Route exact path={`/new-account`} component={NewAccount}/>
                                                                      <RoutePrivate path={`/business/:business/:section/:subsection`} component={Admin}/>
                                                                      <RoutePrivate path={`/business/`} component={Admin}/>
                                                                      <RoutePrivate path={`/crons/calzadogama/logs`} component={Logs}/>
                                                                      <RoutePrivate path="*" component={Error404}/>
                                                                    </Switch>
                                                                  </EventosState>
                                                                </FormsState>
                                                              </ContactsState>
                                                            </ConversationsState>
                                                          </ColeccionesState>
                                                        </InventarioState>
                                                      </PhonesState>
                                                    </ClientsState>
                                                  </PropertyState>
                                                </FormItemsState>
                                              </ItemsState>
                                            </TemplateState>
                                          </SettingsState>
                                        </Switch>
                                      </Router>
                                    </FormatoState>
                                  </ZonaState>
                                </UsersState>
                              </NewUserState>
                            </AlertState>
                          </SectionState>
                        </StylesState>
                      </VariantesState>
                    </ProductosState>
                  </SucursalesState>
                </PreliminaresState>
              </LoadingStates>
            </SideleftStates>
          </LogsState>
        </MercadosState>
      </RutasState>
    </AuthState>
  </ModuleState>);
}

export default App;

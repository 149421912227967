import { 
    GUARDAR_EVENTO,
    EJECUTAR_EVENTO
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case GUARDAR_EVENTO:
            return {
                ...state,
                agregar: action.payload
            }
        case EJECUTAR_EVENTO:
            return;
        default:
            return;
    }
}
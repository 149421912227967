import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BotonJCs } from "../material";
import { ArrowBackIosNew, DragIndicator, Favorite, FavoriteBorder } from "@mui/icons-material";
import moduleContext from "../../context/modules/moduleContext";
import { VariantesContext } from "../../context";

const ID_PRODUCTOS_DEV = "65468dba8700459b6f0760a4"

const General = ({variantes=[]}) => {
    return(<Item>
        <Texto>General</Texto>
        {variantes.map(variante => {
            return(<Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>{variante.categoria}</Texto>
                    <Campo value={variante.value}/>
                </Box>
            </Box>)
        })}
    </Item>)
}

const Multimedia = () => {
    return(<Item>
        <Texto>Multimedia</Texto>
        <AreaFile>
            <Box style={{display: "flex"}}>
                <Boton>Subir</Boton>
                <Boton>Subir desde URL</Boton>
            </Box>
        </AreaFile>
        <Comentario>
            Puedes subir imágenes, videos o modelos 3D de realidad aumentada,
            asi como formatos PNG, JPG o GIF. Te recomendamos utilizar 
            formato multimedia cuadrado.
        </Comentario>
    </Item>)
}

const Precios = () => {
    return(<Item>
        <Texto>Precios, costos y márgenes</Texto>
        <Row sx={{justifyContent: "start", marginBottom: "5px"}}>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Precio de venta</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Precio comparativo o promocional</Texto>
                <CampoPrecios/>
            </Col>
        </Row>
        <Row sx={{justifyContent: "start", marginBottom: "15px", "& option": {color: "#626262 !important"}}}>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Impuestos</Texto>
                <Dropdown sx={{background: "#EBEBEB"}}>
                    <Opcion>Cobrar</Opcion>
                </Dropdown>
            </Col>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Tasa</Texto>
                <Dropdown>
                    <Opcion>Ver mercados</Opcion>
                </Dropdown>
            </Col>
        </Row>
        <Row sx={{justifyContent: "start", marginTop: "30px"}}>
            <Col w={220}>
                <Texto sx={{fontFamily:"semibold"}}>Costo del producto sin impuestos</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Ganancia bruta</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={90}>
                <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                <Campo/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                <CampoPrecios/>
            </Col>
        </Row>
        <Row sx={{justifyContent: "start"}}>
            <Col w={220}>
                <Texto sx={{fontFamily:"semibold"}}>Costo del producto sin impuestos</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Ganancia bruta</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={90}>
                <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                <Campo/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                <CampoPrecios/>
            </Col>
        </Row>
        <Row sx={{justifyContent: "start", marginBottom: "15px"}}>
            <Col w={220}>
                <Texto sx={{fontFamily:"semibold"}}>Costo del producto sin impuestos</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Ganancia bruta</Texto>
                <CampoPrecios/>
            </Col>
            <Col w={90}>
                <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                <Campo/>
            </Col>
            <Col w={150}>
                <Texto sx={{fontFamily:"semibold"}}>Margén bruto</Texto>
                <CampoPrecios/>
            </Col>
        </Row>
    </Item>)
}

const Estado = () => {
    return(<Item>
        <Texto>Estado</Texto>
        <Seleccionar name="pets" id="pet-select">
            <option>Activo</option>
            <option>Borrador</option>
        </Seleccionar>
    </Item>)
}

const Canales = () => {
    const [lista, setLista] = useState([
        "Sitio Web",
        "Punto de ventas",
        "Facebook",
        "Instagram",
        "Google",
        "Youtube",
        "Pinterest",
        "TikTok",
        "LinkedIn",
        "Twitter",
        "Twitch",
        "Threads",
        "Amazon",
        "Mercado Libre",
        "Linio",
        "Wish",
        "Claro Shop",
        "Walmart",
        "Elektra",
        "Liverpool",
        "Coppel",
        "Sears",
        "Otros"
    ])
    return(<Item>
        <Texto>Canales</Texto>
        {/* <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
            >
                {lista.map(item => {
                    return(<FormControlLabel 
                        value={item}
                        control={<Radio />}
                        label={item}
                    />)
                })}
            </RadioGroup>
        </FormControl> */}
        {lista.map(item => {
            return(<Checkbox {...item} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />)
        })}
    </Item>)
}

const Mercados = () => {
    const [lista, setLista] = useState([
        "México",
        "Barbados",
        "Belice",
        "Colombia",
        "Costa Rica",
        "Ecuador",
        "El Salvador",
        "Granada",
        "Guatemala",
        "Guyana",
        "Haití",
        "Honduras",
        "Islas Británicas",
        "Islas Caimán",
        "Islas del Mar Caribe",
        "Islas Francesas",
        "Jamaica",
        "Nicaragua",
        "Panamá",
        "Perú",
        "Puerto Rico",
        "República Dominicana",
        "Surinam",
        "Trinidad y Tobago",
        "Venezuela"
    ])
    return(<Item>
        <Texto>Mercados</Texto>
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
            >
                {lista.map(item => {
                    return(<FormControlLabel 
                        value={item}
                        control={<Radio />}
                        label={item}
                    />)
                })}
            </RadioGroup>
        </FormControl>
    </Item>)
}

const Estadisticas = () => {
    return(<Item>
        <Texto>Estadísticas</Texto>
        <Texto family="semibold">
            El resumen estadístico se mostrará cuando el
            producto comience a tener ventas
        </Texto>
    </Item>)
}

const IdeEsp = () => {
    return(<Item>
        <Texto>Identificación y especificaciones</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>SKU</Texto>
            <Campo placeholder=""/>
        </Box>

        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Código de barras (UPC, EAN, GTIN, MPN, ISBN, etc)</Texto>
            <Campo placeholder=""/>
        </Box>

        <Texto sx={{marginTop: "35px"}}>Especificaciones</Texto>
        <CheckBox>Este es un producto físico o artículo</CheckBox>

        <Row sx={{justifyContent: "start", marginBottom: "5px"}}>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Peso unitario</Texto>
                <CampoPrecios Moneda="kg" SignoMoneda=""/>
            </Col>
            <Col w={210}>
                <Texto sx={{fontFamily:"semibold"}}>Peso con embalaje</Texto>
                <CampoPrecios Moneda="kg" SignoMoneda=""/>
            </Col>
        </Row>
        <Comentario>
            El peso unitario es el peso del producto físico o artículo y el peso con embalaje 
            es el peso del producto que se toma en la paquetería sumando el embalaje.
        </Comentario>

        <Texto sx={{marginTop: "35px"}}>Información aduanal</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>País o región de origen</Texto>
            <Campo placeholder=""/>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Código de sistema armonizado (SA)</Texto>
            <Campo placeholder=""/>
        </Box>
        <CheckBox>Requieres de un agente aduanal para este producto físico o artículo</CheckBox>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Agente aduanal</Texto>
            <Campo placeholder=""/>
        </Box>
    </Item>)
}

const Sucursales = () => {
    return(<Item>
        <Texto>Sucursales e inventario</Texto>
        <Tabla
            encabezados={["Nombre", "Almacenar", "Rastrear", "Existencias"]} 
            datos={[
                ["Nombre de la sucursal no. 1", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Nombre de la sucursal no. 2", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Nombre de la sucursal no. 3", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Nombre de la sucursal no. 4", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Nombre de la sucursal no. 5", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Nombre de la sucursal no. 6", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Nombre de la sucursal no. 7", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Nombre de la sucursal no. 8", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Nombre de la sucursal no. 9", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Nombre de la sucursal no. 10", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Nombre de la sucursal no. 11", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Nombre de la sucursal no. 12", (<CheckBox/>), (<CheckBox/>), "No rastreado"],
                ["Total", "", "", "No rastreado"]
            ]}
        />

        <Boton sx={{marginBottom: "15px"}}>+ Agregar sucursal</Boton>
    </Item>)
}

const Envios = () => {
    return(<Item>
        <Texto>Envíos</Texto>
        <CheckBox>Este producto físico o artículo tiene envío gratis</CheckBox>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto>Embalajes</Texto>
            <Dropdown>
                <Opcion>Nombre del embalaje guardado</Opcion>
            </Dropdown>
        </Box>
    </Item>)
}

const CatOrg = () => {
    return(<Item>
        <Texto>Categorización y organización</Texto>
        <Row sx={{justifyContent: "start", marginBottom: "5px"}}>
            <Col w={260}>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Nodo</Texto>
                    <Campo placeholder="Nombre del Nodo"/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Tipo de producto</Texto>
                    <Campo placeholder="Nombre del tipo de producto"/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Proveedor principal</Texto>
                    <Campo placeholder="Nombre del proveedor principal" sx={{background: "#EBEBEB"}}/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Fabricante</Texto>
                    <Campo placeholder="Nombre del Fabricante"/>
                </Box>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Marca</Texto>
                    <Campo placeholder="Nombre de la Marca"/>
                </Box>
            </Col>
            <Col w={160}/>
        </Row>
    </Item>)
}

const ColeccionesEt = ({colecciones = [], etiquetas = []}) => {
    return(<Item>
        <Texto>Colecciones y etiquetas</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Row>
                <Col w={250}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Colecciones</Texto>
                    <Campo placeholder=""/>
                </Col>
                <Col w={320}>
                    <Box sx={{display: "flex", flexWrap: "wrap"}}>
                        {colecciones.map(coleccion => {
                            return(<Itemce>{coleccion}</Itemce>)
                        })}
                    </Box>
                </Col>
            </Row>
            <Row sx={{marginTop: "25px"}}>
                <Col w={250}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Etiquetas</Texto>
                    <Campo placeholder=""/>
                </Col>
                <Col w={320}>
                    <Box sx={{display: "flex"}}>
                        {etiquetas.map(etiqueta => {return(<Itemce>{etiqueta}</Itemce>)})}
                    </Box>
                </Col>
            </Row>
        </Box>
    </Item>)
}

const Motoresdebusqueda = () => {
    return(<Item>
        <Texto sx={{marginBottom: "0px !important"}}>Motores de búsqueda</Texto>
        <Comentario sx={{margintop: "0px !important"}}>Aumenta la relevancia de tus productos en los motores de búsqueda agregando una descripción.</Comentario>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Título de la página</Texto>
            <Campo placeholder="Nombre del producto"/>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Meta descripción</Texto>
            <TextArea rows="8" cols="50" placeholder="Descripción del producto"/>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Identificador de URL</Texto>
            <Campo placeholder=""/>
        </Box>
    </Item>)
}

const Marketing = () => {
    const [rangos, setRangos] = useState([18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,65])

    return(<Item>
        <Texto>Marketing</Texto>
        <Comentario sx={{margintop: "0px !important"}}>Define la segmentación de marketing de tu producto.</Comentario>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Edad</Texto>
            <Row sx={{width: "30%"}}>    
                <Seleccionar sx={{borderRadius: "8px 0px 0px 8px"}}>
                    {rangos.map((edad,i) => (<option>{edad}</option>))}
                </Seleccionar>
                <Seleccionar sx={{borderRadius: "0px 8px 8px 0px"}}>
                    {rangos.map((edad,i) => (<option selected={i==rangos.length-1? true: false}>{edad}</option>))}
                </Seleccionar>
            </Row>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Sexo</Texto>
            <Row sx={{width: "30%"}}>    
                <Seleccionar>
                    <option>Masculino</option>
                    <option>Femenino</option>
                </Seleccionar>
            </Row>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Segmentación detallada</Texto>
            <Campo placeholder="Agregar datos demográficos, intereses o comportamientos"/>
        </Box>
    </Item>)
}

const Plantilla = () => {
    return(<Item>
        <Texto>Plantilla del tema</Texto>
        <Seleccionar name="pets" id="pet-select">
            <option>Plantilla de producto predeterminado</option>
        </Seleccionar>
    </Item>)
}

const Adicionales = ({datos = []}) => {
    return(<Item>
        <Texto>Campos Adicionales</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            {datos.map(dato =>{
                return(<Row sx={{marginTop: "25px"}}>
                    <Col w={250}>
                        <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>
                            {dato?.titulo ? dato.titulo: "Nombre del campo Adicional"}
                        </Texto>
                    </Col>
                    <Col w={320}>
                        <Box>
                            {dato?.contenido?.length > 0 ? dato.contenido.map((campo, i) => (<Box>
                                <Campo sx={{marginTop: i != 0 ? "15px": "0px"}} value={campo}/>
                            </Box>)): null}
                            <Box sx={{textAlign: "right"}}><BotonAdd>+ Agregar</BotonAdd></Box>
                        </Box>
                    </Col>
                </Row>)
            })}
        </Box>
    </Item>)
}

const Botones = ({mt = "25px"}) => {
    const modulesContext = useContext(moduleContext);
    const { moduloActual, module } = modulesContext;
    const seleccionarModulo = async id => moduloActual(id);

    return(<Box sx={{
        display: "flex",
        justifyContent: "end",
        alignContent: "center",
        alignItems: "center",
        marginTop: mt,
        // maxHeight: "40px",
        minHeight: "40px",
    }}>
        <BotonJCs onClick={()=>{
            seleccionarModulo(ID_PRODUCTOS_DEV)
        }} sx={{display: "grid", alignContent: "center", marginRight: "5px", height:"25px"}}>Cancelar</BotonJCs>
        <BotonJCs sx={{display: "grid", alignContent: "center", height:"25px"}}>Guardar</BotonJCs>
    </Box>)
}

const Titulo = ({texto = "Nuevo Producto"}) => {
    const modulesContext = useContext(moduleContext);
    const { moduloActual, module } = modulesContext;
    const seleccionarModulo = async id => moduloActual(id);

    return(<Box sx={{
        display: "flex",
        justifyContent: "start",
        alignContent: "center",
        maxHeight: "40px",
        minHeight: "40px"
    }}>
        <BotonAtras
            sx={{alignSelf: "center", cursor: "pointer"}}
            onClick={()=>{seleccionarModulo(ID_PRODUCTOS_DEV)}}
        ><ArrowBackIosNew className="arrow-letter"/></BotonAtras>
        <Box sx={{
            position: "relative",
            display: "grid",
            alignItems: "center",
        }}>
            <Texto sx={{
                marginLeft: "15px",
                fontSize: "20px",
                fontFamily: "mulish-extra-bold",
                width: "max-content",
                position: "absolute",
                margin: "0px",
                left: "15px"
            }}>{texto}</Texto>
        </Box>
    </Box>)
}

const Variantes = ({}) => {
    const [variantes, setVariantes] = useState([{
        tipo: "color",
        valores: [
            "Rojo", "Azul"
        ]
    }])

    useEffect(()=>{
        console.log("lekjslkdslkdmekldms")
    },[])

    return(<Item>
        <Texto>Variantes</Texto>
        {variantes.length > 0 ? variantes.map(variante => {
            return(<Grid container sx={{marginBottom: "10px"}}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={1} sx={{
                            display: "grid",
                            alignContent: "center"
                        }}><DragIndicator/></Grid>
                        <Grid item xs={8}>
                            <Texto>{variante?.tipo ? variante.tipo: null}</Texto>
                        </Grid>
                        <Grid item xs={3} sx={{textAlign: "end"}}><BotonJCs>Editar</BotonJCs></Grid>
                    </Grid>
                </Grid>
            </Grid>)
        }): (<Box sx={{"& p": {color: "#626262 !important"}, display: "grid", alignContent: "center", justifyContent: "center", margin: "20px 0px"}}>
            <Texto>Sin variantes</Texto>
        </Box>)}
        
    </Item>)
}

const Variante_Detalle = ({nuevo = false, variantes=[]}) => {
    const variantesContext = useContext(VariantesContext);
    const { id_variante, grupo } = variantesContext;

    useEffect(()=>{
        console.log(variantes)
        console.log(nuevo, grupo)
    },[])

    return(<Box>
        <Row>
            <Col w={304}>
                <Titulo texto={nuevo ? "Nuevo Producto": id_variante}/>
                <Estadisticas/>
            </Col>
            <Col w={642}>
                <Botones mt="0px"/>
                <General variantes={grupo}/>
                <Multimedia/>
                <Precios/>
                <IdeEsp/>
                <Sucursales/>
                <Envios/>
                <Adicionales
                    datos={[
                        {contenido: ["Text 1"]},
                        {contenido: ["Text 2"]}
                    ]}
                />
                <Botones/>
            </Col>

        </Row>
    </Box>)
}

export default Variante_Detalle;

const BotonAdd = styled('button')({
    background: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#08306B",
    fontFamily: "semibold",
    fontSize: "12px"
})

const Row = styled(Box)({
    display: "flex", 
    justifyContent: "center"
})

const Campo = styled("input")({
    display: "flex", 
    justifyContent: "center",
    width: "100%",
    borderRadius: "8px",
    height: "28px",
    border: "1px solid #DAD9D5",
    fontSize: "12px",
    padding: "5px 10px"
})

const Dropdown = styled("select")({
    width: "100%",
    borderRadius: "8px",
    fontSize: "12px",
    padding: "8px",
    color: "#626262 !important",
    border: "1px solid #DAD9D5"
})

const Opcion = styled("option")({
    color: "#626262 !important"
})

const Item = styled(Box)({
    background: "white", 
    padding: "2px 15px !important", 
    borderRadius: "8px",
    marginTop: "15px",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.1) !important",
    "& span":{
        fontSize: "12px !important",
        fontFamily: "semibold"
    },
    "& svg": {
        width: "15px !important"
    },
    "& label": {
        height: "25px"
    }
})

const Col = styled(Box)(props=>({
    width: props?.w ? props.w : 100, 
    marginRight: 20
}))

const Texto = styled('p')(props => ({
    fontSize: 12,
    fontFamily: props?.family ? props.family: "mulish-bold"
}))

const TextArea = styled('textarea')(props => ({
    width: "100%",
    border: "1px solid #DAD9D5",
    fontSize: "12px",
    padding: "5px 10px",
    borderRadius: "0px 0px 8px 8px"
}))

const Seleccionar = styled('select')({
    width: "100%",
    marginBottom: "10px",
    border: "1px solid #DAD9D5",
    padding: "5px 10px",
    borderRadius: "8px",
    fontSize: "12px"
})

const AreaFile = styled('div')({
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    padding: "50px 0px",
    borderRadius: "8px",
    border: "1px dashed #DAD9D5"
})

const Boton = styled('button')({
    background: "#EBEBEB !important",
    border: "none",
    cursor: "pointer",
    margin: "5px",
    borderRadius: "8px",
    padding: "8px 15px",
    fontSize: "12px",
    fontFamily: "semibold"
})

const Comentario = styled("p")({
    marginBottom: "15px",
    fontSize: "12px",
    color: "#626262 !important"
})

const TextoCampos = styled('div')({
    display: "flex",
    "& p, input": {
        color: "#626262 !important"
    }
})

const ContenedorSigno = styled('div')({
    position: "relative"
})

const TextoPrecio = styled('p')({
    margin: "0px !important",
    padding: "0px 10px !important",
    paddingTop: "2px !important",
    height: "28px",
    background: "#EBEBEB",
    borderRadius: "8px 0px 0px 8px",
    border: "1px solid #DAD9D5"
})

const InputPrecio = styled('input')({
    width: "90%",
    fontSize: "12px",
    padding: "0px 10px",
    borderRadius: "0px 8px 8px 0px",
    height: "28px",
    border: "1px solid #DAD9D5",
    paddingLeft: "20px",
    paddingRight: 0
})

const SignoPrecio = styled('i')({
    position: "absolute",
    display: "block",
    transform: "translate(0, -55%)",
    top: "50%",
    pointerEvents: "none",
    width: "28px",
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "semibold",
    color: "#626262 !important",
    fontStyle: "normal"
})

const TablaEstilo       = styled('table')({
    width: "100%",
    margin:"10px 0px"
})
const ColumnaTabla      = styled('tr')({
    "& td": { color: "#626262 !important"},
    "& p": { margin: "0px !important" },

})
const CeldaTabla        = styled('td')({
    fontSize: "12px",
    fontFamily: "semibold"
})
const EncabezadoTabla   = styled('th')({
    fontSize: "12px",
    fontFamily: "semibold"
})

const Itemce = styled('p')({
    background: "#EBEBEB",
    width: "fit-content",
    padding: "5px 10px",
    margin: "5px",
    borderRadius: "8px",
    fontSize: "12px",
    fontFamily: "semibold" 
})

const BotonAtras = styled('div')({
    background: "#626262",
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    width: "33px",
    height: "25px",
    borderRadius: "8px",
    "& .arrow-letter":{
        color: "white !important",
        fontSize: "12px"
    }
})

const Tabla = ({encabezados = [], datos = []}) =>{
    return(<TablaEstilo>
        <ColumnaTabla>
            {encabezados.length > 0 ? encabezados.map((encabezado, i) => (
                <EncabezadoTabla sx={{textAlign: i == 0 ? "left": "center"}}>{encabezado}</EncabezadoTabla>
            )): null}
        </ColumnaTabla>
        {datos.length > 0 ? datos.map(columnas => {
            return(<ColumnaTabla>
                {columnas.length > 0 ? columnas.map((columna,i ) => (<CeldaTabla sx={{textAlign: i == 0 ? "left": "center"}}>
                    {columna}
                </CeldaTabla>)): null}
            </ColumnaTabla>)
        }): null}
    </TablaEstilo>)
}

const CheckBox = ({children}) => {
    const ComponentCheckbox = styled('input')({
        marginRight: "5px"
    })
    const LabelCheckbox = styled('p')({})

    return(<Box sx={{"& p": {fontSize: "12px", color: "#626262 !important"}}}>
        <LabelCheckbox>
            <ComponentCheckbox type="checkbox" value="second_checkbox"/>{children}
        </LabelCheckbox>
    </Box>)
}

const CampoPrecios = ({Moneda="MXN", SignoMoneda="$"}) => {
    return(<TextoCampos>
        <TextoPrecio>{Moneda}</TextoPrecio>
        <ContenedorSigno>
            <SignoPrecio>{SignoMoneda}</SignoPrecio>
            <InputPrecio placeholder="0" type="number" step="10.00" min="0"></InputPrecio>
        </ContenedorSigno>
    </TextoCampos>)
}
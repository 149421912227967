/* eslint-disable import/no-anonymous-default-export */
import { 
    GUARDAR_CONTACTOS,
    OBTENER_CONTACTO_POR_NOMBRE,
    OBTENER_CONTACTOS
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case OBTENER_CONTACTO_POR_NOMBRE:
        case OBTENER_CONTACTOS:
            return {
                ...state,
                contactos: action.payload
            }
        case GUARDAR_CONTACTOS:
            return {
                ...state,
                contactos: [
                    ...state.contactos,
                    action.payload
                ]
            }
        default:
            return state
    }
}
import { useContext, useEffect, useState } from "react";
import { Menu } from "."
import { LogsContext } from "../../context"
import * as React from 'react';
import { Box, Button, Grid, styled, Typography } from "@mui/material";
import DatatableGrid from "../../elementos/DatatableGrid";
import { Refresh } from "@mui/icons-material";

function BasicDatePicker(props) {
    let {
        sx={},
        label = "Default",
        onChange = () => console.log("on change!")
    } = props;
    const DatepickerL = styled('input')({
        marginTop: "15px",
        borderRadius: "4px",
        padding: "10px 2px",
        fontFamily: "'mulish-regular'",
        border: "1px solid #e0e0e0 !important",
        width: "100%"
    })
    return(<DatepickerL onChange={onChange} type="date"/>)
}

const Seleccionar = ({action}) => {
    return(<Box>
        <select onChange={action} style={{
            width: "100%",
            padding: "10px",
            outline: "none",
            border: "1px solid #e0e0e0 !important",
            borderRadius: "4px",
            fontFamily: "'semibold'",
            fontSize: "10px !important"
        }}>
            <option value={0}>TODOS</option>
            <option value={1}>PRODUCT SALE SHOPIFY</option>
            <option value={2}>SYNC CRONJOB SHOPIFY</option>
        </select>
    </Box>)
}

export const Logs = () => {
    const logsContext = useContext(LogsContext);
    const { logs, obtenerLogs } = logsContext;

    const [datalogs,    setDatalogs] = useState([])
    const [filtros,     setFiltros]  = useState([])
    const [start,       setStart]    = useState(0)
    const [end,         setEnd]      = useState(0)

    useEffect(()=>{
        obtenerLogs()
    },[])

    useEffect(()=>{
        if(logs?.length > 0){
            setDatalogs(logs.map(log=>{
                let fecha_str = new Date(log.d_date);
                const yyyy = fecha_str.getFullYear();
                let mm = fecha_str.getMonth() + 1; // Months start at 0!
                let dd = fecha_str.getDate();
                let hh = fecha_str.getHours()
                let min = fecha_str.getMinutes()
                let min_double = parseInt(min) < 10 ? `0${min}`: min


                return{
                    ...log,
                    d_date: `${dd}-${mm}-${yyyy}`,
                    s_hour: `${hh}:${min_double}`
                }
            }))
        }
    },[logs])

    return(<Menu>
        <Box sx={{
            borderRadius: "8px",
            height: "100vh !important",
            "& p": {
                fontSize: "12px !important"
            }
        }}>
            <Grid container>
                <Grid item xs={3}>        
                    <Box sx={{
                        margin: "30px 0px 0px 30px",
                        background: "white",
                        padding: "18px 25px",
                        borderRadius: "8px",
                        border: "1px solid #e0e0e0 !important"
                    }}>
                        {/* <CustomizedInputBase/> */}
                        <Typography variant="h5" sx={{marginBottom: "10px"}}>LOGS</Typography>
                        <Seleccionar action={e=> {
                            if(parseInt(e.target.value) == 1){
                                let filter_logs = logs.filter(log => log?.s_name == "PRODUCT SALE SHOPIFY")
                                setFiltros(filter_logs.map(log=>{
                                    let fecha_str = new Date(log.d_date);
                                    const yyyy = fecha_str.getFullYear();
                                    let mm = fecha_str.getMonth() + 1; // Months start at 0!
                                    let dd = fecha_str.getDate();
                                    let hh = fecha_str.getHours()
                                    let min = fecha_str.getMinutes()
                                    let min_double = parseInt(min) < 10 ? `0${min}`: min
                    
                                    return{
                                        ...log,
                                        d_date: `${dd}-${mm}-${yyyy}`,
                                        s_hour: `${hh}:${min_double}`

                                    }
                                }))
                            }else if(parseInt(e.target.value) == 2){
                                let filter_logs = logs.filter(log => log?.s_name == "SYNC CRONJOB SHOPIFY")
                                setFiltros(filter_logs.map(log=>{
                                    let fecha_str = new Date(log.d_date);
                                    const yyyy = fecha_str.getFullYear();
                                    let mm = fecha_str.getMonth() + 1; // Months start at 0!
                                    let dd = fecha_str.getDate();

                                    let hh = fecha_str.getHours()
                                    let min = fecha_str.getMinutes()
                                    let min_double = parseInt(min) < 10 ? `0${min}`: min
                    
                                    return{
                                        ...log,
                                        d_date: `${dd}-${mm}-${yyyy}`,
                                        s_hour: `${hh}:${min_double}`
                                    }
                                }))
                            }else{
                                setFiltros(logs.map(log=>{
                                    let fecha_str = new Date(log.d_date);
                                    const yyyy = fecha_str.getFullYear();
                                    let mm = fecha_str.getMonth() + 1; // Months start at 0!
                                    let dd = fecha_str.getDate();

                                    let hh = fecha_str.getHours()
                                    let min = fecha_str.getMinutes()
                                    let min_double = parseInt(min) < 10 ? `0${min}`: min
                    
                                    return{
                                        ...log,
                                        d_date: `${dd}-${mm}-${yyyy}`,
                                        s_hour: `${hh}:${min_double}`
                                    }
                                }))
                            }
                        }}/>
                        <BasicDatePicker onChange={e=>{
                            let date_picker = new Date(e.target.value)
                            console.log(date_picker.getTime())
                        }} label="Fecha inicial"/>
                        <BasicDatePicker onChange={e=>{
                            let date_picker = new Date(e.target.value)
                            console.log(date_picker.getTime())
                        }} sx={{ width: "100%" }} label="Fecha final"/>
                        <Box sx={{display: "flex"}}>
                            <Button
                                onClick={e=>{setDatalogs(filtros)}}
                                variant="contained"
                                fullWidth
                                sx={{marginTop: "15px"}}
                            >Filtrar</Button>
                            <Button
                                onClick={e=>{obtenerLogs()}}
                                variant="contained"
                                sx={{
                                    marginTop: "15px",
                                    maxWidth: "45px !important",
                                    minWidth: "45px !important",
                                    marginLeft: "10px"
                                }}
                            >
                                <Refresh/>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{
                        margin: "30px 30px 30px 30px",
                        borderRadius: "8px",
                        height: "calc(100vh - 60px) !important",
                        background: "white"
                    }}>
                        <DatatableGrid rows={datalogs.map((datalog, id_datalogs)=>{
                            return {
                                id: id_datalogs + 1,
                                ...datalog
                            }
                        })} columns={[
                            { field: 'id',            headerName: 'ID',           width: 50 },
                            { field: 's_method',      headerName: 'Metodo',       width: 60 },
                            { field: 's_url',         headerName: 'URL',          width: 50 },
                            { field: 'n_size',        headerName: 'Tamaño',       width: 90 },
                            { field: 's_description', headerName: 'Descripcion',  width: 390 },
                            { field: 'd_date',        headerName: 'Fecha',        width: 130 },
                            { field: 's_hour',        headerName: 'Hora',         width: 130 },
                        ]}/>                        
                    </Box>
                </Grid>
            </Grid>            
        </Box>
    </Menu>)
}
import React, { useReducer } from 'react';
import {RutasContext} from '../index';
import RutasReducers from './rutasReducer';
import clientAxios from '../../../src/config/axios'

import { 
    SELECCIONAR_RUTA
} from '../../types';


const RutasState = props => {
    const initialState = {
        ruta_seleccionada: 0,
        rutas: []
    }

    const [state, dispatch] = useReducer(RutasReducers, initialState)

    const getRoute = id => {
        console.log(state)
        switch(id){
            // case "0": return "testing"              // Check
            // case "1": return "lavoig_net"           // Check
            // case "2": return "maikai"               //`Little Nenes`,
            // case "3": return `hardwarecollection`   //`MAIKA'I`,
            // case "4": return `plusmotors`           //`Lavoig_net`,
            // case "5": return `calzadogama`          //`Hardware Collection`,
            // case "6": return `enjoycanvas`          //`Plus Motors`,
            // case "7": return `gemagic`              //`Calzado Gama`,
            // case "8": return `crimston`             //`Enjoy Canvas`,
            // case "9": return `giggling`             // Check
            // case "10": return `magnosmenswear`      //`Gemagic`,
            // case "11": return `littlenenes`         // Check
            // case "12": return `mimitospets`         // Check
            // case "13": return `homeconfort`         //`MAGNOS Men's Wear`,
            // default: return "testing"
        }
    }

    return(
        <RutasContext.Provider
            value={{
                ruta_seleccionada: state.ruta_seleccionada,
                rutas: state.rutas,
                getRoute
            }}
        >
            {props.children}
        </RutasContext.Provider>
    )

}

export default RutasState;

/* eslint-disable import/no-anonymous-default-export */
import { 
    OBTENER_SUCURSALES,
    GUARDAR_SUCURSALES,
    SELECCIONAR_SUCURSAL
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case SELECCIONAR_SUCURSAL:
            return{
                ...state,
                sucursal: action.payload
            }
        case GUARDAR_SUCURSALES:
        case OBTENER_SUCURSALES:
            return{
                ...state,
                sucursales: action.payload
            }
        default:
            return state
    }
}
import React, { useEffect, useState ,useContext, useRef} from 'react';
import { styled } from '@mui/material/styles';
import { Box, Checkbox, Tooltip, Typography } from '@mui/material';
import { BotonJC } from '../../material';
import Detalle from './Detalle'
import { SucursalesContext } from '../../../context';
import EnhancedTable from './Tabla';
import { useContainerDimensions } from '../../../functions';
import { flags_select } from '../../../img/country_flags';



export default function Sucursales() {
  function createData(id, nombre, tipo, almacenamiento, preparacion, retiro, pais, direccion, search, id_tipo, data) {
    return {
      id,
      nombre,
      tipo,
      almacenamiento,
      preparacion,
      retiro,
      pais, 
      direccion,
      search,
      id_tipo,
      data
    };
  }

  const [detalle, setDetalle] = useState(false)
  const [seleccion, setSeleccion] = useState({})
  const [sucursales_, setSucursales] = useState([])
  const sucursalesContext = useContext(SucursalesContext);
  const { obtener_sucursales, sucursales } = sucursalesContext;

  const headCells = [
    {
      id: 's_name',
      align: "left",
      disablePadding: true,
      label: 'Nombre',
    },
    {
      id: 'n_tipo',
      disablePadding: true,
      label: 'Tipo de sucursal',
    },
    {
      id: 'b_almacenamiento',
      align: "center",
      disablePadding: true,
      label: 'Almacenamiento',
      hideOrder: true
    },{
      id: 'b_preparacion',
      align: "center",
      disablePadding: false,
      label: 'Preparación',
    },
    {
      id: 'b_retiro',
      align: "center",
      disablePadding: false,
      label: 'Ofrece retiro'
    },
    {
      id: 's_pais',
      align: "left",
      disablePadding: false,
      label: 'País',
    },
    {
      id: 's_calle_num',
      align: "left",
      disablePadding: true,
      label: 'Dirección',
    }
  ];

  useEffect(()=>{
    let id_negocio = localStorage.getItem("id_route")
    obtener_sucursales(id_negocio)
  },[])

  useEffect(async ()=>{
    let pais_json = await flags_select([
      "mx",
      "us",
      "br",
      "cn",
      "ar"
    ]);

    if(sucursales?.length > 0){
      let result = await sucursales.map((sucursal, id_sucursal) => {
        let tipo = "Almacén"

        if(sucursal?.n_tipo == 1) tipo = "Centro Logístico"
        if(sucursal?.n_tipo == 2) tipo = "Punto de Venta"
        if(sucursal?.n_tipo == 3) tipo = "Oficina Central"
        if(sucursal?.n_tipo == 4) tipo = "Otros"


        let pais_select = pais_json.filter(pais_item => pais_item?.clave == sucursal.s_pais), simple_pais = null
        console.log(pais_select)
        if(pais_select?.length > 0){ simple_pais = pais_select[0] }
        
        
        console.log(simple_pais)
        return createData(
          id_sucursal,
          (<p
            style={{
              fontSize: "12px",
              fontFamily: "semibold"
            }}
            onClick={e=>{
            // console.log(sucursal)
            setDetalle(true)
            setSeleccion(sucursal)
          }}>{sucursal.s_nombre}</p>),
          tipo,
          (<Checkbox checked={sucursal.b_almacenamiento ? true: false} defaultChecked color="default" disabled/>),
          (<Checkbox checked={sucursal.b_preparacion ? true: false} defaultChecked color="default" disabled/>),
          (<Checkbox checked={sucursal.b_retiro ? true: false} defaultChecked color="default" disabled/>),
          simple_pais ? <Tooltip title={simple_pais.nombre}>
              <img style={{
              width: "20px",
              height: "15px"
            }} src={simple_pais.src}/>
          </Tooltip>: "",
          sucursal.s_calle_num,
          sucursal.s_nombre,
          sucursal?.n_tipo,
          sucursal
        )
      })

      setSucursales(result)
    }
  },[sucursales])

  const isObjectEmpty = (objectName) => {
    return JSON.stringify(objectName) === "{}";
  };

  const ref = useRef(null);
  const { width, height } = useContainerDimensions(ref)

  return(<Box>
    {!detalle > 0 ? (<Box>
        <HeaderModulo ref={ref} sx={{alignItems: "start"}}>
            <AreaTitleModulo>
              <TituloModulo>Sucursales</TituloModulo>
              <Box sx={{"& p":{color: "#626262 !important", fontSize: "12px", margin: "0px !important"}}}>
                  <Box>
                      <Typography>Visualiza y gestiona los lugares en donde tienes, vendes y preparas productos físicos con inventario, productos digitales o servicios.</Typography> 
                  </Box>
              </Box>
            </AreaTitleModulo>
            <AreaButtonModulo>
                <BotonJC onClick={e=>setDetalle(true)}>Agregar Sucursal</BotonJC>
            </AreaButtonModulo>
        </HeaderModulo>
        <Box sx={{"& svg":{color: "#DAD9D5 !important"}}}>
          <EnhancedTable
            hx={height}
            loading={false}
            rows={sucursales_}
            headCells={headCells}
            click_per_item={row_sucursal => {
              setDetalle(true)
              setSeleccion(row_sucursal.data)
            }}
          />
        </Box>
    </Box>): (<Detalle setDetalle={()=>{
      setSeleccion({})
      setDetalle(false)
    }} sucursal={isObjectEmpty(seleccion) ? false: seleccion}/>)}
  </Box>)
}

const HeaderModulo = styled('div')({
    display: "flex",
    alignItems: "center",
    marginBottom: "20px !important"
})
const AreaTitleModulo = styled('div')({
    width: "80%"
})
const AreaButtonModulo = styled('div')({
    width: "20%",
    display: "grid",
    justifyContent: "end",
    alignContent: "start"
})
const TituloModulo = styled('p')({
    fontSize: "1.25rem",
    fontFamily: "'mulish-extra-bold'",
    margin: "0px !important"
})

const styleModal = {
  position: 'absolute',
  color: "black !important",
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  height: "60vh",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  borderRadius: "10px"
};
/* eslint-disable import/no-anonymous-default-export */
import { 
    OBTENER_PRODUCTOS,
    SELECCIONAR_PRODUCTOS,
    TOTAL_VARIANTES
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case SELECCIONAR_PRODUCTOS:
            return{
                ...state,
                producto_seleccionado: action.payload
            }
        case TOTAL_VARIANTES:
            console.log(action.payload)
            return {
                ...state,
                n_variantes: action.payload
            }
        case OBTENER_PRODUCTOS:
            return {
                ...state,
                productos: action.payload
            }
        default:
            return state
    }
}
/* eslint-disable import/no-anonymous-default-export */
import { 
    FORMULARIO_MODULO, 
    OBTENER_MODULOS,
    AGREGAR_MODULO,
    VALIDAR_FORMULARIO,
    MODULO_ACTUAL,
    ELIMINAR_MODULO,
    ABRIR_MODULO,
    GUARDAR_TEMA,
    NUEVO_PRODUCTO
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case FORMULARIO_MODULO:
            return {
                ...state,
                formulario: true
            }
        case ABRIR_MODULO:
            return {
                ...state,
                openDrawer: action.payload
            }
        case OBTENER_MODULOS:
            return {
                ...state,
                modules: action.payload
            }
        case GUARDAR_TEMA:
            return {
                ...state,
                tema: action.payload
            }
        case AGREGAR_MODULO:
            return {
                ...state,
                modules: [...state.modules, action.payload],
                formulario: false,
                errorFormulario: false
            }
        case VALIDAR_FORMULARIO:
                return {
                    ...state,
                    errorFormulario: true
        }
        case MODULO_ACTUAL:
            if(action.payload == "delete"){
                return {
                    ...state,
                    module: []
                }
            }else{
                if(state.modules !== null){
                    let datamodule = state.modules.filter(module => module._id === action.payload)
                    return {
                        ...state,
                        module: datamodule
                    }
                }else{
                    return {
                        ...state,
                        module: null
                    }
                }
            }
        case NUEVO_PRODUCTO:
            return {
                ...state,
                module: [
                    {
                        ...state.module[0],
                        nuevo: action.payload
                    }
                ]
            }
        case ELIMINAR_MODULO:
            return {
                ...state,
                modules: state.modules.filter(module => module._id !== action.payload),
                module: null
        }
        default:
            return state
    }
}
import { Box, Button, styled } from "@mui/material";
import { useEffect, useState } from "react";

export const Bloque = styled('div')((props) => ({
    background: props.bkg ? props.bkg: "transparent !important",
    // background: "black !important",
    // height: "76vh",
    // border: '1px solid rgb(45, 55, 72)',
    marginTop: "10px",
    // boxShadow: "0px 2px 8px -2px #373737",
    borderRadius: '5px'
}))

export const EstadoJC = ({estado, tipo = 0, texto = null}) => {
    
    const [textos, setTextos] = useState([
        {name0: "Eliminado"},
        {
            name0: "Default",
            name1: "Borrador",
            name2: "Infinito",
            name3: "Archivo"
        },
        {
            name0: "Success",
            name1: "Activo",
            name2: "Pagado",
            name3: "Enviado",
            name4: "Subido",
            name5: "Procesado",
            name6: "Alto",
            name7: "Pedido",
            name8: "Recibido",
            name9: "Suscrito",
        },
        {
            name0: "Attention",
            name1: "Atención",
            name2: "Revisar",
            name3: "Medio",
            name4: "Parcial",
            name5: "Expirado",
        },
        {
            name0: "Pending",
            name1: "Pendiente",
            name2: "No suscrito",
        },{
            name0: "Negative",
            name1: "Negativo",
            name2: "Cancelado",
            name3: "Archivado",
            name4: "Bajo",
            name5: "Cancelado",
            name6: "Inactivo",
            name7: "No recibido",
            name8: "Error",
            name9: "Desactivado",
        },{
            name0: "Informative",
            name1: "Informativo",
            name2: "Info",
            name3: "Cerrado",
            name4: "Programado"
        }
    ]);

    const colorPorEstado = () => {
        switch(estado){
            case 0: return "#e06e6e !important"; /* Eliminado */
            case 1: return "#DAD9D5 !important"; /* Pendiente */
            case 2: return "#D6FDB1 !important"; /* Success */
            case 3: return "#FFCA88 !important"; /* Attention */
            case 4: return "#FDFA94 !important"; /* Pending */
            case 5: return "#FFC2B7 !important";
            case 6: return "#92BFFF !important";
            default: return "#DAD9D5 !important";
        }
    }

    const textoPorTipo = () => {
        if(estado){
            let textoPorEstado = textos[estado];
            let textoPorTipo = textoPorEstado ? textoPorEstado[`name${tipo}`]: null
            if(textoPorEstado && textoPorTipo) return textoPorTipo
        }

        return "Error"
    }

    const Contenedor = styled('div')((props) => ({
        background: colorPorEstado(),
        padding: "2px 14px",
        borderRadius: "8px",
        "& p": {
            color: "#303030 !important",
            textAlign: "center",
        }
    }))

    const Texto = styled('p')((props) => ({
        margin: "0px !important",
        padding: "0px !important",
        fontFamily: "semibold",
        fontSize: "12px",
    })) 

    return(<Contenedor>
        <Texto>
            {texto ? texto: textoPorTipo()}
        </Texto>
    </Contenedor>)
} 

export const BotonJC = ({tema = "primario", children, tipo, onClick = e => console.log("default"), sx = {}}) => {
    const [colores, setColores] = useState({
        normal: "#3F3F3F",
        hover: "#2d2d2d",
        active: "#181818"
    })

    const colorPorTema = () => {
        switch(tema){
            case "secundario":
                setColores({
                    normal: "#626262",
                    hover: "#494949",
                    active: "#343434"
                })

                return colores
            default: return colores; 
        }
    }

    useEffect(()=>{
        colorPorTema()
    },[])

    const Boton = styled('button')((props) => ({
        ...sx,
        background: colores.normal,
        color: "#FFFFFF",
        fontSize: "12px",
        fontFamily: "semibold",
        height: "25px !important",
        padding: "0px 12px",
        borderRadius: "8px",
        cursor: "pointer",
        borderStyle: "hidden",
        "&:hover":{ background: colores.hover },
        "&:active":{ background: colores.active }
    }))

    return(<Boton onClick={onClick}>{children}</Boton>)
}

export const BotonJCs = styled(Button)((props) => ({
    background: "#626262",
    textTransform: "none",
    color: "#FFFFFF",
    fontSize: "12px",
    borderRadius: "8px",
    padding: "0px 12px !important",
    cursor: "pointer",
    height:"25px !important",
    fontSize: "12px !important",
    fontFamily: "'semibold' !important",
    borderStyle: "hidden",
    "&:hover":{ background: "#2d2d2d" },
    "&:active":{ background: "#181818" }
}))

export const CountryFlags = ({name = ""}) => {

    return(<Box>

    </Box>)
}
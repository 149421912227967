import React, { useReducer } from 'react';
import {ColeccionesContext} from '../index';
import coleccionesReducer from './coleccionesReducer';
import clientAxios from '../../config/axios'

import { 
    EDITAR_COLECCION,
    GUARDAR_COLECCION,
    MINIMIZAR_MENUBAR,
    OBTENER_COLECCIONES,
    SELECCIONAR_MODULO_MENU
} from '../../types'

const ColeccionesState = props => {
    const initialState = {
        colecciones: [],
    }

    const [state, dispatch] = useReducer(coleccionesReducer, initialState)

    const obtener_colecciones = async () => {
        const res = await clientAxios.get(`/api/colecciones`), data = res?.data ? res.data : null;

        if(data && data?.success){
            dispatch({
                type: OBTENER_COLECCIONES,
                payload: data?.colecciones ? data.colecciones: []
            })
        }
    }

    const guardar_coleccion = async coleccion => {
        const res = await clientAxios.post(`/api/colecciones`, coleccion), data = res?.data ? res.data : null;

        console.log(res)
        if(data && data?.success){
            if(data?.nueva_coleccion){
                dispatch({
                    type: GUARDAR_COLECCION,
                    payload: data.nueva_coleccion
                })
            }

            return true
        }
    }

    const editar_coleccion = async (coleccion, _id) => {
        const res = await clientAxios.post(`/api/colecciones/update/${_id}`, coleccion), data = res?.data ? res.data : null;

        if(data && data?.success){
            dispatch({
                type: EDITAR_COLECCION,
                payload: coleccion
            })

            return true
        }
    }

    return(
        <ColeccionesContext.Provider
            value={{
                colecciones: state.colecciones,
                obtener_colecciones,
                editar_coleccion,
                guardar_coleccion
            }}
        >
            {props.children}
        </ColeccionesContext.Provider>
    )
}

export default ColeccionesState 
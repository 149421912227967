import ad from "./ad.png"
import ae from "./ae.png"
import af from "./af.png"
import ag from "./ag.png"
import ai from "./ai.png"
// import al from "./al.png"
import am from "./am.png"
import ao from "./ao.png"
import aq from "./aq.png"
import ar from "./ar.png"
import as from "./as.png"
import at from "./at.png"
import au from "./au.png"
import aw from "./aw.png"
import ax from "./ax.png"
import az from "./az.png"
import ba from "./ba.png"
import bb from "./bb.png"
import bd from "./bd.png"
import be from "./be.png"
import bf from "./bf.png"
import bg from "./bg.png"
import bh from "./bh.png"
import bi from "./bi.png"
import bj from "./bj.png"
import bl from "./bl.png"
import bm from "./bm.png"
import bn from "./bn.png"
import bo from "./bo.png"
import bq from "./bq.png"
import br from "./br.png"
import bs from "./bs.png"
import bt from "./bt.png"
import bv from "./bv.png"
import bw from "./bw.png"
import by from "./by.png"
import bz from "./bz.png"
import ca from "./ca.png"
import cc from "./cc.png"
import cd from "./cd.png"
import cf from "./cf.png"
import cg from "./cg.png"
import ch from "./ch.png"
import ci from "./ci.png"
import ck from "./ck.png"
import cl from "./cl.png"
import cm from "./cm.png"
import cn from "./cn.png"
import co from "./co.png"
import cr from "./cr.png"
import cu from "./cu.png"
import cv from "./cv.png"
import cw from "./cw.png"
import cx from "./cx.png"
import cy from "./cy.png"
import cz from "./cz.png"
import de from "./de.png"
import dj from "./dj.png"
import dk from "./dk.png"
import dm from "./dm.png"
import doo from "./dm.png"
import dz from "./dz.png"
import ec from "./ec.png"
import ee from "./ee.png"
import eg from "./eg.png"
import eh from "./eh.png"
import er from "./er.png"
import es from "./es.png"
import et from "./et.png"
import fi from "./fi.png"
import fj from "./fj.png"
import fk from "./fk.png"
import fm from "./fm.png"
import fo from "./fo.png"
import fr from "./fr.png"
import ga from "./ga.png"
import gb_eng from "./gb-eng.png"
import gb_nir from "./gb-nir.png"
import gb_sct from "./gb-sct.png"
import gb_wls from "./gb-wls.png"
import gb from "./gb.png"
import gd from "./gd.png"
import ge from "./ge.png"
import gf from "./gf.png"
import gg from "./gg.png"
import gh from "./gh.png"
import gi from "./gi.png"
import gl from "./gl.png"
import gm from "./gm.png"
import gn from "./gn.png"
import gp from "./gp.png"
import gq from "./gq.png"
import gr from "./gr.png"
import gs from "./gs.png"
import gt from "./gt.png"
import gu from "./gu.png"
import gw from "./gw.png"
import gy from "./gy.png"
import hk from "./hk.png"
import hm from "./hm.png"
import hr from "./hr.png"
import hn from "./hn.png"
import hu from "./hu.png"
import id from "./id.png"
import ie from "./ie.png"
import il from "./il.png"
import im from "./im.png"
import inn from "./in.png"
import io from "./io.png"
import iq from "./iq.png"
import ir from "./ir.png"
import is from "./is.png"
import it from "./it.png"
import je from "./je.png"
import jm from "./jm.png"
import jo from "./jo.png"
import jp from "./jp.png"
import ke from "./ke.png"
import kg from "./kg.png"
import kh from "./kh.png"
import ki from "./ki.png"
import km from "./km.png"
import kn from "./kn.png"
import kp from "./kp.png"
import kr from "./kr.png"
import kw from "./kw.png"
import ky from "./ky.png"
import kz from "./kz.png"
import la from "./la.png"
import lb from "./lb.png"
import lc from "./lc.png"
import li from "./li.png"
import lk from "./lk.png"
import lr from "./lr.png"
import ls from "./ls.png"
import lt from "./lt.png"
import lu from "./lu.png"
import lv from "./lv.png"
import ly from "./ly.png"
import ma from "./ma.png"
import mc from "./mc.png"
import md from "./md.png"
import me from "./me.png"
import mf from "./mf.png"
import mg from "./mg.png"
import mh from "./mh.png"
import mk from "./mk.png"
import ml from "./ml.png"
import mm from "./mm.png"
import mn from "./mn.png"
import mo from "./mo.png"
import mp from "./mp.png"
import mq from "./mq.png"
import mr from "./mr.png"
import ms from "./ms.png"
import mt from "./mt.png"
import mu from "./mu.png"
import mv from "./mv.png"
import mw from "./mw.png"
import mx from "./mx.png"
import my from "./my.png"
import mz from "./mz.png"
import na from "./na.png"
import nc from "./nc.png"
import ne from "./ne.png"
import nf from "./nf.png"
import ng from "./ng.png"
import ni from "./ni.png"
import nl from "./nl.png"
import no from "./no.png"
import np from "./np.png"
import nr from "./nr.png"
import nu from "./nu.png"
import nz from "./nz.png"
import om from "./om.png"
import pa from "./pa.png"
import pe from "./pe.png"
import pf from "./pf.png"
import pg from "./pg.png"
import ph from "./ph.png"
import pk from "./pk.png"
import pl from "./pl.png"
import pm from "./pm.png"
import pn from "./pn.png"
import pr from "./pr.png"
import ps from "./ps.png"
import pt from "./pt.png"
import pw from "./pw.png"
import py from "./py.png"
import qa from "./qa.png"
import re from "./re.png"
import ro from "./ro.png"
import rs from "./rs.png"
import ru from "./ru.png"
import rw from "./rw.png"
import sa from "./sa.png"
import sb from "./sb.png"
import sc from "./sc.png"
import sd from "./sd.png"
import se from "./se.png"
import sg from "./sg.png"
import sh from "./sh.png"
import si from "./si.png"
import sj from "./sj.png"
import sk from "./sk.png"
import sl from "./sl.png"
import sm from "./sm.png"
import sn from "./sn.png"
import so from "./so.png"
import sr from "./sr.png"
import ss from "./ss.png"
import st from "./st.png"
import sv from "./sv.png"
import sx from "./sx.png"
import sy from "./sy.png"
import sz from "./sz.png"
import tc from "./tc.png"
import td from "./td.png"
import tf from "./tf.png"
import tg from "./tg.png"
import th from "./th.png"
import tj from "./tj.png"
import tk from "./tk.png"
import tl from "./tl.png"
import tm from "./tm.png"
import tn from "./tn.png"
import to from "./to.png"
import tr from "./tr.png"
import tt from "./tt.png"
import tv from "./tv.png"
import tw from "./tw.png"
import tz from "./tz.png"
import ua from "./ua.png"
import ug from "./ug.png"
import um from "./um.png"
import us from "./us.png"
import uy from "./uy.png"
import uz from "./uz.png"
import va from "./va.png"
import vc from "./vc.png"
import ve from "./ve.png"
import vg from "./vg.png"
import vi from "./vi.png"
import vn from "./vn.png"
import vu from "./vu.png"
import wf from "./wf.png"
import ws from "./ws.png"
import xk from "./xk.png"
import ye from "./ye.png"
import yt from "./yt.png"
import za from "./za.png"
import zm from "./zm.png"
import zw from "./zw.png"

let paises = [
    {nombre: "Andorra",             clave: "ad", src: ad},
    {nombre: "Emiratos Árabes",     clave: "ae", src: ae},
    {nombre: "Afganistán",          clave: "af", src: af},
    {nombre: "Antigua y Barbuda",   clave: "ag", src: ag},
    {nombre: "Anguila",             clave: "ai", src: ai},
    // {nombre: "", clave: "al", src: al},
    {nombre: "Armenia",             clave: "am", src: am},
    {nombre: "Angola",              clave: "ao", src: ao},
    {nombre: "Antártida",           clave: "aq", src: aq},
    {nombre: "Argentina",           clave: "ar", src: ar},
    {nombre: "Samoa Americana",     clave: "as", src: as},
    {nombre: "Austria",             clave: "at", src: at},
    {nombre: "Australia",           clave: "au", src: au},
    {nombre: "Aruba",               clave: "aw", src: aw},
    {nombre: "Aland",               clave: "ax", src: ax},
    {nombre: "Azerbaiyán",          clave: "az", src: az},
    {nombre: "Herzegovina",         clave: "ba", src: ba},
    {nombre: "Barbados",            clave: "bb", src: bb},
    {nombre: "Bangladesh",          clave: "bd", src: bd},
    {nombre: "Bélgica",             clave: "be", src: be},
    {nombre: "Burkina Faso",        clave: "bf", src: bf},
    {nombre: "Bulgaria",            clave: "bg", src: bg},
    {nombre: "Baréin",              clave: "bh", src: bh},
    {nombre: "", clave: "bi", src: bi},
    {nombre: "", clave: "bj", src: bj},
    {nombre: "", clave: "bl", src: bl},
    {nombre: "", clave: "bm", src: bm},
    {nombre: "", clave: "bn", src: bn},
    {nombre: "Bolivia",             clave: "bo", src: bo},
    {nombre: "", clave: "bq", src: bq},
    {nombre: "Brasil", clave: "br", src: br},
    {nombre: "", clave: "bs", src: bs},
    {nombre: "", clave: "bt", src: bt},
    {nombre: "", clave: "bv", src: bv},
    {nombre: "Botsuana", clave: "bw", src: bw},
    {nombre: "", clave: "by", src: by},
    {nombre: "", clave: "bz", src: bz},
    {nombre: "Canadá", clave: "ca", src: ca},
    {nombre: "", clave: "cc", src: cc},
    {nombre: "", clave: "cd", src: cd},
    {nombre: "", clave: "cf", src: cf},
    {nombre: "", clave: "cg", src: cg},
    {nombre: "Suiza", clave: "ch", src: ch},
    {nombre: "Costa de Marfil", clave: "ci", src: ci},
    {nombre: "", clave: "ck", src: ck},
    {nombre: "Chile", clave: "cl", src: cl},
    {nombre: "", clave: "cm", src: cm},
    {nombre: "China", clave: "cn", src: cn},
    {nombre: "Colombia", clave: "co", src: co},
    {nombre: "Costa Rica", clave: "cr", src: cr},
    {nombre: "Cuba", clave: "cu", src: cu},
    {nombre: "Cabo Verde", clave: "cv", src: cv},
    {nombre: "", clave: "cw", src: cw},
    {nombre: "", clave: "cx", src: cx},
    {nombre: "Chipre", clave: "cy", src: cy},
    {nombre: "", clave: "cz", src: cz},
    {nombre: "Alemania", clave: "de", src: de},
    {nombre: "", clave: "dj", src: dj},
    {nombre: "Dinamarca", clave: "dk", src: dk},
    {nombre: "", clave: "dm", src: dm},
    {nombre: "Republica Dominicana", clave: "do", src: doo},
    {nombre: "", clave: "dz", src: dz},
    {nombre: "Ecuador", clave: "ec", src: ec},
    {nombre: "Estonia", clave: "ee", src: ee},
    {nombre: "", clave: "eg", src: eg},
    {nombre: "Sahara Occidental", clave: "eh", src: eh},
    {nombre: "", clave: "er", src: er},
    {nombre: "España", clave: "es", src: es},
    {nombre: "", clave: "et", src: et},
    {nombre: "Finlandia", clave: "fi", src: fi},
    {nombre: "", clave: "fj", src: fj},
    {nombre: "", clave: "fk", src: fk},
    {nombre: "", clave: "fm", src: fm},
    {nombre: "", clave: "fo", src: fo},
    {nombre: "Francia", clave: "fr", src: fr},
    {nombre: "", clave: "ga", src: ga},
    {nombre: "Inglaterra", clave: "gb_eng", src: gb_eng},
    {nombre: "", clave: "gb_nir", src: gb_nir},
    {nombre: "", clave: "gb_sct", src: gb_sct},
    {nombre: "", clave: "gb_wls", src: gb_wls},
    {nombre: "Gran Bretaña", clave: "gb", src: gb},
    {nombre: "", clave: "gd", src: gd},
    {nombre: "", clave: "ge", src: ge},
    {nombre: "", clave: "gf", src: gf},
    {nombre: "", clave: "gg", src: gg},
    {nombre: "", clave: "gh", src: gh},
    {nombre: "", clave: "gi", src: gi},
    {nombre: "", clave: "gl", src: gl},
    {nombre: "", clave: "gm", src: gm},
    {nombre: "", clave: "gn", src: gn},
    {nombre: "", clave: "gp", src: gp},
    {nombre: "", clave: "gq", src: gq},
    {nombre: "Grecia", clave: "gr", src: gr},
    {nombre: "", clave: "gs", src: gs},
    {nombre: "Guatemala", clave: "gt", src: gt},
    {nombre: "", clave: "gu", src: gu},
    {nombre: "", clave: "gw", src: gw},
    {nombre: "", clave: "gy", src: gy},
    {nombre: "", clave: "hk", src: hk},
    {nombre: "", clave: "hm", src: hm},
    {nombre: "", clave: "hn", src: hn},
    {nombre: "Croacia", clave: "hr", src: hr},
    {nombre: "Honduras", clave: "hu", src: hu},
    {nombre: "", clave: "id", src: id},
    {nombre: "", clave: "ie", src: ie},
    {nombre: "Israel", clave: "il", src: il},
    {nombre: "", clave: "im", src: im},
    {nombre: "", clave: "inn", src: inn},
    {nombre: "", clave: "io", src: io},
    {nombre: "Irak", clave: "iq", src: iq},
    {nombre: "Irán", clave: "ir", src: ir},
    {nombre: "", clave: "is", src: is},
    {nombre: "Italia", clave: "it", src: it},
    {nombre: "", clave: "je", src: je},
    {nombre: "Jamaica", clave: "jm", src: jm},
    {nombre: "", clave: "jo", src: jo},
    {nombre: "Japón", clave: "jp", src: jp},
    {nombre: "", clave: "ke", src: ke},
    {nombre: "", clave: "kg", src: kg},
    {nombre: "", clave: "kh", src: kh},
    {nombre: "", clave: "ki", src: ki},
    {nombre: "", clave: "km", src: km},
    {nombre: "", clave: "kn", src: kn},
    {nombre: "Corea del Norte", clave: "kp", src: kp},
    {nombre: "Corea del Sur", clave: "kr", src: kr},
    {nombre: "", clave: "kw", src: kw},
    {nombre: "", clave: "ky", src: ky},
    {nombre: "", clave: "kz", src: kz},
    {nombre: "", clave: "la", src: la},
    {nombre: "", clave: "lb", src: lb},
    {nombre: "", clave: "lc", src: lc},
    {nombre: "", clave: "li", src: li},
    {nombre: "", clave: "lk", src: lk},
    {nombre: "", clave: "lr", src: lr},
    {nombre: "", clave: "ls", src: ls},
    {nombre: "", clave: "lt", src: lt},
    {nombre: "", clave: "lu", src: lu},
    {nombre: "", clave: "lv", src: lv},
    {nombre: "", clave: "ly", src: ly},
    {nombre: "", clave: "ma", src: ma},
    {nombre: "", clave: "mc", src: mc},
    {nombre: "", clave: "md", src: md},
    {nombre: "", clave: "me", src: me},
    {nombre: "", clave: "mf", src: mf},
    {nombre: "", clave: "mg", src: mg},
    {nombre: "", clave: "mh", src: mh},
    {nombre: "", clave: "mk", src: mk},
    {nombre: "", clave: "ml", src: ml},
    {nombre: "", clave: "mm", src: mm},
    {nombre: "", clave: "mn", src: mn},
    {nombre: "", clave: "mo", src: mo},
    {nombre: "", clave: "mp", src: mp},
    {nombre: "", clave: "mq", src: mq},
    {nombre: "", clave: "mr", src: mr},
    {nombre: "", clave: "ms", src: ms},
    {nombre: "", clave: "mt", src: mt},
    {nombre: "", clave: "mu", src: mu},
    {nombre: "", clave: "mv", src: mv},
    {nombre: "", clave: "mw", src: mw},
    {nombre: "México", clave: "mx", src: mx},
    {nombre: "", clave: "my", src: my},
    {nombre: "", clave: "mz", src: mz},
    {nombre: "", clave: "na", src: na},
    {nombre: "", clave: "nc", src: nc},
    {nombre: "", clave: "ne", src: ne},
    {nombre: "", clave: "nf", src: nf},
    {nombre: "", clave: "ng", src: ng},
    {nombre: "", clave: "ni", src: ni},
    {nombre: "", clave: "nl", src: nl},
    {nombre: "", clave: "no", src: no},
    {nombre: "", clave: "np", src: np},
    {nombre: "", clave: "nr", src: nr},
    {nombre: "", clave: "nu", src: nu},
    {nombre: "", clave: "nz", src: nz},
    {nombre: "", clave: "om", src: om},
    {nombre: "Panamá", clave: "pa", src: pa},
    {nombre: "Perú", clave: "pe", src: pe},
    {nombre: "", clave: "pf", src: pf},
    {nombre: "", clave: "pg", src: pg},
    {nombre: "", clave: "ph", src: ph},
    {nombre: "", clave: "pk", src: pk},
    {nombre: "", clave: "pl", src: pl},
    {nombre: "", clave: "pm", src: pm},
    {nombre: "", clave: "pn", src: pn},
    {nombre: "Puerto Rico", clave: "pr", src: pr},
    {nombre: "", clave: "ps", src: ps},
    {nombre: "Portugal", clave: "pt", src: pt},
    {nombre: "", clave: "pw", src: pw},
    {nombre: "Paraguay", clave: "py", src: py},
    {nombre: "Qatar", clave: "qa", src: qa},
    {nombre: "", clave: "re", src: re},
    {nombre: "", clave: "ro", src: ro},
    {nombre: "", clave: "rs", src: rs},
    {nombre: "Rusia", clave: "ru", src: ru},
    {nombre: "", clave: "rw", src: rw},
    {nombre: "", clave: "sa", src: sa},
    {nombre: "", clave: "sb", src: sb},
    {nombre: "", clave: "sc", src: sc},
    {nombre: "", clave: "sd", src: sd},
    {nombre: "", clave: "se", src: se},
    {nombre: "", clave: "sg", src: sg},
    {nombre: "", clave: "sh", src: sh},
    {nombre: "", clave: "si", src: si},
    {nombre: "", clave: "sj", src: sj},
    {nombre: "", clave: "sk", src: sk},
    {nombre: "", clave: "sl", src: sl},
    {nombre: "", clave: "sm", src: sm},
    {nombre: "", clave: "sn", src: sn},
    {nombre: "", clave: "so", src: so},
    {nombre: "", clave: "sr", src: sr},
    {nombre: "", clave: "ss", src: ss},
    {nombre: "", clave: "st", src: st},
    {nombre: "El Salvador", clave: "sv", src: sv},
    {nombre: "", clave: "sx", src: sx},
    {nombre: "", clave: "sy", src: sy},
    {nombre: "", clave: "sz", src: sz},
    {nombre: "", clave: "tc", src: tc},
    {nombre: "", clave: "td", src: td},
    {nombre: "", clave: "tf", src: tf},
    {nombre: "", clave: "tg", src: tg},
    {nombre: "", clave: "th", src: th},
    {nombre: "", clave: "tj", src: tj},
    {nombre: "", clave: "tk", src: tk},
    {nombre: "", clave: "tl", src: tl},
    {nombre: "", clave: "tm", src: tm},
    {nombre: "", clave: "tn", src: tn},
    {nombre: "", clave: "to", src: to},
    {nombre: "Turquia", clave: "tr", src: tr},
    {nombre: "", clave: "tt", src: tt},
    {nombre: "", clave: "tv", src: tv},
    {nombre: "", clave: "tw", src: tw},
    {nombre: "", clave: "tz", src: tz},
    {nombre: "", clave: "ua", src: ua},
    {nombre: "", clave: "ug", src: ug},
    {nombre: "EUA", clave: "um", src: um},
    {nombre: "EUA", clave: "us", src: us},
    {nombre: "Uruguay", clave: "uy", src: uy},
    {nombre: "", clave: "uz", src: uz},
    {nombre: "", clave: "va", src: va},
    {nombre: "", clave: "vc", src: vc},
    {nombre: "Venezuela", clave: "ve", src: ve},
    {nombre: "", clave: "vg", src: vg},
    {nombre: "", clave: "vi", src: vi},
    {nombre: "", clave: "vn", src: vn},
    {nombre: "", clave: "vu", src: vu},
    {nombre: "", clave: "wf", src: wf},
    {nombre: "", clave: "ws", src: ws},
    {nombre: "", clave: "xk", src: xk},
    {nombre: "", clave: "ye", src: ye},
    {nombre: "", clave: "yt", src: yt},
    {nombre: "", clave: "za", src: za},
    {nombre: "", clave: "zm", src: zm},
    {nombre: "", clave: "zw", src: zw},
]

export const flags_select = async (rango = [], valor = null) => {
    let result_rango = await paises.filter(pais => rango.filter(item_rango => item_rango == pais.clave)?.length > 0)
    if(valor){
        let valor_rango = await paises.filter(pais => pais.clave == valor)

        if(valor_rango?.length > 0){
            return valor_rango[0]
        }

        return;
    }
    if(result_rango?.length > 0){
        return result_rango
    }
    return paises.filter(pais => pais?.nombre != "");
}
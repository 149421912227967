/* eslint-disable import/no-anonymous-default-export */
import { 
    GUARDAR_INVENTARIO, 
    OBTENER_INVENTARIO_PRODUCTOS,
    ELIMINAR_INVENTARIO,
    ABRIR_MODAL,
    OBTENER_PRODUCTOS,
    GUARDAR_PRODUCTO,
    AGREGAR_DATA_BTN,
    MODIFICAR_INVENTARIO,
    TOTAL_INVENTARIO,
    EXISTENCIAS_INVENTARIO,
    TOTAL_VARIANTES,
    OBTENER_INVENTARIO_DETALLE
} from "../../types"


export default (state, action) => {
    switch(action.type){
        case ABRIR_MODAL:
            return {
                ...state,
                modalView: action.payload
            }
        case AGREGAR_DATA_BTN:
            console.log(action.payload)
            return {
                ...state,
                dataBtnAgregar: action.payload
            }
        case ELIMINAR_INVENTARIO:
            return {
                ...state,
                datos: action.payload,
                inventario: action.payload
            }
        case GUARDAR_INVENTARIO:
            return {
                ...state,
                inventario: action.payload
            }
        case OBTENER_INVENTARIO_PRODUCTOS:
            return {
                ...state,
                inventario: action.payload
            }
        case OBTENER_PRODUCTOS:
            return {
                ...state,
                productos: action.payload
            }
        case GUARDAR_PRODUCTO:
            return {
                ...state,
                productos: [
                    action.payload,
                    ...state.productos
                ]
            }
        case TOTAL_INVENTARIO:
            return {
                ...state,
                total: action.payload
            }
        case EXISTENCIAS_INVENTARIO:
            return {
                ...state,
                existencias: action.payload
            }
        case TOTAL_VARIANTES:
            return {
                ...state,
                n_variantes: action.payload
            }
        case MODIFICAR_INVENTARIO:
            return {
                ...state,
                inventario_modificado: action.payload
            }
        case OBTENER_INVENTARIO_DETALLE:
            return {
                ...state,
                inventario_detalle: action.payload
            }
        default:
            return state
    }
}
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, styled } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BotonJCs } from "../../material";
import { ArrowBackIosNew, Circle, RadioButtonUnchecked } from "@mui/icons-material";
import moduleContext from "../../../context/modules/moduleContext";
import { SucursalesContext } from "../../../context/";
import Icon from "../../layout/Material/Icons";
import { flags_select } from "../../../img/country_flags";

const ID_PRODUCTOS_DEV = process.env.REACT_APP_ENTORNO == "DESARROLLO" ? "65468dba8700459b6f0760a4": "64b21a1ee9300ac2545a3bf1"

const Direccion = (props) => {
    let {
        set_s_pais,
        set_s_calle_num,
        set_s_apartamento,
        set_s_colonia,
        set_s_codigo_postal,
        set_s_ciudad,
        set_s_estado,
        set_s_telefono,
        set_n_lada,
        data
    } = props

    const [lada, setLada]       = useState(null)
    const [pais, setPais]       = useState("");
    const [paises, setPaises]   = useState([]);
    useEffect(()=>{
        if(data?.n_lada){
            setLada(data.n_lada)
        }else{
            // LADA SELECCIONADA POR DEFAULT
            setLada(0)
        }

        if(data?.s_pais){ setPais(data.s_pais) }
    },[data])

    useEffect(()=>{
        const obtener_paises = async () => {
            let paises_ = await flags_select([
                "mx",
                "us",
                "br",
                "cn",
                "ar"
            ]);
            setPaises(paises_)
        }

        obtener_paises()
    },[])

    useEffect(()=>{
        set_s_pais(pais)
    },[pais])



    const sx_op = {
        fontSize: "12px !important",
        fontFamily: "semibold",
        display: "flex",
        padding: "3px 5px",
        alignItems: "center"
    }

    const json_paises = [
        ["mx", "+1"],
        ["us", "+52"],
        ["br", "+57"],
        ["cn", "+86"],
        // "ar"
    ]

    return(<Item>
        <Texto>Dirección</Texto>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Calle y número</Texto>
            <Campo onChange={set_s_calle_num} value={data.s_calle_num} placeholder=""/>
        </Box>
        <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
            <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Apartamento, local, etc.</Texto>
            <Campo onChange={set_s_apartamento} value={data.s_apartamento} placeholder=""/>
        </Box>
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Colonia</Texto>
                    <Campo onChange={set_s_colonia} value={data.s_colonia} placeholder=""/>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{
                    marginBottom: "10px",
                    "& p": {color: "#626262 !important"},
                    "& .MuiSelect-select": {
                        margin: "0px !important",
                        padding: "2px 10px !important",
                        fontSize: "12px !important"
                    }
                }}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>País</Texto>
                    <Select value={pais} onChange={e => setPais(e.target.value)} fullWidth displayEmpty sx={sx_op}>
                        <MenuItem sx={sx_op} value="">Selecciona un país</MenuItem>
                        {paises.map(flag => {
                            return (<MenuItem sx={sx_op} value={`${flag.clave}`}>
                                <img
                                    src={flag.src}
                                    style={{
                                        width: "15px",
                                        marginRight: "5px",
                                        marginLeft: "10px",
                                        height: "10px"
                                    }}
                                />
                                {flag.nombre}
                            </MenuItem>)
                        })}
                    </Select>
                    {/* <Campo onChange={set_s_pais} value={data.s_pais} placeholder=""/> */}
                </Box>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Código Postal</Texto>
                    <Campo onChange={set_s_codigo_postal} value={data.s_codigo_postal} placeholder=""/>
                </Box>  
            </Grid>
            <Grid item xs={4}>
                <Box sx={{"& p": {color: "#626262 !important"}}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Ciudad</Texto>
                    <Campo onChange={set_s_ciudad} value={data.s_ciudad} placeholder=""/>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{"& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Estado</Texto>
                    <Campo onChange={set_s_estado} value={data.s_estado} placeholder=""/>
                </Box>
            </Grid>
        </Grid>
        <Grid container sx={{marginBottom: "15px"}}>
            <Grid item xs={2}>
                <Box id="lkdjelkd" sx={{
                    marginRight: "10px", 
                    "& p": {color: "#626262 !important"}, marginBottom: "10px",
                    paddingTop: "12px",
                    "& div": {
                        fontSize: "12px !important",
                        fontFamily: "semibold"
                    },
                    "& .MuiSelect-select": {
                        margin: "0px !important",
                        padding: "2px !important",
                        fontSize: "12px !important"
                    }
                }}>
                    
                    {lada != null ? (<Select
                            onChange={set_n_lada}
                            value={lada}
                            className="item"
                            name="languages"
                            style={{ 
                              width: "100%",
                              fontSize: "12px",
                              borderRadius: "8px",
                              marginTop: "10px",
                              padding: "4px",
                              border: "1px solid #DAD9D5"
                            }}
                        >
                            <MenuItem sx={sx_op} value="">Selecciona un país</MenuItem>
                            {json_paises.map((flag, id_flag) => {
                                let result_pais = paises.filter(pais_select => flag[0]== pais_select.clave)
                                let src_img = null
                                if(result_pais.length > 0){ src_img = result_pais[0].src }
                                return (<MenuItem sx={sx_op} value={id_flag}>
                                    <img
                                        src={src_img}
                                        style={{
                                            width: "15px",
                                            marginRight: "5px",
                                            marginLeft: "10px",
                                            height: "10px"
                                        }}
                                    />
                                    {flag[1]}
                                </MenuItem>)
                            })}
                    </Select>): null}
                    {/* <Campo onChange={set_s_telefono} value={data.s_telefono} sx={{marginTop: "20px"}} placeholder=""/> */}
                </Box>
            </Grid>
            <Grid item xs={10}>
                <Box sx={{marginLeft: "10px", "& p": {color: "#626262 !important"}, marginBottom: "10px"}}>
                    <Texto family="semibold" sx={{margin: "0px", marginBottom: "2px"}}>Teléfono</Texto>
                    <Campo onChange={set_s_telefono} value={data.s_telefono} placeholder=""/>
                </Box>
            </Grid>
        </Grid>
    </Item>)
}

const AreaRadioButton = ({onChange, sucursales = [], name="default"}) => {
    const Radio = styled('input')({})

    const DescRadio = styled('p')({
        fontSize: "10px",
        margin: "0px",
        marginLeft: "40px",
        color: "#626262"
    })
    const LabelRadio = styled('label')({
        fontSize: "12px",
        color: "#626262",
        marginLeft: "5px",
        display: "inline-flex",
        alignItems: "center",
        cursor: "pointer"
    })
    return(<Box>
        {sucursales.map((sucursal, i_sucursal) => {
            return(<Box sx={{            
                "& input[type='radio']::before":{
                    width: "15px",
                    height: "15px",
                    borderRadius: "15px",
                    top: "-2px",
                    left: "-1px",
                    position: "relative",
                    backgroundColor: "#d1d3d1",
                    color: "#d1d3d1",
                    display: "inline-block",
                    visibility: "visible",
                    border: "2px solid white",
                    accentColor: "black",
                    cursor: "pointer"
                },
            
                "& input[type='radio']:checked":{
                    width: "15px",
                    height: "15px",
                    borderRadius: "15px",
                    top: -"2px",
                    left: "-1px",
                    position: "relative",
                    backgroundColor: "#ffa500",
                    color: "#ffa500",
                    display: "inline-block",
                    visibility: "visible",
                    border: "2px solid white",
                    accentColor: "black",
                    cursor: "pointer"
                },
                "& input[type='radio']":{
                    cursor: "pointer"
                }
            }}>
                <Radio
                    onClick={onChange}
                    type="radio"
                    id={sucursal.name}
                    name={name}
                    value={i_sucursal}
                    defaultChecked={sucursal.check}
                />
                <LabelRadio for={sucursal.name}>
                    {sucursal?.icon ? sucursal.icon: null}
                    <b style={{marginLeft: "5px"}}>{sucursal.name}</b>
                </LabelRadio>
                <DescRadio sx={{marginLeft: sucursal?.icon ? "40": "22px"}} for={sucursal.name}>{sucursal.description}</DescRadio><br/>
            </Box>)
        })}
    </Box>)
}

const TipoSucursales = ({set_tipo_sucursal, id_check=null}) => {
    const [sucursales, setSucursales] = useState([
        {
            name: "Almacén",
            description: "Almacena productos y puede o no distribuir o enviar. Espacio físico que facilita el acceso y la gestión del inventario.",
            icon: (<Icon name="Warehouse"/>),
            check: false
        },{
            name: "Centro logístico",
            description: "Almacena productos y coordina actividades clave, como recepción, almacenamiento y distribución/envío de productos involucrando a participantes externos.",
            icon: (<Icon name="Fullfillment"/>),
            check: false
        },{
            name: "Punto de venta",
            description: "Puede o no almacenar productos, ofrece retiro físico y opciones de pago, sirviendo como el punto de contacto con el cliente ya sea en una tienda o punto físico.",
            icon: (<Icon name="Point"/>),
            check: false
        },{
            name: "Oficina central",
            description: "Espacio de trabajo y sede principal donde puede ofrecer retiro físico y métodos de pago, además albergan funciones administrativas.",
            icon: (<Icon name="Office"/>),
            check: false
        },{
            name: "Otros",
            description: "Ubicaciones adicionales como plantas de producción, showrooms, oficinas adicionales o filiales para productos físicos, digitales o servicios.",
            icon: (<Icon name="Location"/>),
            check: false
        }
    ])

    useEffect(()=>{
        if(id_check || id_check == 0){
            setSucursales(sucursales_old => {
                return sucursales_old.map((s_o, i_s_o) => {
                    if(i_s_o == id_check){
                        s_o["check"] = true
                    }
                    
                    return s_o
                })
            })
        }
    },[id_check])

    return(<Item>
        <Texto>Sucursales e inventario</Texto>
        <AreaRadioButton
            onChange={e=>{
                setSucursales(sucs=>{
                    let res_sucs = sucs.map((suc, i_suc)=>{
                        if(i_suc == e?.target?.value){
                            return {
                                ...suc,
                                check: true
                            }
                        }else{
                            return {
                                ...suc,
                                check: false
                            }
                        }

                        return suc
                    })

                    return res_sucs
                })

                set_tipo_sucursal(e)
            }}
            sucursales={sucursales}
            name="sucursales"
        />
    </Item>)
}

const LogisticaEnvios = ({set_n_logistica_envios, sucursal=null, is_new}) => {
    const on_change = e => {
        set_n_logistica_envios(e)
    }

    const [logistica_envios, setLogistica] = useState([
        {
            name_form: "b_almacenamiento",
            name: "Almacenar productos en esta sucursal",
            description: "Los productos físicos, digitales y servicios se pueden almacenar en esta sucursal.",
            check: true,
        },{
            name_form: "b_preparacion",
            name: "Preparar pedidos y ventas en línea de productos físicos o artículos",
            description: "Los productos y el inventario está disponible para los canales de venta, además de las tarifas de envíos.",
            check: true
        },{
            name_form: "b_retiro",
            name: "Ofrecer retiro en esta sucursal",
            description: "Los clientes pueden retirar de manera física los productos físicos, digitales o servicios.",
            check: true
        }
    ])

    const [b_almacenamiento, set_b_almacenamiento]  = useState(null)
    const [b_retiro, set_b_retiro]                  = useState(null)
    const [b_preparacion, set_b_preparacion]        = useState(null)

    useEffect(()=>{
        if(!is_new){
            setLogistica(log_env => {
                return log_env.map(l_env => {
                    l_env.check = sucursal[l_env.name_form] ? true: false
                    if(l_env.name_form == "b_almacenamiento"){ set_b_almacenamiento(l_env.check) }
                    if(l_env.name_form == "b_retiro"){ set_b_retiro(l_env.check) }
                    if(l_env.name_form == "b_preparacion"){ set_b_preparacion(l_env.check) }
                    return l_env
                })
            })
        }else{
            set_b_almacenamiento(false)
            set_b_retiro(false)
            set_b_preparacion(false)
        }

    },[sucursal, is_new])

    return(<Item>
        <Texto>Logística y envíos</Texto>
        <Box sx={{margin: "10px", padding: "0px"}}>
            <FormGroup>
                {b_almacenamiento != null && b_preparacion != null && b_retiro != null ? logistica_envios.length > 0 ? logistica_envios.map((item, key_item)=>{
                    return(<FormControlLabel
                        onChange={on_change}
                        key={key_item}
                        control={<Checkbox
                            name={item.name_form}
                            defaultChecked={sucursal[item.name_form] ? true: false}
                            // checked={sucursal[item.name_form] ? true: false}
                        />}
                    label={item.name}
                />)}):null: null}
            </FormGroup>
        </Box>
    </Item>)
}

const NombreSucursal = ({ set_s_nombre, s_nombre="", tipo_sucursal=0}) => {
    const LabelSuc = styled('p')({
        margin: "3px",
        fontFamily: "semibold",
        fontSize: "12px",
        paddingLeft: "2px",
        paddingTop: "2px !important",
        width: "max-content"
    })

    const sx_p = {
        display: "flex",
        alignItems: "center",
        paddingRight: "5px",
        paddingLeft: "10px",
        margin: "2px"
    }

    const [labels_suc, setLabels] = useState([
        (<p style={sx_p}><Icon sx={{marginBottom: "2px !important"}} name="Warehouse"/><LabelSuc>Almacén:</LabelSuc></p>),
        (<p style={sx_p}><Icon sx={{marginBottom: "2px !important"}} name="Fullfillment"/><LabelSuc>Centro logístico:</LabelSuc></p>),
        (<p style={sx_p}><Icon sx={{marginBottom: "2px !important"}} name="Point"/><LabelSuc>Punto de venta:</LabelSuc></p>),
        (<p style={sx_p}><Icon sx={{marginBottom: "2px !important"}} name="Office"/><LabelSuc>Oficina central:</LabelSuc></p>),
        (<p style={sx_p}><Icon sx={{marginBottom: "2px !important"}} name="Location"/><LabelSuc>Otros:</LabelSuc></p>)
    ])
    const [tipo, setTipo] = useState("Almacén")

    useEffect(()=>{

    },[tipo_sucursal])

    return(<Item>
        <Texto>Nombre de la sucursal</Texto>
        <Texto sx={{
            color: "#626262",
            fontSize: "10px",
            fontFamily: "'semibold'"
        }}>
            Asigna un nombre corto para identificarla fácilmente en algunas secciones
            como productos, inventarios, órdenes de compra, así como reportes y estadísticas.
            Si esta sucursal es un punto de venta, oficina central o una ubicación que ofrece
            retiro físico, será visible para tus clientes en la pantalla de pago y notificaciones.
        </Texto>
        <div style={{
            width: "100%",
            display: "flex",
            border: "1px solid #DAD9D5",
            borderRadius: "8px",
            marginBottom: "12px"

        }}>
            {labels_suc[tipo_sucursal]}
            <input
                type="text" 
                value={s_nombre}
                onChange={set_s_nombre}
                style={{
                    width: "100%",
                    padding: "5px",
                    fontSize: "12px",
                    fontFamily: "semibold",
                    border: "none",
                    outline: "none",
                    background: "transparent"
                }}
            />
        </div>
    </Item>)
}

const SucursalPredeterminada = ({setPredeterminada = e => console.log(e), predeterminada}) => {
    useEffect(()=>{
        console.log(predeterminada)
    },[predeterminada])

    return(<Item>
        <Texto>Sucursal predeterminada</Texto>
        <FormControlLabel
            sx={{marginBottom: "10px"}}
            onChange={setPredeterminada}
            control={<Checkbox
                checked={predeterminada}
                // defaultChecked={predeterminada}
            />}
            label="Activar esta opción si la sucursal seleccionada es la predeterminada para visualizarse."
        />
    </Item>)
}

const Botones = ({nuevo, editar_sucursal, guardar_sucursal, mt = "25px", form, retroceder = () => console.log("back")}) => {
    const modulesContext = useContext(moduleContext);
    const { moduloActual, module } = modulesContext;
    const seleccionarModulo = async id => moduloActual(id);

    return(<Box sx={{
        display: "flex",
        justifyContent: "end",
        alignContent: "center",
        alignItems: "center",
        marginTop: mt,
        minHeight: "40px"
    }}>
        <BotonJCs onClick={()=>{seleccionarModulo(ID_PRODUCTOS_DEV)}} sx={{display: "grid", alignContent: "center", marginRight: "5px", height:"25px"}}>Cancelar</BotonJCs>
        <BotonJCs onClick={()=>{
            if(nuevo){
                guardar_sucursal(form)
            }else{
                editar_sucursal(form, form._id)
            }
            retroceder()
            // if(!nuevo){ 
            // }else{ console.log("edit")}
        }} sx={{display: "grid", alignContent: "center", height:"25px"}}>Guardar</BotonJCs>
    </Box>)
}

const Titulo = ({texto = "Nueva Sucursal", retroceder}) => {
    return(<Box sx={{
        display: "flex",
        justifyContent: "start",
        alignContent: "center",
        maxHeight: "40px",
        minHeight: "40px"
    }}>
        <BotonAtras
            sx={{alignSelf: "center", cursor: "pointer"}}
            onClick={()=>{
                retroceder()
            }}
        ><ArrowBackIosNew className="arrow-letter"/></BotonAtras>
        <Box sx={{
            position: "relative",
            display: "grid",
            alignItems: "center",
        }}>
            <Texto sx={{
                marginLeft: "15px",
                fontSize: "20px",
                fontFamily: "mulish-extra-bold",
                width: "max-content",
                position: "absolute",
                margin: "0px",
                left: "15px",
                width: "410px",
                height: texto?.length > 41 ? "-webkit-fill-available": "auto",
                fontSizeAdjust: texto?.length > 41 ? "0.45": "0.5",
                lineHeight: 1
            }}>{texto}</Texto>
        </Box>
    </Box>)
}

const Row = styled(Box)({
    display: "flex", 
    justifyContent: "center"
})

const Campo = styled("input")({
    display: "flex", 
    justifyContent: "center",
    width: "100%",
    borderRadius: "8px",
    height: "28px",
    border: "1px solid #DAD9D5",
    fontSize: "12px",
    outline: "none",
    padding: "5px 10px"
})

const Item = styled(Box)({
    background: "white", 
    padding: "2px 15px !important", 
    borderRadius: "8px",
    marginTop: "15px",
    boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.1) !important",
    "& span":{
        fontSize: "12px !important",
        fontFamily: "semibold"
    },
    "& svg": {
        width: "15px !important"
    },
    "& label": {
        height: "25px"
    }
})

const Col = styled(Box)(props=>({
    width: props?.w ? props.w : 100, 
    marginRight: 20
}))

const Texto = styled('p')(props => ({
    fontSize: 12,
    fontFamily: props?.family ? props.family: "mulish-bold"
}))

const BotonAtras = styled('div')({
    background: "#626262",
    display: "grid",
    alignContent: "center",
    justifyContent: "center",
    width: "33px",
    height: "25px",
    borderRadius: "8px",
    "& .arrow-letter":{
        color: "white !important",
        fontSize: "12px"
    }
})

const Detalle = ({setDetalle, sucursal=false}) => {
    const sucursalesContext = useContext(SucursalesContext);
    const { guardar_sucursal, editar_sucursal } = sucursalesContext;

    const [is_new, setIsNew]    = useState(true)
    const [form, setForm]       = useState({
        b_almacenamiento: 0,
        b_preparacion: 0,
        b_retiro: 0
    })

    useEffect(()=>{
        const route = localStorage.getItem('id_route');
        setForm({
            ...form,
            id_negocio: route
        })
    },[])

    useEffect(()=>{
        if(sucursal){
            setForm(sucursal)
            setIsNew(false)
        }
    },[sucursal])

    const set_field = (value, name) => {
        setForm({
            ...form,
            [name]: value
        })
    }

    return(<Box>
        <Row>
            <Col w={321}>
                <Titulo retroceder={setDetalle} texto={is_new ? "Nueva Sucursal": sucursal.s_nombre}/>                
            </Col>
            <Col w={321}>
                <Botones
                    nuevo={is_new}
                    retroceder={setDetalle}
                    guardar_sucursal={guardar_sucursal}
                    editar_sucursal={editar_sucursal}
                    form={form} mt="0px"
                />
            </Col>
        </Row>
        <Row>
            <Col w={655}>
                <TipoSucursales id_check={form?.n_tipo || form.n_tipo == 0 ? form.n_tipo: null} set_tipo_sucursal={e=>{
                    setForm({
                        ...form,
                        n_tipo: e.currentTarget.value
                    })
                }}/>
                <NombreSucursal
                    tipo_sucursal={form?.n_tipo ? form.n_tipo: 0}
                    s_nombre={form?.s_nombre ? form.s_nombre: ""}
                    set_s_nombre={e=>setForm({...form, s_nombre: e.target.value})}
                />
                <SucursalPredeterminada
                    predeterminada={form?.b_predeterminada ? true: false}
                    setPredeterminada={e=> {
                        setForm({...form, b_predeterminada: e.target.checked})
                    }}
                />
                <Direccion
                    set_s_pais          = {value=>set_field(value, "s_pais")}
                    set_s_calle_num     = {e=>set_field(e.target.value, "s_calle_num")}
                    set_s_apartamento   = {e=>set_field(e.target.value, "s_apartamento")}
                    set_s_colonia       = {e=>set_field(e.target.value, "s_colonia")}
                    set_s_codigo_postal = {e=>set_field(e.target.value, "s_codigo_postal")}
                    set_s_ciudad        = {e=>set_field(e.target.value, "s_ciudad")}
                    set_s_estado        = {e=>set_field(e.target.value, "s_estado")}
                    set_s_telefono      = {e=>set_field(e.target.value, "s_telefono")}
                    set_n_lada          = {e=>set_field(e.target.value, "n_lada")}
                    data                = {{
                        s_pais:             form?.s_pais ? form.s_pais: "",
                        s_calle_num:        form?.s_calle_num ? form.s_calle_num: "",
                        s_apartamento:      form?.s_apartamento ? form.s_apartamento: "",
                        s_colonia:          form?.s_colonia ? form.s_colonia: "",
                        s_codigo_postal:    form?.s_codigo_postal ? form.s_codigo_postal: "",
                        s_ciudad:           form?.s_ciudad ? form.s_ciudad: "",
                        s_estado:           form?.s_estado ? form.s_estado: "",
                        s_telefono:         form?.s_telefono ? form.s_telefono: "",
                        n_lada:             form?.n_lada ? form.n_lada: "",
                    }}
                />
                <LogisticaEnvios
                    set_n_logistica_envios={e=>{
                        setForm({
                            ...form,
                            [e.target.name]: e.currentTarget.checked ? 1: 0
                        })
                    }}
                    is_new={is_new}
                    sucursal={sucursal}
                />
                <Botones
                    nuevo={is_new}
                    retroceder={setDetalle}
                    guardar_sucursal={guardar_sucursal}
                    editar_sucursal={editar_sucursal}
                    form={form} mt="20px"
                />
                {/* <Botones/> */}
            </Col>
        </Row>
    </Box>)
}

export default Detalle;
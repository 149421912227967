export const isObjectEmpty = (objectName) => {
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
};

export const transferKeysImp = async (data = []) => {
  let array_data = []
  const heds_keys = {
    // "Gift Card": "b_gift_card",
    "Handle":"s_handle",
    "Product Category": "s_product_category",
    // Published: "b_published",
    Tags:"s_tags",
    Title:"s_title",
    Type:"s_type",
    "Variant Fulfillment Service":"s_variant_fulfillment_service",
    "Variant Grams":"n_variant_grams",
    "Variant Inventory Policy":"s_variant_inventory_policy",
    "Variant Inventory Tracker":"s_variant_inventory_tracker",
    "Variant Price":"n_variant_price",
    "Variant SKU":"s_variant_sku",
    // "Variant Taxable": "b_variant_taxable",
    // "Variant Requires Shipping": "b_variant_requires_shipping",
    "Variant Weight Unit":"n_variant_weight_unit",
    Vendor:"s_vendor",
    "Variant Inventory Qty":"n_variant_inventory_qty",
    // Status:"draft",    
  }


  if(data?.length > 0){
    let result_map_array = await data.map(producto => {
      let array_new_convert = {}
      let validate_keys_data = isObjectEmpty(producto);
      let keys_producto = Object.keys(producto);
      if(validate_keys_data == false){
        keys_producto.map(key_data => {
          let key_name = heds_keys[key_data]
          let key_value = producto[key_data]
  
          if(key_name){
            array_new_convert[key_name] = key_value
            return key_name
          }
        })

      }
      
      return array_new_convert
    })
    
    return result_map_array
  }
}
/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useContext, useEffect} from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import phonesContext from '../../context/phones/phonesContext';

const Phones = props => {
    const phoneContext = useContext(phonesContext);
    const { phones, obtenerTelefonos } = phoneContext;

    useEffect(()=>{
        obtenerTelefonos()
    },[])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [columns, saveColumns] = useState([
        { id: 'number', label: 'Number', minWidth: 170, align: 'right'},
        { id: 'isSaved', label: 'Guardado', minWidth: 100 },
        { id: 'contact', label: 'Contacto', minWidth: 170},
        { id: 'company', label: 'Compañía', minWidth: 170},
        { id: 'createAt', label: 'creado', minWidth: 170, align: 'right'}
    ])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    console.log(phones)
    return (
        <Box className="contenedor-template">
            <Paper sx={{
                width: '100%',
                overflow: 'hidden',
                backgroundColor: 'transparent',
                color: 'white',
                boxShadow: 'none !important'
            }}>
                <TableContainer className='scrollTable' sx={{ height: "100vh", maxHeight: 480 }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                sx={{
                                    color: 'white',
                                    borderBottom: 'none'
                                }}
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {phones
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                    <TableCell sx={{
                                        color: '#b9bcc5',
                                        borderBottom: '1px solid #4b5368'
                                    }} key={column.id} align={column.align}>
                                    {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={phones.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
        
    )
}

export default Phones;

/* eslint-disable import/no-anonymous-default-export */
import { 
    SELECCIONAR_VARIANTE,
    SELECCIONAR_COMBINACIONES
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case SELECCIONAR_VARIANTE:
            return{
                ...state,
                id_variante: action.payload
            }
        case SELECCIONAR_COMBINACIONES:
            return{
                ...state,
                grupo: action.payload
            }
        default:
            return state
    }
}
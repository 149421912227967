import { useEffect, useState } from "react";

export const epochToDate = async (props) => {
    try{
        let {epochTime, format = "dd-mm-yyyy", separator="-"} = await props
        let fecha_str = new Date(epochTime);
        const yyyy = fecha_str.getFullYear();
        let mm = fecha_str.getMonth() + 1; // Months start at 0!
        let dd = fecha_str.getDate();
        
    
        switch(format){
            case "mm-dd-yyyy":
                return `${mm}${separator}${dd}${separator}${yyyy}`
            default:
                return `${dd}${separator}${mm}${separator}${yyyy}`
        }
    }catch(err){
        console.log(err)
        return false
    }
}

export const useContainerDimensions = myRef => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  
    useEffect(() => {
      const getDimensions = () => ({
        width:  myRef?.current?.offsetWidth ? myRef.current.offsetWidth: 0,
        height: myRef?.current?.offsetHeight ? myRef.current.offsetHeight: 0
      })
  
      const handleResize = () => {
        setDimensions(getDimensions())
      }
  
      if (myRef.current) {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])
  
    return dimensions;
};

export const convertMonths = mm => {
  switch(mm){
    case 1:
      return "Enero"
    case 2:
      return "Febrero"
    case 3:
      return "Marzo"
    case 4:
      return "Abril"
    case 5:
      return "Mayo"
    case 6:
      return "Junio"
    case 7:
      return "Julio"
    case 8:
      return "Agosto"
    case 9:
      return "Septiembre"
    case 10:
      return "Octubre"
    case 11:
      return "Noviembre"
    case 12:
      return "Diciembre"
    default: 
      return "Enero"
  }
}
import React from 'react';
import {
    ChevronLeft,
    ChevronRight,
    Inbox,
    Mail,
    WhatsApp,
    Dashboard,
    QuestionAnswer,
    Facebook,
    Instagram,
    Send,
    MoreVert,
    Search,
    AttachFile,
    Image,
    SentimentSatisfied,
    MenuOpen,
    RecentActors,
    FolderOutlined,
    FolderCopyOutlined,
    ContactPhone,
    Widgets,
    Camera,
    CurrencyExchange,
    Groups3,
    Storefront,
    LocalShipping,
    WifiChannel,
    Leaderboard,
    FlightTakeoff,
    SupportAgent,
    Loyalty,
    Home,
    MilitaryTech,
    ViewInAr,
    WaterfallChart,
    AutoGraph
} from '@mui/icons-material';
import icon_analytics_dark_gray_1 from '../../../img/icons/Analytics-Dark-Gray-1.png'
import icon_analytics_dark_gray_2 from '../../../img/icons/Analytics-Dark-Gray-2.png'
import icon_analytics_whure_1 from '../../../img/icons/Analytics-White-1.png'
import icon_channels_dark_1 from '../../../img/icons/Channels-Dark-1.png'
import icon_channels_dark_gray_1 from '../../../img/icons/Channels-Dark-Gray-1.png'
import icon_channels_white_1 from '../../../img/icons/Channels-White-1.png'
import icon_chat_white_1 from '../../../img/icons/Chat-White-1.png'
import icon_chat_white_2 from '../../../img/icons/Chat-White-2.png'
import icon_chat_white_3 from '../../../img/icons/Chat-White-3.png'
import icon_configuration_dark_gray_1 from '../../../img/icons/Configuration-Dark-Gray-1.png'
import icon_configuration_white_1 from '../../../img/icons/Configurations-White-1.png'
import icon_customers_dark_gray_1 from '../../../img/icons/Customers-Dark-Gray-1.png'
import icon_customers_dark_gray_2 from '../../../img/icons/Customers-Dark-Gray-2.png'
import icon_customers_white_1 from '../../../img/icons/Customers-White-1.png'
import icon_filer_options_dark_gray_1 from '../../../img/icons/Filer-Options-Dark-Gray-1.png'
import icon_filer_options_dark_gray_2 from '../../../img/icons/Filer-Options-Dark-Gray-2.png'
import icon_finances_dark_gray_1 from '../../../img/icons/Finances-Dark-Gray-1.png'
import icon_finances_dark_gray_2 from '../../../img/icons/Finances-Dark-Gray-2.png'
import icon_finances_dark_gray_3 from '../../../img/icons/Finances-Dark-Gray-3.png'
import icon_finances_white_1 from '../../../img/icons/Finances-White-1.png'
import icon_help_blue_1 from '../../../img/icons/Help-Blue-1.png'
import icon_help_dark_gray_1 from '../../../img/icons/Help-Dark-Gray-1.png'
import icon_help_white_3 from '../../../img/icons/Help-White-3.png'
import icon_inicio_home_dark_1 from '../../../img/icons/Inicio-Home-Dark-1.png'
import icon_inicio_home_dark_gray_1 from '../../../img/icons/Inicio-Home-Dark-Gray-1.png'
import icon_inicio_home_outline from '../../../img/icons/Inicio-Home-Outline.png'
import icon_inicio_home_white from '../../../img/icons/Inicio-Home-White.png'
import icon_logistics_dark_1 from '../../../img/icons/Logistics-Dark-1.png'
import icon_logistics_dark_gray_1 from '../../../img/icons/Logistics-Dark-Gray-1.png'
import icon_logistics_dark_gray_2 from '../../../img/icons/Logistics-Dark-Gray-2.png'
import icon_logistics_white_1 from '../../../img/icons/Logistics-White-1.png'
import icon_loyalty_dark_gray_1 from '../../../img/icons/Loyalty-Dark-Gray-1.png'
import icon_loyalty_dark_gray_2 from '../../../img/icons/Loyalty-Dark-Gray-2.png'
import icon_loyalty_dark_gray_3 from '../../../img/icons/Loyalty-Dark-Gray-3.png'
import icon_loyalty_white_1 from '../../../img/icons/Loyalty-White-1.png'
import icon_notification_ring_white from '../../../img/icons/Notification-Ring-White.png'
import icon_people_dark_gray_1 from '../../../img/icons/People-Dark-Gray-1.png'
import icon_people_dark_gray_2 from '../../../img/icons/People-Dark-Gray-2.png'
import icon_people_dark_gray_3 from '../../../img/icons/People-Dark-Gray-3.png'
import icon_people_white_1 from '../../../img/icons/People-White-1.png'
import icon_research_dark_gray_1 from '../../../img/icons/Research-Dark-Gray-1.png'
import icon_research_dark_gray_2 from '../../../img/icons/Research-Dark-Gray-2.png'
import icon_research_dark_gray_3 from '../../../img/icons/Research-Dark-Gray-3.png'
import icon_research_white_1 from '../../../img/icons/Research-White-1.png'
import icon_row_down_dark_gray from '../../../img/icons/Row-Down-Dark-Gray.png'
import icon_row_down_white from '../../../img/icons/Row-Down-White.png'
import icon_row_left_dark_gray from '../../../img/icons/Row-Left-Dark-Gray.png'
import icon_row_left_white from '../../../img/icons/Row-Left-White.png'
import icon_row_right_dark_gray from '../../../img/icons/Row-Right-Dark-Gray.png'
import icon_row_right_white from '../../../img/icons/Row-Right-White.png'
import icon_row_up_dark_gray from '../../../img/icons/Row-Up-Dark-Gray.png'
import icon_row_up_white from '../../../img/icons/Row-Up-White.png'
import icon_search_buscar_dark_gray_1 from '../../../img/icons/Search-Buscar-Dark-Gray-1.png'
import icon_search_buscar_light_gray from '../../../img/icons/Search-Buscar-Light-Gray.png'
import icon_search_buscar_white from '../../../img/icons/Search-Buscar-White.png'
import icon_shipment_dark_gray_1 from '../../../img/icons/Shipment-Dark-Gray-1.png'
import icon_warehouse from '../../../img/icons/Warehouse-Dark-Gray-1.png'

import icon_location from '../../../img/icons/Location-Dark-Gray-1.png'
import icon_office from '../../../img/icons/Office-Dark-Gray-1.png'
import icon_point from '../../../img/icons/Point-of-Sale-Dark-Gray-1.png'
import icon_fullfillment from '../../../img/icons/Fulfillment-Dark-Gray-1.png'


const Icon = ({name, sx}) =>{
    switch(name){
        case 'deals':
            return <FolderCopyOutlined sx={sx}/>;
        case 'phones':
            return <ContactPhone sx={sx}/>;
        case 'leads':
            return <FolderOutlined sx={sx}/>;
        case 'contacts':
            return <RecentActors sx={sx}/>;
        case 'AttachFile':
            return <AttachFile sx={sx}/>;
        case 'ChevronLeft':
            return <ChevronLeft sx={sx}/>;
        case 'ChevronRight':
            return <ChevronRight sx={sx}/>
        case 'Dashboard':
            return <Dashboard sx={sx}/>;
        case 'Image':
            return <Image sx={sx}/>;
        case 'Inbox':
            return <Inbox sx={sx}/>
        case 'Mail':
            return <Mail sx={sx}/>
        case 'MenuOpen':
            return <MenuOpen sx={sx}/>;
        case 'Whatsapp':
            return <WhatsApp sx={sx}/>
        case 'Facebook':
            return <Facebook sx={sx}/>
        case 'Instagram':
            return <Instagram sx={sx}/>
        case 'Conversations':
            return <QuestionAnswer sx={sx}/>
        case 'Send':
            return <Send sx={sx}/>
        case 'SentimentSatisfied':
            return <SentimentSatisfied sx={sx}/>;
        case 'Search':
            return <Search sx={sx}/>;
        case 'ThreePoints':
            return <MoreVert sx={sx}/>;
        case 'Inicio':
            // return <Home sx={sx}/>;
            return <img src={icon_inicio_home_dark_1} width="15px" height="15px"/>
        case 'Studio':
        case 'Estudio':
            return <Camera sx={sx}/>;
        case 'Financial':
        case 'Finanzas':
            return <img src={icon_finances_dark_gray_2} width="16px" height="16px"/>
        case 'Notifications':
            return <img src={icon_chat_white_2} style={sx} width="16px" height="16px"/>
            // return <CurrencyExchange sx={sx}/>;
        case 'People':
        case 'Equipo':
            return <img src={icon_people_dark_gray_1} width="16px" height="16px"/>
            // return <Groups3 sx={sx}/>;
        case 'Markets':
        case 'Tiendas':
            return <Storefront sx={sx}/>;
        case 'Logistica':
        case 'Logistics':
            // return <ViewInAr sx={sx}/>;
            return <img src={icon_logistics_dark_1} width="16px" height="16px"/>
        case 'Multichannel':
            return <WifiChannel sx={sx}/>;
        case 'Analytics':
        case 'Analítica':
            return <img src={icon_analytics_dark_gray_1} width="15px" height="15px"/>
            // return <AutoGraph sx={sx}/>;
        case 'Customers':
        case 'Clientes':
            return <img src={icon_customers_dark_gray_2} width="16px" height="16px"/>
            // return <SupportAgent sx={sx}/>;
        case 'Shipment':
        case 'Envíos':
            // return <LocalShipping sx={sx}/>;
            return <img src={icon_shipment_dark_gray_1} width="16px" height="16px"/>
        case 'Loyalty':
        case 'Lealtad':
            // return <MilitaryTech sx={sx}/>;
            return <img src={icon_loyalty_dark_gray_1} width="16px" height="16px"/>
        case 'Canales':
            return <img src={icon_channels_dark_1} width="16px" height="16px"/>
        case 'SearchDark':
            return <img src={icon_search_buscar_dark_gray_1} height={sx?.height ? sx?.height: "16px"} width={sx?.width ? sx?.width: "16px"}/>
        case 'SearchLigth':
            return <img src={icon_search_buscar_light_gray} height={sx?.height ? sx?.height: "14px"} width={sx?.width ? sx?.width: "14px"}/>
        case 'SearchWhite':
            return <img src={icon_search_buscar_white} height={sx?.height ? sx?.height: "16px"} width={sx?.width ? sx?.width: "16px"}/>
        case 'Warehouse':
            return <img src={icon_warehouse} height={sx?.height ? sx?.height: "16px"} width={sx?.width ? sx?.width: "16px"}/>
        case 'Location':
            return <img src={icon_location} height={sx?.height ? sx?.height: "16px"} width={sx?.width ? sx?.width: "16px"}/>
        case 'Office':
            return <img src={icon_office} height={sx?.height ? sx?.height: "16px"} width={sx?.width ? sx?.width: "16px"}/>
        case 'Point':
            return <img src={icon_point} height={sx?.height ? sx?.height: "16px"} width={sx?.width ? sx?.width: "16px"}/>
        case 'Fullfillment':
            return <img src={icon_fullfillment} height={sx?.height ? sx?.height: "16px"} width={sx?.width ? sx?.width: "16px"}/>
        default:
            return <Inbox sx={sx}/>
    }
};

export default Icon;

import { Box, Button, ButtonGroup, Divider, Grid, InputAdornment, TextField, Typography } from "@mui/material"
import { BotonJCs } from "../../material"
import { useContext, useEffect, useRef, useState } from "react"
import Icon from "../../layout/Material/Icons"
import { M_Table } from "../../../elementos"
import Coleccion_Detalle from "./Detalle"
import { ColeccionesContext } from "../../../context"
import { useContainerDimensions } from "../../../functions"

const Colecciones = () => {
    const coleccionesContext = useContext(ColeccionesContext);
    const { obtener_colecciones, colecciones } = coleccionesContext;

    const [detalle, setDetalle] = useState(false)
    const [n_colecciones, setN_Colecciones] = useState(0)
    const [n_productos, setN_Productos] = useState(0)
    const [lista, setLista]     = useState([])

    useEffect(()=>{
        let result_productos = 0, result_colecciones = 0

        if(colecciones?.length > 0){
            result_colecciones = colecciones.length
            
            colecciones.map(coleccion => {
                if(coleccion?.a_productos?.length > 0){
                    result_productos = result_productos + coleccion.a_productos.length
                }
            })
        }

        setN_Colecciones(result_colecciones)
        setN_Productos(result_productos)

    },[colecciones])

    const MenuSeccion = () => {
        return(
            <Box className='area-modulo' sx={{borderRadius: "5px"}}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography id="titulo-modulo" sx={[{
                            paddingBottom: '0px',
                            textAlign: 'start',
                            fontWeight: "bold",
                            color: "black !important"
                        }]} variant='h6'>Colecciones</Typography>
                        <Box sx={{"& p":{color: "#626262 !important", fontSize: "12px", margin: "0px !important"}}}>
                            <Box>
                                <Typography>Organiza tus productos.</Typography> 
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{
                        textAlign: "end",
                        "& #btn-agregar":{
                            margin: "10px !important", padding: '5px 20px !important'
                        }
                    }}>
                        <AreaButton nombre_modulo="Productos"/>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    useEffect(()=>{
        obtener_colecciones()
    },[])

    useEffect(()=>{
        let result_colecciones = colecciones.map(coleccion=>{
            let label_tipo = "Manual"

            
            if(coleccion?.i_tipo == 1){
                label_tipo = "Automatizada"
            }
            
            return [
                (<p onClick={()=>{
                    setDetalle(coleccion)
                }} style={{fontSize: "12px", margin: "0px", cursor: "pointer"}}>
                    {coleccion.s_nombre}
                </p>),
                coleccion.a_productos.length,
                (<p style={{fontSize: "12px", margin: "0px"}}>{label_tipo}</p>),
                coleccion.a_mercados.length,
                coleccion.a_canales.length,
            ]
        })
        setLista(result_colecciones)
    },[colecciones])

    const AreaButton = () => {
        return(<Box sx={{
            display: "grid",
            alignContent: "start",
            justifyContent: "end"
        }}>
            <Box sx={{display: "flex"}}>
                <BotonJCs sx={{
                    marginLeft: "8px",
                    bgcolor: "#3F3F3F !important",
                    height: "fit-content"
                }} onClick={() => {
                    setDetalle(true)
                }}>Crear colección</BotonJCs>
            </Box>
        </Box>)
    }
    
    const Side_Filter = (props) => {
        let {
            n_productos     = 0,
            n_existencia    = 0,
            n_totales       = 0,
            filterFunction  = e=>console.log("filter"),
            statusFilter    = e=>console.log("filter")
        } = props

        const [btnActivado, setBtnActivado] = useState(0);
        const [search, setSearch] = useState(false);

        useEffect(()=>{
            statusFilter(btnActivado)
        }, [btnActivado])

        return(<Box>
            <Box sx={{
                marginY: "20px",
                borderRadius: "8px",
                background: "white !important",
                width: "100%",
                boxShadow: "0px 2px 5px -4px black"
            }}>
                <Grid container>
                    {search ? (<Grid item xs={10} sx={{paddingLeft: "5px", display: "flex", justifyContent: "start", alignItems: "center"}}>
                        <Box sx={{width: "100%", "& input":{
                            outline: "none !important",
                            padding: "5px 2px !important",
                            fontSize: "12px",
                        }}}>
                            <TextField
                                onChange={filterFunction}
                                placeholder="Buscando en todos los productos"
                                sx={{
                                    width: "100%",
                                    outline: "none",
                                    margin: "12px 10px",
                                    background: "#FAFAFA",
                                    fontFamily: "semibold",
                                    "& .MuiOutlinedInput-root": {
                                        fontFamily: "'semibold'",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#EBEBEB",
                                        borderWidth: "1px",
                                        },
                                    },
                                    // Class for the label of the input field
                                    "& .MuiInputLabel-outlined": {
                                        color: "#626262",
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon name="SearchLigth" sx={{height: "12px", width: "12px"}}/>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>):(<Grid item xs={10} sx={{paddingLeft: "5px", display: "flex", justifyContent: "start"}}>
                        <Box sx={{margin: "6px"}}>
                            <Typography sx={{fontSize: "12px", fontWeight: "bold"}}>
                                {n_productos}
                            </Typography>
                            <Typography sx={{
                                fontSize: "12px",
                                color: "#626262"
                            }}>
                                Colecciones
                            </Typography>
                        </Box>
                        <Box sx={{margin: "6px"}}>
                            <Typography sx={{fontSize: "12px", fontWeight: "bold"}}>{n_totales}</Typography>
                            <Typography sx={{
                                fontSize: "12px",
                                color: "#626262"
                            }}>
                                Productos
                            </Typography>
                        </Box>
                    </Grid>)}
                    <Grid item xs={2} sx={{display: "flex", justifyContent: "end"}}>
                        <Box sx={{margin: "6px", display: "flex", marginRight: "10px", alignItems: "center"}}>
                            <Box onClick={e=>{
                                    setSearch(!search)
                                }} sx={{
                                marginX: "5px",
                                background: "#EBEBEB",
                                width: "30px",
                                height: "30px",
                                display: "grid",
                                justifyContent: "center",
                                alignContent: "center",
                                borderRadius: "8px",
                                cursor: "pointer"
                            }}>
                                <Icon name="SearchDark" sx={{height: "12px", width: "12px"}}/>
                            </Box>
                            <Box sx={{
                                marginX: "5px",
                                background: "#EBEBEB",
                                width: "30px",
                                height: "30px",
                                display: "grid",
                                justifyContent: "center",
                                alignContent: "center",
                                borderRadius: "8px",
                                cursor: "pointer"
                            }}>
                                {/* <MenuComponente/> */}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Divider color="#EBEBEB"/>
                <Box sx={{
                    marginLeft: "5px",
                    "& #filtro":{fontSize: "12px !important"},
                    "& button": {
                        borderRadius: "8px !important",
                    }
                }}>
                    <ButtonGroup variant="standard" aria-label="outlined button group">
                        <Button onClick={e => setBtnActivado(0)} sx={btnActivado == 0? btnMenu: btnMenuActive}>Todos</Button>
                    </ButtonGroup>
                </Box>
            </Box> 
        </Box>)
    }

    const ref = useRef(null);
    const { width, height } = useContainerDimensions(ref)

    return(<Box>
        {detalle ? (<Box>
            <Coleccion_Detalle array={detalle == true ? null: detalle} regresar={()=>setDetalle(false)}/>
        </Box>):(<Box>
            <Box ref={ref}>
                <MenuSeccion/>
                <Side_Filter
                    n_totales={n_productos}
                    n_productos={n_colecciones}
                    filterFunction={e=>{
                        // if(dataTable.length > 0){
                        //     let result_filter = dataTable.filter(item_table => item_table.search.search(e.target.value) > -1)
                        //     setFilterTable(result_filter)
                        // }
                    }}
                    statusFilter={status=>{
                        // 0-Todos
                        // 1-Activos
                        // 2-Borradores
                        // 3-Archivados
                        // if(dataTable.length > 0){
                        //     if(status != 0){
                        //         let result_filter = dataTable.filter(item_table => item_table.status == status)
                        //         setFilterTable(result_filter)
                        //     }else{
                        //         setFilterTable(dataTable)
                        //     }
                        // }
                    }}
                />
            </Box>
            <M_Table
                hx={height}
                heads={["Nombre", "Productos", "Tipo", "Mercados", "Canales"]}
                body={lista}
            />
        </Box>)}
    </Box>)
}

export default Colecciones

const btnMenu = {
    background: "#626262",
    color: "white",
    textTransform: 'none',
    padding: "10px !important",
    margin: "5px",
    fontSize: "12px",
    fontFamily: "semibold",
    // width: "60px",
    height: "30px"
}

const btnMenuActive = {
    background: "#EBEBEB",
    color: "#303030",
    textTransform: 'none',
    padding: "10px !important",
    margin: "5px",
    fontSize: "12px",
    fontFamily: "semibold",
    // width: "60px",
    height: "30px"
}
import React, { Fragment, useState, useEffect } from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    StepButton,
    Button,
    Typography,
    Grid
} from '@mui/material';
    
const steps = [
    'Nuevo',
    'Contacto',
    'Emailing',
    'Whatsapp',
    'Videollamada',
    'Propuesta',
    'Negociación',
    'Visita',
    'Cierre',
    'Por entregar',
    'En revisión',
    'Cerrar prospecto'
];

const Flow = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});



  const stepChange = async (n) => {
    await setActiveStep(n)
  }

  return (<Grid container sx={{      
      backgroundColor: 'transparent !important',
      width: '100%',
      alignContent: 'center',
      borderBottom: '1px solid rgb(45, 55, 72)',
    }} 
    spacing={0}>
    {steps.map((label, i) => {
      const nLimit = steps.length;
      const wAdjust = 100 / nLimit
      return(
        <Box
          key={i}
          className={i === activeStep ? 'arrowText arrowRight' : ''}
          style={{
            width: `${wAdjust}%`,
            color: 'white',
            textAlign: 'center'
            }}
          >
          <Typography
            style={{
              alignSelf: 'center',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              paddingLeft: '15px',
              cursor: 'pointer'
            }}
            onClick={()=>stepChange(i)}
          >
            {label}
          </Typography>
        </Box>
      )})
    }
    </Grid>);
}

export default Flow;
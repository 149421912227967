import React, { useReducer } from 'react';
import phonesReducer from './phonesReducer';
import PhonesContext  from './phonesContext';
import clientAxios from '../../config/axios';
import { 
    OBTENER_PHONES
} from '../../types'

const PhonesState = props => {
    const initialState = {
        phones: []
    }
    const [state, dispatch] = useReducer(phonesReducer, initialState)

    const obtenerTelefonos = async () => {
        try{
            const res = await clientAxios.get('/api/phones')
            dispatch({
                type: OBTENER_PHONES, 
                payload: res.data.phones
            })
        } catch(err){
            console.log(err)
        }
    }

    const guardarTelefono = async _id => {
        try{
            const res = await clientAxios.post('/api/phones/isSaved', {_id: _id})
            console.log(res)
            dispatch({
                type: OBTENER_PHONES, 
                payload: res.data.phones
            })
        } catch(err){
            console.log(err)
        }
    }
    
    return(
        <PhonesContext.Provider value={{
            phones: state.phones,
            obtenerTelefonos,
            guardarTelefono
        }}>
            {props.children}
        </PhonesContext.Provider>
    )
}

export default PhonesState;

/* eslint-disable import/no-anonymous-default-export */
import React, {useState, useContext, useEffect} from 'react';
import convertDate from '../layout/Material/Date'
import contactsContext from '../../context/contacts/contactsContext';
import phonesContext from '../../context/phones/phonesContext';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';


const Contacts = props => {
    const contactContext = useContext(contactsContext);
    const { contactos, obtenerContactos } = contactContext;

    const phoneContext = useContext(phonesContext);
    const { phones, obtenerTelefonos } = phoneContext;

    useEffect(()=>{
        obtenerContactos()
        obtenerTelefonos()
    },[])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [columns, saveColumns] = useState([
        { id: 'name', label: 'Nombre', minWidth: 170},
        { id: 'lastName', label: 'Apellido', minWidth: 100 },
        { id: 'email', label: 'Email', minWidth: 170},
        { id: 'phoneId', label: 'Telefóno', minWidth: 170},
        { id: 'createAt', label: 'creado', minWidth: 170, align: 'right'}
    ])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Box className="contenedor-template" sx={{
            height: '86%',
        }}>
            <Paper sx={{
                width: '100%',
                overflow: 'hidden',
                backgroundColor: 'transparent',
                color: 'white',
                boxShadow: 'none !important',
                borderRadius: "0px",
                border: '1px solid rgb(45, 55, 72) !important'
            }}>
                <TableContainer className='scrollTable' sx={{ height: "100vh", maxHeight: 480 }}>
                    <Table sx={{
                        borderRadius: '0px'
                    }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                sx={{
                                    color: 'white',
                                    borderBottom: 'none'
                                }}
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contactos
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map(column => {
                                const value = row[column.id];
                                let phone = []
                                if(value){
                                    // console.log(value)
                                    phone = phones.filter(phone => phone._id === value)
                                    // console.log(phone[0])
                                }else{phone = []}
                                return (
                                    <TableCell sx={{
                                        color: '#b9bcc5',
                                        borderBottom: '1px solid #4b5368'
                                    }} key={column.id} align={column.align}>
                                    {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : column.id === 'createAt' ? convertDate(value) 
                                        : column.id === 'phoneId' && phone.length > 0 ? 
                                            phone[0].number
                                        :value
                                    }
                                    </TableCell>
                                );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={contactos.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
        
    )
}

export default Contacts;

import { Box, Grid, Modal, Typography, styled } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from "react"

const Boton = styled('button')({
    background: "#EBEBEB !important",
    border: "none",
    cursor: "pointer",
    margin: "5px",
    borderRadius: "8px",
    padding: "8px 15px",
    fontSize: "12px",
    fontFamily: "semibold"
})


const Sucursal = ({datos=[], open=false, setOpen = () => console.log(open), save}) => {
    const on_click = e => { setOpen(true) }
    const handleClose = e => { setOpen(false) }

    return (<Box>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Grid container sx={{
                    background: "#ebebeb",
                    borderRadius: "8px 8px 0px 0px"
                }}>
                    <Grid xs={10}>
                        <Typography sx={{
                            fontSize: "14px",
                            fontFamily: "mulish-bold",
                            color: "black",
                            padding: "5px 15px",
                        }}>
                            Sucursales
                        </Typography>
                    </Grid>
                    <Grid xs={2} sx={{
                        justifyContent: "end",
                        display: "grid",
                        alignContent: "center"
                    }}>
                        <CloseIcon sx={{
                            fontSize: "18px",
                            marginRight: "6px",
                            cursor: "pointer"
                        }} onClick={handleClose}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={6}>
                        <Typography sx={{
                            marginLeft: "15px",
                            marginTop: "8px",
                            fontSize: "12px",
                            color: "#377edd",
                            cursor: "pointer"
                        }}>
                            Seleccionar todo
                        </Typography>
                    </Grid>
                    <Grid xs={6} sx={{
                        justifyContent: "end",
                        display: "grid",
                        alignContent: "center"
                    }}>
                        
                    </Grid>
                </Grid>
                <Box sx={{
                    margin: "10px 15px"
                }}>
                    <Tabla
                        encabezados={["Nombre", "Almacenar"]} 
                        datos={datos}
                    />
                </Box>
                <Box sx={{
                    margin: "0px 10px 10px 0px",
                    display: "flex",
                    justifyContent: "end"
                }}>
                    <Boton onClick={handleClose}>Cancelar</Boton>
                    <Boton onClick={save} sx={{
                        background:"#3f3f3f !important",
                        color: "white"
                    }}>Guardar</Boton>
                </Box>
            </Box>
        </Modal>
        <Boton onClick={on_click} sx={{marginBottom: "15px"}}>+ Agregar sucursal</Boton>
    </Box>)
}

const TablaEstilo       = styled('table')({
    width: "100%",
    margin:"10px 0px"
})
const ColumnaTabla      = styled('tr')({
    "& td": { color: "#626262 !important"},
    "& p": { margin: "0px !important" },

})
const CeldaTabla        = styled('td')({
    fontSize: "12px",
    fontFamily: "semibold"
})
const EncabezadoTabla   = styled('th')({
    fontSize: "12px",
    fontFamily: "semibold"
})

const Tabla = ({tabla_sx={}, col_enc_sx={}, encabezados = [], datos = [], encabezados_sx = {}}) =>{
    return(<TablaEstilo sx={tabla_sx}>
        {/* <ColumnaTabla sx={col_enc_sx}>
            {encabezados.length > 0 ? encabezados.map((encabezado, i) => (
                <EncabezadoTabla sx={[encabezados_sx, {textAlign: i == 0 ? "left": "center"}]}>{encabezado}</EncabezadoTabla>
            )): null}
        </ColumnaTabla> */}
        {datos.length > 0 ? datos.map(columnas => {
            return(<ColumnaTabla>
                {columnas.length > 0 ? columnas.map((columna,i ) => (<CeldaTabla sx={{textAlign: "left"}}>
                    {columna}
                </CeldaTabla>)): null}
            </ColumnaTabla>)
        }): null}
    </TablaEstilo>)
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "46%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "8px",
    minWidth: "600px"
};

export default Sucursal
import { createContext } from 'react';

export const RutasContext       = createContext();
export const VariantesContext   = createContext();
export const ProductosContext   = createContext();
export const SucursalesContext  = createContext();
export const LoadingContext     = createContext();
export const SideleftContext    = createContext();
export const ColeccionesContext = createContext();
export const LogsContext        = createContext();

import React, { useReducer } from 'react';
import inventarioReducer from './inventarioReducer';
import InventarioContext  from './inventarioContext';
import clientAxios from '../../config/axios';
import { 
    GUARDAR_INVENTARIO,
    OBTENER_INVENTARIO_PRODUCTOS,
    OBTENER_PRODUCTOS,
    ELIMINAR_INVENTARIO,
    ABRIR_MODAL,
    AGREGAR_DATA_BTN,
    GUARDAR_PRODUCTO,
    MODIFICAR_INVENTARIO,
    TOTAL_INVENTARIO,
    EXISTENCIAS_INVENTARIO,
    TOTAL_VARIANTES,
    OBTENER_INVENTARIO_DETALLE
} from '../../types'

const InventarioState = props => {
    const initialState = {
        inventario:             [],
        productos:              [],
        total:                  0,
        n_variantes:            0,
        existencias:            0,
        inventario_modificado:  [],
        producto:               [],
        datos:                  [],
        modalView:              false,
        dataBtnAgregar:         [],
        inventario_productos:   [],
        inventario_detalle:     []
    };
    
    const [state, dispatch] = useReducer(inventarioReducer, initialState)

    const guardarInventario = async nuevoInventario => {
        console.log("Nueva existencia")
        try{
            const res = await clientAxios.post('/api/inventory', nuevoInventario);
            if(res.data.success){
                dispatch({
                    type: GUARDAR_INVENTARIO, 
                    payload: [...state.inventario, ...nuevoInventario]
                })
            } else {
                return res.data
            }
        }catch(err){
            // window.location.href = '/login';
            console.log(err)
        }
    }

    const guardarProducto = async nuevoProducto => {
        try{
            let id_negocio = localStorage.getItem("id_route")
            let productoNegocio = {
                ...nuevoProducto,
                id_negocio: id_negocio ? id_negocio: 12
            }

            const res = await clientAxios.post('/api/productos/nuevo-producto', productoNegocio);

            if(res.data.success && res.data.producto){
                dispatch({
                    type: GUARDAR_PRODUCTO, 
                    payload: res.data.producto
                })

                let productoGuardado = res.data.producto
                if(!productoGuardado?.b_variantes){
                    guardarInventario([{
                        id_producto:        productoGuardado._id,
                        s_name:             productoGuardado.s_title,
                        id_negocio: id_negocio ? id_negocio: "12",
                        i_status :          1,
                        i_entrante:         0,
                        i_markets :         0,
                        i_sales_channels:   0,
                        i_stock :           0
                    }])
                }

                return res.data.producto
            } else {
                return res.data
            }
        }catch(err){
            // window.location.href = '/login';
            console.log(err)
        }
    }
    
    const guardarProductos = async nuevoProductos => {
        try{
            let id_negocio = localStorage.getItem('id_route')

            let nuevoProductosNegocio = nuevoProductos.map(p=>{
                let array = {};
                if(p.i_status == "active"){
                    array = {
                        ...p,
                        i_status: 2
                    }
                }
                return {
                    ...array,
                    id_negocio: id_negocio
                }
            })

            const res = await clientAxios.post('/api/productos/agregar-productos', nuevoProductosNegocio);

            console.log(res)
            if(res.data.success){

                // dispatch({
                    // type: GUARDAR_PRODUCTOS, 
                    // payload: nuevoProducto
                // })
            } else {
                return res.data
            }
        }catch(err){
            // window.location.href = '/login';
            console.log(err)
        }
    }

    const obtenerInventario = async (id_sucursal) => {
        try{
            let id_negocio = localStorage.getItem("id_route");
            const res = await clientAxios.post('/api/inventory/obtener-existencias', {id_negocio: id_negocio, id_sucursal: id_sucursal});
            let data = res.data, inventario = data.inventory, totales = data.totales
            
            let inventario_ = inventario.map(producto_inv => {
                let inventario_result = producto_inv
                if(producto_inv?.variante?.length > 0){
                    let producto_validado = producto_inv.variante
                    inventario_result = {
                        ...inventario_result,
                        ...producto_validado[0],
                        is_variant: true
                    }
                }else{
                    if(producto_inv?.producto?.length > 0){
                        let producto_validado = producto_inv.producto
                        inventario_result = {
                            ...inventario_result,
                            ...producto_validado[0],
                            is_variant: false
                        }
                    }
                }                
                return inventario_result 
            })

            if(data.success){
                dispatch({
                    type: OBTENER_INVENTARIO_PRODUCTOS,
                    payload: inventario_
                })

                dispatch({
                    type: TOTAL_INVENTARIO,
                    payload: totales.total
                })

                dispatch({
                    type: EXISTENCIAS_INVENTARIO,
                    payload: totales.existencias
                })
            }
        } catch(err){
            // window.location.href = '/login';
            console.log(err)
        }
    }

    const obtenerInventarioDetalle = async (id_producto, producto) => {
        try{
            const res = await clientAxios.get(`/api/inventory/existencias/${id_producto}`);            
            let data = res.data
            if(data.success){
                dispatch({
                    type: OBTENER_INVENTARIO_DETALLE,
                    payload: data.inventory
                })
            }
        } catch(err){
            // window.location.href = '/login';
            console.log(err)
        }
    }

    const obtenerInventarioDetalleVariante = async (id_producto, producto) => {
        try{
            const res = await clientAxios.get(`/api/inventory/existencias/${id_producto}`);            
            let data = res.data
            console.log(data)
            if(data.success){
                if(data?.inventory?.length > 0){
                    dispatch({
                        type: OBTENER_INVENTARIO_DETALLE,
                        payload: data.inventory
                    })
                }else{
                    const res = await clientAxios.post('/api/inventory/new_variant', {...producto, id_producto: id_producto});
                    let inventario_nuevo = res?.data?.inventario ? res.data.inventario: null

                    if(inventario_nuevo){
                        dispatch({
                            type: OBTENER_INVENTARIO_DETALLE,
                            payload: [inventario_nuevo]
                        })
                    }
                }
            }
        } catch(err){
            // window.location.href = '/login';
            console.log(err)
        }
    }

    const obtenerProductos = async () => {
        try{
            let id_negocio = localStorage.getItem("id_route")
            const res = await clientAxios.post('/api/productos/obtener-productos', {id_negocio: id_negocio});
            let data = res.data;
            
            if(data.success){
                dispatch({
                    type: OBTENER_PRODUCTOS,
                    payload: data.productos
                })

                let productos_totales = await data.productos;
                if(productos_totales?.length > 0){
                    console.log(productos_totales)

                    dispatch({
                        type: TOTAL_VARIANTES,
                        payload: data.n_variantes
                    })
                }
            }
        } catch(err){
            // window.location.href = '/login';
            console.log(err)
        }
    }
    
    const eliminarDeInventario = async id => {
        try{
            let inventario = state.datos; 
            let resultFull = state.datos;
            resultFull = resultFull.filter((product, i) => i === id);

            let _id = resultFull[0]._id;
            const res = await clientAxios.post('/api/inventory/delete', {_id});
            if(res.data.success){
                dispatch({
                    type: ELIMINAR_INVENTARIO, 
                    payload: inventario.filter((product, i) => i !== id)
                })
            }else {
                return res.data
            }
        }catch(err){
            // window.location.href = '/login';
            console.log(err);
        }
    }

    const eliminarDeProductos = async id => {
        try{
            console.log(id)
            // let _id = resultFull[0]._id;
            // const res = await clientAxios.post('/api/productos/delete-producto', {_id});
            // if(res.data.success){
            //     dispatch({
            //         type: ELIMINAR_INVENTARIO, 
            //         payload: inventario.filter((product, i) => i !== id)
            //     })
            // }else {
            //     return res.data
            // }
        }catch(err){
            // window.location.href = '/login';
            console.log(err);
        }
    }

    const abrirModal = async (estatus, data = null) => {
        dispatch({
            type: ABRIR_MODAL,
            payload: estatus
        })
        if(data){
            dispatch({
                type: AGREGAR_DATA_BTN,
                payload: data
            })
        }
    }
 
    const modificar_inventario = async cambios => {
        try{
            console.log(cambios)
            const res = await clientAxios.post('/api/inventory/modificar-existencia', cambios);

            console.log(res)

            // let inventario_ = await state.inventario_modificado,
            // existe = inventario_.filter(producto => producto._id == cambios._id),
            // resto = inventario_.filter(producto => producto._id != cambios._id)
    
            // if(existe.length > 0){
            //     let mod = {
            //         ...existe[0],
            //         ...cambios
            //     }

            //     if(mod.i_stock == mod.i_stock__ && mod.i_entrante == mod.i_entrante__){
            //         return dispatch({
            //             type: MODIFICAR_INVENTARIO,
            //             payload: resto
            //         })
            //     }

            //     dispatch({
            //         type: MODIFICAR_INVENTARIO,
            //         payload: [
            //             ...resto,
            //             mod
            //         ]
            //     })
            // }else{
            //     dispatch({
            //         type: MODIFICAR_INVENTARIO,
            //         payload: [
            //             ...resto,
            //             cambios
            //         ]
            //     })
            // }
            
        } catch(err){
            // window.location.href = '/login';
            console.log(err)
        }
    }

    const actualizar_inventario = async cambios => {
        const res = await clientAxios.post('/api/inventory/modificar-existencia', cambios);
        if(res?.data?.success){
            dispatch({
                type: MODIFICAR_INVENTARIO,
                payload: []
            })
            return true
        }else{
            return false
        }
    }

    return(
        <InventarioContext.Provider value={{
            inventario: state.inventario,
            producto: state.producto,
            inventario_productos: state.inventario_productos,
            modalView: state.modalView,
            dataBtnAgregar: state.dataBtnAgregar,
            productos: state.productos,
            total: state.total,
            n_variantes: state.n_variantes,
            existencias: state.existencias,
            inventario_modificado: state.inventario_modificado,
            inventario_detalle: state.inventario_detalle,
            obtenerInventarioDetalleVariante,
            abrirModal,
            guardarInventario,
            obtenerInventarioDetalle,
            guardarProducto,
            obtenerInventario,
            eliminarDeInventario,
            obtenerProductos,
            actualizar_inventario,
            modificar_inventario,
            eliminarDeProductos,
            guardarProductos
        }}>
            {props.children}
        </InventarioContext.Provider>
    )
}

export default InventarioState;

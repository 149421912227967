/* eslint-disable import/no-anonymous-default-export */
import { 
    GUARDAR_CONVERSACIONES,
    OBTENER_CONVERSACIONES,
    SELECCIONAR_CONTACTO,
    ULTIMA_CONVERSACION,
    ENVIAR_MENSAJE,
    GUARDAR_MENSAJE
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case ENVIAR_MENSAJE:
            return {
                ...state,
                message: action.payload
            }
        case GUARDAR_CONVERSACIONES:
            return {
                ...state,
                conversations: action.payload
            }
        case GUARDAR_MENSAJE:
            return {
                ...state,
                conversations: [...state.conversations, action.payload]
            }
        case OBTENER_CONVERSACIONES:
            return {
                ...state,
                conversations: action.payload
            }
        case SELECCIONAR_CONTACTO:
            return {
                ...state,
                contactSelected: action.payload
            }
        case ULTIMA_CONVERSACION:
            return {
                ...state,
                lastConversation: action.payload
            }
        default:
            return state
    }
}
import React, { useReducer } from 'react';
import moduleContext from './moduleContext';
import moduleReducer from './moduleReducer';
import clientAxios from '../../config/axios'
import { 
    FORMULARIO_MODULO, 
    OBTENER_MODULOS,
    AGREGAR_MODULO,
    VALIDAR_FORMULARIO,
    MODULO_ACTUAL,
    ELIMINAR_MODULO,
    ABRIR_MODULO,
    GUARDAR_TEMA,
    NUEVO_PRODUCTO
} from '../../types'

const ModuleState = props => {
    const initialState = {
        modules:[],
        formulario: false,
        errorFormulario: false,
        openDrawer: "admin",
        module: null
    }

    const [state, dispatch] = useReducer(moduleReducer, initialState)

    const mostrarFormulario = () => {
        dispatch({
            type: FORMULARIO_MODULO
        })
    }

    const obtenerModulos = async ()  => {
        try{
            const res = await clientAxios.get('/api/module')
            dispatch({
                type: OBTENER_MODULOS,
                payload: res.data.modules
            })
        } catch(err){
            window.location.href = '/login'
            console.log(err)
        }
    }

    const abrirModulo = async modulo => {
        try{
            dispatch({
                type: ABRIR_MODULO,
                payload: modulo
            })
        } catch(err){ console.log(err)}
    }

    const agregarModulo = async modulo => {
        try{
            const res = await clientAxios.post('/api/modulos', modulo)

            dispatch({
                type: AGREGAR_MODULO,
                payload: res.data
            })
        }catch(err){
            console.log(err)
        }
    }

    const mostrarError = () => {
        dispatch({
            type: VALIDAR_FORMULARIO
        })
    }

    const nuevoProducto = async () => {
        let modulo_productos = process.env.REACT_APP_ENTORNO == "DESARROLLO"? process.env.REACT_APP_LOCAL_PRODUCTOS: process.env.REACT_APP_PROD_PRODUCTOS
        moduloActual(modulo_productos)    // DEV
        
        // moduloActual("65a71a07d5cbf094eee6f930")    // PROD

        dispatch({
            type: NUEVO_PRODUCTO,
            payload: true
        })
    }

    const verSucursales = async () => {
        let modulo_productos = process.env.REACT_APP_ENTORNO == "DESARROLLO"? process.env.REACT_APP_LOCAL_SUCURSALES: process.env.REACT_APP_PROD_SUCURSALES
        moduloActual(modulo_productos)
    }

    // selecciona el modulo en el que el usuario dio clic
    const moduloActual = id => {
        dispatch({
            type: MODULO_ACTUAL,
            payload: id
        })
    }

    const eliminarModulo = async id =>{
        try{
            const res = await clientAxios.delete(`http://localhost:4000/api/modulos/${id}`)
            dispatch({
                type: ELIMINAR_MODULO,
                payload: id
            })
        }catch(err){
            console.log(err)
        }
    } 

    return(
        <moduleContext.Provider
            value={{
                modules: state.modules,
                openDrawer: state.openDrawer,
                formulario: state.formulario,
                errorFormulario: state.errorFormulario,
                module: state.module,
                mostrarFormulario,
                obtenerModulos,
                agregarModulo,
                mostrarError,
                abrirModulo,
                moduloActual,
                verSucursales,
                eliminarModulo,
                nuevoProducto
            }}
        >
            {props.children}
        </moduleContext.Provider>
    )
}

export default ModuleState 
import { Box, Button, CssBaseline, Grid, TextField, Typography, Modal } from "@mui/material";
import React, { useState } from "react";
import SideLeft from './SideLeft'
import ToolbarMui from "../layout/Toolbar";
import ToolbarMui_Secondary from "../layout/Toolbar_Secondary";
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { useContext } from "react";
import stylesContext from "../../context/styles/stylesContext";
import AuthContext from "../../context/authentication/authContext";
import SettingsContext from "../../context/settings/settingsContext";
import { useEffect } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import moduleContext from "../../context/modules/moduleContext";
import { SideleftContext } from "../../context";

const drawerMaxWidth = 60;
const drawerMinWidth = 220;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: (open ? drawerMinWidth : drawerMaxWidth),
        // width: `calc(100% - ${(open ? drawerMinWidth : drawerMaxWidth)}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const MenuStatic = ({children}) => {
    const modulesContext = useContext(moduleContext);
    const { modules,  obtenerModulos } = modulesContext;
    // const settingContext = useContext(settingsContext);
    // const { settings, options, getSettings, setOptions } = settingContext;
    
    const styleContext = useContext(stylesContext);
    const { styles, getStyles } = styleContext;

    useEffect(()=>{obtenerModulos()},[])

    const [colorSelect, setColorSelect] = useState(null);
    const [color, setColor] = useColor("hex", "#121212");
    const [themeSelecto, setThemeSelecto] = useState(0);

    const sideleftContext = useContext(SideleftContext);
    const { minimizar, open_menu } = sideleftContext;
    
    const settingContext = useContext(SettingsContext);
    const { settingThemes, viewSettings, options, setOptions } = settingContext;

    const authToken = useContext(AuthContext);
    const { usuario, modificarTema } = authToken;
    
    const [open, setOpen] = useState(true);
    const [openTheme, setOpenTheme] = useState(false);
    
    const [settingData, setSetting] = useState({})

    useEffect(()=>{
        if(usuario?.theme) setThemeSelecto(usuario?.theme)
    },[usuario])

    useEffect(()=>{getStyles(options)},[options])
    useEffect(()=>{ 
        setSetting(styles)
    },[styles])
    
    const handleClose = () => setOpenTheme(false);
    const handleOpen = async (name, value) => {
        setOpenTheme(true)
        if(value){
            setColorSelect({name, value})
            await setColor({
                ...color,
                hex:`${value}`
            })
        }
    };

    const onChangeColor = async colorChange => {
        setColor(colorChange)
        if(colorChange.hex !== colorSelect.value){
            await setSetting({
                ...settingData,
                [colorSelect.name]: colorChange.hex
            })
        }
    }

    const onSave = async () => {
        if(settingThemes){
            options.map(option =>{
                if(settingData[option.name] !== option.value){
                    setOptions({
                        name: `${option.name}`,
                        value: `${settingData[option.name]}`
                    })
                }
            })
        }

    }

    useEffect(()=>{
        if(document.getElementById('container-sideleft')){
            document.getElementById('container-sideleft').classList.add(!viewSettings ? 'dark-theme': 'light-theme');
            document.getElementById('container-sideleft').classList.remove(!viewSettings ? 'light-theme': 'dark-theme');
        }

        if(document.getElementById('main-sideleft')){
            document.getElementById('main-sideleft').classList.add(!viewSettings ? 'dark-theme': 'light-theme');
            document.getElementById('main-sideleft').classList.remove(!viewSettings ? 'light-theme': 'dark-theme');
        }
    },[viewSettings])

    return(<Box sx={{ display: 'flex', background: "#f1f1f1 !important" }}>
        <Modal
            open={openTheme}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    p: 4
            }}>
                {colorSelect ? (
                    <ColorPicker
                        width={456}
                        height={228}
                        color={color}
                        id='colorPickerTest'
                        onChange={e => onChangeColor(e)}
                        hideHSV dark 
                    />
                ):null}
            </Box>
        </Modal>
        <CssBaseline />
        <AppBar position='fixed' style={{boxShadow: 'none', width: "100% !important"}} open={open} 
            sx={{
                // display: open_menu ? "none": "block",
                width: "100% !important",
                // width: { sm: `calc(100% - ${(open ? drawerMinWidth : drawerMaxWidth)}px)` },
                ml: { sm: `${(open ? drawerMinWidth : drawerMaxWidth)}px` },
        }}>
            {styles ? (<Box id="bar_menu_contenedor">
                <ToolbarMui
                    usuario={usuario}
                    sty={styles}
                    position="fixed"
                    setOpen={setOpen}
                    open={open}
                    modificarTema={modificarTema}
                />
            </Box>): null}
        </AppBar>
        <SideLeft setOpen={setOpen} open={open} modulos={modules}/>
        {styles && usuario ? (<Box component="main" 
            id="main-sideleft"
            sx={{ 
                flexGrow: 1,
                p: 3, 
                border: "0px !important",
                padding: 0,
                height: 'auto',
                minHeight: "97vh",
                background: "transparent !important",
                width: {sm: `calc(100% - ${(open ? drawerMinWidth : drawerMaxWidth)}px)`}
            }}>
            <DrawerHeader sx={{margin: "0px !important"}}/>
            <Box id="container-sideleft" 
                sx={{
                    padding: "10px 30px 0px 30px",
                    border: "0px !important",
                    background: "transparent !important",
                    "& p, h6, h5, tr, th, td, span, div, svg":{ color: viewSettings ? "black !important": "white !important"},
                }}>
                {children}
            </Box>
        </Box>): null}
        <SideRight wx={'50%'} open={settingThemes} animation='start-slide' time='2s'>
            <Typography variant='h5' sx={{
                color: 'gray',
                textAlign: 'center',
                marginBottom: '2vh'
            }}>Ajuste de colores</Typography>
            {settingThemes ? options.map((option, key_grid_settings) => {
                return(<Grid key={key_grid_settings} sx={{paddingY: 1}} container spacing={2}>
                    <Grid item xs={6}>
                        <Typography>{option.name}:</Typography>
                    </Grid>
                    <Grid sx={{display: 'flex'}} item xs={6}>
                        <TextField
                            disabled = {option.name !== 'logo' ? true: false} 
                            id='btn-settings'
                            key={option._id}
                            name={option.name}
                            value={settingData[`${option.name}`]}
                            onChange={e=> setSetting({
                                ...settingData,
                                [e.target.name]: e.target.value
                            })}
                            variant="filled"
                            fullWidth
                            margin='none'
                            inputProps={{
                                padding: 0
                            }}
                        ></TextField>
                        {option.name==='logo'? null: (
                            <Button
                                sx={{
                                    background: `${option.value}`,
                                    width: '30px',
                                    height: '30px',
                                }}
                                onClick={()=>handleOpen(option.name, option.value)}>
                            </Button>
                        )}
                    </Grid>
                </Grid>)
            }): null}
            <Button sx={{
                position: 'absolute',
                right: 0,
                margin: '10px',
                bottom: 0
            }} onClick={onSave}>Guardar</Button>
        </SideRight>
    </Box>)
}

const SideRight = styled(Box)`
    padding: 10vh 2.5vw;
    width: ${props => props.wx ? props.wx : null};
    display: ${props => props.open ? 'block' : 'none !important'};
    animation-name: ${props => props.animation ? props.animation : 'test'};
    animation-duration: ${props => props.time ? props.time : '2s'};
`
export default MenuStatic;                                                      